import { ComponentType, useCallback, useEffect, useState } from "react";
import { RequestOptions, useRequest } from "../context/RequestContext";


export interface WithDataProps<T> {
    data: T;
    request: {
        endpoint: string;
        onError?: () => void;
        options?: Partial<RequestOptions>;
    }
}

const withData = <T, P extends WithDataProps<T>>(
    WrappedComponent: ComponentType<P>,
): React.FC<Omit<P, 'data'>> => {
    return ({ request: requestProps, ...props }: Omit<P, 'data'>) => {
        const request = useRequest();
        const [data, setData] = useState<T | null>(null);

        const fetch = useCallback((requestProps: P['request']) => {
            setData(null);
            request.get<T>(requestProps.endpoint, requestProps.options)
                .then(setData)
                .catch(() => {
                    if (requestProps.onError) requestProps.onError();
                });
        }, [request]);

        useEffect(() => {
            fetch(requestProps);
        }, [requestProps]);

        if (!data) return null;

        return <WrappedComponent {...(props as P)} data={data} />
    };
}

export default withData;