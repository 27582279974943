export enum Colors {
    PRIMARY = "primary",
    SECONDARY = "secondary",
    TERTIARY = "tertiary",
    ACCENT = "accent",
    SUCCESS = "success",
    WARNING = "warning",
    PRISTINE = "pristine",
    ERROR = "error",
    CRITICAL = "critical",
    GREY = "grey",
    WHITE = "white",
    BLACK = "black",
    PALETTE1 = "palette1",
    PALETTE2 = "palette2",
    PALETTE3 = "palette3",
    PALETTE4 = "palette4",
    PALETTE5 = "palette5",
    PALETTE6 = "palette6",
    PALETTE7 = "palette7",
    PALETTE8 = "palette8",
    PALETTE9 = "palette9",
    PALETTE10 = "palette10",
}

export enum ToastType {
    Success = "SUCCESS",
    Warning = "WARNING",
    Error = "ERROR",
}
