import { FormEvent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useRequest, useUiContext } from '../../../context';
import { Form } from '../../../form';
import { useForm } from '../../../hooks';
import { NewPassword } from '../../../models/auth';
import { Button } from '../../../ui';
import Info from '../../../ui/Info';
import { Colors } from '../../../ui/enums';
import { requestErrorToToast } from '../../../utils/format';
import NewPasswordForm, { PASSWORD_VALIDATION } from '../../Account/components/NewPasswordForm';
import PortalPromoWrapper from './PortalPromoWrapper';

const ConfirmRecovery = () => {
    const { t } = useTranslation();
    const [searchParams] = useSearchParams();
    const { addToast } = useUiContext();
    const { validate, attachInput, entity } = useForm<NewPassword>({}, PASSWORD_VALIDATION);
    const request = useRequest();
    const [token, setToken] = useState<string>();
    const navigate = useNavigate();
    const [message, setMessage] = useState<string | null>(null);

    const handleSubmit = useCallback((e?: FormEvent) => validate((entity) => {
        e?.preventDefault();
        if (request.loading || entity.password !== entity.passwordVerify) return;

        request.post('/auth/confirm-recovery', { password: entity.password, token }, {
            loader: true,
        })
            .then(() => navigate('/auth?message=success.password_set'))
            .catch(e => {
                const toastError = requestErrorToToast(e as Error);

                if (toastError.i18n === 'auth') {
                    setMessage(toastError.message);
                } else {
                    addToast(toastError);
                }
            });
    }), [validate, addToast, request, token]);

    useEffect(() => {
        if (searchParams.get('np_tk')) {
            setToken(searchParams.get('np_tk')!);
        } else {
            navigate('/');
        }
    }, []);

    return (
        <PortalPromoWrapper>
            <Form onSubmit={handleSubmit} loading={request.loading}>
                <Info label="confirm_recovery_password_tooltip" i18n="auth" noIcon />
                {!!message && <Info type={message.startsWith('success') ? 'success' : 'warning'} label={message} i18n="auth" />}
                <NewPasswordForm<NewPassword> attachInput={attachInput} entity={entity} />
                <div className="col col-double-gap col-vertical-padding">
                    <Button color={Colors.ACCENT} i18n="actions" label="submit" type="submit" loading={request.loading} />
                    <Link to='/' className="center">{t('auth:back_to_login')}</Link>
                </div>
            </Form>
        </PortalPromoWrapper>
    )
}

export default ConfirmRecovery;
