import { ReactNode } from 'react';
import { conditionnalClassnames } from '../../utils/helpers';
import { TagProps } from '../Tag';
import ValueDescription from '../ValueDescription';
import './index.scss';

interface ListItemProps {
    icon?: ReactNode;
    tag?: TagProps;
    value?: string;
    description?: string;
    onClick?: () => void;
    children?: ReactNode;
    preline?: boolean;
    active?: boolean;
}

const ListItem = ({ icon, tag, value, description, onClick, children, preline, active }: ListItemProps) => (
    <div
        className={conditionnalClassnames({
            "list-item": true,
            pointer: !!onClick,
            active
        })}
        onClick={onClick ? (e) => { e.stopPropagation(); onClick(); } : undefined}
    >
        {icon}
        <ValueDescription value={value} description={description} tag={tag} preline={preline} />
        {children}
    </div>
);

export default ListItem;