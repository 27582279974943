// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input[input-type=tags] > .input-field {
  border: none;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: -moz-fit-content;
  height: fit-content;
}
.input[input-type=tags] > .input-field > div {
  flex-grow: 1;
}`, "",{"version":3,"sources":["webpack://./src/sg-react/form/TagsInput/index.scss"],"names":[],"mappings":"AACI;EACI,YAAA;EACA,6BAAA;EACA,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,wBAAA;EAAA,mBAAA;AAAR;AAEQ;EACI,YAAA;AAAZ","sourcesContent":[".input[input-type=\"tags\"] {\n    & > .input-field {\n        border: none;\n        background-color: transparent;\n        display: flex;\n        align-items: center;\n        justify-content: space-between;\n        height: fit-content;\n\n        & > div {\n            flex-grow: 1;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
