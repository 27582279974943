// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tags-picker .tag-component {
  justify-content: space-between;
}`, "",{"version":3,"sources":["webpack://./src/sg-react/data/TagsPicker/index.scss"],"names":[],"mappings":"AACI;EACI,8BAAA;AAAR","sourcesContent":[".tags-picker {\n    & .tag-component {\n        justify-content: space-between;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
