import { useRouteError } from "react-router-dom";
import './index.scss';
import { useTranslation } from "react-i18next";

const RouteError = () => {
    const error = useRouteError();
    const { t } = useTranslation();

    return (
        <div id="error-page">
            <h1>{t('message:error.page_not_found')}</h1>
            <p>{t('message:error.page_not_found_description')}</p>
        </div>
    );
}

export default RouteError;