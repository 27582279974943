import { ComponentType, useState } from "react";
import ModalPassword from "../auth/ModalPassword";

export interface WithPasswordCheckProps {
    token: string;
    onClose: () => void;
}

const withPasswordCheck = <P extends WithPasswordCheckProps>(
    WrappedComponent: ComponentType<P>
): React.FC<Omit<P, 'token'>> => {
    return (props: Omit<P, 'token'>) => {
        const [token, setToken] = useState<string | null>(null);

        if (!token) return <ModalPassword onToken={setToken} onClose={props.onClose} />;

        return <WrappedComponent {...props as P} token={token} />
    };
}

export default withPasswordCheck;