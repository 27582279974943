import { Fragment, useCallback, useMemo, useState } from "react";
import { useRequest, useWorkspaceContext } from "../../context";
import { Checkbox, ColorPicker, TextArea, TextField } from "../../form";
import { useForm } from "../../hooks";
import { EditIcon, MenuIcon, TrashIcon } from "../../icons";
import { WORKSPACE_TAG_VALIDATION, WorkspaceRole, WorkspaceTag } from "../../models/workspace";
import { Button, Modal } from "../../ui";
import DropdownMenu from "../../ui/DropdownMenu";
import Tag from "../../ui/Tag";
import { joinOptionnalStrings } from "../../utils/format";
import { toggleInArray } from "../../utils/objects";
import DataComponentList from "../DataComponentList";
import ModalDelete from "../ModalDelete";
import './index.scss';

interface TagComponentProps {
    tag: WorkspaceTag;
    selected: boolean;
    onToggle: () => void;
    onEdit?: (tag: WorkspaceTag) => void;
    onDelete?: (tag: WorkspaceTag) => void;
}

const TagComponent = ({ tag, onEdit, onToggle, onDelete, selected }: TagComponentProps) => {
    return (
        <div className="row tag-component">
            <div className="row">
                <Checkbox id={tag._id} value={selected} onChange={onToggle} />
                <Tag label={tag.name} color={tag.color} />
            </div>
            {(onEdit && onDelete) && (
                <DropdownMenu disposition="left" items={[
                    { itemKey: 'edit', icon: <EditIcon />, i18n: "actions", label: "edit", onClick: () => onEdit(tag) },
                    { itemKey: 'delete', icon: <TrashIcon />, i18n: "actions", label: "delete", onClick: () => onDelete(tag) }
                ]}>
                    <Button color="navigation" icon={<MenuIcon />} />
                </DropdownMenu>
            )}
        </div>
    )
}

interface TagsPickerModalProps {
    selected?: string[];
    onClose: () => void;
    onSubmit: (tagsIds: string[]) => void;
    category: string;
}

export const TagsPickerModal = ({ onClose, onSubmit, category, selected }: TagsPickerModalProps) => {
    const [selectedTags, setSelectedTags] = useState<string[]>(selected ?? []);

    const handleSubmit = useCallback(() => {
        onSubmit(selectedTags);
        onClose();
    }, [selectedTags, onSubmit, onClose]);

    return (
        <Modal
            className="tags-picker"
            size="small"
            i18n="ui"
            title="tags"
            onClose={onClose}
            onSubmit={handleSubmit}
            overflow="visible"
        >
            <TagsPicker onSelect={setSelectedTags} selected={selectedTags} category={category} />
        </Modal>
    )
}

interface TagsPickerProps {
    selected?: string[];
    onSelect: (s: string[]) => void;
    category: string;
}

const TagsPicker = ({ category, selected, onSelect }: TagsPickerProps) => {
    const [tagToDelete, setTagToDelete] = useState<WorkspaceTag | null>(null);
    const { currentWorkspace, refreshWorkspaces } = useWorkspaceContext();
    const { entity, reset, attachInput, validate, onChange } = useForm<WorkspaceTag>({}, WORKSPACE_TAG_VALIDATION);
    const request = useRequest();

    const availableTags = useMemo(() => currentWorkspace.tags?.filter(t => t.category === category), [currentWorkspace, category]);

    const createOrUpdate = useCallback(() => validate((entity) => {
        const requestMethod = !entity._id ? request.post : request.put;

        requestMethod(`/workspaces/tag`, entity, {
            loader: true,
            withWorkspace: true,
            successMessage: true,
            errorMessage: true,
        })
            .then(() => {
                refreshWorkspaces();
                reset({});
            })
            .catch(() => null);
    }), [request, validate, reset, refreshWorkspaces]);

    return (
        <Fragment>
            <DataComponentList
                component={(props: { entity: WorkspaceTag }) => (
                    <TagComponent
                        tag={props.entity}
                        selected={!!selected?.includes(props.entity._id)}
                        onToggle={() => onSelect(toggleInArray(selected, props.entity._id))}
                        onDelete={currentWorkspace.role !== WorkspaceRole.User ? setTagToDelete : undefined}
                        onEdit={reset}
                    />
                )}
                data={availableTags}
                primaryKey="_id"
                onSearch={(t, k) => joinOptionnalStrings([t.name, t.description]).toLowerCase().includes(k.toLowerCase())}
                onAdd={() => reset({ category })}
                sort={[{ field: 'name', i18n: 'entity', label: 'name' }]}
                simple
            />
            {!!entity.category && (
                <Modal
                    size="small"
                    i18n="actions"
                    title={entity._id ? 'edit' : 'create'}
                    onClose={() => reset({})}
                    onSubmit={createOrUpdate}
                    overflow="visible"
                >
                    <TextField label i18n="entity" {...attachInput('name')} />
                    <TextArea label i18n="entity" rows={5} {...attachInput('description')} />
                    <ColorPicker label i18n="entity" {...attachInput('color')} />
                </Modal>
            )}
            {!!tagToDelete && <ModalDelete onClose={() => setTagToDelete(null)} onSubmit={() => console.log(tagToDelete)} />}

        </Fragment>
    )
}

export default TagsPicker;