import Authentication from "./components/Authentication";
import Profile from "./components/Profile";
import Settings from "./components/Settings";
import Workspaces from "./components/Workspaces";
import './index.scss';

const Account = () => {
    return (
        <div id="account" className="layout-container row row-layout">
            <div className="col col-layout col-lg-70">
                <Profile />
            </div>
            <div className="col col-layout col-lg-30">
                <Workspaces />
                <Authentication />
                <Settings />
            </div>
        </div>
    )
}

export default Account;