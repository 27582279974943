import Validation, {
    ObjectValidation,
} from "../sg-react/form/Validation/class";
import {
    NAMED_ENTITY_VALIDATION,
    NamedEntity,
} from "../sg-react/models/entity";
import { WorkspaceTag } from "../sg-react/models/workspace";

export interface Incident extends NamedEntity {
    workspaceId: string;
    criticity: number;
    tagsIds: string[];
    tags?: WorkspaceTag[];
}

export const INCIDENT_VALIDATION = new ObjectValidation<Incident>({
    tagsIds: Validation.array(Validation.string().required().isMongoId()),
    criticity: Validation.number().required().integer().positive(),
}).merge(NAMED_ENTITY_VALIDATION);
