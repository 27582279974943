// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fake-input {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
  height: var(--theme-input-height);
  padding: var(--theme-input-padding);
  border-radius: var(--theme-input-border-radius);
  border: var(--theme-input-border-color) var(--theme-input-border-width) solid;
  background-color: var(--theme-input-background-color);
  transition: var(--theme-input-transition);
  font-size: var(--theme-input-font-size);
  font-family: var(--theme-input-font-family);
  color: var(--theme-input-font-color);
  letter-spacing: var(--theme-input-letter-spacing);
  box-shadow: var(--theme-input-box-shadow);
  overflow: hidden;
  width: 100%;
}
.fake-input:hover {
  background-color: var(--theme-input-hover-background-color);
  background-color: var(--theme-input-hover-background-color);
  border: var(--theme-input-hover-border-color) var(--theme-input-hover-border-width) solid;
  box-shadow: var(--theme-input-hover-box-shadow-focus);
  color: var(--theme-input-hover-font-color);
}
.fake-input > span {
  flex-grow: 1;
  flex-shrink: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.fake-input > svg {
  width: 16px;
  height: 16px;
  fill: var(--theme-color-grey-medium);
}`, "",{"version":3,"sources":["webpack://./src/sg-react/ui/FakeInput/index.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,QAAA;EACA,iCAAA;EACA,mCAAA;EACA,+CAAA;EACA,6EAAA;EAEA,qDAAA;EAEA,yCAAA;EACA,uCAAA;EACA,2CAAA;EACA,oCAAA;EACA,iDAAA;EACA,yCAAA;EACA,gBAAA;EACA,WAAA;AAAJ;AAEI;EACI,2DAAA;EACA,2DAAA;EACA,yFAAA;EAEA,qDAAA;EACA,0CAAA;AADR;AAII;EACI,YAAA;EACA,cAAA;EACA,mBAAA;EACA,gBAAA;EACA,uBAAA;AAFR;AAKI;EACI,WAAA;EACA,YAAA;EACA,oCAAA;AAHR","sourcesContent":[".fake-input {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    gap: 6px;\n    height: var(--theme-input-height);\n    padding: var(--theme-input-padding);\n    border-radius: var(--theme-input-border-radius);\n    border: var(--theme-input-border-color) var(--theme-input-border-width)\n        solid;\n    background-color: var(--theme-input-background-color);\n    -webkit-transition: var(--theme-input-transition);\n    transition: var(--theme-input-transition);\n    font-size: var(--theme-input-font-size);\n    font-family: var(--theme-input-font-family);\n    color: var(--theme-input-font-color);\n    letter-spacing: var(--theme-input-letter-spacing);\n    box-shadow: var(--theme-input-box-shadow);\n    overflow: hidden;\n    width: 100%;\n\n    &:hover {\n        background-color: var(--theme-input-hover-background-color);\n        background-color: var(--theme-input-hover-background-color);\n        border: var(--theme-input-hover-border-color)\n            var(--theme-input-hover-border-width) solid;\n        box-shadow: var(--theme-input-hover-box-shadow-focus);\n        color: var(--theme-input-hover-font-color);\n    }\n\n    & > span {\n        flex-grow: 1;\n        flex-shrink: 1;\n        white-space: nowrap;\n        overflow: hidden;\n        text-overflow: ellipsis;\n    }\n\n    & > svg {\n        width: 16px;\n        height: 16px;\n        fill: var(--theme-color-grey-medium);\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
