import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DirectoryItemViewPatchProps } from '..';
import { DirectoryContact } from '../../../models/directory-contact';
import { DirectoryItem } from '../../../models/directory-item';
import { useRequest } from '../../../sg-react/context';
import { ModalDelete } from '../../../sg-react/data';
import DataComponentList from '../../../sg-react/data/DataComponentList';
import { EditIcon, EmailIcon, MenuIcon, PhoneIcon, TrashIcon } from '../../../sg-react/icons';
import { Button, Card, Modal, UserItem } from '../../../sg-react/ui';
import DropdownMenu from '../../../sg-react/ui/DropdownMenu';
import IconValue from '../../../sg-react/ui/IconValue';
import Info from '../../../sg-react/ui/Info';
import { joinOptionnalStrings } from '../../../sg-react/utils/format';
import DirectoryContactForm from './ContactForm';

interface ContactItemProps {
    entity: DirectoryContact;
    directoryItem: DirectoryItem;
    canEdit: boolean;
    onViewInfos: (c: DirectoryContact) => void;
    onEdit: (c: DirectoryContact) => void;
    onDelete: (c: DirectoryContact) => void;
}

const ContactItemComponent = ({ entity, canEdit, onViewInfos, onEdit, onDelete }: ContactItemProps) => {
    const { t } = useTranslation();
    return (
        <div className="row pointer" onClick={() => onViewInfos(entity)}>
            {entity.user
                ? (
                    <UserItem user={entity.user} />
                ) : (
                    <UserItem user={{ ...entity.contact, field: entity.contact?.service ? t('contacts:enums.services.' + entity.contact.service) : undefined }} />
                )}
            {canEdit && (
                <DropdownMenu disposition="left" items={[
                    ...(!entity.userId ? [{ itemKey: 'edit', icon: <EditIcon />, i18n: "actions", label: "edit", onClick: () => onEdit(entity) }] : []),
                    { itemKey: 'delete', icon: <TrashIcon />, i18n: "actions", label: "delete", onClick: () => onDelete(entity) },
                ]}>
                    <Button color="navigation" icon={<MenuIcon />} />
                </DropdownMenu>
            )}
        </div>
    )
}

const Contacts = ({ directoryItem, directoryData, refresh, canEdit }: DirectoryItemViewPatchProps) => {
    const { t } = useTranslation();
    const request = useRequest();
    const [contactToEdit, setContactToEdit] = useState<Partial<DirectoryContact> | null>(null);
    const [contactToDelete, setContactToDelete] = useState<DirectoryContact | null>(null);
    const [contact, setContact] = useState<DirectoryContact | null>(null);

    const getContactInfo = useCallback((contact: DirectoryContact) => {
        request.get<DirectoryContact>(`/directory-data/${directoryItem._id}/contact/${contact._id}`, {
            withWorkspace: true,
            errorMessage: true
        })
            .then(setContact)
            .catch(() => setContact(null));
    }, [request, directoryItem]);

    const createOrUpdateContact = useCallback(async (entity: Partial<DirectoryContact>) => {
        const requestMethod = !entity._id ? request.post : request.put;

        requestMethod(`/directory-data/${directoryItem._id}/contact`, entity, {
            loader: true,
            withWorkspace: true,
            successMessage: true,
            errorMessage: true,
        })
            .then(() => {
                setContactToEdit(null);
                refresh();
            })
            .catch(() => null);
    }, [request, directoryItem, refresh]);

    const deleteContact = useCallback((contact: DirectoryContact) => {
        request.delete(`/directory-data/${directoryItem._id}/contact/${contact._id}`, {
            loader: true,
            withWorkspace: true,
            successMessage: true,
            errorMessage: true,
        })
            .then(() => {
                setContactToDelete(null);
                refresh();
            })
            .catch(() => null);
    }, [request, directoryItem, refresh]);

    return (
        <Card
            id="directory-item-view-contacts"
            title="contacts"
            i18n="contacts"
        >
            <DataComponentList
                component={(props: { entity: DirectoryContact }) => <ContactItemComponent directoryItem={directoryItem} entity={props.entity} onViewInfos={getContactInfo} canEdit={canEdit} onDelete={setContactToDelete} onEdit={setContactToEdit} />}
                data={directoryData.contacts}
                primaryKey="_id"
                onSearch={(c, k) => (c.user?.fullName ?? joinOptionnalStrings([c.contact?.firstName, c.contact?.lastName], ' '))?.toLowerCase().includes(k.toLowerCase())}
                onAdd={canEdit ? () => setContactToEdit({ internal: true }) : undefined}
            />
            {contactToEdit && <DirectoryContactForm contact={contactToEdit} onClose={() => setContactToEdit(null)} onSubmit={createOrUpdateContact} />}
            {contact && (
                <Modal
                    size="small"
                    title={contact.user ? contact.user.fullName : joinOptionnalStrings([contact.contact?.firstName, contact.contact?.lastName], ' ')}
                    onClose={() => setContact(null)}
                >
                    <Info type="error" label="view_contact_infos_warning" i18n="contacts" />
                    {contact.user ? (
                        <div className="col">
                            <IconValue icon={<EmailIcon />} value={contact.user.email} />
                            <IconValue icon={<PhoneIcon />} value={contact.user.phone} />
                        </div>
                    ) : (
                        <div className="col">
                            <IconValue icon={<EmailIcon />} value={contact.contact?.email} />
                            <IconValue icon={<PhoneIcon />} value={contact.contact?.phone} />
                        </div>
                    )}
                </Modal>
            )}
            {!!contactToDelete && <ModalDelete onClose={() => setContactToDelete(null)} onSubmit={() => deleteContact(contactToDelete)} />}
        </Card>
    )
}
export default Contacts;