import { DirectoryItem } from "../../models/directory-item";
import { EntitySearch } from "../../sg-react/data";

interface DirectoryItemSearchProps {
    global?: boolean;
    multiple?: boolean;
    onSubmit: (selected: DirectoryItem[]) => void;
    onClose: () => void;
}

const DirectoryItemSearch = ({ global, multiple, onClose, onSubmit }: DirectoryItemSearchProps) => {
    return (
        <EntitySearch<DirectoryItem>
            endpoint={`/directory/search/${global ? 'global' : ''}`}
            onClose={onClose}
            onSubmit={onSubmit}
            multiple={multiple}
            primaryKey="_id"
            columns={[
                { field: 'name', label: 'name', i18n: 'entity' },
                { field: 'location.city', label: 'city', i18n: 'location' },
                { field: 'location.country', label: 'country', i18n: 'location' },
            ]}
            sort={{ field: "name", direction: 1 }}
        />
    )
}
export default DirectoryItemSearch;