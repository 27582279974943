import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { WorkspaceSettingsFormProps } from "..";
import { useAuthContext, useRequest } from "../../../sg-react/context";
import { useWorkspaceContext } from "../../../sg-react/context/WorkspaceContext";
import DataComponentList from "../../../sg-react/data/DataComponentList";
import { Select, TextField } from "../../../sg-react/form";
import { useForm } from "../../../sg-react/hooks";
import { MenuIcon, TrashIcon } from "../../../sg-react/icons";
import { WORKSPACE_INVITE_VALIDATION, Workspace, WorkspaceRole, WorkspaceUser } from "../../../sg-react/models/workspace";
import { Button, Modal } from "../../../sg-react/ui";
import DropdownMenu from "../../../sg-react/ui/DropdownMenu";
import Info from "../../../sg-react/ui/Info";
import ValueDescription from "../../../sg-react/ui/ValueDescription";
import { joinOptionnalStrings, translateEnum } from "../../../sg-react/utils/format";

interface WorkspaceInviteProps {
    onClose: () => void;
    roles: { key: WorkspaceRole, label: string }[];
}

const WorkspaceInvite = ({ onClose, roles }: WorkspaceInviteProps) => {
    const { validate, attachInput } = useForm<{ email: string, role: WorkspaceRole }>({ role: WorkspaceRole.User }, WORKSPACE_INVITE_VALIDATION);
    const request = useRequest();

    const handleInvite = useCallback(() => validate((entity) => {
        request.post(`/workspaces/invite`, entity, {
            loader: true,
            withWorkspace: true,
            successMessage: { i18n: 'workspaces', message: 'success.invite_sent' },
            errorMessage: true
        })
            .then(onClose)
            .catch(() => null);
    }), [request, validate, onClose]);

    return (
        <Modal
            size="small"
            i18n="workspaces"
            title="invite"
            onClose={onClose}
            onSubmit={handleInvite}
        >
            <Info noIcon label="invite_tooltip" i18n="workspaces" />
            <div className="form-inline-group">
                <TextField inline label i18n="users" {...attachInput('email')} />
                <Select inline label items={roles} i18n="users" {...attachInput('role')} />
            </div>
        </Modal>
    )
}

interface WorkspaceUserComponentProps {
    entity: WorkspaceUser;
    roles: { key: WorkspaceRole, label: string }[];
    onRole: (r?: WorkspaceRole) => void;
    onRemove: (wu: WorkspaceUser) => void;
}

const WorkspaceUserComponent = ({ entity, onRole, onRemove, roles }: WorkspaceUserComponentProps) => {
    const { currentWorkspace } = useWorkspaceContext();
    const { currentUser } = useAuthContext();

    return (
        <div className="row workspace-user-component">
            <ValueDescription
                value={entity.user?.fullName ? entity.user.fullName : entity.user?.email ?? entity.userId}
                description={entity.user ? joinOptionnalStrings([entity.user?.position, entity.user?.field]) : undefined}
            />
            <Select id="entity-role" disabled={currentWorkspace.role !== WorkspaceRole.Owner || currentUser._id === entity.userId} items={roles} i18n="users" value={entity.role} onChange={onRole} />
            {(currentWorkspace.role === WorkspaceRole.Owner || (currentWorkspace.role === WorkspaceRole.Maintainer && entity.role === WorkspaceRole.User)) && currentUser._id !== entity.userId && (
                <DropdownMenu disposition="left" items={[
                    { itemKey: 'delete', icon: <TrashIcon />, i18n: "actions", label: "remove", onClick: () => onRemove(entity) }
                ]}>
                    <Button color="navigation" icon={<MenuIcon />} />
                </DropdownMenu>
            )}
        </div>
    )
}

const WorkspaceSettingsUsers = ({ entity, attachInput, onChange }: WorkspaceSettingsFormProps) => {
    const request = useRequest();
    const [isInviteModalVisible, setInviteModalVisible] = useState<boolean>(false);
    const { t } = useTranslation();

    const roles = useMemo(() => translateEnum(WorkspaceRole, 'workspaces', 'roles', t), [t]);

    const getUsers = useCallback(() => {
        request.get<Workspace>(`/workspaces/${entity._id}/edit`)
            .then((data) => onChange('users', data.users))
            .catch(() => null);
    }, [request, entity, onChange]);

    const handleRemove = useCallback((wu: WorkspaceUser) => {
        if (!entity.users?.length) return;
        onChange('users', entity.users?.filter(u => u.userId !== wu.userId));
    }, []);

    const handleChangeRole = useCallback((wu: WorkspaceUser, role?: WorkspaceRole) => {
        if (!role || !entity.users?.length) return;

        const index = entity.users?.findIndex(u => u.userId === wu.userId);

        if (index >= 0) {
            const users = [...entity.users];
            users[index].role = role;

            onChange('users', users);
        }
    }, []);

    useEffect(() => {
        getUsers();
    }, []);

    return (
        <Fragment>
            <Info noIcon i18n="workspaces" label="role_tooltip" />
            <Info type="warning" i18n="workspaces" label="users_tooltip" />
            <div className="row row-layout">
                <DataComponentList
                    component={(props: { entity: WorkspaceUser }) => <WorkspaceUserComponent entity={props.entity} roles={roles} onRemove={handleRemove} onRole={(r) => handleChangeRole(props.entity, r)} />}
                    data={entity.users}
                    primaryKey="userId"
                    onAdd={() => setInviteModalVisible(true)}
                    onSearch={(c, k) => (c.user?.fullName ?? '')?.toLowerCase().includes(k.toLowerCase())}
                    className="col col-lg-50"
                />
            </div>
            {isInviteModalVisible && <WorkspaceInvite onClose={() => setInviteModalVisible(true)} roles={roles} />}
        </Fragment>
    )
}
export default WorkspaceSettingsUsers;