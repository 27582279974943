import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useAuthContext } from "../../../context";
import { useWorkspaceContext } from "../../../context/WorkspaceContext";
import { WorkspaceRole } from "../../../models/workspace";
import { Card } from "../../../ui";
import ListItem from "../../../ui/ListItem";
import { Colors } from "../../../ui/enums";
import { Role } from "../../../utils/enums";
import { translateEnum } from "../../../utils/format";

const Workspaces = () => {
    const { workspaces } = useWorkspaceContext();
    const { currentRole } = useAuthContext();
    const { t } = useTranslation();

    const roles = useMemo(() => translateEnum(Role, 'users', 'roles', t), [t]);
    const workspaceRoles = useMemo(() => translateEnum(WorkspaceRole, 'workspaces', 'roles', t), [t]);

    return (
        <Card title="workspaces" id="acount-workspaces" i18n="workspaces">
            <div className="simple-list">
                {workspaces.map(w => (
                    <ListItem
                        key={w._id}
                        value={w.name}
                        description={w.description}
                        tag={currentRole !== Role.User
                            ? { color: Colors.PRIMARY, label: roles.find(r => r.key === currentRole)?.label ?? '' }
                            : { color: Colors.PRIMARY, label: workspaceRoles.find(r => r.key === w.role)?.label ?? '' }}
                    />
                ))}
            </div>
        </Card >
    )
}
export default Workspaces;