// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#specification-view {
  height: 100%;
  overflow: hidden;
  align-items: stretch;
}
#specification-view > * {
  height: 100%;
}
#specification-view #specification-view-list {
  height: 100%;
}
#specification-view #specification-view-list .data-component-list {
  overflow-y: hidden;
}
#specification-view #specification-view-list .data-component-list > .simple-list {
  overflow-y: auto;
}
#specification-view #specification-map {
  height: 100%;
}
#specification-view #specification-map .card-content {
  padding: 0;
  overflow: hidden;
}
#specification-view #specification-map .map {
  height: 100%;
}
#specification-view #specification-map .marker-directory {
  content: "";
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background-color: var(--theme-color-pristine);
  border: var(--theme-color-white) 1px solid;
}
#specification-view #specification-map .marker-directory > svg {
  width: 18px;
  height: 18px;
  fill: var(--theme-color-white);
}`, "",{"version":3,"sources":["webpack://./src/pages/SpecificationView/index.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,gBAAA;EACA,oBAAA;AACJ;AACI;EACI,YAAA;AACR;AAEI;EACI,YAAA;AAAR;AACQ;EACI,kBAAA;AACZ;AACY;EACI,gBAAA;AAChB;AAII;EACI,YAAA;AAFR;AAIQ;EACI,UAAA;EACA,gBAAA;AAFZ;AAKQ;EACI,YAAA;AAHZ;AAKQ;EACI,WAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EAEA,WAAA;EACA,YAAA;EACA,kBAAA;EACA,6CAAA;EACA,0CAAA;AAJZ;AAMY;EACI,WAAA;EACA,YAAA;EACA,8BAAA;AAJhB","sourcesContent":["#specification-view {\n    height: 100%;\n    overflow: hidden;\n    align-items: stretch;\n\n    & > * {\n        height: 100%;\n    }\n\n    & #specification-view-list {\n        height: 100%;\n        & .data-component-list {\n            overflow-y: hidden;\n\n            & > .simple-list {\n                overflow-y: auto;\n            }\n        }\n    }\n\n    #specification-map {\n        height: 100%;\n\n        & .card-content {\n            padding: 0;\n            overflow: hidden;\n        }\n\n        & .map {\n            height: 100%;\n        }\n        & .marker-directory {\n            content: \"\";\n            display: flex;\n            align-items: center;\n            justify-content: center;\n\n            width: 28px;\n            height: 28px;\n            border-radius: 50%;\n            background-color: var(--theme-color-pristine);\n            border: var(--theme-color-white) 1px solid;\n\n            & > svg {\n                width: 18px;\n                height: 18px;\n                fill: var(--theme-color-white);\n            }\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
