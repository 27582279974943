// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.list-item {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  gap: 6px;
  align-items: center;
  overflow: hidden;
}
.list-item .tag {
  overflow: hidden;
  text-overflow: ellipsis;
}
.list-item > svg {
  fill: var(--theme-label-color);
  width: 18px;
  height: 18px;
}
.list-item > .value-description {
  flex-grow: 1;
}`, "",{"version":3,"sources":["webpack://./src/sg-react/ui/ListItem/index.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;EACA,YAAA;EACA,QAAA;EACA,mBAAA;EACA,gBAAA;AACJ;AACI;EACI,gBAAA;EACA,uBAAA;AACR;AAEI;EACI,8BAAA;EACA,WAAA;EACA,YAAA;AAAR;AAGI;EACI,YAAA;AADR","sourcesContent":[".list-item {\n    display: flex;\n    flex-direction: row;\n    flex-grow: 1;\n    gap: 6px;\n    align-items: center;\n    overflow: hidden;\n\n    & .tag {\n        overflow: hidden;\n        text-overflow: ellipsis;\n    }\n\n    & > svg {\n        fill: var(--theme-label-color);\n        width: 18px;\n        height: 18px;\n    }\n\n    & > .value-description {\n        flex-grow: 1;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
