import { useTranslation } from "react-i18next";
import { DirectoryItem } from "../../../models/directory-item";
import { BubbleHead } from "../../../sg-react/ui";
import LabelValue from "../../../sg-react/ui/LabelValue";
import { WizardContentComponentProps } from "../../../sg-react/ui/Wizard";

const DirectoryItemCreateSummary = ({ entity, attachInput, hasChanged }: WizardContentComponentProps<DirectoryItem>) => {
    const { t } = useTranslation();

    return (
        <div className="col">
            <h3>{t('directory:informations')}</h3>
            <div className="simple-list">
                <LabelValue inline fixed displayIfNull i18n="entity" label="name" value={entity.name} />
                <LabelValue inline fixed displayIfNull i18n="entity" label="description" value={entity.description} />
                <LabelValue inline fixed displayIfNull i18n="directory" label="localBusinessId" value={entity.localBusinessId} />
                <LabelValue inline fixed displayIfNull i18n="directory" label="localBusinessIdType" value={entity.localBusinessIdType} />
                <LabelValue inline fixed displayIfNull i18n="directory" label="internationalBusinessId" value={entity.internationalBusinessId} />
                <LabelValue inline fixed displayIfNull i18n="directory" label="internationalBusinessIdType" value={entity.internationalBusinessIdType} />
                <LabelValue inline fixed displayIfNull i18n="directory" label="vatId" value={entity.vatId} />

                <LabelValue inline fixed displayIfNull i18n="directory" label="parent" value={entity.parents?.map(p => p.name).join(', ')} />
                <LabelValue inline fixed displayIfNull i18n="directory" label="type" value={entity.type ? t('directory:enums.types.' + entity.type) : undefined} />
                <LabelValue inline fixed displayIfNull i18n="directory" label="sectors" value={entity.sectors?.join(', ')} />
                <LabelValue inline fixed displayIfNull i18n="directory" label="logo" value={entity.logo?.base64 ? <BubbleHead image={entity.logo?.base64} size="medium" /> : undefined} />

            </div>
            <h3>{t('location:location')}</h3>
            <div className="simple-list">
                <LabelValue inline fixed displayIfNull i18n="location" label="streetNumber" value={entity.location?.streetNumber} />
                <LabelValue inline fixed displayIfNull i18n="location" label="route" value={entity.location?.route} />
                <LabelValue inline fixed displayIfNull i18n="location" label="city" value={entity.location?.city} />
                <LabelValue inline fixed displayIfNull i18n="location" label="region" value={entity.location?.region} />
                <LabelValue inline fixed displayIfNull i18n="location" label="country" value={entity.location?.country} />
            </div>
            <h3>{t('contacts:contacts')}</h3>
            <div className="simple-list">
                <LabelValue inline fixed displayIfNull i18n="directory" label="localPhone" value={entity.localPhone} />
                <LabelValue inline fixed displayIfNull i18n="directory" label="internationalPhone" value={entity.internationalPhone} />
                <LabelValue inline fixed displayIfNull i18n="directory" label="website" value={entity.website} />
                <LabelValue inline fixed displayIfNull i18n="directory" label="email" value={entity.email} />
            </div>
            <h3>{t('entity:privacy')}</h3>
            <div className="simple-list">
                <LabelValue inline fixed displayIfNull i18n="directory" label="allowImport" value={t('forms:' + entity.permissions?.allowImport ? 'yes' : 'no')} />
                <LabelValue inline fixed displayIfNull i18n="directory" label="allowSg" value={t('forms:' + entity.permissions?.allowSg ? 'yes' : 'no')} />
            </div>
        </div>
    )
}
export default DirectoryItemCreateSummary;