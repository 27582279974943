import { MouseEvent, ReactNode, useCallback, useRef } from 'react';
import './index.scss';

interface TooltipProps {
    children: ReactNode;
    tooltip?: string;
    id?: string;
    className?: string;
}

const Tooltip = ({ children, tooltip, id, className }: TooltipProps) => {
    const ref = useRef<HTMLDivElement | null>(null);

    const handleMouseMove = useCallback((event: MouseEvent<HTMLDivElement>) => {
        if (ref.current) {
            const left = event.clientX + 6;
            const top = event.clientY + 6;
            const box = ref.current.getBoundingClientRect();

            ref.current.style.visibility = 'visible';
            if (left + box.width > window.innerWidth) {
                ref.current.style.right = '0';
                ref.current.style.left = 'unset';
            } else {
                ref.current.style.right = 'unset';
                ref.current.style.left = left + 'px';
            }

            if (top + box.height > window.innerHeight) {
                ref.current.style.bottom = '0';
                ref.current.style.top = 'unset';
            } else {
                ref.current.style.bottom = 'unset';
                ref.current.style.top = top + 'px';
            }
        }
    }, []);

    const handleMouseLeave = useCallback(() => {
        if (ref.current) {
            ref.current.style.visibility = 'hidden';
        }
    }, []);

    return (
        <div
            className={`tooltip ${className ?? ''}`}
            onMouseMove={handleMouseMove}
            onMouseLeave={handleMouseLeave}
            id={id}
        >
            {children}
            {!!tooltip && (
                <div className="tooltip-tooltip" ref={ref}>
                    {tooltip}
                </div>
            )}
        </div>
    )
}

export default Tooltip;