import { useCallback, useEffect, useState } from 'react';
import { useRequest } from '../../context';
import { PaginatedResults } from '../../models/pagination';
import { conditionnalClassnames } from '../../utils/helpers';
import { toggleInArray } from '../../utils/objects';
import DropdownInput from '../DropdownInput';
import { FormPropsInterface } from '../InputWrapper';

type SelectListItem<T extends string | number> = T | { key: T, label?: string };

export interface SelectMultipleProps<T extends string | number> extends FormPropsInterface<T[]> {
    items?: SelectListItem<T>[],
    endpoint?: string,
    maxHeight?: string;
}

const SelectMultiple = <T extends string | number,>({
    value,
    items,
    endpoint,
    onChange,
    ...rest
}: SelectMultipleProps<T>) => {
    const [listItems, setListItems] = useState<SelectListItem<T>[]>([]);
    const [isLoading, setLoading] = useState<boolean>(false);
    const request = useRequest();

    const handleGetItems = useCallback(async () => {
        if (!endpoint || isLoading) return;

        setLoading(true);
        request.get<PaginatedResults<SelectListItem<T>> | SelectListItem<T>[]>(endpoint, { params: { perPage: -1 } })
            .then((data) => setListItems(Array.isArray(data) ? data : data.data))
            .catch(() => setListItems([]))
            .finally(() => setLoading(false));
    }, [endpoint, request, isLoading]);

    const handleChange = useCallback((i: SelectListItem<T>, value: T[] | undefined) => {
        if (rest.disabled || !onChange) return;

        if (!value?.length) {
            onChange([getId(i)]);
        } else {
            onChange(toggleInArray(value, getId(i)));
        }
    }, []);

    const getId = useCallback((i: SelectListItem<T>): T =>
        typeof i === 'string' || typeof i === 'number' ? i : i.key
        , []);

    const getLabel = useCallback((i?: SelectListItem<T>): string | undefined => i === undefined
        ? undefined
        : typeof i === 'string' || typeof i === 'number'
            ? String(i)
            : String(i.label) ?? String(i.key)
        , []);

    const isSelected = useCallback((i: SelectListItem<T>, value: T[] | undefined): boolean => !!value?.includes(getId(i))
        , [getId, value]);

    useEffect(() => {
        if (endpoint) {
            handleGetItems();
        } else if (items?.length) {
            setListItems(items);
        }
    }, [endpoint, items]);

    return (
        <DropdownInput
            type="select"
            onClear={onChange ? () => onChange(undefined) : undefined}
            value={listItems.filter((i) => isSelected(i, value)).map(getLabel).join(', ')}
            {...rest}
        >
            {listItems.map((i) => (
                <div
                    key={getId(i) ?? getLabel(i)}
                    onClick={() => handleChange(i, value)}
                    className={conditionnalClassnames({ "dropdown-input-item": true, selected: isSelected(i, value) })}
                >
                    <span>{getLabel(i)}</span>
                </div>
            ))}
        </DropdownInput>
    );
}

export default SelectMultiple;
