import { Fragment, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DirectoryItemCreate from '../../components/DirectoryItemCreate';
import DirectoryItemIcon from '../../components/DirectoryItemIcon';
import { DirectoryImport, DirectoryItem } from '../../models/directory-item';
import { useMenuContext, useRequest, useWorkspaceContext } from '../../sg-react/context';
import { DataTableList } from '../../sg-react/data';
import FileUploadModal from '../../sg-react/data/FileUploadModal';
import { FilterType } from '../../sg-react/data/FiltersPanel';
import { WorkspaceRole } from '../../sg-react/models/workspace';
import { Action } from '../../sg-react/utils/enums';

const DirectoryList = () => {
    const navigate = useNavigate();
    const { currentWorkspace } = useWorkspaceContext();
    const { action, setActionDone, setDisabledActions } = useMenuContext();
    const [isFileUploadModalVisible, setFileUploadModalVisible] = useState<boolean>(false);
    const [isCreationModalVisible, setCreationModalVisible] = useState<boolean>(false);
    const request = useRequest();

    const startImportWorkflow = useCallback((file: File) => {
        const data = new FormData();
        data.append('file', file);

        request.post<DirectoryImport>('/directory/import', data, { loader: true, errorMessage: true, withWorkspace: true, headers: { 'Content-Type': 'multipart/form-data' } })
            .then((data) => navigate(`/dashboard/import/${data._id}`))
            .catch(() => null);
    }, [request]);

    useEffect(() => {
        if (action?.action) {
            switch (action.action) {
                case Action.Create:
                    setCreationModalVisible(true);
                    break;
                case Action.Import:
                    setFileUploadModalVisible(true);
                    break;
                default:
                    break;
            }
            setActionDone(action.timestamp);
        }
    }, [action?.timestamp]);

    useEffect(() => {
        if (currentWorkspace.role === WorkspaceRole.User) {
            setDisabledActions([Action.Create, Action.Import]);
        }
    }, [currentWorkspace.role]);

    return (
        <Fragment>
            <DataTableList<DirectoryItem>
                primaryKey="_id"
                endpoint="/directory"
                onClick={(d) => navigate(`/directory/view/${d._id}`)}
                filters={[
                    { field: 'name', label: 'name', i18n: 'directory', type: FilterType.Search },
                    { field: 'city', label: 'city', i18n: 'location', type: FilterType.Ilike },
                    { field: 'region', label: 'region', i18n: 'location', type: FilterType.Ilike },
                    { field: 'country', label: 'country', i18n: 'location', type: FilterType.Ilike },
                ]}
                columns={[
                    {
                        field: 'type',
                        display: (e) => <DirectoryItemIcon type={e.type} />,
                        width: '48px'
                    },
                    { field: 'name', label: 'name', i18n: 'directory', sort: true },
                    { field: 'location.city', label: 'city', i18n: 'location', sort: true },
                    { field: 'location.country', label: 'country', i18n: 'location', sort: true },
                ]}
                initialPagination={{ sort: { name: 1 } }}
                additionalFilters={{ workspaceId: currentWorkspace._id }}
                withSearch
            />
            {!!isCreationModalVisible && <DirectoryItemCreate onClose={() => setCreationModalVisible(false)} />}
            {!!isFileUploadModalVisible && <FileUploadModal tooltip={{ label: 'import_from_file_tooltip', i18n: 'directory' }} extensions={['csv']} onSubmit={startImportWorkflow} onClose={() => setFileUploadModalVisible(false)} />}
        </Fragment>
    );
}

export default DirectoryList;