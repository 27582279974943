import React, { useCallback, useState } from 'react';
import { HideIcon, ShowIcon } from '../../icons';
import InputWrapper, { FormPropsInterface } from '../InputWrapper';
import { ValidationError } from '../Validation/types';
import './index.scss';

const REGEX = [
    { re: /.{8,32}/, error: { type: 'PASSWORD_LENGTH' } },
    { re: /[A-Z]/, error: { type: 'PASSWORD_LOWERCASE' } },
    { re: /[a-z]/, error: { type: 'PASSWORD_UPPERCASE' } },
    { re: /[0-9]/, error: { type: 'PASSWORD_DIGIT' } },
    { re: /[#?!@$%^&*-]/, error: { type: 'PASSWORD_SPECIAL' } },
];

interface PasswordProps extends FormPropsInterface<string> {
    placeholder?: string,
    regex?: boolean,
    onBlur?: (field: string, value: string) => void,
    onFocus?: (field: string, value: string) => void,
    compareTo?: string,
    withProgressBar?: boolean,
    withWarnings?: boolean,
    withShowIcon?: boolean,
}

const Password = ({
    id,
    value,
    placeholder,
    regex,
    onChange,
    onBlur,
    onFocus,
    compareTo,
    withProgressBar = false,
    withWarnings = false,
    withShowIcon = false,
    errors,
    ...rest
}: PasswordProps) => {
    const [warning, setWarning] = useState<ValidationError | null>(null);
    const [progressBarSize, setProgressBarSize] = useState<number>(100);
    const [isProgressBarVisible, setProgressBarVisible] = useState<boolean>(false);
    const [isPasswordShown, setPasswordShown] = useState<boolean>(false);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const _value = e.target.value;
        if (!_value || !withWarnings || !regex) {
            setProgressBarSize(100);
        } else {
            let regexWarnings = [];

            for (const re of REGEX) {
                if (!_value.match(re.re)) {
                    regexWarnings.push(re.error);
                }
            }

            setProgressBarSize((regexWarnings.length) * 100 / REGEX.length);
            setWarning(regexWarnings.length ? regexWarnings[0] : null);
        }

        if (typeof onChange === 'function') {
            onChange(_value);
        }
    }

    const handleBlur = useCallback(() => {
        setProgressBarVisible(false);

        if (!withProgressBar && compareTo && compareTo !== value) {
            setWarning({ type: 'PASSWORD_MATCH' });
        } else if (!withProgressBar) {
            setWarning(null);
        }

    }, [withProgressBar, compareTo, value]);

    return (
        <InputWrapper
            id={id}
            type="password"
            icon={withShowIcon ? (isPasswordShown
                ? <HideIcon onClick={() => setPasswordShown(!isPasswordShown)} />
                : <ShowIcon onClick={() => setPasswordShown(!isPasswordShown)} />) : null
            }
            errors={!withProgressBar || !isProgressBarVisible ? errors : []}
            warnings={(!withProgressBar || !isProgressBarVisible) && warning ? [warning] : []}
            {...rest}
        >
            <input
                autoComplete="off"
                id={id}
                type={isPasswordShown ? "text" : "password"}
                onChange={handleChange}
                onFocus={() => setProgressBarVisible(true)}
                onBlur={handleBlur}
                value={value ?? ''}
                placeholder={placeholder ?? ''}
            />
            {withProgressBar && isProgressBarVisible && (
                <div className="password-progress">
                    <div className="password-progress-cursor" style={{ width: progressBarSize + "%" }} />
                </div>
            )}
        </InputWrapper>
    );
}

export default Password;