import { Card } from "../../../sg-react/ui";
import Collapse from "../../../sg-react/ui/Collapse";
import ValueDescription from "../../../sg-react/ui/ValueDescription";
import { useMapContext } from "../../MainMap/components/MapContext";


export const PlanTree = () => {
    const { planResults, plan } = useMapContext();

    return (
        <Card>
            <div className="list">
                {planResults?.map(p => (
                    <div key={p.stepId} className="col">
                        <h3><div className="row">
                            <div className="step-pill" style={{ backgroundColor: p.color }} />
                            <span>{p.name}</span></div>
                        </h3>
                        <Collapse title="Segmentations" key={p.stepId}>
                            <div className="list">
                                {plan.steps?.find(s => s.stepId === p.stepId)?.segmentationsIds?.map(segmentationId => <ValueDescription key={segmentationId} value={segmentationId} description={segmentationId} />)}
                            </div>
                        </Collapse>
                        <Collapse title="Results" key={p.stepId}>
                            <div className="list">
                                {p.data.items.map(d => <ValueDescription key={d._id} value={d.name} description={d.location?.address} />)}
                            </div>
                        </Collapse>
                    </div>
                ))}
            </div>
        </Card>
    );
}