// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input[input-type=password] .password-progress {
  opacity: 20%;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  bottom: 0;
  position: absolute;
  z-index: 1;
  pointer-events: none;
  background: linear-gradient(90deg, rgb(254, 46, 46) 0%, rgb(251, 254, 46) 33%, rgb(174, 254, 46) 66%, rgb(77, 254, 46) 100%);
}
.input[input-type=password] .password-progress > .password-progress-cursor {
  background-color: white;
  content: "";
  height: 100%;
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/sg-react/form/Password/index.scss"],"names":[],"mappings":"AACI;EACI,YAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;EACA,aAAA;EACA,qBAAA;EACA,yBAAA;EACA,SAAA;EACA,kBAAA;EACA,UAAA;EACA,oBAAA;EACA,4HAAA;AAAR;AAQQ;EACI,uBAAA;EACA,WAAA;EACA,YAAA;EACA,WAAA;AANZ","sourcesContent":[".input[input-type=\"password\"] {\n    & .password-progress {\n        opacity: 20%;\n        width: 100%;\n        height: 100%;\n        border-radius: 4px;\n        display: flex;\n        align-items: flex-end;\n        justify-content: flex-end;\n        bottom: 0;\n        position: absolute;\n        z-index: 1;\n        pointer-events: none;\n        background: linear-gradient(\n            90deg,\n            rgb(254, 46, 46) 0%,\n            rgb(251, 254, 46) 33%,\n            rgb(174, 254, 46) 66%,\n            rgb(77, 254, 46) 100%\n        );\n\n        & > .password-progress-cursor {\n            background-color: white;\n            content: \"\";\n            height: 100%;\n            width: 100%;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
