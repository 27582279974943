import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { ColorPicker, NumberField } from "../../form";
import { PlusIcon, TrashIcon } from "../../icons";
import { Button, Modal } from "../../ui";
import { Colors } from "../../ui/enums";
import './index.scss';

const polarToCartesian = (centerX: number, centerY: number, radius: number, angleInDegrees: number) => {
    var angleInRadians = (angleInDegrees - 90) * Math.PI / 180.0;

    return {
        x: centerX + (radius * Math.cos(angleInRadians)),
        y: centerY + (radius * Math.sin(angleInRadians))
    };
}

const describeArc = (x: number, y: number, radius: number, startAngle: number, endAngle: number) => {

    var start = polarToCartesian(x, y, radius, endAngle);
    var end = polarToCartesian(x, y, radius, startAngle);

    var largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";

    var d = [
        "M", start.x, start.y,
        "A", radius, radius, 0, largeArcFlag, 0, end.x, end.y
    ].join(" ");

    return d;
}

export interface DialChartSvgLimit {
    limit: number;
    color: string;
}

interface DialChartSvgSettingsProps {
    limits: DialChartSvgLimit[];
    onChange: (l: DialChartSvgLimit[]) => void;
}

export const DialChartSvgSettings = ({ limits: limitsFromProps, onChange }: DialChartSvgSettingsProps) => {
    const { t } = useTranslation();
    const [limits, setLimits] = useState<(DialChartSvgLimit & { index: number, length: number })[]>([]);
    const [limitToEdit, setLimitToEdit] = useState<Partial<DialChartSvgLimit & { index: number, length: number }> | null>(null);

    const max = useMemo(() => Math.max(...limits.map(l => l.limit)), [limits]);

    const handleSubmit = useCallback(() => {
        if (!limitToEdit?.limit || !limitToEdit?.color) return;

        const _limits = limits.map(l => ({ limit: l.limit, color: l.color }))
        const _limit = { limit: limitToEdit.limit, color: limitToEdit.color };
        if (limitToEdit.index !== undefined) {
            _limits[limitToEdit.index] = _limit;
        } else {
            _limits.push(_limit)
        }

        onChange(_limits);
        setLimitToEdit(null);
    }, [limitToEdit, limits, onChange]);

    const handleDelete = useCallback(() => {
        if (limitToEdit?.index === undefined) return;

        onChange(limits.filter((l, i) => i !== limitToEdit.index).map(l => ({ limit: l.limit, color: l.color })));
        setLimitToEdit(null);
    }, [limitToEdit, limits, onChange]);

    useEffect(() => {
        if (!limitsFromProps?.length) {
            setLimits([{ limit: 100, color: '#ffffff', length: 100, index: 0 }]);
        } else {
            const _limits = limitsFromProps.sort((l1, l2) => l1.limit > l2.limit ? 1 : -1);
            setLimits(_limits.map((l, index) => ({
                ...l,
                index,
                length: index === 0 ? l.limit : l.limit - _limits[index - 1].limit
            })));
        }
    }, [limitsFromProps]);

    return (
        <div className="dial-chart-svg-settings">
            <div className="dial-chart-svg-settings-bar">
                {limits.map(l => (
                    <div
                        key={l.index + '' + l.limit}
                        style={{ backgroundColor: l.color, width: (l.length * 100 / (max ?? 1)) + '%' }}
                        onClick={() => setLimitToEdit(l)}
                    >
                        {l.limit}
                    </div>
                ))}
            </div>
            <Button i18n="actions" label="add" icon={<PlusIcon />} color="navigation" onClick={() => setLimitToEdit({ limit: max + 1 })} />
            {!!limitToEdit && (
                <Modal
                    size="small"
                    className="dial-chart-svg-settings-modal"
                    title={limitToEdit.index !== undefined
                        ? t('actions:edit')
                        : t('actions:new')
                    }
                    actions={limitToEdit.index !== undefined ? [{ label: 'delete', i18n: 'actions', icon: <TrashIcon />, color: Colors.ERROR, onClick: handleDelete }] : undefined}
                    onClose={() => setLimitToEdit(null)}
                    onSubmit={handleSubmit}
                >
                    <NumberField
                        id="dial-chart-svg-settings-limit"
                        value={limitToEdit.limit}
                        onChange={(limit) => setLimitToEdit({ ...limitToEdit, limit })}
                    />
                    <ColorPicker
                        id="dial-chart-svg-settings-color"
                        value={limitToEdit.color}
                        onChange={(color) => setLimitToEdit({ ...limitToEdit, color })}
                    />
                </Modal>
            )}
        </div>
    )

}

interface DialChartSvgProps {
    value: number;
    limits: DialChartSvgLimit[];
    withLegend?: boolean;
}

const DialChartSvg = ({ value, limits, withLegend }: DialChartSvgProps) => {

    const max = useMemo(() => Math.max(...limits.map(l => l.limit)), [limits]);

    const limitsSorted = useMemo(() => {
        const _limits = limits.sort((l1, l2) => l1.limit > l2.limit ? 1 : -1);
        return _limits.map((l, index) => ({
            ...l,
            index,
            previousLimit: index === 0 ? 0 : _limits[index - 1].limit,
            start: index === 0 ? 0 : ((_limits[index - 1].limit * 280 / max) + 4),
            end: l.limit === max ? 280 : ((l.limit * 280 / max) - 4),
            length: index === 0 ? l.limit : l.limit - _limits[index - 1].limit
        }));
    }, [limits, max]);

    if (!limitsSorted?.length) return null;

    return (
        <div className="dial-chart-svg">
            <svg viewBox="0 0 300 250" fill="traparent">
                {limitsSorted.map(l => (
                    <path key={l.index + '' + l.limit} fill="none" stroke={l.color} strokeLinecap="round" strokeWidth="10" d={describeArc(150, 135, 130, l.start - 140, l.end - 140)} />
                ))}

                <path className="dial-chart-svg-grey" fill="none" strokeLinecap="round" strokeWidth="20" d={describeArc(150, 135, 100, -140, 140)} />
                <path fill="none" stroke={limitsSorted.find(l => l.previousLimit < value && l.limit >= value)?.color} strokeLinecap="round" strokeWidth="20" d={describeArc(150, 135, 100, -140, (Math.floor(Math.min(max, value) * 280 / max)) - 142)} />
                <text x="150" y="135" dominantBaseline="middle" textAnchor="middle">{value}</text>
            </svg>
            {!!withLegend && (
                <div className="dial-chart-svg-legend">
                    {limitsSorted.map(l => (
                        <div key={l.index + '' + l.limit}>
                            <div style={{ backgroundColor: l.color }} />
                            <span>{`${l.previousLimit} - ${l.limit}`}</span>
                        </div>
                    ))}
                </div>
            )}
        </div>
    )
}
export default DialChartSvg;