import { Fragment, useMemo, useState } from "react";
import { Link, Outlet } from "react-router-dom";
import HeaderSearch from "../components/HeaderSearch";
import { useAuthContext } from "../sg-react/context";
import { useWorkspaceContext } from "../sg-react/context/WorkspaceContext";
import { LogoutIcon, SettingIcon, UserSettingIcon, UsersIcon, WorkspaceIcon } from "../sg-react/icons";
import defaultAvatar from '../sg-react/icons/assets/user-tie.svg';
import { WorkspaceRole } from "../sg-react/models/workspace";
import { BubbleHead, Button, HorizontalNavigation, Modal } from "../sg-react/ui";
import DepthContainer from "../sg-react/ui/DepthContainer";
import DropdownMenu from "../sg-react/ui/DropdownMenu";
import MenuContextMenu from "../sg-react/ui/MenuContextMenu";
import SoftContainer from "../sg-react/ui/SoftContainer";
import ValueDescription from "../sg-react/ui/ValueDescription";
import { Role } from "../sg-react/utils/enums";
import WorkspaceSettings from "./WorkspaceSettings";
import './index.scss';

interface WorkspacesListProps {
    onClose: () => void;
}

const WorkspacesList = ({ onClose }: WorkspacesListProps) => {
    const { workspaces, setCurrentWorkspace } = useWorkspaceContext();

    return (
        <Modal id="workspaces-modal" title="switch_workspace" i18n="workspaces" size="medium" overflow="auto" onClose={onClose}>
            <div className="row row-equal-cols row-wrap">
                {workspaces.map(w => (
                    <div key={w._id} onClick={() => { setCurrentWorkspace(w._id); onClose(); }}>
                        {!!w.image?.base64 ? (
                            <DepthContainer>
                                <img src={w.image?.base64} alt="" />
                                <ValueDescription wrap value={w.name} description={w.description} />
                            </DepthContainer>
                        ) : (
                            <SoftContainer>
                                <ValueDescription wrap value={w.name} description={w.description} />
                            </SoftContainer>
                        )}
                    </div>
                ))}
            </div>
        </Modal>
    )
}

const Private = () => {
    const { currentUser, currentRole, logout } = useAuthContext();
    const { currentWorkspace } = useWorkspaceContext();
    const [isSettingsModalVisible, setSettingsModalVisible] = useState<boolean>(false);
    const [isWorkspacesModalVisible, setWorkspacesModalVisible] = useState<boolean>(false);

    const navigation = useMemo(() => [
        { path: '/', label: 'map', i18n: 'path' },
        { path: '/dashboard', label: 'dashboard', i18n: 'path' },
        { path: '/directory', label: 'directory', i18n: 'path' },
        { path: '/parts', label: 'parts', i18n: 'parts' },
        { path: '/specifications', label: 'specifications', i18n: 'specifications' },
    ], []);

    return (
        <Fragment>
            <header>
                <div id="header-container">
                    <div id="header-logo" className="row row.no-gap">
                        <Link to="/">
                            <img src="/images/logo/logo_official_1.png" alt="Supply-Graph" />
                        </Link>
                        <Button color="navigation" label={currentWorkspace.name} onClick={() => setWorkspacesModalVisible(true)} />
                    </div>
                    <HeaderSearch />
                    <HorizontalNavigation items={navigation} />
                    <div id="header-user" className="row row-layout">
                        {currentWorkspace.role === WorkspaceRole.Owner && <SettingIcon className="icon pointer" onClick={() => setSettingsModalVisible(true)} />}
                        <DropdownMenu
                            disposition="left"
                            items={[
                                ...(currentRole !== Role.User ? [
                                    { itemKey: 'divider-admin', divider: true, i18n: "administration", label: "administration" },
                                    { itemKey: 'workspaces', icon: <WorkspaceIcon />, i18n: "workspaces", label: "workspaces", to: '/administration/workspaces' },
                                    { itemKey: 'users', icon: <UsersIcon />, i18n: "users", label: "users", to: "/administration/users" },
                                ] : []),
                                { itemKey: 'divider-account', divider: true, i18n: "users", label: "account" },
                                { itemKey: 'settings', icon: <UserSettingIcon />, i18n: "users", label: "account", to: "/account" },
                                { itemKey: 'logout', icon: <LogoutIcon />, i18n: "users", label: "logout", onClick: logout }
                            ]}>
                            <BubbleHead image={currentUser.avatar?.base64 ?? defaultAvatar} alt="Avatar" size="xs" />
                        </DropdownMenu>
                    </div>
                </div>
                <MenuContextMenu />
            </header>
            <div id="content">
                <Outlet />
                {isSettingsModalVisible && <WorkspaceSettings onClose={() => setSettingsModalVisible(false)} />}
                {isWorkspacesModalVisible && <WorkspacesList onClose={() => setWorkspacesModalVisible(false)} />}
            </div>
        </Fragment>
    );

}

export default Private;