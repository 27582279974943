import { ReactNode, Ref, useCallback, useEffect, useRef, useState } from 'react';
import { UseFocusHandleRef, useFocusHandle, useOutsideClick } from '../../hooks';
import { CloseIcon } from '../../icons';
import { Spinner } from '../../ui';
import { conditionnalClassnames } from '../../utils/helpers';
import InputWrapper, { FormPropsInterface } from '../InputWrapper';
import './index.scss';

export interface DropdownInputProps extends Omit<FormPropsInterface<string>, 'onIconClick'> {
    type: string;
    maxHeight?: string;
    placeholder?: string,
    children?: ReactNode;
    loading?: boolean;
    closeOnClick?: boolean;
    onClear?: () => void;
    onFocus?: () => void;
    onBlur?: () => void;
    focusHandle?: Ref<UseFocusHandleRef>;
}

const DropdownInput = ({
    type,
    value,
    disabled,
    placeholder,
    maxHeight = '300px',
    onChange,
    onFocus,
    onBlur,
    loading,
    onClear,
    icon,
    children,
    className,
    closeOnClick,
    focusHandle,
    ...rest
}: DropdownInputProps) => {
    const [isFocused, setFocused] = useState<boolean>(false);
    const ref = useOutsideClick(() => setFocused(false));
    const inputRef = useFocusHandle(focusHandle, setFocused);

    useEffect(() => {
        if (isFocused && onFocus) onFocus();
        if (!isFocused && onBlur) onBlur();
    }, [isFocused]);

    return (
        <InputWrapper
            type={type}
            className={conditionnalClassnames({ 'dropdown-input-wrapper': true, className })}
            {...rest}
        >
            <div
                className={conditionnalClassnames({
                    "dropdown-input": true,
                    focus: isFocused
                })}
                ref={ref}
            >
                <div className="dropdown-input-field" onClick={!disabled ? () => setFocused(true) : undefined}>
                    {!!onChange && !disabled ? (
                        <input
                            id={rest.id}
                            value={value ?? ''}
                            placeholder={placeholder}
                            onChange={(e) => onChange(e.target.value)}
                            autoComplete="off"
                            ref={inputRef}
                        />
                    ) : (
                        <span>{value ?? ''}</span>
                    )}
                    {!!loading && <Spinner small />}
                    {!loading && !disabled && !!onClear && <CloseIcon onClick={onClear} />}
                    {!loading && !onClear && icon}
                </div>
                {!!children && !!isFocused && (
                    <div
                        className="dropdown-input-children"
                        style={{ maxHeight }}
                        onClick={closeOnClick ? () => setFocused(false) : undefined}
                    >
                        {children}
                    </div>
                )}
            </div>
        </InputWrapper>
    )
}

export default DropdownInput;
