import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { DirectoryItemViewPatchProps } from "..";
import { DirectoryTopic } from "../../../models/directory-data";
import { useRequest } from "../../../sg-react/context";
import { ModalDelete } from "../../../sg-react/data";
import DataComponentList from "../../../sg-react/data/DataComponentList";
import { TextArea, TextField } from "../../../sg-react/form";
import Validation, { ObjectValidation } from "../../../sg-react/form/Validation/class";
import { useForm } from "../../../sg-react/hooks";
import { EditIcon, MenuIcon, TrashIcon } from "../../../sg-react/icons";
import { Button, Card, Modal } from "../../../sg-react/ui";
import DropdownMenu from "../../../sg-react/ui/DropdownMenu";
import { dateToLocaleString } from "../../../sg-react/utils/date";

interface TopicComponentProps {
    topic: DirectoryTopic;
    onEdit?: (c: DirectoryTopic) => void;
    onDelete?: (c: DirectoryTopic) => void;
}

const TopicComponent = ({ topic, onEdit, onDelete }: TopicComponentProps) => {
    const { t } = useTranslation();
    return (
        <div className="row">
            <div className="col directory-item-view-message-topic">
                <label>{dateToLocaleString(topic.date, true)}</label>
                <div className="directory-item-view-message-topic-main">{topic.topic}</div>
                {(topic.part || topic.project) && (
                    <div className="row">
                        {!!topic.project && <div className="row"><label>{t('directory-data:project')}</label><span>{topic.project}</span></div>}
                        {!!topic.part && <div className="row"><label>{t('directory-data:part')}</label><span>{topic.part}</span></div>}
                    </div>
                )}
            </div>
            {(onEdit && onDelete) && (
                <DropdownMenu disposition="left" items={[
                    { itemKey: 'edit', icon: <EditIcon />, i18n: "actions", label: "edit", onClick: () => onEdit(topic) },
                    { itemKey: 'delete', icon: <TrashIcon />, i18n: "actions", label: "delete", onClick: () => onDelete(topic) }
                ]}>
                    <Button color="navigation" icon={<MenuIcon />} />
                </DropdownMenu>
            )}
        </div>
    )
}

const VALIDATION = new ObjectValidation({
    topic: Validation.string().required().minLength(5).maxLength(250),
})

const Topics = ({ directoryItem, directoryData, refresh, canEdit }: DirectoryItemViewPatchProps) => {
    const request = useRequest();
    const { entity, reset, attachInput, validate } = useForm<DirectoryTopic>({}, VALIDATION);
    const [topicToDelete, setTopicToDelete] = useState<DirectoryTopic | null>(null);

    const createOrUpdateTopic = useCallback(() => validate((entity) => {
        const requestMethod = !entity._id ? request.post : request.put;

        requestMethod(`/directory-data/${directoryItem._id}/topic`, entity, {
            loader: true,
            withWorkspace: true,
            successMessage: true,
            errorMessage: true,
        })
            .then(() => {
                reset({});
                refresh();
            })
            .catch(() => null);
    }), [request, directoryItem, refresh, validate, reset]);

    const deleteTopic = useCallback((topic: DirectoryTopic) => {
        request.delete(`/directory-data/${directoryItem._id}/topic/${topic._id}`, {
            loader: true,
            withWorkspace: true,
            successMessage: true,
            errorMessage: true,
        })
            .then(() => {
                setTopicToDelete(null);
                refresh();
            })
            .catch(() => null);
    }, [request, directoryItem, refresh]);

    return (
        <Card title="topics" i18n="directory-data" className="col col-lg-50">
            <DataComponentList
                component={(props: { entity: DirectoryTopic }) => <TopicComponent topic={props.entity} onDelete={canEdit ? setTopicToDelete : undefined} onEdit={canEdit ? reset : undefined} />}
                data={directoryData.topics}
                primaryKey="_id"
                onSearch={(t, k) => t.topic?.toLowerCase().includes(k.toLowerCase())}
                onAdd={canEdit ? () => reset({ date: new Date() }) : undefined}
                sort={[{ field: 'date', i18n: 'directory-data', label: 'date' }]}
                simple
            />
            {!!entity.date && (
                <Modal
                    size="small"
                    i18n="directory-data"
                    title="topic"
                    onClose={() => reset({})}
                    onSubmit={createOrUpdateTopic}
                >
                    <TextArea
                        label
                        i18n="directory-data"
                        {...attachInput('topic')}
                    />
                    <TextField
                        label
                        i18n="directory-data"
                        {...attachInput('project')}
                    />
                    <TextField
                        label
                        i18n="directory-data"
                        {...attachInput('part')}
                    />
                </Modal>
            )}
            {!!topicToDelete && <ModalDelete onClose={() => setTopicToDelete(null)} onSubmit={() => deleteTopic(topicToDelete)} />}
        </Card>
    )
}
export default Topics;