// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.image-uploader {
  width: 100%;
  cursor: pointer;
}
.image-uploader > img, .image-uploader > svg {
  object-fit: cover;
  height: 200px;
  fill: var(--theme-color-grey-light);
}
.image-uploader > .image-uploader-icon {
  bottom: 12px;
  right: 8px;
  width: 32px;
  height: 32px;
  border-radius: 4px;
  background-color: var(--theme-color-secondary);
  display: flex;
  align-items: center;
  justify-content: center;
}
.image-uploader > .image-uploader-icon > svg {
  fill: var(--theme-color-grey-lightest);
}`, "",{"version":3,"sources":["webpack://./src/sg-react/form/ImageUploader/index.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,eAAA;AACJ;AACI;EAEI,iBAAA;EACA,aAAA;EACA,mCAAA;AAAR;AAGI;EACI,YAAA;EACA,UAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;EACA,8CAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AADR;AAGQ;EACI,sCAAA;AADZ","sourcesContent":[".image-uploader {\n    width: 100%;\n    cursor: pointer;\n\n    & > img,\n    & > svg {\n        object-fit: cover;\n        height: 200px;\n        fill: var(--theme-color-grey-light);\n    }\n\n    & > .image-uploader-icon {\n        bottom: 12px;\n        right: 8px;\n        width: 32px;\n        height: 32px;\n        border-radius: 4px;\n        background-color: var(--theme-color-secondary);\n        display: flex;\n        align-items: center;\n        justify-content: center;\n\n        & > svg {\n            fill: var(--theme-color-grey-lightest);\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
