import { memo, useMemo, useState } from "react";
import DirectoryItemsList from "../../../components/DirectoryItemsList";
import { DirectoryItem } from "../../../models/directory-item";
import { useWorkspaceContext } from "../../../sg-react/context";
import { Button, Card } from "../../../sg-react/ui";
import { conditionnalClassnames } from "../../../sg-react/utils/helpers";
import MainMapResultsAnalysis from "./MainMapResultsAnalysis";
import { useMapContext } from "./MapContext";

interface MainMapResultsProps {
    onClick: (c: DirectoryItem) => void;
}

const MainMapResults = ({ onClick }: MainMapResultsProps) => {
    const { results, planResults, panels, filters } = useMapContext();
    const { currentWorkspace } = useWorkspaceContext();
    const [mode, setMode] = useState<'list' | 'analysis'>('list');

    const data = useMemo(() => planResults?.find(p => p.stepId === filters.stepId)?.data ?? results?.data ?? undefined, [planResults, results, filters]);
    const directoryItems = useMemo(() => data?.items, [data]);
    return (
        <Card
            id="main-map-results"
            className={conditionnalClassnames({ visible: panels.results })}
            overflow="hidden"
        >
            <div className="row">
                <Button color="navigation" i18n="data" label="list" active={mode === 'list'} onClick={() => setMode('list')} />
                <Button color="navigation" i18n="data" label="analysis" active={mode === 'analysis'} onClick={() => setMode('analysis')} />
            </div>
            {mode === 'analysis' ? (
                <MainMapResultsAnalysis otdChartData={data?.indicators.otd} oqdChartData={data?.indicators.oqd} segmentationsChartData={data?.indicators.segmentationsCount} />
            ) : (
                <DirectoryItemsList withSearch directoryItems={directoryItems ?? []} onClick={onClick} />
            )}
        </Card>
    )
}

export default memo(MainMapResults);