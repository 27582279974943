import { Pagination } from "../../models/pagination";
import { Button } from "../../ui";
import { Colors } from "../../ui/enums";
import './index.scss';

interface PaginationProps {
    onPageChange: (page: number) => void;
    pagination?: Partial<Pagination>;
}

const PaginationComponent = ({ pagination, onPageChange }: PaginationProps) => !!pagination && !!pagination.page && !!pagination.pages && pagination.pages > 1
    ? (
        <div className="pagination">
            {pagination.page > 2 && <Button color="navigation" label="&lt;&lt;" onClick={() => onPageChange(1)} />}
            {pagination.page > 1 && <Button color="navigation" label="&lt;" onClick={() => onPageChange(pagination.page! - 1)} />}
            {pagination.page - 2 >= 1 && <Button color="navigation" label="..." onClick={() => onPageChange(pagination.page! - 2)} />}
            {pagination.page >= 2 && <Button color="navigation" label={String(pagination.page - 1)} onClick={() => onPageChange(pagination.page! - 1)} />}
            {pagination.page && <Button color={Colors.ACCENT} label={String(pagination.page)} />}
            {pagination.page + 1 <= pagination.pages && <Button color="navigation" label={String(pagination.page + 1)} onClick={() => onPageChange(pagination.page! + 1)} />}
            {pagination.page + 2 <= pagination.pages && <Button color="navigation" label="..." onClick={() => onPageChange(pagination.page! + 2)} />}
            {pagination.page < pagination.pages && <Button color="navigation" label="&gt;" onClick={() => onPageChange(pagination.page! + 1)} />}
            {pagination.page < pagination.pages - 1 && <Button color="navigation" label="&gt;&gt;" onClick={() => onPageChange(pagination.pages!)} />}
        </div>
    )
    : null;

export default PaginationComponent;