import { useTranslation } from 'react-i18next';
import { CloseIcon } from '../../icons';
import { translateLabel } from '../../utils/format';
import { conditionnalClassnames } from '../../utils/helpers';
import { Colors } from '../enums';
import './index.scss';

export interface TagProps {
    label: string;
    i18n?: string;
    color?: Colors | string;
    onClose?: () => void;
}

const Tag = ({ label, i18n, color, onClose }: TagProps) => {
    const { t } = useTranslation();

    return (
        <div
            className={conditionnalClassnames({
                tag: true,
                [`background-color-${color}`]: !color?.startsWith('#'),
                "tag-transparent": !color
            })}
            style={color && color?.startsWith('#') ? { backgroundColor: color } : undefined}
        >
            {translateLabel(label, t, i18n)}
            {onClose && <CloseIcon className="icon icon-small pointer" onClick={onClose} />}
        </div>
    )
}
export default Tag;