import Validation, { ObjectValidation } from "../form/Validation/class";
import { User } from "./user";
import { Workspace } from "./workspace";

export interface Entity {
    _id: string;
    active: boolean;
    createdById?: string;
    createdBy?: User;
    createdAt: number;
    updatedBy?: User;
    updatedById?: string;
    updatedAt: number;
}

export interface Revision {
    _id: string;
    sourceId: string;
    comment?: string;
    workspaceId: string;
    workspace?: Workspace;
    createdById: string;
    createdBy?: User;
    createdAt: Date;
}

export interface RevisionEntity<T> extends Revision {
    entity: T;
}

export interface NamedEntity extends Entity {
    name: string;
    description?: string;
    search: {
        text: string;
        name: string;
        description: string;
    };
}

export const NAMED_ENTITY_VALIDATION = new ObjectValidation<NamedEntity>({
    name: Validation.string().required().minLength(3).maxLength(150),
    description: Validation.string().minLength(20).maxLength(250),
});
