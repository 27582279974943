import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PlusIcon } from '../../icons';
import { conditionnalClassnames } from '../../utils/helpers';
import { getPrimaryKeysAsString } from '../../utils/objects';
import ListActions from '../ListActions';
import './index.scss';

export interface DataComponentListComponentProps<T> {
    entity: T;
    onEdit?: (entity: T) => void;
    onClick?: (entity: T) => void;
}

interface DataComponentListProps<T> {
    primaryKey: keyof T | (keyof T)[];
    data?: T[];
    component: (props: DataComponentListComponentProps<T>) => JSX.Element;
    onClick?: (entity: T) => void;
    onSearch?: (entity: T, keyword: string) => boolean;
    onAdd?: () => void;
    onEdit?: (e: T) => void;
    sort?: { field: keyof T, label: string, i18n?: string }[];
    className?: string;
    take?: number;
    simple?: boolean;
}

const DataComponentList = <T,>({
    primaryKey,
    data,
    component: ItemComponent,
    onClick,
    onSearch,
    onAdd,
    onEdit,
    className,
    sort,
    take,
    simple
}: DataComponentListProps<T>) => {
    const { t } = useTranslation();
    const [showCount, setShowCount] = useState<number>(take ?? 20);
    const [dataVisible, setDataVisible] = useState<T[] | undefined>(data);

    const dataSliced = useMemo(() => dataVisible?.slice(0, showCount) ?? [], [dataVisible, showCount]);
    const isActionsVisible = useMemo(() => (onSearch || onAdd || !!sort?.length), [onSearch, onAdd, sort]);

    useEffect(() => {
        if (!isActionsVisible) {
            setDataVisible(data);
            setShowCount(20);
        }
    }, [data, isActionsVisible]);
    return (
        <div className={conditionnalClassnames({ "data-component-list": true, className })}>
            {isActionsVisible && (
                <ListActions
                    data={data}
                    onAdd={onAdd}
                    onSearch={onSearch}
                    sort={sort}
                    onListUpdate={setDataVisible}
                />
            )}
            <div className={simple ? 'simple-list' : 'list'}>
                {dataSliced?.map(entity => <ItemComponent key={getPrimaryKeysAsString(entity, primaryKey)} entity={entity} onClick={onClick} onEdit={onEdit} />)}
                {(dataVisible?.length ?? 0) > dataSliced.length && (
                    <div className="data-component-list-more" onClick={() => setShowCount(showCount + (take ?? 20))}>
                        <PlusIcon /><span>{t('data:show_more')}</span>
                    </div>
                )}
            </div>
        </div>
    )
}
export default DataComponentList;