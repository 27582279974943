import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import DirectoryItemEdit from '../../components/DirectoryItemEdit';
import DirectoryItemIcon from '../../components/DirectoryItemIcon';
import DirectoryItemMap from '../../components/DirectoryItemMap';
import { DirectoryData, DirectoryDataWidget } from '../../models/directory-data';
import { DirectoryItem } from '../../models/directory-item';
import { useAuthContext, useMenuContext, useRequest, useWorkspaceContext } from '../../sg-react/context';
import { LinkIcon, LocationIcon, PhoneIcon } from '../../sg-react/icons';
import { WorkspaceRole } from '../../sg-react/models/workspace';
import { Card } from '../../sg-react/ui';
import IconValue from '../../sg-react/ui/IconValue';
import LabelValue from '../../sg-react/ui/LabelValue';
import { Action } from '../../sg-react/utils/enums';
import Contacts from './components/Contacts';
import Dates from './components/Dates';
import DirectoryDataParts from './components/DirectoryDataParts';
import DirectoryDataSegmentations from './components/DirectoryDataSegmentations';
import DirectoryDataSettingsModal from './components/DirectoryDataSettings';
import DirectoryDataSpecifications from './components/DirectoryDataSpecifications';
import DirectoryViewIndicators from './components/DirectoryViewIndicators';
import DirectoryViewMessages from './components/DirectoryViewMessages';
import DirectoryViewRevenues from './components/DirectoryViewRevenues';
import RseEmissions from './components/RseEmissions';
import RseProjects from './components/RseProjects';
import Topics from './components/Topics';
import './index.scss';

export interface DirectoryItemViewPatchProps {
    refresh: () => void;
    directoryItem: DirectoryItem;
    directoryData: DirectoryData;
    canEdit: boolean;
}

const DirectoryItemView = () => {
    const { t } = useTranslation();
    const [directoryItem, setDirectoryItem] = useState<DirectoryItem | null>(null);
    const [directoryData, setDirectoryData] = useState<DirectoryData | null>(null);
    const [isEditModalVisible, setEditModalVisible] = useState<boolean>(false);
    const [settingsModalStep, setSettingsModalStep] = useState<string | null>(null);
    const { currentWorkspace } = useWorkspaceContext();
    const { currentUser } = useAuthContext();
    const request = useRequest();
    const { setCurrentItem, removeCurrentItem, action, setActionDone, setDisabledActions } = useMenuContext();
    const { _id } = useParams();
    const navigate = useNavigate();

    const get = useCallback(() => {
        request.get<{ directoryItem: DirectoryItem, directoryData: DirectoryData }>(`/directory/${_id}/view`, {
            withWorkspace: true,
            loader: true,
            errorMessage: true
        })
            .then((data) => {
                setDirectoryItem(data.directoryItem);
                setDirectoryData(data.directoryData);
                setCurrentItem({ label: data.directoryItem.name, loading: false });
                setDisabledActions(currentWorkspace.role === WorkspaceRole.Maintainer
                    || currentWorkspace.role === WorkspaceRole.Owner ? [] : [Action.Settings, Action.Edit]);
            })
            .catch(() => navigate(-1));
    }, [_id, request, currentWorkspace, currentUser, setCurrentItem, setDisabledActions]);

    const onEdit = useCallback(() => {
        setEditModalVisible(false);
        get();
    }, [get]);

    useEffect(() => {
        if (_id) {
            get();
        }
    }, [_id]);

    useEffect(() => {
        if (action?.action) {
            switch (action.action) {
                case Action.Close:
                    removeCurrentItem();
                    break;
                case Action.Edit:
                    setEditModalVisible(true);
                    break;
                case Action.Widgets:
                    setSettingsModalStep('widgets');
                    break;
                case Action.Users:
                    setSettingsModalStep('reference_users');
                    break;
                case Action.Privacy:
                    setSettingsModalStep('visibility');
                    break;
                case Action.Notifications:
                    setSettingsModalStep('notifications');
                    break;
                default:
                    break;
            }
            setActionDone(action.timestamp);
        }
    }, [action?.timestamp]);

    if (!directoryItem || !directoryData) return null;

    return (
        <div id="directory-item-view" className="layout-container layout-container-scroll row row-layout">
            <div className="col col-layout col-lg-20">
                <div className="col col-layout">
                    <Card id="directory-item-form-infos-name">
                        {(directoryItem?.logo?.base64 || directoryItem?.logo?.url) && <img src={directoryItem.logo.base64 ?? directoryItem.logo.url} alt={directoryItem.name} />}
                        <div className="row"><DirectoryItemIcon type={directoryItem.type} /><span>{directoryItem.name}</span></div>
                        <div className="col">
                            <LabelValue label={directoryItem.localBusinessIdType ?? t('directory:localBusinessId')} value={directoryItem.localBusinessId} />
                            <LabelValue label="vatId" i18n="directory" value={directoryItem.vatId} />
                            <IconValue icon={<LinkIcon />} value={directoryItem.website ? <a href={directoryItem.website} target="_blank" rel="noreferrer">{directoryItem.website}</a> : undefined} />
                            <IconValue icon={<LocationIcon />} value={directoryItem.location?.address} />
                            <IconValue icon={<PhoneIcon />} value={directoryItem.localPhone} />
                            <IconValue icon={<PhoneIcon />} value={directoryItem.internationalPhone} />
                        </div>
                    </Card>
                    {!!directoryData?.permissions?.read?.includes(DirectoryDataWidget.Contacts) && (
                        <Contacts directoryItem={directoryItem} directoryData={directoryData} refresh={get} canEdit={!!directoryData?.permissions?.write?.includes(DirectoryDataWidget.Contacts)} />
                    )}
                </div>
            </div>
            <div className="col col-layout col-lg-50">
                {!!directoryData?.permissions?.read?.includes(DirectoryDataWidget.SalesIndicators) && (
                    <DirectoryViewIndicators directoryItem={directoryItem} directoryData={directoryData} refresh={get} canEdit={!!directoryData?.permissions?.write?.includes(DirectoryDataWidget.SalesIndicators)} />
                )}
                {!!directoryData?.permissions?.read?.includes(DirectoryDataWidget.Revenue) && (
                    <DirectoryViewRevenues directoryItem={directoryItem} directoryData={directoryData} refresh={get} canEdit={!!directoryData?.permissions?.write?.includes(DirectoryDataWidget.Revenue)} />
                )}
                {!!directoryData?.permissions?.read?.includes(DirectoryDataWidget.Segmentations) && (
                    <DirectoryDataSegmentations directoryItem={directoryItem} directoryData={directoryData} refresh={get} canEdit={!!directoryData?.permissions?.write?.includes(DirectoryDataWidget.Segmentations)} />
                )}
                <div className="row row-layout row-equal-cols">
                    {!!directoryData?.permissions?.read?.includes(DirectoryDataWidget.Specifications) && (
                        <DirectoryDataSpecifications directoryItem={directoryItem} directoryData={directoryData} refresh={get} canEdit={!!directoryData?.permissions?.write?.includes(DirectoryDataWidget.Specifications)} />
                    )}
                    {!!directoryData?.permissions?.read?.includes(DirectoryDataWidget.Parts) && (
                        <DirectoryDataParts directoryItem={directoryItem} directoryData={directoryData} refresh={get} canEdit={!!directoryData?.permissions?.write?.includes(DirectoryDataWidget.Parts)} />
                    )}
                </div>
                {!!directoryData?.permissions?.read?.includes(DirectoryDataWidget.Emissions) && (
                    <RseEmissions directoryItem={directoryItem} directoryData={directoryData} refresh={get} canEdit={!!directoryData?.permissions?.write?.includes(DirectoryDataWidget.Emissions)} />
                )}
                {!!directoryData?.permissions?.read?.includes(DirectoryDataWidget.ReductionProjects) && (
                    <RseProjects directoryItem={directoryItem} directoryData={directoryData} refresh={get} canEdit={!!directoryData?.permissions?.write?.includes(DirectoryDataWidget.ReductionProjects)} />
                )}
            </div>
            <div className="col col-layout col-lg-25">
                <Card id="directory-view-location">
                    <DirectoryItemMap directoryItem={directoryItem} />
                </Card>
                {!!directoryData?.permissions?.read?.includes(DirectoryDataWidget.Dates) && (
                    <Dates directoryItem={directoryItem} directoryData={directoryData} refresh={get} canEdit={!!directoryData?.permissions?.write?.includes(DirectoryDataWidget.Dates)} />
                )}
                {!!directoryData?.permissions?.read?.includes(DirectoryDataWidget.Messages) && (
                    <DirectoryViewMessages directoryItem={directoryItem} directoryData={directoryData} refresh={get} canEdit={!!directoryData?.permissions?.write?.includes(DirectoryDataWidget.Messages)} />
                )}
                {!!directoryData?.permissions?.read?.includes(DirectoryDataWidget.Topics) && (
                    <Topics directoryItem={directoryItem} directoryData={directoryData} refresh={get} canEdit={!!directoryData?.permissions?.write?.includes(DirectoryDataWidget.Topics)} />
                )}
            </div>
            {!!isEditModalVisible && !!directoryItem && <DirectoryItemEdit directoryItem={directoryItem} onClose={onEdit} />}
            {settingsModalStep !== null && !!directoryItem && <DirectoryDataSettingsModal directoryItem={directoryItem} directoryData={directoryData} step={settingsModalStep} refresh={get} onClose={() => setSettingsModalStep(null)} />}
        </div >
    )
}

export default DirectoryItemView;