import { useEffect, useRef } from "react";
import InputWrapper, { FormPropsInterface } from "../InputWrapper";
import './index.scss';

interface ScrollableListItem<T> { key: T, label: string }

interface ScrollableListProps<T> extends FormPropsInterface<T> {
    items: ScrollableListItem<T>[];
}

const ScrollableList = <T,>({
    value,
    items,
    onChange,
    disabled,
    ...rest
}: ScrollableListProps<T>) => {
    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (value && ref.current) {
            ref.current.querySelector<HTMLDivElement>(`div.selected`)?.scrollIntoView();
        }
    }, []);

    return (
        <InputWrapper type="scrollable-list" {...rest}>
            <div className="scrollable-list" ref={ref}>
                {items.map((i) => (
                    <div
                        key={String(i.key)}
                        className={i.key === value ? 'selected' : undefined}
                        onClick={onChange ? () => onChange(value === String(i.key) ? undefined : i.key) : undefined}
                    >
                        {i.label}
                    </div>
                ))}
            </div>
        </InputWrapper>
    )
}

export default ScrollableList;