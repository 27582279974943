import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRequest } from '../../context';
import { Image } from '../../models/image';
import { BubbleHead } from '../../ui';
import ImageUploader from '../ImageUploader';
import InputWrapper, { FormPropsInterface } from '../InputWrapper';
import './index.scss';

interface ImageFieldProps extends FormPropsInterface<Image> {
    endpoint: string,
    className?: string,
}

const ImageField = ({
    value,
    endpoint,
    disabled,
    onChange,
    ...rest
}: ImageFieldProps) => {
    const [isLoading, setLoading] = useState<boolean>(false);
    const { t } = useTranslation();
    const request = useRequest();

    const handleChange = async (file: File) => {
        if (isLoading || !onChange) return;

        const data = new FormData();
        data.append('image', file);

        setLoading(true);
        request.post<string>(endpoint, data, { loader: true, headers: { 'Content-Type': 'multipart/form-data' } })
            .then((base64) => onChange({ base64 }))
            .catch(() => null)
            .finally(() => setLoading(false));
    }

    return (
        <InputWrapper type="image" {...rest}>
            {!!value?.base64 && <BubbleHead image={value?.base64} size={rest.inline ? 'large' : 'medium'} />}
            <div className="input-actions">
                {!!value?.base64 && <span onClick={onChange ? () => onChange(undefined) : undefined}>{t('actions:delete')}</span>}
                <ImageUploader onChange={handleChange}>{t('actions:update')}</ImageUploader>
            </div>
        </InputWrapper>
    );
}

export default ImageField;