import { useCallback, useEffect, useState } from "react";
import InputWrapper, { FormPropsInterface } from "../InputWrapper";
import './index.scss';
import { isType } from "../../utils/objects";

type SwitchListItem<T> = { key: T, label: string };

export interface SwitchProps<T> extends FormPropsInterface<T> {
    items?: T[] | SwitchListItem<T>[];
}

const Switch = <T,>({
    value,
    items,
    disabled,
    onChange,
    ...rest
}: SwitchProps<T>) => {
    const [listItems, setListItems] = useState<T[] | SwitchListItem<T>[]>([]);

    const handleChange = (_id: T) => {
        if (disabled || !onChange) return;
        onChange(_id);
    }

    const getId = useCallback((i: T | SwitchListItem<T>): T =>
        isType<SwitchListItem<T>>(i, (o) => o.hasOwnProperty('label')) ? i.key : i
        , []);

    const getLabel = useCallback((i: T | SwitchListItem<T>): string =>
        isType<SwitchListItem<T>>(i, (o) => o.hasOwnProperty('label')) ? i.label : String(i)
        , []);

    const isSelected = useCallback((i: T | SwitchListItem<T>): boolean =>
        (Array.isArray(value) && value.includes(getId(i))) || value === getId(i)
        , [getId, value]);

    useEffect(() => {
        if (items?.length) {
            setListItems(items);
        }
    }, [items]);

    return (
        <InputWrapper type="switch" {...rest}>
            {listItems.map((i) => (
                <div
                    key={String(getId(i))}
                    onClick={() => handleChange(getId(i))}
                    className={`switch-input-item ${!!isSelected(i) ? 'switch-item-selected' : ''}`}
                >
                    {getLabel(i)}
                </div>
            ))}
        </InputWrapper>
    )
};

export default Switch;
