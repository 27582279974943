import { useEffect, useState } from "react";
import { PartsDashboard } from "../../../models/part";
import { useRequest } from "../../../sg-react/context";
import { Card } from "../../../sg-react/ui";
import LabelValue from "../../../sg-react/ui/LabelValue";
import { Colors } from "../../../sg-react/ui/enums";

const DashboardParts = ({ }) => {
    const [partsDashboard, setPartsDashboard] = useState<PartsDashboard | null>(null);
    const request = useRequest();

    useEffect(() => {
        request.get<PartsDashboard>('/parts/dashboard', { errorMessage: true, withWorkspace: true })
            .then(setPartsDashboard)
            .catch(() => null);
    }, []);

    if (!partsDashboard) return null;

    return (
        <div className="col col-layout" id="dashboard-parts">
            <Card className="col">
                <div className={`row row-padding border-color-${partsDashboard.noSourceRatio === undefined ? '' : partsDashboard.noSourceRatio < 5 ? Colors.SUCCESS : partsDashboard.noSourceRatio < 10 ? Colors.WARNING : Colors.ERROR}`}>
                    <LabelValue
                        displayIfNull
                        label="No source"
                        value={(
                            <div className="row dashboard-part-value">
                                {partsDashboard.noSource}
                                {partsDashboard.noSourceRatio !== undefined && <span>{`(${partsDashboard.noSourceRatio}%)`}</span>}
                            </div>
                        )}
                    />
                </div>
            </Card>
            <Card className="col">
                <div className={`row row-padding border-color-${partsDashboard.monoSourceRatio === undefined ? '' : partsDashboard.monoSourceRatio < 5 ? Colors.SUCCESS : partsDashboard.monoSourceRatio < 10 ? Colors.WARNING : Colors.ERROR}`}>
                    <LabelValue
                        displayIfNull
                        label="Mono-source"
                        value={(
                            <div className="row dashboard-part-value">
                                {partsDashboard.monoSource}
                                {partsDashboard.monoSourceRatio !== undefined && <span>{`(${partsDashboard.monoSourceRatio}%)`}</span>}
                            </div>
                        )}
                    />
                </div>
            </Card>
            {partsDashboard.complexity?.average !== undefined && (
                <Card className="col">
                    <div className={`row row-padding border-color-${partsDashboard.complexity?.average === undefined ? '' : partsDashboard.complexity?.average <= 8 ? Colors.SUCCESS : partsDashboard.complexity?.average <= 12 ? Colors.WARNING : partsDashboard.complexity?.average <= 15 ? Colors.ERROR : Colors.CRITICAL}`}>
                        <LabelValue
                            displayIfNull
                            label="Average complexity"
                            value={(
                                <div className="row dashboard-part-value">
                                    {partsDashboard.complexity?.average}
                                </div>
                            )}
                        />
                    </div>
                </Card>
            )}
        </div>
    )
}
export default DashboardParts;