export enum MapAction {
    QuerySave = "QUERY_SAVE",
    QuerySaveAs = "QUERY_SAVE_AS",
    QueryOpen = "QUERY_OPEN",
    PlanSave = "PLAN_SAVE",
    PlanSaveAs = "PLAN_SAVE_AS",
    PlanOpen = "PLAN_OPEN",
    WindowFilters = "WINDOW_FILTER",
    WindowResults = "WINDOW_RESULTS",
    WindowPlan = "WINDOW_PLAN",
    WindowLayers = "WINDOW_LAYERS",
}

export enum DataManagementAction {
    Synchronise = "SYNCHRONISE",
}
