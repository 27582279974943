import { useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useMenuContext } from "../../context";
import { CloseIcon, MenuIcon } from "../../icons";
import Button from "../Button";
import DropdownMenu from "../DropdownMenu";
import './index.scss';
import { Colors } from "../enums";

const MenuContextMenu = () => {
    const { menus, items, setAction, removeItem, disabledActions } = useMenuContext();
    const location = useLocation();
    const navigate = useNavigate();

    const menusComponents = useMemo(() => menus
        ?.map(m => (
            <DropdownMenu disabledKeys={disabledActions} onAction={setAction} items={m.items} key={m.label}>
                <Button color="navigation" i18n={m.i18n} label={m.label} icon={m.icon ?? <MenuIcon />} />
            </DropdownMenu>
        )), [menus, setAction, disabledActions]);

    const itemsComponents = useMemo(() => items
        .map((item) => (
            <Button
                key={item.path}
                label={item.label}
                i18n={item.i18n}
                color={item.error ? Colors.ERROR : item.warning ? Colors.WARNING : item.color ?? 'navigation'}
                onClick={() => navigate(item.path + (item.searchParams ? `?${item.searchParams}` : ''))}
                active={item.active !== undefined ? item.active : location.pathname.replace(/\/$/, '') === item.path}
                loading={item.loading}
                icon={item.temporary ? <CloseIcon /> : item.icon}
                onIconClick={item.temporary ? () => removeItem(item) : undefined}
            />
        )), [items, removeItem, navigate, location.pathname]);

    return (
        <div className="menu-context-menu">
            {menusComponents}
            {itemsComponents}
        </div>
    );
}

export default MenuContextMenu;