// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.filters-panel > .filters-panel-item {
  display: flex;
  flex-direction: column;
  border-left: transparent 4px solid;
}`, "",{"version":3,"sources":["webpack://./src/sg-react/data/FiltersPanel/index.scss"],"names":[],"mappings":"AACI;EACI,aAAA;EACA,sBAAA;EACA,kCAAA;AAAR","sourcesContent":[".filters-panel {\n    & > .filters-panel-item {\n        display: flex;\n        flex-direction: column;\n        border-left: transparent 4px solid;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
