import InputWrapper, { FormPropsInterface } from "../InputWrapper";
import './index.scss';

interface ToggleProps extends FormPropsInterface<boolean> {
    label?: string;
    className?: string;
}

const Toggle = ({
    value,
    disabled,
    onChange,
    ...rest
}: ToggleProps) => {
    return (
        <InputWrapper type="toggle" {...rest}>
            <div
                className={`toggle-container ${value ? 'toggle-container-toggled' : ''}`}
                onClick={() => !disabled && onChange ? onChange(!value) : null}
            >
                <div className="toggle-button" />
            </div>
        </InputWrapper>
    )
};

export default Toggle;
