// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#error-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6px;
  margin: auto;
}`, "",{"version":3,"sources":["webpack://./src/sg-react/ui/RouteError/index.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,QAAA;EACA,YAAA;AACJ","sourcesContent":["#error-page {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    gap: 6px;\n    margin: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
