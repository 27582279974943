// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.label-value {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.label-value.inline {
  flex-direction: row;
  align-items: center;
  gap: 8px;
}
.label-value.fixed > label {
  width: 30%;
  text-align: right;
}`, "",{"version":3,"sources":["webpack://./src/sg-react/ui/LabelValue/index.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,YAAA;AACJ;AACI;EACI,mBAAA;EACA,mBAAA;EACA,QAAA;AACR;AAEI;EACI,UAAA;EACA,iBAAA;AAAR","sourcesContent":[".label-value {\n    display: flex;\n    flex-direction: column;\n    flex-grow: 1;\n\n    &.inline {\n        flex-direction: row;\n        align-items: center;\n        gap: 8px;\n    }\n\n    &.fixed > label {\n        width: 30%;\n        text-align: right;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
