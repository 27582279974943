import { ReactNode, useEffect, useState } from 'react';
import { DownIcon, UpIcon } from '../../icons';
import './index.scss';
import { useTranslation } from 'react-i18next';
import { translateLabel } from '../../utils/format';

interface CollapseProps {
    i18n?: string;
    title: string;
    opened?: boolean;
    onOpen?: () => void;
    closeOnClick?: boolean;
    withIcon?: boolean;
    children: ReactNode;
    className?: string;
}

const Collapse = ({
    title,
    i18n,
    withIcon = true,
    opened = false,
    onOpen,
    children,
    className
}: CollapseProps) => {
    const { t } = useTranslation();
    const [isOpened, setOpened] = useState<boolean>(opened);
    const [isInit, setInit] = useState<boolean>(opened);

    useEffect(() => {
        if (isOpened && !isInit) {
            setInit(true);
        }
        if (onOpen && isOpened) {
            onOpen();
        }
    }, [isOpened]);

    useEffect(() => {
        setOpened(opened);
    }, [opened]);

    useEffect(() => {
        setInit(false);
    }, [children, title]);

    return (
        <div className="collapse">
            <div className="collapse-header" onClick={() => setOpened(!isOpened)}>
                <div>
                    <span className="collapse-title">{translateLabel(title, t, i18n)}</span>
                </div>
                {withIcon && isOpened && <UpIcon />}
                {withIcon && !isOpened && <DownIcon />}
            </div>
            <div className={`collapse-children ${isOpened ? 'opened' : ''} ${className ?? ''}`}>
                {(isInit || isOpened) ? children : null}
            </div>
        </div>

    )
}

export default Collapse;