import { MouseEvent, ReactNode, useCallback, useEffect, useState } from 'react';
import { useOutsideClick } from '../../hooks';
import { CloseIcon, DownIcon, UpIcon } from '../../icons';
import './index.scss';
import { conditionnalClassnames } from '../../utils/helpers';
import FixedWrapper from '../FixedWrapper';
import { Colors } from '../enums';
import Button from '../Button';

interface DropdownProps {
    children: ReactNode;
    dropdown: ReactNode;
    disabled?: boolean;
    closeOnClick?: boolean;
    openOnHover?: boolean;
    withIcon?: boolean;
    withCloseButton?: boolean;
    maxHeight?: string;
    className?: string;
    onToggle?: (visible: boolean) => void;
    disposition?: 'right' | 'left';
}

const Dropdown = ({
    children,
    dropdown,
    disabled,
    maxHeight,
    className,
    closeOnClick,
    withIcon,
    withCloseButton,
    openOnHover,
    onToggle,
    disposition
}: DropdownProps) => {
    const [isVisible, setVisible] = useState<boolean>(false);
    const ref = useOutsideClick(useCallback(() => setVisible(false), [isVisible]));

    const handleClick = useCallback((e: MouseEvent) => {
        e.stopPropagation();

        if (closeOnClick) {
            setVisible(false);
        }
    }, [closeOnClick]);

    useEffect(() => {
        if (!disposition && ref.current) {
            const bound = ref.current.getBoundingClientRect();

            if (bound.top + bound.height > window.innerHeight) {
                ref.current.style.bottom = '0';
                ref.current.style.top = 'auto';
            } else {
                ref.current.style.top = '100%';
                ref.current.style.bottom = 'auto';
            }
            if (bound.left + bound.width > window.innerWidth) {
                ref.current.style.right = '0';
                ref.current.style.left = 'auto';
            } else {
                ref.current.style.left = '0';
                ref.current.style.right = 'auto';
            }
        }
    }, [isVisible, ref, disposition]);

    useEffect(() => {
        if (onToggle) onToggle(isVisible);
    }, [isVisible]);

    return (
        <div
            className={conditionnalClassnames({
                dropdown: true,
                "dropdown-left": disposition === 'left',
                "dropdown-right": disposition === 'right',
                className,
                "dropdown-visible": isVisible
            })}
            onMouseEnter={!!openOnHover && !disabled ? () => setVisible(true) : undefined}
            onMouseLeave={!!openOnHover && !disabled ? () => setVisible(false) : undefined}
        >
            <div
                className={`dropdown-children ${isVisible ? 'focus' : ''}`}
                onClick={!disabled && !openOnHover ? (e) => { e.stopPropagation(); setVisible(!isVisible); } : undefined}
            >
                {children}
                {isVisible && withIcon && <UpIcon className="dropdown-icon" />}
                {!isVisible && withIcon && <DownIcon className="dropdown-icon" />}
            </div>
            <div
                className="dropdown-content"
                style={maxHeight ? { maxHeight } : undefined}
                ref={ref}
                onClick={handleClick}
            >
                {dropdown}
                {!!withCloseButton && (
                    <div className="dropdown-content-close">
                        <Button small onClick={() => setVisible(false)} label="close" i18n="actions" color={Colors.PRIMARY} icon={<CloseIcon />} />
                    </div>
                )}
            </div>
        </div>
    )
}

export default Dropdown;