import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Label, ResponsiveContainer, Scatter, ScatterChart, Tooltip, XAxis, YAxis, ZAxis } from 'recharts';
import { DirectoryItemViewPatchProps } from '..';
import { PartSize } from '../../../models/part';
import { useWorkspaceContext } from '../../../sg-react/context';
import TagsList from '../../../sg-react/data/TagsList';
import { Switch } from '../../../sg-react/form';
import { Card, Nothing } from '../../../sg-react/ui';
import ValueDescription from '../../../sg-react/ui/ValueDescription';

export interface IndicatorChartData {
    chartData: {
        value: number;
        count: number;
        index: number;
        color: string;
    }[];
    domain: number[];
    ticks: number[];
}

const DirectoryDataParts = ({ directoryItem, directoryData, refresh, canEdit }: DirectoryItemViewPatchProps) => {
    const { currentWorkspace } = useWorkspaceContext();
    const [mode, setMode] = useState<string | undefined>('chart');
    const { t } = useTranslation();

    const modes = useMemo(() => [{ key: 'chart', label: t('charts:chart') }, { key: 'list', label: t('data:list') }], []);

    const chartData = useMemo(() => {
        const chartData: { size: PartSize, complexity: number, count: number }[] = [];

        for (const part of directoryData.parts ?? []) {
            if (!part.complexity) continue;

            const index = chartData.findIndex(c => c.size === part.size && c.complexity === part.complexity?.total);
            if (index === -1) {
                chartData.push({ size: part.size, complexity: part.complexity.total, count: 1 });
            } else {
                chartData[index].count++;
            }
        }

        return chartData;
    }, [directoryData.parts, currentWorkspace]);

    return (
        <Card
            id="directory-view-parts"
            title="parts"
            i18n="parts"
            overflow="hidden"
            options={<Switch id="parts-switch" items={modes} value={mode} onChange={setMode} />}
        >
            {!directoryData.parts?.length && <Nothing />}
            {!!directoryData.parts?.length && mode === 'chart' && (
                <ResponsiveContainer minWidth="100%" width="100%" height={280}>
                    <ScatterChart margin={{ left: 0 }}>
                        <YAxis width={30} type="category" tick={false} dataKey="size" name="Size" domain={Object.keys(PartSize)} allowDuplicatedCategory={false}>
                            <Label
                                style={{
                                    textAnchor: "middle",
                                }}
                                angle={270}
                                value="Size" />
                        </YAxis>
                        <XAxis type="number" tick={false} dataKey="complexity" name="Complexity" domain={[0, 16]}>
                            <Label
                                style={{
                                    textAnchor: "middle",
                                }}
                                value="Complexity" />
                        </XAxis>
                        <ZAxis type="number" dataKey="count" range={[20, 60]} name="Count" />
                        <Tooltip cursor={{ strokeDasharray: '3 3' }} />
                        <Scatter data={chartData} fill="#8884d8" />
                    </ScatterChart>
                </ResponsiveContainer>
            )}
            {!!directoryData.parts?.length && mode === 'list' && (
                <div className="simple-list">
                    {directoryData.parts?.map(part => (
                        <ValueDescription
                            key={part._id}
                            value={part.name}
                            description={part.steps.find(s => s.businesses.some(b => b.directoryItemId === directoryItem._id))?.name}
                            tags={<TagsList tagsIds={part.tagsIds} />}
                        />
                    ))}
                </div>
            )}
        </Card>
    )
}
export default DirectoryDataParts