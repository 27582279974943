import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../../context";
import { useWorkspaceContext } from "../../context/WorkspaceContext";
import { AuthenticationStatus } from "../../models/auth";
import { WorkspaceRole } from "../../models/workspace";
import { Role } from "../../utils/enums";

interface ProtectedRouteProps {
    children: JSX.Element;
    roles?: { roles?: Role[], workspaceRoles?: WorkspaceRole[] };
    alternative?: JSX.Element;
}

export const ProtectedRoute = ({ children, roles, alternative }: ProtectedRouteProps) => {
    const { currentRole, status, redirectToPortal } = useAuthContext();
    const { currentWorkspace } = useWorkspaceContext();
    const navigate = useNavigate();

    useEffect(() => {
        if (status !== AuthenticationStatus.Authenticated) {
            redirectToPortal();
        }
    }, [status]);

    if (status !== AuthenticationStatus.Authenticated) return null;

    if (roles) {
        if (roles.roles?.length && !roles.roles.includes(currentRole)) {
            if (alternative) return alternative;
            navigate('/error/401');
        } else if (roles.workspaceRoles?.length && !roles.workspaceRoles.includes(currentWorkspace.role)) {
            if (alternative) return alternative;
            navigate('/error/401');
        }
    }

    return children;
}
