// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form {
  overflow: auto;
}
.form > .form-actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.form > .form-actions > * {
  display: flex;
  align-items: center;
  gap: 4px;
}`, "",{"version":3,"sources":["webpack://./src/sg-react/form/Form/index.scss"],"names":[],"mappings":"AAAA;EACI,cAAA;AACJ;AACI;EACI,aAAA;EACA,mBAAA;EACA,8BAAA;AACR;AACQ;EACI,aAAA;EACA,mBAAA;EACA,QAAA;AACZ","sourcesContent":[".form {\n    overflow: auto;\n\n    & > .form-actions {\n        display: flex;\n        align-items: center;\n        justify-content: space-between;\n\n        & > * {\n            display: flex;\n            align-items: center;\n            gap: 4px;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
