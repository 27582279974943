import { useCallback, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAuthContext, useRequest, useUiContext } from '../../../context';
import { Form } from '../../../form';
import { useForm } from '../../../hooks';
import { RightIcon } from '../../../icons';
import { NewPassword, TokenResponse } from '../../../models/auth';
import { User } from '../../../models/user';
import { Button } from '../../../ui';
import DepthContainer from '../../../ui/DepthContainer';
import Info from '../../../ui/Info';
import { Colors } from '../../../ui/enums';
import NewPasswordForm, { PASSWORD_VALIDATION } from '../../Account/components/NewPasswordForm';
import ProfileForm, { PROFILE_VALIDATION } from '../../Account/components/ProfileForm';

const ConfirmSignup = () => {
    const { t } = useTranslation();
    const [searchParams] = useSearchParams();
    const { handleToken } = useAuthContext();
    const { isDarkMode } = useUiContext();
    const { entity, validate, attachInput } = useForm<NewPassword & User>({});
    const [isPasswordSet, setPasswordSet] = useState<boolean>(false);
    const [isInit, setInit] = useState<boolean>(false);
    const request = useRequest();
    const [token, setToken] = useState<string>();
    const navigate = useNavigate();

    const handleInit = useCallback(async (): Promise<void> => {
        if (request.loading) return;

        request.post<{ passwordSet: boolean }>(
            `/auth/confirm-signup`,
            { token },
            {
                loader: true,
                errorMessage: true,
            })
            .then((data) => {
                setPasswordSet(data.passwordSet);
                setInit(true);
            })
            .catch(() => null);
    }, [validate, request, token, entity]);

    const handleSetPassword = useCallback(() => validate(
        (entity) => {
            if (request.loading) return;

            request.post(
                `/auth/confirm-signup/password`,
                { password: entity.password, token },
                {
                    loader: true,
                    errorMessage: true,
                })
                .then(() => setPasswordSet(true))
                .catch(() => null);
        },
        PASSWORD_VALIDATION
    ), [validate, request, token]);

    const handleSetProfile = useCallback(() => validate(
        (entity) => {
            if (request.loading || entity.password !== entity.passwordVerify) return;

            request.post<TokenResponse>(
                `/auth/confirm-signup/profile`,
                { ...entity, password: undefined, passwordVerify: undefined, token },
                {
                    loader: true,
                    errorMessage: true,
                    withCredentials: true,
                })
                .then(handleToken)
                .catch(() => null);
        },
        PROFILE_VALIDATION
    ), [validate, request, token, handleToken]);

    useEffect(() => {
        if (token) {
            handleInit();
        }
    }, [token]);

    useEffect(() => {
        if (searchParams.get('cs_tk')) {
            setToken(searchParams.get('cs_tk')!);
        } else {
            navigate('/');
        }
    }, []);

    if (!isInit) return null;

    return (
        <div className="row row-layout">
            <DepthContainer id="portal-promo-user">
                <img src={`/images/promo_user_${isDarkMode ? 'dark' : 'light'}.png`} alt="" />
            </DepthContainer>
            <div className="col col-layout" style={{ overflow: 'auto', height: '100%' }}>
                <div className="row">
                    <img src="/images/logo/logo_white.png" alt="" id="portal-logo-small" />
                    <h2><Trans i18nKey="ui:welcome" /></h2>
                </div>
                <Info label={isPasswordSet ? 'confirm_subscription_profile_tooltip' : 'confirm_subscription_password_tooltip'} i18n="auth" noIcon />
                {isPasswordSet
                    ? (
                        <Form onSubmit={handleSetProfile} loading={request.loading}>
                            <ProfileForm attachInput={attachInput} />
                            <div className="row row-end">
                                <Button color={Colors.ACCENT} i18n="actions" label="submit" type="submit" loading={request.loading} />
                            </div>
                        </Form>
                    ) : (
                        <Form onSubmit={handleSetPassword} loading={request.loading}>
                            <NewPasswordForm<NewPassword> attachInput={attachInput} entity={entity} />
                            <div className="row row-end">
                                <Button color={Colors.ACCENT} icon={<RightIcon />} i18n="actions" label="next" type="submit" loading={request.loading} />
                            </div>
                        </Form>
                    )
                }s
            </div>
        </div>
    )
}

export default ConfirmSignup;
