import { useMemo } from "react";

interface PieChartSvgProps {
    values: { count: number, color?: string }[];
    withTotal?: boolean;
    withCenter?: boolean;
    size?: number;
}

const PieChartSvg = ({ values, size, withTotal, withCenter }: PieChartSvgProps) => {

    const arcs = useMemo(() => {
        const total = Object.values(values).reduce((t, v) => t + v.count, 0);

        const slices: { count: number, color?: string }[] = [];

        for (const v of values) {
            if (!v.count) continue;
            const index = slices.findIndex(s => s.color === v.color);
            if (index >= 0) {
                slices[index].count += v.count;
            } else {
                slices.push(v);
            }
        }

        if (slices.length === 0 || total === 0 || !slices[0].color) {
            return null;
        }

        let cumulativeRadians = 0;

        const _arcs = values.filter(({ count, color }) => !!count && !!color).map(({ count, color }) => {
            const percent = count / total;

            const startX = Math.cos(cumulativeRadians);
            const startY = Math.sin(cumulativeRadians);

            cumulativeRadians += 2 * Math.PI * percent;

            const endX = Math.cos(cumulativeRadians);
            const endY = Math.sin(cumulativeRadians);

            const largeArcFlag = percent > 0.5 ? 1 : 0;

            const d = [
                `M ${startX} ${startY}`,
                `A 1 1 0 ${largeArcFlag} 1 ${endX} ${endY}`
            ].join(` `);

            return <path
                key={color}
                d={d}
                stroke={color}
                fill="none"
            />;
        });

        if (withTotal) {
            _arcs.push(<text x="0" y="0.1" dominantBaseline="middle" textAnchor="middle">{total}</text>);
        }

        return _arcs;
    }, [values]);

    return (
        <svg
            width={size ?? "100%"}
            height={size ?? "100%"}
            viewBox="-1.2 -1.2 2.4 2.4"
            className="pie-chart-svg"
            strokeWidth={0.3}
            fill="none"
        >
            {arcs}
            {!!withCenter && <circle cx="0" cy="0" r="0.85" className="pie-chart-svg-center" />}
        </svg>
    )
}
export default PieChartSvg;