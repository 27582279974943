import { AxiosError } from "axios";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRequest } from "../../context";
import { TextArea } from "../../form";
import { translateLabel } from "../../utils/format";
import Collapse from "../Collapse";
import Info from "../Info";
import LabelValue from "../LabelValue";
import Modal from "../Modal";
import { ToastData } from "../Toast";

interface ToastInfoModalProps {
    toast: ToastData;
    onClose: () => void;
}

const ToastInfoModal = ({ toast, onClose }: ToastInfoModalProps) => {
    const { t } = useTranslation();
    const request = useRequest();
    const [message, setMessage] = useState<string | undefined>();

    const handleSubmit = useCallback(() => {

    }, [message, request, toast]);

    const errorData = useMemo(() => {
        try {
            return {
                status: (toast.error as any)?.response?.status ?? (toast.error as any)?.status,
                message: (toast.error as any)?.response?.data?.message?.toString() ?? (toast.error as any)?.message?.toString(),
            }
        } catch {
            return {};
        }
    }, [toast]);

    return (
        <Modal
            i18n="ui"
            title="error_info"
            onClose={onClose}
            onSubmit={handleSubmit}
            size="medium"
        >
            <Info i18n="ui" label="error_info_tooltip" />
            <LabelValue i18n="ui" label="error_error" value={translateLabel(toast.message, t, toast.i18n === true ? 'messages' : toast.i18n)} />
            <LabelValue i18n="ui" label="error_id" value={toast._id} />
            <LabelValue i18n="ui" label="error_code" value={errorData?.status} />
            <LabelValue i18n="ui" label="error_message" value={errorData?.message} />
            <TextArea rows={5} id="error_admin_message" value={message} onChange={setMessage} placeholder={t('ui:error_scenario_tooltip')} />
            <Collapse i18n="ui" title="error_technical">
                <div style={{ whiteSpace: 'pre' }}>
                    {JSON.stringify({ ...toast.error, response: (toast.error as AxiosError).response }, null, 2)}
                </div>
            </Collapse>
        </Modal>
    )
}
export default ToastInfoModal;