import { ReactNode } from "react";
import './index.scss';

interface DepthContainerProps {
    id?: string;
    className?: string;
    children: ReactNode;
}

const DepthContainer = ({ children, id, className }: DepthContainerProps) => (
    <div className={`col depth-container ${className ?? ''}`} id={id}>
        {children}
    </div>
);

export default DepthContainer;