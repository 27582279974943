// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.layout-sub-nav {
  padding: var(--theme-layout-padding);
  width: 100%;
  display: grid;
  grid-template-rows: auto minmax(0, 1fr);
}
.layout-sub-nav.no-scroll {
  height: 100%;
}
.layout-sub-nav.no-nav {
  grid-template-rows: minmax(0, 1fr) 0;
}
.layout-sub-nav > .layout-sub-nav-navigation {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  margin-bottom: var(--theme-layout-padding);
  flex-shrink: 0;
  width: 100%;
}
.layout-sub-nav > .layout-sub-nav-navigation > .button {
  max-width: 140px;
}
.layout-sub-nav > .layout-sub-nav-navigation > .button > span {
  white-space: nowrap;
}`, "",{"version":3,"sources":["webpack://./src/sg-react/ui/LayoutSubNav/index.scss"],"names":[],"mappings":"AAAA;EACI,oCAAA;EACA,WAAA;EACA,aAAA;EACA,uCAAA;AACJ;AACI;EACI,YAAA;AACR;AAEI;EACI,oCAAA;AAAR;AAGI;EACI,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,SAAA;EACA,0CAAA;EACA,cAAA;EACA,WAAA;AADR;AAGQ;EACI,gBAAA;AADZ;AAGY;EACI,mBAAA;AADhB","sourcesContent":[".layout-sub-nav {\n    padding: var(--theme-layout-padding);\n    width: 100%;\n    display: grid;\n    grid-template-rows: auto minmax(0, 1fr);\n\n    &.no-scroll {\n        height: 100%;\n    }\n\n    &.no-nav {\n        grid-template-rows: minmax(0, 1fr) 0;\n    }\n\n    & > .layout-sub-nav-navigation {\n        display: flex;\n        flex-direction: row;\n        align-items: center;\n        gap: 12px;\n        margin-bottom: var(--theme-layout-padding);\n        flex-shrink: 0;\n        width: 100%;\n\n        & > .button {\n            max-width: 140px;\n\n            & > span {\n                white-space: nowrap;\n            }\n        }\n    }\n\n    &.with-padding {\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
