import { useCallback, useState } from "react";
import { DirectoryItemsListItem } from "../../components/DirectoryItemsList";
import { DirectoryItem } from "../../models/directory-item";
import { useRequest } from "../../sg-react/context";
import RevisionModal from "../../sg-react/data/RevisionsModal";
import { DIRECTORY_REVISION_FIELDS } from "../DirectoryItemForm";
import DashboardBusinesses from "./components/DashboardBusinesses";
import DashboardDates from "./components/DashboardDates";
import DashboardIncidents from "./components/DashboardIncidents";
import DashboardParts from "./components/DashboardParts";
import DashboardRevenues from "./components/DashboardRevenues";
import './index.scss';

interface DashboardData {
    revisions?: DirectoryItem[];
    requests?: any[];
    recent: (DirectoryItem & { tag?: string })[];
    new: (DirectoryItem & { tag?: string })[];
}

const Dashboard = () => {
    const [dashboardData, setDashboardData] = useState<DashboardData>({ recent: [], new: [] });
    const [directoryItemForRevision, setDirectoryItemForRevision] = useState<DirectoryItem | null>(null);
    const request = useRequest();

    const getDateTag = useCallback((d: number): string | undefined => {
        const today = new Date();
        const date = new Date(d);

        if (today.getFullYear() !== date.getFullYear()) return;
        if (today.getMonth() !== date.getMonth()) return 'this_year';
        if (today.getMonth() === date.getMonth() + 1) return 'last_month';
        if (today.getDate() - date.getDate() < 7 && today.getDay() > date.getDay()) return 'this_week';
        if (today.getDate() !== date.getDate()) return 'this_month';
        return 'today';
    }, []);

    const get = useCallback(() => {
        request.get<DashboardData>('/directory/dashboard', { withWorkspace: true, loader: true, errorMessage: true })
            .then(data => setDashboardData({
                ...data,
                new: data.new.map(d => ({ ...d, tag: getDateTag(d.createdAt) })),
                recent: data.recent.map(d => ({ ...d, tag: getDateTag(d.updatedAt) }))
            }))
            .catch(() => null);
    }, [request]);

    return (
        <div id="dashboard" className="layout-container col col-layout">
            <div className="row row-layout">
                <div className="col col-layout col-lg-70">
                    <DashboardRevenues />
                    <div id="dashboard-businesses" className="row row-layout row-wrap">
                        <DashboardBusinesses />
                        <DashboardParts />
                    </div>
                </div>
                <div className="col col-layout col-lg-30">
                    <DashboardDates />
                    <DashboardIncidents />
                </div>
            </div>
            {!!directoryItemForRevision && (
                <RevisionModal
                    getUrl={`/directory/${directoryItemForRevision._id}/revisions`}
                    postUrl="/directory/revision"
                    component={DirectoryItemsListItem}
                    fields={DIRECTORY_REVISION_FIELDS}
                    entity={directoryItemForRevision}
                    onClose={() => setDirectoryItemForRevision(null)}
                    onSubmit={get}
                />
            )}
        </div>
    );
}



/* 
<div className="row row-layout row-equal-cols">
{!!dashboardData.revisions?.length && (
    <Card i18n="entity" title="revisions">
        <Info i18n="entity" label="revisions_tooltip" noIcon />
        <div className="list">
            {dashboardData.revisions?.map(revision => (
                <ListItem
                    key={revision._id}
                    value={revision.name}
                    description={revision.revisions?.map(r => `${dateToLocaleString(r.createdAt, true)} - ${r.createdBy?.fullName}`).join('\n')}
                    preline
                    onClick={() => setDirectoryItemForRevision(revision)}
                />
            ))}
        </div>
    </Card>
)}
</div> */

export default Dashboard;