import { Fragment, Ref, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Segmentation, filterTree } from '../../models/segmentation';
import { useRequest, useWorkspaceContext } from '../../sg-react/context';
import { ModalDelete, SearchField } from '../../sg-react/data';
import { Checkbox, Switch } from '../../sg-react/form';
import { UseFocusHandleRef } from '../../sg-react/hooks';
import { DownIcon, EditIcon, PlusIcon, RightIcon, TrashIcon } from '../../sg-react/icons';
import { Button } from '../../sg-react/ui';
import Pill from '../../sg-react/ui/Pill';
import Tag from '../../sg-react/ui/Tag';
import ValueDescription from '../../sg-react/ui/ValueDescription';
import { Colors } from '../../sg-react/ui/enums';
import { toggleInArray } from '../../sg-react/utils/objects';
import SegmentationForm from '../SegmentationForm';
import './index.scss';

interface SegmentationNodeProps {
    segmentation: Segmentation;
    color?: string;
    selected?: string[];
    matching?: string[];
    parentSelected?: boolean;
    showAll: boolean;
    onSelect?: (segmentation: Segmentation) => void;
    onEdit?: (segmentation: Partial<Segmentation>) => void;
    onDelete?: (segmentation: Segmentation) => void;
}

const SegmentationNode = ({
    segmentation,
    showAll,
    selected,
    matching,
    parentSelected,
    onSelect,
    onEdit,
    onDelete,
    color
}: SegmentationNodeProps) => {
    const [isOpened, setOpened] = useState<boolean>(false);

    const isSelected = useMemo(() => parentSelected || selected?.includes(segmentation._id), [segmentation, selected, parentSelected]);
    const childrenSelectedCount = useMemo(() => segmentation.childrenRecursiveIds.filter(_id => selected?.includes(_id)).length ?? 0, [segmentation, selected]);
    const isMatching = useMemo(() => matching?.some(m => segmentation._id === m || segmentation.childrenRecursiveIds.includes(m)), [segmentation, matching]);


    return (
        <Fragment>
            <div className="segmentation-node">
                <div className="segmentation-node-icon">
                    {!!isOpened && !isSelected && (!!segmentation.children?.length) && <DownIcon className="icon icon-small pointer" onClick={() => setOpened(false)} />}
                    {!isOpened && !isSelected && (!!segmentation.children?.length) && <RightIcon className="icon icon-small pointer" onClick={() => setOpened(true)} />}
                </div>
                <div className="segmentation-node-item" onClick={onSelect ? () => onSelect(segmentation) : undefined}>
                    {!!onSelect && <Checkbox id={segmentation._id} value={isSelected} onChange={() => onSelect(segmentation)} />}
                    {!isSelected && !!childrenSelectedCount && <Pill color={Colors.ACCENT} value={childrenSelectedCount} />}
                    <ValueDescription
                        value={<Tag color={segmentation.color ?? color} label={segmentation.name} />}
                        description={segmentation.description}
                    />
                    {isMatching && <Tag color={Colors.SUCCESS} label="matching" i18n="filters" />}
                    {onDelete && <Button small color="navigation" icon={<TrashIcon />} onClick={() => onDelete(segmentation)} />}
                    {onEdit && <Button small color="navigation" icon={<PlusIcon />} onClick={() => onEdit({ parentId: segmentation._id, parent: segmentation })} />}
                    {onEdit && <Button small color="navigation" icon={<EditIcon />} onClick={() => onEdit(segmentation)} />}
                </div>
            </div>
            {!isSelected && !!segmentation.children?.length && !!isOpened && (
                <div className="segmentation-node-children">
                    {segmentation.children.map(child => (
                        <SegmentationNode
                            key={child._id}
                            showAll={showAll}
                            segmentation={child}
                            selected={selected}
                            matching={matching}
                            parentSelected={isSelected}
                            onSelect={onSelect}
                            onEdit={onEdit}
                            onDelete={onDelete}
                            color={segmentation.color ?? color}
                        />)
                    )}
                </div>
            )}
        </Fragment>
    )
}

interface SegmentationsTreeProps {
    workspaceId?: string;
    selected?: string[];
    matching?: string[];
    editable?: boolean;
    onSelect?: (segmentations: string[]) => void,
    focusHandle?: Ref<UseFocusHandleRef>;
    defaultMode?: 'all' | 'selected';
    hideSearch?: boolean;
}

const SegmentationsTree = ({ workspaceId, defaultMode, selected, matching, onSelect, editable, focusHandle, hideSearch }: SegmentationsTreeProps) => {
    const request = useRequest();
    const { currentWorkspace } = useWorkspaceContext();
    const [segmentations, setSegmentations] = useState<Segmentation[]>([]);
    const [segmentationToEdit, setSegmentationToEdit] = useState<Partial<Segmentation> | null>(null);
    const [segmentationToDelete, setSegmentationToDelete] = useState<Segmentation | null>(null);
    const [showAll, setShowAll] = useState<boolean>(defaultMode !== 'selected');
    const [keyword, setKeyword] = useState<string | undefined>();
    const { t } = useTranslation();

    const filteredSegmentations = useMemo(() => filterTree(segmentations, showAll, selected ?? [], keyword), [segmentations, keyword, showAll, selected])
    const currentWorkspaceId = useMemo(() => workspaceId ?? currentWorkspace._id, [currentWorkspace, workspaceId]);

    const handleSelect = useCallback((segmentation: Segmentation) => {
        if (!onSelect) return;

        onSelect(toggleInArray(selected, segmentation._id));
    }, [selected, onSelect]);


    const getSegmentations = useCallback(() => {
        request.get<Segmentation[]>('/segmentations/tree', {
            loader: true,
            withWorkspace: currentWorkspaceId,
            errorMessage: true
        })
            .then(setSegmentations)
            .catch(() => false);
    }, [request]);

    const handleDelete = useCallback((segmentation: Segmentation) => {
        request.delete(`/segmentations/${segmentation._id}`, {
            loader: true,
            withWorkspace: currentWorkspaceId,
            successMessage: true,
            errorMessage: true
        })
            .then(() => {
                setSegmentationToDelete(null);
                getSegmentations();
            })
            .catch(() => false);
    }, [request, getSegmentations]);

    useEffect(() => {
        getSegmentations();
    }, [workspaceId, currentWorkspace]);

    return (
        <div className="segmentation-tree">
            {!hideSearch && (
                <div className="row">
                    <SearchField minLength={2} onChange={setKeyword} focusHandle={focusHandle} />
                    <Switch<boolean> id="show_all" items={[{ key: true, label: t('actions:all') }, { key: false, label: t('actions:selected') }]} value={showAll} onChange={(v) => setShowAll(!!v)} />
                    {!!editable && <Button color="navigation" icon={<PlusIcon />} onClick={() => setSegmentationToEdit({})} />}
                </div>
            )}
            <div className="segmentation-tree-content">
                {filteredSegmentations.map(segmentation => (
                    <SegmentationNode
                        key={segmentation._id}
                        showAll={showAll}
                        segmentation={segmentation}
                        selected={selected}
                        matching={matching}
                        onSelect={onSelect ? handleSelect : undefined}
                        onDelete={editable ? setSegmentationToDelete : undefined}
                        onEdit={editable ? setSegmentationToEdit : undefined}
                    />
                ))}
            </div>
            {segmentationToEdit && <SegmentationForm workspaceId={currentWorkspaceId} segmentation={segmentationToEdit} onClose={() => setSegmentationToEdit(null)} onSubmit={() => getSegmentations()} />}
            {segmentationToDelete && <ModalDelete withDebounce onClose={() => setSegmentationToDelete(null)} onSubmit={() => handleDelete(segmentationToDelete)} />}
        </div>
    )
}
export default SegmentationsTree;