import { useCallback } from "react";
import { Specification } from "../../models/specification";
import { useRequest } from "../../sg-react/context";
import { NAMED_ENTITY_VALIDATION } from "../../sg-react/models/entity";
import { WizardModal, WizardStep } from "../../sg-react/ui/Wizard";
import SpecificationiWizardIdentification from "./SpecificationWizardIdentification";
import SpecificationiWizardTags from "./SpecificationWizardTags";

const STEPS: WizardStep<Specification>[] = [{
    label: 'creation.identification',
    tooltip: 'creation.identification_text',
    i18n: 'specifications',
    validation: NAMED_ENTITY_VALIDATION,
    component: SpecificationiWizardIdentification,
}, {
    label: 'creation.tags',
    tooltip: 'creation.tags_text',
    i18n: 'specifications',
    component: SpecificationiWizardTags,
}];

interface SpecificationWizardProps {
    specification?: Partial<Specification>;
    onClose: () => void;
    onSubmit?: () => void;
}

const SpecificationWizard = ({ onClose, onSubmit, specification }: SpecificationWizardProps) => {
    const request = useRequest();

    const handleSubmit = useCallback((entity: Partial<Specification>) => {
        const requestMethod = !entity._id ? request.post : request.put;

        requestMethod<Specification>('/specifications', entity, {
            loader: true,
            successMessage: true,
            errorMessage: true,
            withWorkspace: true
        })
            .then(() => {
                if (onSubmit) {
                    onSubmit();
                }
                onClose();
            })
            .catch(() => null);
    }, [request, onSubmit, onClose]);

    return (
        <WizardModal<Specification>
            i18n="actions"
            title={specification?._id ? 'edit' : 'create'}
            steps={STEPS}
            initialMaxStep={3}
            initial={specification ?? {}}
            onSubmit={handleSubmit}
            onClose={onClose}
        />
    )
}

export default SpecificationWizard;