import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { DIRECTORY_ITEM_CONTACTS_VALIDATION, DIRECTORY_ITEM_INFORMATION_VALIDATION, DirectoryItem } from "../../models/directory-item";
import { useRequest } from "../../sg-react/context";
import Validation, { ObjectValidation } from "../../sg-react/form/Validation/class";
import { LOCATION_VALIDATION } from "../../sg-react/models/location";
import { Modal } from "../../sg-react/ui";
import Wizard, { WizardStep } from "../../sg-react/ui/Wizard";
import DirectoryItemCreateData from "./components/DirectoryItemCreateData";
import DirectoryItemCreateDuplicatesIdentification from "./components/DirectoryItemCreateDuplicatesIdentification";
import DirectoryItemCreateDuplicatesLocation from "./components/DirectoryItemCreateDuplicatesLocation";
import DirectoryItemCreateIdentification from "./components/DirectoryItemCreateIdentification";
import DirectoryItemCreateLocation from "./components/DirectoryItemCreateLocation";
import DirectoryItemCreatePrivacy from "./components/DirectoryItemCreatePrivacy";
import DirectoryItemCreateSummary from "./components/DirectoryItemCreateSummary";
import './index.scss';

const STEPS: WizardStep<DirectoryItem>[] = [{
    label: 'creation.introduction',
    tooltip: 'creation.introduction_text',
    i18n: 'directory'
}, {
    label: 'creation.identification',
    tooltip: 'creation.identification_text',
    i18n: 'directory',
    validation: new ObjectValidation({
        name: Validation.string().required().minLength(3).maxLength(150),
        localBusinessId: Validation.string().required().minLength(5).maxLength(75),
    }),
    component: DirectoryItemCreateIdentification,
}, {
    label: 'creation.duplicates_check_identification',
    tooltip: 'creation.duplicates_check_identification_text',
    i18n: 'directory',
    component: DirectoryItemCreateDuplicatesIdentification,
    disabled: (d) => !!d._id
}, {
    label: 'creation.location',
    tooltip: 'creation.location_text',
    i18n: 'directory',
    validation: new ObjectValidation({ location: LOCATION_VALIDATION }),
    component: DirectoryItemCreateLocation,
}, {
    label: 'creation.duplicates_check_location',
    tooltip: 'creation.duplicates_check_location_text',
    i18n: 'directory',
    component: DirectoryItemCreateDuplicatesLocation,
    disabled: (d) => !!d._id
}, {
    label: 'creation.data',
    tooltip: 'creation.data_text',
    i18n: 'directory',
    validation: new ObjectValidation({ ...DIRECTORY_ITEM_INFORMATION_VALIDATION, ...DIRECTORY_ITEM_CONTACTS_VALIDATION }),
    component: DirectoryItemCreateData,
}, {
    label: 'creation.privacy',
    tooltip: 'creation.privacy_text',
    i18n: 'directory',
    component: DirectoryItemCreatePrivacy,
    disabled: (d) => !!d._id
}, {
    label: 'creation.summary',
    tooltip: 'creation.summary_text',
    i18n: 'directory',
    component: DirectoryItemCreateSummary,
}];

interface DirectoryItemCreateProps {
    onClose: () => void;
}

const INITIAL_ENTITY = { permissions: { allowImport: true, allowSg: true, workspaces: [], users: [] } };

const DirectoryItemCreate = ({ onClose }: DirectoryItemCreateProps) => {
    const request = useRequest();
    const navigate = useNavigate();

    const handleSubmit = useCallback((entity: Partial<DirectoryItem>) => {
        request.post<DirectoryItem>('/directory', { ...entity, parents: undefined }, { withWorkspace: true, errorMessage: true, successMessage: true, loader: true })
            .then((data) => navigate(`/view/${data._id}`))
            .catch(() => null);
    }, [request]);

    return (
        <Modal
            id="directory-item-create"
            i18n="directory"
            title="create"
            overflow="hidden"
        >
            <Wizard steps={STEPS} initial={INITIAL_ENTITY} onSubmit={handleSubmit} onClose={onClose} />
        </Modal>
    )
}

export default DirectoryItemCreate;