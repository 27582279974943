import { ReactNode, useCallback, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../context';
import { Role } from '../../utils/enums';
import Card from '../Card';
import './index.scss';
import { translateLabel } from '../../utils/format';

export interface LayoutLeftTabsItem {
    id: string;
    i18n?: string;
    label?: string;
    icon?: ReactNode;
    roles?: Role[];
    children?: LayoutLeftTabsItem[];
}

interface LayoutLeftTabsProps {
    items: LayoutLeftTabsItem[];
    children: ReactNode;
    id?: string;
}

const LayoutLeftTabs = ({ items, children, id }: LayoutLeftTabsProps) => {
    const { currentUser, currentRole } = useAuthContext();
    const { t } = useTranslation();
    const contentRef = useRef<HTMLDivElement>(null);

    const goTo = useCallback((id: string, child?: string) => {
        if (!contentRef.current) return;

        const element = contentRef.current.querySelector<HTMLDivElement>(`#${id}`);

        if (!element) return;

        if (child) {
            const childElement = element.querySelector<HTMLDivElement>(`#${child}`);
            if (childElement) {
                childElement.scrollIntoView({ behavior: 'smooth' });
                return;
            }
        }

        element.scrollIntoView({ behavior: 'smooth' });
    }, [items]);

    const tabs = useMemo(() => items
        .filter(item => !item.roles || (currentRole && item.roles.includes(currentRole)))
        .map(item => (
            <div key={item.id} className="layout-left-tabs-tab">
                <span onClick={() => goTo(item.id)}>{translateLabel(item.label ?? item.id, t, item.i18n)}</span>
                {!!item.children?.length && (
                    <div className="layout-left-tabs-tab-children">
                        {item.children.map(c => <span key={c.id} onClick={() => goTo(item.id, c.id)}>{translateLabel(c.label ?? c.id, t, c.i18n)}</span>)}
                    </div>
                )}
            </div>
        )), [items, currentRole, currentUser, goTo, t])

    return (
        <div id={id} className="layout-left-tabs row row-layout">
            <Card className="layout-left-tabs-tabs col col-layout col-lg-20">
                {tabs}
            </Card>
            <div className="layout-left-tabs-content col col-layout col-lg-80" ref={contentRef}>
                {children}
            </div>
        </div>
    )
}

export default LayoutLeftTabs;