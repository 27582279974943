import { useCallback, useState } from "react";
import { useRequest, useUiContext } from "../../context";
import { Form, Password } from "../../form";
import { useForm } from "../../hooks";
import { TokenResponse } from "../../models/auth";
import { Modal } from "../../ui";
import Info from "../../ui/Info";
import { requestErrorToToast } from "../../utils/format";

interface PasswordModalProps {
    onClose: () => void;
    onToken: (token: string) => void;
}

const ModalPassword = ({ onClose, onToken }: PasswordModalProps) => {
    const request = useRequest();
    const { addToast } = useUiContext();
    const { entity, attachInput } = useForm<{ password?: string }>({});
    const [error, setError] = useState<string | null>(null);

    const handleSubmit = useCallback(async () => {
        if (request.loading || !entity.password) return;
        request.post<TokenResponse>('/auth/login/one-use', entity, {
            loader: true,
        }).then((data) => onToken(data.token))
            .catch((e) => {
                const toastError = requestErrorToToast(e as Error);

                if (toastError.i18n === 'auth') {
                    setError(toastError.message);
                } else {
                    addToast(toastError);
                }
            })

    }, [entity, request, onToken]);

    return (
        <Modal
            size="xs"
            i18n="auth"
            title="password_verify"
            onClose={onClose}
            onSubmit={handleSubmit}
        >
            {!!error && <Info type="warning" label={error} i18n="auth" />}
            <Form onSubmit={handleSubmit}>
                <Password
                    {...attachInput('password')}
                    label
                    i18n="auth"
                />
            </Form>
        </Modal>
    )
}
export default ModalPassword;