import axios from "axios";
import {
    FilterType,
    ListFilter,
    ListFilterSetting,
    ListFilters,
} from "../data/FiltersPanel";
import { Pagination } from "../models/pagination";
import { ToastData } from "../ui/Toast";
import { ERR_STRING } from "./constants";

export const joinOptionnalStrings = (
    values: (string | undefined)[],
    delimiter?: string
) => values.filter((v) => !!v).join(delimiter ?? ", ");

export const normalize = (text: string): string =>
    text.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

export const removeSpecialCharacters = (text: string) =>
    text.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, " ");

export const stringToSearchField = (keyword: string) =>
    normalize(removeSpecialCharacters(keyword.trim().toLowerCase())).replace(
        /[\s-]+/g,
        " "
    );

export const keywordToSearchRegex = (keyword: string): string =>
    `.?${stringToSearchField(keyword)
        .split(/[\s]+/)
        .filter((k) => k.length >= 3)
        .join(".?")}.?`;

export const getStringValue = (obj?: any): string => {
    if (obj === undefined || obj === null) return "";
    if (obj.hasOwnProperty("toString")) return obj.toString();
    if (Array.isArray(obj)) return obj.map(getStringValue).join(",");

    try {
        return String(obj);
    } catch {
        return ERR_STRING;
    }
};

export const filtersToSearchParams = (filters: ListFilters): string => {
    const searchParams = new URLSearchParams();

    for (const key in filters) {
        const filter = filters[key];

        for (const filterKey in filter) {
            const filterForType = filter[filterKey as keyof ListFilter];
            if (Array.isArray(filterForType)) {
                filterForType.forEach((p: any) =>
                    searchParams.append(`${key}_in`, String(p))
                );
            } else if (filterForType !== undefined && filterForType !== "") {
                searchParams.append(
                    `${key}_${filterKey}`,
                    String(filterForType)
                );
            }
        }
    }
    return searchParams.toString();
};

export const paginationFromSearchParams = (
    URLSearchParams: URLSearchParams
): Partial<Pagination> => {
    const pagination: Partial<Pagination> = {};

    if (
        URLSearchParams.get("page") !== null &&
        !isNaN(Number(URLSearchParams.get("page")))
    ) {
        pagination.page = Number(URLSearchParams.get("page"));
    }
    if (
        URLSearchParams.get("take") !== null &&
        !isNaN(Number(URLSearchParams.get("take")))
    ) {
        pagination.take = Number(URLSearchParams.get("take"));
    }
    if (URLSearchParams.get("sort")) {
        try {
            const sort = JSON.parse(URLSearchParams.get("sort") ?? "{}");
            pagination.sort = {};
            for (const field in sort) {
                if (!isNaN(Number(sort[field]))) {
                    pagination.sort[field] = Number(sort[field]) > 0 ? 1 : -1;
                }
            }
        } catch {}
    }

    return pagination;
};

export const filtersFromSearchParams = (
    URLSearchParams: URLSearchParams,
    filtersSettings: Pick<ListFilterSetting, "type" | "field">[] | undefined
): ListFilters => {
    const filters: ListFilters = {};
    for (const filter of filtersSettings ?? []) {
        switch (filter.type) {
            case FilterType.In:
            case FilterType.Tree:
                const inValues = URLSearchParams.getAll(`${filter.field}_in`);
                if (!inValues?.length) continue;

                filters[filter.field] = {
                    ...filters[filter.field],
                    in: inValues,
                };
                break;
            case FilterType.Null:
                const nullValue = URLSearchParams.get(`${filter.field}_null`);

                if (nullValue !== "true" && nullValue !== "false") continue;

                filters[filter.field] = {
                    ...filters[filter.field],
                    null: nullValue === "true",
                };
                break;
            case FilterType.Search:
            case FilterType.StartsWith:
            case FilterType.Ilike:
                const searchValue = URLSearchParams.get(
                    `${filter.field}_${filter.type}`
                );
                if (!searchValue) continue;

                filters[filter.field] = {
                    ...filters[filter.field],
                    [filter.type]: searchValue,
                };
                break;
            default:
        }
    }
    return filters;
};

export const translateEnum = <T extends string>(
    e: Record<string, T>,
    i18n: string,
    name: string,
    t: (s: string) => string
): { key: T; label: string }[] =>
    Object.keys(e).map((en) => ({
        key: e[en],
        label: t(`${i18n}:enums.${name}.${e[en]}`),
    }));

export const translateLabel = (
    label: string,
    t: (s: string, v?: Record<string, string>) => string,
    i18n: string | undefined,
    variables?: Record<string, string>
): string => (i18n ? t(`${i18n}:${label}`, variables) : label);

export const formatNumberToFixedDecimal = (
    num?: number,
    decimals: number = 2,
    withSign: boolean = false
): string =>
    num !== null && num !== undefined && !isNaN(num)
        ? `${!!withSign && num >= 0 ? "+" : ""}${num.toFixed(decimals)}`
        : "--";

export const requestErrorToToast = (error: Error): Omit<ToastData, "_id"> => {
    if (axios.isAxiosError(error)) {
        const status = error.response?.status;
        const message: string | string[] | undefined =
            error.response?.data.message;

        if (message) {
            if (message.toString().startsWith("SG_EX")) {
                const domain = message
                    .toString()
                    .replace("SG_EX_", "")
                    .split("_")[0];
                const errorMessage = message
                    .toString()
                    .replace("SG_EX_" + domain + "_", "");

                return {
                    type: "error",
                    i18n: domain.toLowerCase(),
                    message: `errors.${errorMessage}`,
                    error,
                };
            }
        }

        if (status) {
            switch (status) {
                case 429:
                    return {
                        type: "error",
                        i18n: true,
                        message: "errors.too_many_requests",
                        error,
                    };
                case 400:
                    return {
                        type: "error",
                        i18n: true,
                        message: "errors.bad_request",
                        error,
                    };
                case 401:
                    return {
                        type: "error",
                        i18n: true,
                        message: "errors.unauthorized",
                        error,
                    };
                case 403:
                    return {
                        type: "error",
                        i18n: true,
                        message: "errors.forbidden",
                        error,
                    };
                case 404:
                    return {
                        type: "error",
                        i18n: true,
                        message: "errors.not_found",
                        error,
                    };
                default:
            }
        }
    }

    return {
        type: "error",
        i18n: true,
        message: "errors.server_error",
        error,
    };
};
