// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dropdown {
  position: relative;
  height: -moz-fit-content;
  height: fit-content;
}
.dropdown > .dropdown-children {
  width: 100%;
  cursor: pointer;
  height: 100%;
  position: relative;
  height: -moz-fit-content;
  height: fit-content;
}
.dropdown > .dropdown-children > .dropdown-icon {
  position: absolute;
  right: 6px;
  width: 12px;
  height: 12px;
  fill: var(--theme-color-grey-medium);
}
.dropdown > .dropdown-content {
  width: 100%;
  position: absolute;
  visibility: hidden;
  display: none;
  border-radius: 4px;
  top: 100%;
  z-index: 99;
}
.dropdown > .dropdown-content > .dropdown-content-close {
  display: flex;
  justify-content: end;
}
.dropdown.dropdown-left > .dropdown-content {
  right: 0;
  left: unset;
}
.dropdown.dropdown-right > .dropdown-content {
  left: 0;
  right: unset;
}
.dropdown.dropdown-visible > .dropdown-content {
  visibility: visible;
  width: -moz-fit-content;
  width: fit-content;
  display: block;
}`, "",{"version":3,"sources":["webpack://./src/sg-react/ui/DropdownLegacy/index.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,wBAAA;EAAA,mBAAA;AACJ;AACI;EACI,WAAA;EACA,eAAA;EACA,YAAA;EACA,kBAAA;EACA,wBAAA;EAAA,mBAAA;AACR;AACQ;EACI,kBAAA;EACA,UAAA;EACA,WAAA;EACA,YAAA;EACA,oCAAA;AACZ;AAGI;EACI,WAAA;EACA,kBAAA;EACA,kBAAA;EACA,aAAA;EACA,kBAAA;EACA,SAAA;EACA,WAAA;AADR;AAGQ;EACI,aAAA;EACA,oBAAA;AADZ;AAKI;EACI,QAAA;EACA,WAAA;AAHR;AAMI;EACI,OAAA;EACA,YAAA;AAJR;AAQQ;EACI,mBAAA;EACA,uBAAA;EAAA,kBAAA;EACA,cAAA;AANZ","sourcesContent":[".dropdown {\n    position: relative;\n    height: fit-content;\n\n    & > .dropdown-children {\n        width: 100%;\n        cursor: pointer;\n        height: 100%;\n        position: relative;\n        height: fit-content;\n\n        & > .dropdown-icon {\n            position: absolute;\n            right: 6px;\n            width: 12px;\n            height: 12px;\n            fill: var(--theme-color-grey-medium);\n        }\n    }\n\n    & > .dropdown-content {\n        width: 100%;\n        position: absolute;\n        visibility: hidden;\n        display: none;\n        border-radius: 4px;\n        top: 100%;\n        z-index: 99;\n\n        & > .dropdown-content-close {\n            display: flex;\n            justify-content: end;\n        }\n    }\n\n    &.dropdown-left > .dropdown-content {\n        right: 0;\n        left: unset;\n    }\n\n    &.dropdown-right > .dropdown-content {\n        left: 0;\n        right: unset;\n    }\n\n    &.dropdown-visible {\n        & > .dropdown-content {\n            visibility: visible;\n            width: fit-content;\n            display: block;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
