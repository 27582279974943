// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.slider {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  position: relative;
}
.slider > .slider-bar {
  content: "";
  position: absolute;
  width: 100%;
  height: 4px;
  border-radius: 2px;
  left: 0;
  top: calc(50% - 2px);
  background-color: var(--theme-input-background-color);
  cursor: pointer;
}
.slider > .slider-handle {
  content: "";
  position: absolute;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--theme-color-primary);
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/sg-react/form/Slider/index.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,YAAA;EACA,kBAAA;AACJ;AACI;EACI,WAAA;EACA,kBAAA;EACA,WAAA;EACA,WAAA;EACA,kBAAA;EACA,OAAA;EACA,oBAAA;EACA,qDAAA;EACA,eAAA;AACR;AAEI;EACI,WAAA;EACA,kBAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;EACA,QAAA;EACA,gCAAA;EACA,4CAAA;EACA,eAAA;AAAR","sourcesContent":[".slider {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    flex-grow: 1;\n    position: relative;\n\n    & > .slider-bar {\n        content: \"\";\n        position: absolute;\n        width: 100%;\n        height: 4px;\n        border-radius: 2px;\n        left: 0;\n        top: calc(50% - 2px);\n        background-color: var(--theme-input-background-color);\n        cursor: pointer;\n    }\n\n    & > .slider-handle {\n        content: \"\";\n        position: absolute;\n        width: 12px;\n        height: 12px;\n        border-radius: 50%;\n        top: 50%;\n        transform: translate(-50%, -50%);\n        background-color: var(--theme-color-primary);\n        cursor: pointer;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
