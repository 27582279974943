import { UserData } from "./../../sg-react/models/user-data";
import { DEFAULT_FILTERS } from "./components/MapContext";
import { MapFilters, MapReducerAction, MapState } from "./types";

export const mapReducer = (
    state: MapState,
    action: MapReducerAction
): MapState => {
    switch (action.type) {
        case "filters":
            const currentStepIndex = state.plan.steps.findIndex(
                (s) => s.stepId === state.currentStepId
            );
            if (currentStepIndex === -1) return state;

            const filters =
                action.payload.filters && !action.payload.reset
                    ? {
                          ...state.plan.steps[currentStepIndex],
                          ...action.payload.filters,
                      }
                    : {
                          ...DEFAULT_FILTERS,
                          ...action.payload.filters,
                          stepId: state.plan.steps[currentStepIndex].stepId,
                      };

            if (!filters.keywords?.length) delete filters.keywords;
            if (!filters.segmentationsIds?.length)
                delete filters.segmentationsIds;
            if (!filters.geosearches?.length) delete filters.geosearches;

            const steps = [...state.plan.steps];
            steps[currentStepIndex] = filters;

            return {
                ...state,
                plan: { ...state.plan, steps },
            };
        case "togglePanel":
            if (!action.payload?.panel) return state;

            const panels = { ...state.panels };
            panels[action.payload.panel] = !panels[action.payload.panel];

            return { ...state, panels };
        case "layer":
            return { ...state, layer: action.payload?.layer };
        case "plan":
            if (!action.payload?.plan?.steps?.length) return state;

            return {
                ...state,
                plan: action.payload?.plan,
                currentStepId: action.payload.plan.steps.some(
                    (s) => s.stepId === state.currentStepId
                )
                    ? state.currentStepId
                    : action.payload?.plan.steps[0].stepId,
            };
        case "state":
            return action.payload.state ?? state;
        case "deleteStep":
            if (!action.payload?.stepId || state.plan.steps.length === 1)
                return state;

            const stepsFiltered = state.plan.steps.filter(
                (s) => s.stepId !== action.payload?.stepId
            );

            return {
                ...state,
                plan: { ...state.plan, steps: stepsFiltered },
                currentStepId: stepsFiltered[0].stepId,
            };
        case "addStep":
            const newFilters = {
                ...DEFAULT_FILTERS,
                ...action.payload.filters,
            };
            const stepId =
                Math.max(...state.plan.steps.map((s) => s.stepId)) + 1;
            const newStep = {
                ...newFilters,
                name:
                    newFilters.name ?? "Step " + (state.plan.steps.length + 1),
                stepId,
            };

            return {
                ...state,
                plan: {
                    ...state.plan,
                    steps: [newStep, ...state.plan.steps],
                },
                currentStepId: stepId,
            };
        case "currentStep":
            if (!action.payload.stepId) return state;
            return {
                ...state,
                currentStepId: action.payload.stepId,
            };
        default:
            return state;
    }
};

export const mapFilterSchemaUpdate = (
    filters: Record<string, any>
): Partial<MapFilters> => {
    const filtersUpdated: Partial<MapFilters> = {};
    if (filters?.keyword) {
        filtersUpdated.keywords = [filters?.keyword];
    }
    if (filters?.geosearches?.length) {
        filtersUpdated.geosearches = filters.geosearches.map(
            (gs: Record<string, any>) => ({
                ...gs,
                name: gs.label,
            })
        );
    }
    return filtersUpdated;
};

export const checkUserDataSchemaForMap = (
    ud: UserData,
    type: "map-query" | "map-plan"
): UserData => {
    if (ud.schemaVersion !== 1 || !ud.data) return ud;

    if (type === "map-query") {
        ud.data = { ...ud.data, ...mapFilterSchemaUpdate(ud.data) };
    } else if (ud.data.steps) {
        ud.data.steps = ud.data.steps.map((s: Record<string, any>) => ({
            ...s,
            ...mapFilterSchemaUpdate(s),
        }));
    }

    return ud;
};
