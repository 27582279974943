import { useCallback, useState } from "react";
import { useRequest, useUiContext } from "../../context";
import withPasswordCheck from "../../hoc/WithPasswordCheck";
import { useForm } from "../../hooks";
import NewPasswordForm, { PASSWORD_VALIDATION } from "../../pages/Account/components/NewPasswordForm";
import { Modal } from "../../ui";
import Info from "../../ui/Info";
import { requestErrorToToast } from "../../utils/format";

interface ModalNewPasswordProps {
    onClose: () => void;
    token: string;
}

const ModalNewPassword = ({ onClose, token }: ModalNewPasswordProps) => {
    const { entity, validate, attachInput } = useForm<{ password?: string; passwordVerify?: string }>({}, PASSWORD_VALIDATION);
    const request = useRequest();
    const { addToast } = useUiContext();
    const [error, setError] = useState<string | null>(null);

    const handleSubmit = useCallback(() => validate((entity) => {
        if (request.loading || entity.password !== entity.passwordVerify) return;

        request.post('/auth/new-password', { password: entity.password, token }, {
            loader: true,
            successMessage: { i18n: "auth", message: "success.password_set" }
        })
            .then(() => onClose())
            .catch((e) => {
                const toastError = requestErrorToToast(e as Error);

                if (toastError.i18n === 'auth') {
                    setError(toastError.message);
                } else {
                    addToast(toastError);
                }
            })
    }), [request, onClose, token, validate]);

    return (
        <Modal
            size="xs"
            i18n="auth"
            title="password_new"
            onClose={onClose}
            onSubmit={handleSubmit}
        >
            {!!error && <Info type="warning" label={error} i18n="auth" />}
            <NewPasswordForm entity={entity} attachInput={attachInput} />
        </Modal>
    )
}
export default withPasswordCheck(ModalNewPassword);
