import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Cell, Label, ResponsiveContainer, Scatter, ScatterChart, Tooltip, XAxis, YAxis, ZAxis } from "recharts";
import { TooltipProps } from "recharts/types/component/Tooltip";
import { DirectoryItemsListItem } from "../../../components/DirectoryItemsList";
import { DirectoryDataDashboard, DirectoryDataDashboardTops, DirectoryPurchasingStrategy } from "../../../models/directory-data";
import { useRequest } from "../../../sg-react/context";
import { ArrowRightIcon } from "../../../sg-react/icons";
import { Card } from "../../../sg-react/ui";
import ValueDescription from "../../../sg-react/ui/ValueDescription";
import { conditionnalClassnames } from "../../../sg-react/utils/helpers";


const DashboardBusinessTop = ({ directoryItem, value, average }: DirectoryDataDashboardTops) => {
    return (
        <DirectoryItemsListItem entity={directoryItem}>
            <div className={conditionnalClassnames({
                row: true,
                'dashboard-business-top': true,
                up: average !== undefined && value > average,
                down: average !== undefined && value < average,
                same: average !== undefined && value === average,
            })}>
                <ArrowRightIcon className="icon icon-small" />
                <span className="dashboard-business-top-value">{value}</span>
                {average !== undefined && <span className="dashboard-business-top-avg">{Math.round(average)}</span>}
            </div>
        </DirectoryItemsListItem>
    )
}

const DashboardBusinessesChartTooltip = ({ active, payload }: TooltipProps<string, number>) => {
    const { t } = useTranslation();
    if (!active || !payload?.length) return null;

    return (
        <Card className="dashboard-businesses-chart-tooltip">
            <ValueDescription
                value={payload[0].payload.directoryItem.name}
                description={`Grade: ${t('directory-data:enums.purchasing_strategy.' + payload[0].payload.grade)}, complexity: ${payload[0].payload.complexity}`}
            />
        </Card>
    );
}

const DashboardBusinesses = ({ }) => {
    const [directoryDataDashboard, setDirectoryDataDashboard] = useState<DirectoryDataDashboard | null>(null);
    const { t } = useTranslation();
    const request = useRequest();

    useEffect(() => {
        request.get<DirectoryDataDashboard>('/directory-data/dashboard', { errorMessage: true, withWorkspace: true })
            .then(setDirectoryDataDashboard)
            .catch(() => null);
    }, []);

    if (!directoryDataDashboard) return null;

    return (
        <Fragment>
            <Card id="dashboard-businesses-tops" className="col-lg-100">
                <table>
                    <thead>
                        <tr>
                            <th />
                            <th>Top 5</th>
                            <th>Flop 5</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>OTD</td>
                            <td>
                                <div className="simple-list">
                                    {directoryDataDashboard.otd?.tops?.map(d => (
                                        <DashboardBusinessTop key={d.directoryItem._id} {...d} />
                                    ))}
                                </div>
                            </td>
                            <td>
                                <div className="simple-list">
                                    {directoryDataDashboard.otd?.flops?.map(d => (
                                        <DashboardBusinessTop key={d.directoryItem._id} {...d} />
                                    ))}
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>OQD</td>
                            <td>
                                <div className="simple-list">
                                    {directoryDataDashboard.oqd?.tops?.map(d => (
                                        <DashboardBusinessTop key={d.directoryItem._id} {...d} />
                                    ))}
                                </div>
                            </td>
                            <td>
                                <div className="simple-list">
                                    {directoryDataDashboard.oqd?.flops?.map(d => (
                                        <DashboardBusinessTop key={d.directoryItem._id} {...d} />
                                    ))}
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </Card>
            <Card title="suppliers_classification" i18n="directory-data" id="dashboard-businesses-chart" className="col-lg-60">
                <ResponsiveContainer minWidth="100%" width="100%" height={200}>
                    <ScatterChart margin={{ left: 0 }}>
                        <YAxis width={30} type="number" tick={false} dataKey="complexity" name="Complexity" domain={[0, 16]}>
                            <Label
                                style={{
                                    textAnchor: "middle",
                                }}
                                angle={270}
                                value="Complexity" />
                        </YAxis>
                        <XAxis
                            type="category"
                            tick={true}
                            tickFormatter={(tick) => t('directory-data:enums.purchasing_strategy.' + tick)}
                            dataKey="grade"
                            name="Grade"
                            domain={Object.values(DirectoryPurchasingStrategy).reverse()}
                            allowDuplicatedCategory={false}
                        />
                        <ZAxis type="number" dataKey="count" range={[20, 60]} name="Count" />
                        <Tooltip cursor={{ strokeDasharray: '3 3' }} content={<DashboardBusinessesChartTooltip />} />
                        <Scatter data={directoryDataDashboard.classifications} fill="#8884d8">
                            {directoryDataDashboard.classifications.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={entry.grade === DirectoryPurchasingStrategy.Exit || entry.grade === DirectoryPurchasingStrategy.BlackListed ? 'red' : (entry.grade === DirectoryPurchasingStrategy.Target || entry.grade === DirectoryPurchasingStrategy.PotentialTarget) && entry.complexity > 9 ? 'green' : 'orange'} />
                            ))}
                        </Scatter>
                    </ScatterChart>
                </ResponsiveContainer>
            </Card>
        </Fragment>
    )
}
export default DashboardBusinesses;