// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input[input-type=button-list] > .input-field {
  height: -moz-fit-content;
  height: fit-content;
}`, "",{"version":3,"sources":["webpack://./src/sg-react/form/ButtonList/index.scss"],"names":[],"mappings":"AAAA;EACI,wBAAA;EAAA,mBAAA;AACJ","sourcesContent":[".input[input-type=\"button-list\"] > .input-field {\n    height: fit-content;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
