import { Role } from "../utils/enums";
import { Image } from "./image";

export type User = {
    _id: string;
    firstName: string;
    lastName: string;
    fullName: string;
    avatar?: Image;
    position?: string;
    company?: string;
    field?: string;
    phone?: string;
    linkedIn?: string;
    email?: string;
    status?: UserStatus;
    role?: Role;
    connectedAt?: number;
};

export type CurrentUser = User & {
    twoFactor: CurrentUserTwoFactor;
    settings: CurrentUserSettings;
};

export interface CurrentUserSettings {
    language?: string;
    darkMode?: boolean;
}

export interface CurrentUserTwoFactor {
    method: TwoFactorMethod;
    qrCodeSecret?: string;
    enabled: boolean;
}

export enum TwoFactorMethod {
    QrCode = "QRCODE",
    Email = "EMAIL",
}

export enum UserStatus {
    Pending = "PENDING",
    Active = "ACTIVE",
    Suspended = "SUSPENDED",
    Banned = "BANNED",
}
