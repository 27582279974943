import { BulletIcon, CloseIcon, DownloadIcon, EditIcon, PlusIcon } from "../../../sg-react/icons";
import { LayoutMenuItem, LayoutMenuMenu } from "../../../sg-react/ui/LayoutMenu";
import MenuContextOutlet from "../../../sg-react/ui/MenuContextOutlet";
import { Action } from "../../../sg-react/utils/enums";

const ITEMS: LayoutMenuItem[] = [
    { path: '/specifications', label: 'list', i18n: 'data', icon: <BulletIcon /> },
];

const MENUS: LayoutMenuMenu[] = [{
    path: '/specifications',
    label: 'menu',
    i18n: 'ui',
    items: [
        { itemKey: Action.Create, icon: <PlusIcon />, i18n: "actions", label: "create" },
    ]
}, {
    path: '/specifications/edit',
    pathStartsWith: true,
    label: 'file',
    i18n: 'ui',
    items: [
        { itemKey: Action.Edit, icon: <EditIcon />, i18n: "actions", label: "edit" },
        { itemKey: Action.Export, icon: <DownloadIcon />, i18n: "actions", label: "export" },
        { itemKey: Action.Close, icon: <CloseIcon />, i18n: "actions", label: "close" },
    ]
}];


const SpecificationsLayoutWrapper = () => <MenuContextOutlet items={ITEMS} menus={MENUS} />;

export default SpecificationsLayoutWrapper;