// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-item {
  flex-grow: 1;
  gap: 8px;
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/sg-react/ui/UserItem/index.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,QAAA;EACA,mBAAA;AACJ","sourcesContent":[".user-item {\n    flex-grow: 1;\n    gap: 8px;\n    align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
