import { Entity } from "../sg-react/models/entity";
import { Location } from "../sg-react/models/location";
import { DirectoryItem } from "./directory-item";

export type Dependency = Entity & {
    name: string;
    type: string;
    description?: string;
    location?: Location;
    directoryItem?: DirectoryItem;
    data?: Record<string, any>;
};

export enum DependencyType {
    WindTurbine = "WIND_TURBINE",
    ProductionLine = "PRODUCTION_LINE",
}
/* 
export const DEPENDENCY_SIMPLE_LIST_SCHEMA: SimpleListSchemaColumn<Dependency>[] =
    [
        {
            field: "name",
            label: "Nom",
        },
        {
            field: "additionalData.modele",
            label: "Modèle",
        },
        {
            field: "additionalData.constructorName",
            label: "Constructeur",
        },
        {
            field: "additionalData.puissance",
            label: "Puissance nominale",
        },
        {
            field: "additionalData.hauteur_max_mat",
            label: "Hauteur",
        },
    ];
 */
