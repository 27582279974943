import { Role } from "../utils/enums";

export interface TokenParsed {
    _id: string;
    role?: Role;
    iat: number;
}

export interface LoginCredentials {
    email: string;
    password: string;
}

export interface NewPassword {
    password: string;
    passwordVerify: string;
}

export enum TokenType {
    Refresh = "refresh",
    TwoFA = "twoFA",
    Access = "access",
    OneUse = "oneUse",
    Confirmation = "confirmation",
    Recovery = "recovery",
}

export enum AuthenticationStatus {
    Unauthenticated = "UNAUTHENTICATED",
    TwoFactorNeeded = "TWO_FACTOR_NEEDED",
    WaitingForUser = "WAITING_FOR_USER",
    Authenticated = "AUTHENTICATED",
}

export interface TokenResponse {
    type: TokenType;
    token: string;
    tokenParsed: TokenParsed;
}
