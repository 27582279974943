import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { DirectoryItemViewPatchProps } from "..";
import { DirectoryMessage } from "../../../models/directory-data";
import { useRequest } from "../../../sg-react/context";
import { ModalDelete } from "../../../sg-react/data";
import DataComponentList from "../../../sg-react/data/DataComponentList";
import { Select, TextArea } from "../../../sg-react/form";
import Validation, { ObjectValidation } from "../../../sg-react/form/Validation/class";
import { useForm } from "../../../sg-react/hooks";
import { EditIcon, MenuIcon, TrashIcon } from "../../../sg-react/icons";
import { Button, Card, Modal } from "../../../sg-react/ui";
import DropdownMenu from "../../../sg-react/ui/DropdownMenu";
import Tag from "../../../sg-react/ui/Tag";
import { Colors } from "../../../sg-react/ui/enums";
import { dateToLocaleString } from "../../../sg-react/utils/date";

interface DirectoryViewMessagesComponentProps {
    message: DirectoryMessage;
    onEdit?: (c: DirectoryMessage) => void;
    onDelete?: (c: DirectoryMessage) => void;
}

const DirectoryViewMessagesComponent = ({ message, onEdit, onDelete }: DirectoryViewMessagesComponentProps) => {
    const { t } = useTranslation();
    return (
        <div className="row">
            <div className="col directory-item-view-message-topic">
                <div className="row">
                    <label>{dateToLocaleString(message.date, true)}</label>
                    <Tag label={`${t('directory-data:priority')} ${message.priority}`} color={message.priority === 1 ? Colors.ERROR : message.priority < 4 ? Colors.WARNING : Colors.SUCCESS} />
                </div>
                <div className="directory-item-view-message-topic-main">{message.message}</div>
            </div>
            {(onEdit && onDelete) && (
                <DropdownMenu disposition="left" items={[
                    { itemKey: 'edit', icon: <EditIcon />, i18n: "actions", label: "edit", onClick: () => onEdit(message) },
                    { itemKey: 'delete', icon: <TrashIcon />, i18n: "actions", label: "delete", onClick: () => onDelete(message) }
                ]}>
                    <Button color="navigation" icon={<MenuIcon />} />
                </DropdownMenu>
            )}
        </div>
    )
}

const VALIDATION = new ObjectValidation({
    message: Validation.string().required().minLength(20).maxLength(250),
    priority: Validation.number().required().greaterThan(1, true).lesserThan(5, true)
})

const DirectoryViewMessages = ({ directoryItem, directoryData, refresh, canEdit }: DirectoryItemViewPatchProps) => {
    const { entity, reset, attachInput, validate } = useForm<DirectoryMessage>({}, VALIDATION);
    const [messageToDelete, setMessageToDelete] = useState<DirectoryMessage | null>(null);
    const request = useRequest();

    const messagesSorted = useMemo(() => directoryData.messages?.sort((m1, m2) => m1.priority > m2.priority ? 1 : -1), [directoryData]);

    const createOrUpdateMessage = useCallback(() => validate((entity) => {
        const requestMethod = !entity._id ? request.post : request.put;

        requestMethod(`/directory-data/${directoryItem._id}/message`, entity, {
            loader: true,
            withWorkspace: true,
            successMessage: true,
            errorMessage: true,
        })
            .then(() => {
                reset({});
                refresh();
            })
            .catch(() => null);
    }), [request, directoryItem, refresh, validate, reset]);

    const deleteMessage = useCallback((date: DirectoryMessage) => {
        request.delete(`/directory-data/${directoryItem._id}/message/${date._id}`, {
            loader: true,
            withWorkspace: true,
            successMessage: true,
            errorMessage: true,
        })
            .then(() => {
                setMessageToDelete(null);
                refresh();
            })
            .catch(() => null);
    }, [request, directoryItem, refresh]);

    return (
        <Card title="messages" i18n="directory-data" className="col col-lg-50">
            <DataComponentList
                component={(props: { entity: DirectoryMessage }) => <DirectoryViewMessagesComponent message={props.entity} onDelete={canEdit ? setMessageToDelete : undefined} onEdit={canEdit ? reset : undefined} />}
                data={messagesSorted}
                primaryKey="_id"
                onSearch={(t, k) => t.message?.toLowerCase().includes(k.toLowerCase())}
                onAdd={canEdit ? () => reset({ date: new Date() }) : undefined}
                sort={[{ field: 'date', i18n: 'directory-data', label: 'date' }, { field: 'priority', i18n: 'directory-data', label: 'priority' }]}
                simple
            />
            {!!entity.date && (
                <Modal
                    size="small"
                    i18n="directory-data"
                    title="message"
                    onClose={() => reset({})}
                    onSubmit={createOrUpdateMessage}
                >
                    <TextArea
                        label
                        i18n="directory-data"
                        {...attachInput('message')}
                    />
                    <Select
                        label
                        i18n="directory-data"
                        items={[1, 2, 3, 4, 5]}
                        {...attachInput('priority')}
                    />
                </Modal>
            )}
            {!!messageToDelete && <ModalDelete onClose={() => setMessageToDelete(null)} onSubmit={() => deleteMessage(messageToDelete)} />}
        </Card>
    )
}

export default DirectoryViewMessages;