// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#breadcrumbs {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-shrink: 0;
  gap: 4px;
  overflow: hidden;
}
#breadcrumbs .breadcrumb-delimiter {
  width: 8px;
  height: 8px;
  border-top: var(--theme-font-color-medium) 2px solid;
  border-right: var(--theme-font-color-medium) 2px solid;
  transform: rotate(45deg);
  margin-right: 6px;
}
#breadcrumbs > span, #breadcrumbs > a {
  color: var(--theme-font-color-medium);
  font-weight: 400;
  font-size: 11px;
  overflow: hidden;
  display: inline;
  text-overflow: ellipsis;
  text-decoration: none;
  white-space: nowrap;
}
#breadcrumbs > span:last-child, #breadcrumbs > a:last-child {
  color: var(--theme-font-color-main);
  font-size: 13px;
}`, "",{"version":3,"sources":["webpack://./src/sg-react/ui/Breadcrumbs/index.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,cAAA;EACA,QAAA;EACA,gBAAA;AACJ;AACI;EACI,UAAA;EACA,WAAA;EACA,oDAAA;EACA,sDAAA;EACA,wBAAA;EACA,iBAAA;AACR;AAEI;EAEI,qCAAA;EACA,gBAAA;EACA,eAAA;EACA,gBAAA;EACA,eAAA;EACA,uBAAA;EACA,qBAAA;EACA,mBAAA;AADR;AAGQ;EACI,mCAAA;EACA,eAAA;AADZ","sourcesContent":["#breadcrumbs {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    flex-shrink: 0;\n    gap: 4px;\n    overflow: hidden;\n\n    & .breadcrumb-delimiter {\n        width: 8px;\n        height: 8px;\n        border-top: var(--theme-font-color-medium) 2px solid;\n        border-right: var(--theme-font-color-medium) 2px solid;\n        transform: rotate(45deg);\n        margin-right: 6px;\n    }\n\n    & > span,\n    & > a {\n        color: var(--theme-font-color-medium);\n        font-weight: 400;\n        font-size: 11px;\n        overflow: hidden;\n        display: inline;\n        text-overflow: ellipsis;\n        text-decoration: none;\n        white-space: nowrap;\n\n        &:last-child {\n            color: var(--theme-font-color-main);\n            font-size: 13px;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
