import { useMemo } from "react";
import { Bar, BarChart, LabelList, ResponsiveContainer, XAxis, YAxis } from "recharts";
import { Card } from "../../../sg-react/ui";
import Info from "../../../sg-react/ui/Info";
import { useMapContext } from "../../MainMap/components/MapContext";

const DirectoryItemsEmissions = () => {
    const { planResults, plan } = useMapContext();

    const data = useMemo(() => planResults?.map((p) => p.data.items.map(d => ({
        name: d.name,
        emissions: Math.floor(Math.random() * 15000),
        fill: p.color
    }))).flat().sort((d1, d2) => d1.emissions > d2.emissions ? -1 : 1) ?? [], [plan]);

    return (
        <Card title="Results emissions" id="plan-analysis-directory-items-emissions">
            <Info type="warning" i18n="ui" label="degraded_feature" />
            <ResponsiveContainer width="100%" height={data.length * 40}>
                <BarChart
                    data={data}
                    layout="vertical"
                >
                    <LabelList dataKey="name" position="right" />
                    <XAxis type="number" />
                    <YAxis type="category" dataKey="name" width={200} />
                    <Bar dataKey="emissions" barSize={20}>
                        <LabelList dataKey="emissions" position="insideRight" style={{ fill: "white" }} />
                    </Bar>
                </BarChart>
            </ResponsiveContainer>
        </Card>
    )
}
export default DirectoryItemsEmissions;