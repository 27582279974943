import { Fragment, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Legend, Line, LineChart, ResponsiveContainer, XAxis, YAxis } from "recharts";
import { DirectoryItemViewPatchProps } from "..";
import { DirectoryRseProject, ProjectStatus } from "../../../models/directory-data";
import { useRequest } from "../../../sg-react/context";
import { ModalDelete } from "../../../sg-react/data";
import DataContainerList, { DataContainerListColumn } from "../../../sg-react/data/DataContainerList";
import ValueDateModal from "../../../sg-react/data/ValueDateModal";
import { NumberField, Select, Switch, TextField } from "../../../sg-react/form";
import Validation, { ObjectValidation } from "../../../sg-react/form/Validation/class";
import { useForm } from "../../../sg-react/hooks";
import { EditIcon, PlusIcon, TrashIcon } from "../../../sg-react/icons";
import { ValueDate } from "../../../sg-react/models/data";
import { Button, Card, Modal } from "../../../sg-react/ui";
import { COLORS_FOR_CHARTS } from "../../../sg-react/utils/constants";
import { dateToLocaleString } from "../../../sg-react/utils/date";
import { translateEnum } from "../../../sg-react/utils/format";

interface RseProjectActionsProps {
    onEdit: () => void;
    onDelete: () => void;
    onNewValue: () => void;
}

const RseProjectActions = ({ onEdit, onDelete, onNewValue }: RseProjectActionsProps) => (
    <div className="row no-gap">
        <Button color="navigation" icon={<TrashIcon />} small onClick={onDelete} />
        <Button color="navigation" icon={<EditIcon />} small onClick={onEdit} />
        <Button color="navigation" icon={<PlusIcon />} small onClick={onNewValue} />
    </div>
)

const VALIDATION = new ObjectValidation({
    name: Validation.string().required().minLength(5).maxLength(100),
    status: Validation.string().required().isEnum(ProjectStatus),
    leader: Validation.string().minLength(1).maxLength(100),
    impactEmission: Validation.number(),
    impactCost: Validation.number(),
})

const RseProjects = ({ directoryItem, directoryData, refresh, canEdit }: DirectoryItemViewPatchProps) => {
    const { t } = useTranslation();
    const [mode, setMode] = useState<string | undefined>('list');
    const { entity, reset, attachInput, validate } = useForm<DirectoryRseProject>({}, VALIDATION);
    const [projectToDelete, setProjectToDelete] = useState<DirectoryRseProject | null>(null);
    const [projectForNewValue, setProjectForNewValue] = useState<DirectoryRseProject | null>(null);
    const request = useRequest();

    const rseProjectsWithValue = useMemo(() => directoryData.rseProjects?.map(r => ({ ...r, progressValue: r.progress.sort((p1, p2) => p1.date > p2.date ? -1 : 1)[0]?.value ?? 0 })), [directoryData])
    const projectStatus = useMemo(() => translateEnum(ProjectStatus, 'rse', 'project_status', t), [t]);
    const series = useMemo(() => directoryData.rseProjects?.map(project => ({
        name: project.name,
        data: project.progress.map(p => ({ date: p.date.getTime(), value: p.value }))
    })), [directoryData])

    const createOrUpdateProject = useCallback(() => validate((entity) => {
        const requestMethod = !entity._id ? request.post : request.put;

        requestMethod(`/directory-data/${directoryItem._id}/rse-project`, entity, {
            loader: true,
            withWorkspace: true,
            successMessage: true,
            errorMessage: true,
        })
            .then(() => {
                reset({});
                refresh();
            })
            .catch(() => null);
    }), [request, directoryItem, refresh, validate, reset]);

    const deleteProject = useCallback((project: DirectoryRseProject) => {
        request.delete(`/directory-data/${directoryItem._id}/rse-project/${project._id}`, {
            loader: true,
            withWorkspace: true,
            successMessage: true,
            errorMessage: true,
        })
            .then(() => {
                setProjectToDelete(null);
                refresh();
            })
            .catch(() => null);
    }, [request, directoryItem, refresh]);

    const addProjectProgress = useCallback((dto: ValueDate<number>, project: DirectoryRseProject) => {
        request.post(`/directory-data/${directoryItem._id}/rse-project/${project._id}/progress`, dto, {
            loader: true,
            withWorkspace: true,
            successMessage: true,
            errorMessage: true,
        })
            .then(() => {
                setProjectForNewValue(null);
                refresh();
            })
            .catch(() => null);
    }, [request, directoryItem, refresh]);

    return (
        <Fragment>
            <Card
                title="rseProjects"
                i18n="rse"
                options={(
                    <Fragment>
                        {canEdit && <Button color="navigation" label="create" i18n="actions" icon={<PlusIcon />} onClick={() => reset({ status: ProjectStatus.Planned })} />}
                        <Switch id="project-switch" items={[{ key: 'list', label: t('data:list') }, { key: 'hist', label: t('data:history') }]} value={mode} onChange={setMode} />
                    </Fragment>
                )}
            >
                {mode === 'list' ? (
                    <DataContainerList<DirectoryRseProject & { progressValue: number; }>
                        primaryKey="_id"
                        data={rseProjectsWithValue}
                        columns={[
                            { field: 'name', label: 'name', i18n: 'entity' },
                            { field: 'leader', label: 'leader', i18n: 'rse' },
                            { field: 'status', label: 'status', i18n: 'entity', display: (e) => projectStatus.find(p => p.key === e.status)?.label },
                            { field: 'impactEmission', label: 'impactEmission', i18n: 'rse' },
                            { field: 'impactCost', label: 'impactCost', i18n: 'rse', display: (e) => e.impactCost > 0 ? '+' + e.impactCost : String(e.impactCost) },
                            { field: 'progressValue', label: 'progress', i18n: 'rse' },
                            ...(canEdit ? [
                                { field: 'actions', display: (e) => <RseProjectActions onDelete={() => setProjectToDelete(e)} onEdit={() => reset(e)} onNewValue={() => setProjectForNewValue(e)} /> },
                            ] as DataContainerListColumn<DirectoryRseProject & { progressValue: number; }>[] : [])
                        ]}
                        sort={{ field: "progressValue", direction: -1 }}
                    />
                ) : (
                    <ResponsiveContainer minWidth="100%" width="100%" height={250}>
                        <LineChart>
                            <XAxis
                                dataKey="date"
                                type="number"
                                allowDuplicatedCategory={false}
                                domain={['auto', 'auto']}
                                tickFormatter={(time) => dateToLocaleString(new Date(time))}
                            />
                            <YAxis dataKey="value" domain={[0, 100]} />
                            <Legend formatter={(value) => <label>{value}</label>} />
                            {series?.map((s, i) => (
                                <Line dataKey="value" data={s.data} name={s.name} key={s.name} strokeWidth={2} stroke={COLORS_FOR_CHARTS[i]} dot={false} />
                            ))}
                        </LineChart>
                    </ResponsiveContainer>
                )}
            </Card>
            {!!entity?.status && (
                <Modal
                    size="small"
                    title={entity._id ? entity.name : t('actions:create')}
                    onClose={() => reset({})}
                    onSubmit={createOrUpdateProject}
                >
                    <TextField label i18n="entity" {...attachInput('name')} />
                    <TextField label i18n="rse" {...attachInput('leader')} />
                    <Select label items={projectStatus} i18n="entity" {...attachInput('status')} />
                    <NumberField label i18n="rse" {...attachInput('impactEmission')} />
                    <NumberField label i18n="rse" {...attachInput('impactCost')} />
                </Modal>
            )}
            {!!projectToDelete && <ModalDelete onClose={() => setProjectToDelete(null)} onSubmit={() => deleteProject(projectToDelete)} />}
            {!!projectForNewValue && <ValueDateModal i18n="rse" title="progress" onClose={() => setProjectForNewValue(null)} onSubmit={(v) => addProjectProgress(v, projectForNewValue)} />}
        </Fragment >
    )
}
export default RseProjects;