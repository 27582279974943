// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.soft-container {
  display: flex;
  flex-direction: column;
  border-radius: var(--theme-container-border-radius);
  gap: var(--theme-container-gap);
  padding: var(--theme-container-padding);
  border: var(--theme-soft-container-border-color) 1px solid;
  background-color: var(--theme-soft-container-background-color);
  height: -moz-fit-content;
  height: fit-content;
  position: relative;
}`, "",{"version":3,"sources":["webpack://./src/sg-react/ui/SoftContainer/index.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,mDAAA;EACA,+BAAA;EACA,uCAAA;EACA,0DAAA;EACA,8DAAA;EACA,wBAAA;EAAA,mBAAA;EACA,kBAAA;AACJ","sourcesContent":[".soft-container {\n    display: flex;\n    flex-direction: column;\n    border-radius: var(--theme-container-border-radius);\n    gap: var(--theme-container-gap);\n    padding: var(--theme-container-padding);\n    border: var(--theme-soft-container-border-color) 1px solid;\n    background-color: var(--theme-soft-container-background-color);\n    height: fit-content;\n    position: relative;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
