import { FormEvent, Ref, useCallback, useState } from 'react';
import { TextField } from '../../form';
import { CloseIcon, PlusIcon } from '../../icons';
import { Button } from '../../ui';
import './index.scss';
import { UseFocusHandleRef } from '../../hooks';

interface ValueAddListProps {
    id: string;
    value?: string[];
    placeholder?: string;
    onChange: (v?: string[]) => void;
    autoFocus?: boolean;
    focusHandle?: Ref<UseFocusHandleRef>,
}

const ValueAddList = ({ id, value, onChange, placeholder, autoFocus, focusHandle }: ValueAddListProps) => {
    const [inputValue, setInputValue] = useState<string | undefined>();

    const handleAdd = useCallback((e: FormEvent) => {
        e?.preventDefault();
        if (!inputValue) return;

        if (!value?.includes(inputValue)) {
            onChange([...(value ?? []), inputValue]);
        }

        setInputValue(undefined);
    }, [onChange, inputValue, value]);

    const handleRemove = useCallback((valueToRemove: string) => {
        if (!value?.length) return;
        const _value = value.filter(v => v !== valueToRemove);

        onChange(_value.length === 0 ? undefined : _value);
    }, [onChange, value]);

    return (
        <div className="col value-add-list" onSubmit={handleAdd}>
            <form className="row">
                <TextField
                    id={id}
                    placeholder={placeholder}
                    value={inputValue}
                    onChange={setInputValue}
                    autoFocus={autoFocus}
                    focusHandle={focusHandle}
                />
                <Button type="submit" color="navigation" icon={<PlusIcon />} small />
            </form>
            {!!value?.length && (
                <div className="list">
                    {value.map(v => (
                        <div key={v} className="row value-add-list-item">
                            <span>{v}</span>
                            <CloseIcon onClick={() => handleRemove(v)} />
                        </div>
                    ))}
                </div>
            )}
        </div>
    )
}
export default ValueAddList;