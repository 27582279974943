import { Fragment, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Card } from "..";
import { ColorPicker, TextField } from "../../form";
import { BackwardIcon, CheckIcon, CloseIcon } from "../../icons";
import { Colors } from "../enums";
import './index.scss';

export type LooperData<T> = {
    key: number;
    name?: string;
    color?: string;
    data?: T;
}

export interface LooperFormComponentProps<T> {
    value: LooperData<T>;
    onChange: (e: LooperData<T>) => void;
}

export interface LooperDisplayComponentProps<T> {
    entity?: LooperData<T>;
    expanded?: boolean;
}

export interface LooperFinalComponentProps<T> {
    value: LooperData<T>[];
}

interface LooperProps<T> {
    formComponent: (p: LooperFormComponentProps<T>) => JSX.Element;
    displayComponent: (p: LooperDisplayComponentProps<T>) => JSX.Element;
    finalComponent: (p: LooperFinalComponentProps<T>) => JSX.Element;
}

const INITIAL_VALUE = { key: 0 };

const Looper = <T,>({
    formComponent: FormComponent,
    displayComponent: DisplayComponent,
    finalComponent: FinalComponent,
}: LooperProps<T>) => {
    const { t } = useTranslation();
    const [value, setValue] = useState<LooperData<T>[]>([{ ...INITIAL_VALUE }]);
    const [editValue, setEditValue] = useState<LooperData<T> | null>({ ...INITIAL_VALUE });
    const [finalValue, setFinalValue] = useState<LooperData<T>[] | null>(null);

    const handleSave = useCallback((editValue: LooperData<T>) => {
        setValue((value) => {
            const index = value.findIndex(v => v.key === editValue.key);

            if (index === -1) return value;

            const _value = [...value];
            _value[index] = editValue;

            setEditValue(null);
            return _value;
        });
    }, []);

    const handleAdd = useCallback((after: number) => {
        setValue((value) => {
            const newValue = { key: Math.max(...value.map(v => v.key)) + 1 };
            const _value = [...value];
            _value.splice(after + 1, 0, newValue);
            setEditValue(newValue);
            return _value;
        });
    }, []);

    const handleDelete = useCallback((key: number) => {
        setValue((value) => {
            if (value.length === 1) {
                setEditValue({ ...INITIAL_VALUE });
                return [{ ...INITIAL_VALUE }];
            }

            const _value = [...value];
            const index = _value.findIndex(v => v.key === key);
            _value.splice(index, 1);
            return _value;
        });
    }, []);

    const handleEdit = useCallback((value: LooperData<T>) => {
        setEditValue(value);
        setFinalValue(null)
    }, []);

    const handleSubmit = useCallback((value: LooperData<T>[]) => {
        setEditValue(null);
        setFinalValue(value)
    }, []);

    return (
        <div className="looper">
            <div className="looper-loop">
                {value.map((v, i) => (
                    <Fragment key={v.key}>
                        <div className={`looper-element ${v.key === editValue?.key ? 'active' : ''}`}>
                            <div className="looper-icon">
                                <div
                                    style={v.color ? { backgroundColor: v.color, boxShadow: `0 0px 12px 6px ${v.color}30` } : undefined}
                                    onClick={() => handleEdit(v)}
                                >
                                    {i + 1}
                                </div>
                            </div>
                            <DisplayComponent entity={v} />
                        </div>
                        <div className="looper-element looper-add">
                            <div className="looper-icon">
                                <div onClick={() => handleAdd(i)}>+</div>
                            </div>
                        </div>
                    </Fragment>
                )
                )}
                <div className="looper-element looper-submit">
                    <div className="looper-icon">
                        <div onClick={() => handleSubmit(value)}><CheckIcon /></div>
                    </div>
                </div>
            </div>
            {!!editValue && (
                <div className="looper-form">
                    <Card>
                        <div className="looper-form-content">
                            <FormComponent value={editValue} onChange={setEditValue} />
                        </div>
                    </Card>
                    <div className="looper-actions">
                        <div className="looper-actions-form">
                            <TextField label={t('entity:name')} id="name" value={editValue.name} onChange={(v) => setEditValue({ ...editValue, name: v as string })} />
                            <ColorPicker label={t('entity:color')} id="color" value={editValue.color} onChange={(c) => setEditValue({ ...editValue, color: c })} />
                        </div>
                        <div className="looper-actions-buttons">
                            <Button color={Colors.ERROR} onClick={() => handleDelete(editValue.key)} label={t('actions:delete')} icon={<CloseIcon />} />
                            <Button color={Colors.SECONDARY} onClick={() => setEditValue(null)} label={t('actions:cancel')} icon={<BackwardIcon />} />
                            <Button color={Colors.PRIMARY} onClick={() => handleSave(editValue)} label={t('actions:save')} icon={<CheckIcon />} />
                        </div>
                    </div>
                </div>
            )}
            {!!finalValue && !editValue && (
                <div className="looper-final">
                    <FinalComponent value={finalValue} />
                </div>
            )}
        </div>
    )
}

export default Looper;