import { ReactNode } from 'react';
import { conditionnalClassnames } from '../../utils/helpers';
import Tag, { TagProps } from '../Tag';
import './index.scss';

interface ValueDescriptionProps {
    tag?: TagProps;
    tags?: ReactNode;
    value?: ReactNode;
    description?: string;
    inline?: boolean;
    wrap?: boolean;
    onClick?: () => void;
    preline?: boolean;
}

const ValueDescription = ({ value, tag, tags, description, inline, onClick, wrap, preline }: ValueDescriptionProps) => (
    <div className={conditionnalClassnames({
        "value-description": true,
        inline,
        wrap,
        preline
    })} onClick={onClick}>
        {(!!value || !!tag) && <div><span>{value}</span>{!!tag && <Tag {...tag} />}{tags}</div>}
        {!!description && <span>{description}</span>}
    </div>
);

export default ValueDescription;