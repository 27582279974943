import Validation, {
    ObjectValidation,
} from "../sg-react/form/Validation/class";
import {
    NAMED_ENTITY_VALIDATION,
    NamedEntity,
} from "../sg-react/models/entity";

export interface Segmentation extends NamedEntity {
    color?: string;
    parentId?: string;
    parent?: Segmentation;
    childrenIds: string[];
    childrenRecursiveIds: string[];
    children?: Segmentation[];
    workspaceId: string;
}

export interface SegmentationsCount {
    color?: string;
    _id: string;
    count: number;
}

export const SEGMENTATION_VALIDATION = new ObjectValidation<Segmentation>({
    color: Validation.string().minLength(6).maxLength(7),
}).merge(NAMED_ENTITY_VALIDATION);

export const filterTree = (
    segmentations: Segmentation[],
    showAll: boolean,
    selected: string[],
    keyword?: string
): Segmentation[] =>
    segmentations
        .map((segmentation) => ({
            ...segmentation,
            children: segmentation.children?.length
                ? filterTree(segmentation.children, showAll, selected, keyword)
                : [],
        }))
        .filter(
            (segmentation) =>
                ((showAll ||
                    (!showAll && selected.includes(segmentation._id))) &&
                    (!keyword ||
                        (keyword &&
                            (segmentation.name
                                .toLowerCase()
                                .includes(keyword.toLowerCase()) ||
                                segmentation.description
                                    ?.toLowerCase()
                                    .includes(keyword.toLowerCase()))))) ||
                segmentation.children.length
        );
