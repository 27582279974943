import { useCallback, useRef, useState } from "react";
import { DownloadIcon } from "../../icons";
import { Modal } from "../../ui";
import ButtonBig from "../../ui/ButtonBig";
import Info from "../../ui/Info";
import LabelValue from "../../ui/LabelValue";

interface FileUploadModalProps {
    tooltip?: string | { label: string, i18n: string };
    extensions: string[];
    onClose: () => void;
    onSubmit: (f: File) => void;
}

const FileUploadModal = ({ tooltip, extensions, onClose, onSubmit }: FileUploadModalProps) => {
    const inputRef = useRef<HTMLInputElement | null>(null);
    const [file, setFile] = useState<File | null>(null);

    const handleFile = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        if (!e.target.files) {
            return;
        }
        const file = e.target.files[0];
        const fileExtension = file.name.split('.').pop();
        if (fileExtension && extensions.includes(fileExtension)) {
            setFile(e.target.files[0]);
        }
    }, [extensions]);

    const handleSubmit = useCallback(() => {
        if (!file) return;

        onSubmit(file);
        onClose();
    }, [file, onSubmit, onClose]);

    return (
        <Modal
            i18n="files"
            title="upload_file"
            size="small"
            onClose={onClose}
            onSubmit={handleSubmit}
        >
            {tooltip && <Info noIcon label={typeof tooltip !== 'string' ? tooltip.label : tooltip} i18n={typeof tooltip !== 'string' ? tooltip.i18n : undefined} />}
            <LabelValue inline i18n="files" label="authorized_extensions" value={extensions.join(', ')} />
            <ButtonBig icon={<DownloadIcon />} label="select_file" i18n="files" onClick={() => inputRef.current?.click()} />
            <input
                type="file"
                accept={extensions.map(e => '.' + e).join(',')}
                ref={inputRef}
                onChange={handleFile}
                style={{ display: 'none' }}
            />
            {!!file && (
                <LabelValue inline i18n="files" label="selected_file" value={file.name} />
            )}
        </Modal>
    )
}
export default FileUploadModal;