import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Bar, BarChart, Cell, LabelList, ResponsiveContainer, Treemap, XAxis, YAxis } from "recharts";
import { DirectoryDataDashboardRevenues } from "../../../models/directory-data";
import { useRequest } from "../../../sg-react/context";
import { Card } from "../../../sg-react/ui";
import { COLORS_FOR_CHARTS } from "../../../sg-react/utils/constants";

const DashboardRevenues = ({ }) => {
    const [directoryDataDashboardRevenues, setDirectoryDataDashboardRevenues] = useState<DirectoryDataDashboardRevenues | null>(null);
    const { t } = useTranslation();
    const request = useRequest();

    useEffect(() => {
        request.get<DirectoryDataDashboardRevenues>('/directory-data/dashboard/revenues', { withWorkspace: true })
            .then(setDirectoryDataDashboardRevenues)
            .catch(() => null);
    }, []);

    if (!directoryDataDashboardRevenues) return null;

    return (
        <div id="dashboard-revenues" className="row row-layout row-equal-cols">
            <Card title="segmentations_revenues" i18n="directory-data">
                <ResponsiveContainer minWidth="100%" width="100%" height={220}>
                    <BarChart
                        data={directoryDataDashboardRevenues.segmentationsRevenues.map(r => ({
                            name: r.segmentation?.name,
                            color: r.segmentation?.color,
                            amount: r.amount
                        }))}
                        layout="horizontal"
                        margin={{ top: 30 }}
                    >
                        <LabelList dataKey="name" position="right" />
                        <XAxis type="category" dataKey="name" />
                        <YAxis type="number" />
                        <Bar dataKey="amount" barSize={20}>
                            <LabelList dataKey="amount" position="top" style={{ fill: "white" }} />
                            {directoryDataDashboardRevenues.segmentationsRevenues.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={entry.segmentation?.color} />
                            ))}
                        </Bar>
                    </BarChart>
                </ResponsiveContainer>
            </Card>
            <Card title="directory_items_revenues" i18n="directory-data">
                <ResponsiveContainer width="99%" height={220}>
                    <Treemap
                        data={directoryDataDashboardRevenues.directoryItemsRevenues.map((r, index) => ({
                            name: r.directoryItem.name,
                            size: r.amount,
                            fill: COLORS_FOR_CHARTS[index % COLORS_FOR_CHARTS.length]
                        }))}
                        dataKey="size"
                        aspectRatio={6 / 3}
                        stroke="#fff"
                        fill="#8884d8"
                    >
                        <LabelList dataKey="size" position="insideRight" style={{ fill: "white" }} />
                    </Treemap>
                </ResponsiveContainer>
            </Card>
        </div>
    )
}
export default DashboardRevenues;