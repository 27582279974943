import { FormEvent, ReactNode, useCallback } from "react";
import { Button } from "../../ui";
import { Colors } from "../../ui/enums";
import './index.scss';
import { ButtonProps } from "../../ui/Button";

interface FormProps {
    id?: string;
    onSubmit: (e: FormEvent) => void;
    children: ReactNode;
    submitButton?: boolean;
    loading?: boolean;
    actionsRight?: ButtonProps[];
    actionsLeft?: ButtonProps[];
}

const Form = ({ id, onSubmit, children, submitButton, actionsLeft, actionsRight, loading }: FormProps) => {
    const handleSubmit = useCallback((e: FormEvent) => {
        e.preventDefault();

        if (loading) return;
        onSubmit(e);
    }, [onSubmit, loading]);

    return (
        <form id={id} onSubmit={handleSubmit} className="form col col-layout">
            {children}
            {(!!submitButton || !!actionsLeft?.length || !!actionsRight?.length) && (
                <div className="form-actions">
                    <div className="form-actions-left">
                        {actionsLeft?.map(a => <Button key={a.label} {...a} />)}
                    </div>
                    <div className="form-actions-right">
                        {actionsRight?.map(a => <Button key={a.label} {...a} />)}
                        {!!submitButton && <Button color={Colors.ACCENT} loading={loading} type="submit" label="submit" i18n="actions" />}
                    </div>
                </div>
            )}
            <button type="submit" style={{ display: 'none' }} />
        </form>
    )
}
export default Form;