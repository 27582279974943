import { ReactNode, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { useAuthContext } from '../../context';
import { Role } from '../../utils/enums';
import { translateLabel } from '../../utils/format';
import { conditionnalClassnames } from '../../utils/helpers';
import './index.scss';

export interface HorizontalNavigationItem {
    path: string;
    label?: string;
    i18n?: string;
    icon?: ReactNode;
    roles?: Role[];
}

export interface HorizontalNavigationProps {
    items: HorizontalNavigationItem[];
    root?: string;
    labelOnHover?: boolean;
}

const HorizontalNavigation = ({ items, labelOnHover, root }: HorizontalNavigationProps) => {
    const { currentRole } = useAuthContext();
    const { t } = useTranslation();
    const location = useLocation();

    const navigationItems = useMemo(() => items
        .filter(item => !item.roles || (currentRole && item.roles.includes(currentRole)))
        .map(item => {
            const fullPath = !root ? item.path : `${root}${item.path}`;

            return (
                <Link
                    key={item.path}
                    to={fullPath}
                    className={conditionnalClassnames({
                        "horizontal-navigation-item": true,
                        "horizontal-navigation-item-no-label": !item.i18n && !item.label,
                        "horizontal-navigation-item-label-hover": labelOnHover,
                        active: location.pathname === fullPath || (location.pathname + '/') === fullPath || (location.pathname !== '/' && location.pathname.startsWith(fullPath + '/')),
                    })}
                >
                    {item.icon}
                    {!!item.label && <span>{translateLabel(item.label, t, item.i18n)}</span>}
                </Link>
            );
        }), [items, currentRole, location.pathname, labelOnHover, t, root])


    return (
        <div className="horizontal-navigation">
            {navigationItems}
        </div>
    )
}

export default HorizontalNavigation;