import { t } from "i18next";
import { TypingIcon } from "../../../sg-react/icons";
import { Card } from "../../../sg-react/ui";
import FakeInput from "../../../sg-react/ui/FakeInput";
import Pill from "../../../sg-react/ui/Pill";
import { Colors } from "../../../sg-react/ui/enums";
import ValueAddList from "../../../sg-react/data/ValueAddList";
import { useCallback, useEffect, useRef } from "react";
import { UseFocusHandleRef } from "../../../sg-react/hooks";
import { useTranslation } from "react-i18next";
import Dropdown, { useDropdown } from "../../../sg-react/ui/Dropdown";

interface MainMapKeywordFilterProps {
    keywords?: string[];
    onChange: (k?: string[]) => void;
}

const MainMapKeywordFilter = ({ keywords, onChange }: MainMapKeywordFilterProps) => {
    const focusHandle = useRef<UseFocusHandleRef | null>(null);
    const { visible } = useDropdown();

    useEffect(() => {
        if (visible) focusHandle.current?.focus();
    }, [visible]);

    return (
        <ValueAddList
            focusHandle={focusHandle}
            id="map-keyword"
            placeholder={t('data:keyword')}
            value={keywords}
            onChange={onChange}
        />
    )
}
export default MainMapKeywordFilter;