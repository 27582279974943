
import { Dependency, DependencyType } from "../../models/dependency";
import { NetworkIcon, WindTurbine } from "../../sg-react/icons";

interface DepencyIconProps {
    depency?: Partial<Dependency>;
    type?: DependencyType;
}

const DepencyIcon = ({ depency, type }: DepencyIconProps) => {
    switch (depency?.type ?? type) {
        case DependencyType.WindTurbine:
            return <WindTurbine className="dependency-icon" />;
        default:
            return <NetworkIcon className="dependency-icon" />;
    }
}

export default DepencyIcon;