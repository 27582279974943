
import { Fragment, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import SpecificationWizard from "../../components/SpecificationWizard";
import { Specification } from "../../models/specification";
import { useMenuContext, useWorkspaceContext } from "../../sg-react/context";
import { DataTableList } from "../../sg-react/data";
import { DataTableListRefProps } from "../../sg-react/data/DataTableList";
import { FilterType } from "../../sg-react/data/FiltersPanel";
import TagsList from "../../sg-react/data/TagsList";
import { Action } from "../../sg-react/utils/enums";

const Specifications = () => {
    const { currentWorkspace } = useWorkspaceContext();
    const { action, setActionDone } = useMenuContext();
    const [specificationToEdit, setSpecificationToEdit] = useState<Partial<Specification> | null>(null);
    const navigate = useNavigate();
    const ref = useRef<DataTableListRefProps>(null);

    useEffect(() => {
        if (action?.action) {
            switch (action.action) {
                case Action.Create:
                    setSpecificationToEdit({});
                    break;
                default:
                    break;
            }
            setActionDone(action.timestamp);
        }
    }, [action?.timestamp]);

    return (
        <Fragment>
            <DataTableList<Specification>
                primaryKey="_id"
                endpoint="/specifications"
                onEdit={(d) => setSpecificationToEdit(d)}
                onClick={(d) => navigate(`/specifications/view/${d._id}`)}
                filters={[
                    { field: 'name', label: 'name', i18n: 'entity', type: FilterType.Search },
                    { field: 'description', label: 'description', i18n: 'entity', type: FilterType.Ilike },
                ]}
                columns={[
                    { field: 'name', label: 'name', i18n: 'entity', sort: true },
                    { field: 'description', label: 'description', i18n: 'entity', sort: true },
                    { field: 'tags', label: 'tags', i18n: 'ui', display: (e) => <TagsList tagsIds={e.tagsIds} /> },
                ]}
                initialPagination={{ sort: { name: 1 } }}
                additionalFilters={{ workspaceId: currentWorkspace._id }}
                withSearch
                refHandle={ref}
            />
            {!!specificationToEdit && (
                <SpecificationWizard specification={specificationToEdit} onClose={() => setSpecificationToEdit(null)} onSubmit={() => ref.current?.refresh()} />
            )}
        </Fragment>
    );
}
export default Specifications;