import { ValueDate, ValueDateWithHistory } from "../sg-react/models/data";
import { Entity } from "../sg-react/models/entity";
import { Image } from "../sg-react/models/image";
import { User } from "../sg-react/models/user";
import { Workspace, WorkspacePrivacy } from "../sg-react/models/workspace";
import { Colors } from "../sg-react/ui/enums";
import { DirectoryContact } from "./directory-contact";
import { DirectoryItem, DirectoryItemType } from "./directory-item";
import { Part } from "./part";
import { Segmentation, SegmentationsCount } from "./segmentation";
import { Specification } from "./specification";

export type DirectoryData = Entity & {
    directoryItemId: string;
    directoryItem?: DirectoryItem;
    segmentationsIds?: string[];
    specificationsIds?: string[];
    specifications?: Specification[];
    parts?: Part[];
    contacts?: DirectoryContact[];
    indicators?: DirectoryDataIndicators;
    revenues?: DirectoryRevenue[];
    messages?: DirectoryMessage[];
    topics?: DirectoryTopic[];
    dates?: DirectoryDate[];
    rseProjects?: DirectoryRseProject[];
    emissions?: DirectoryEmissions;
    workspaceId: string;
    settings?: DirectoryDataSettings;
    permissions: {
        read?: DirectoryDataWidget[];
        write?: DirectoryDataWidget[];
    };
};

export interface DirectoryDataSettingsUser {
    userId: string;
    user?: User;
    widgets?: string[];
}

export interface DirectoryDataSettingsGuests {
    usersIds: string[];
    users?: User[];

    workspacesIds: string[];
    workspaces: Workspace[];

    organisationsIds: string[];
}

export interface DirectoryDataPrivacy {
    mode: WorkspacePrivacy;
    users?: DirectoryDataSettingsUser[];
}

export interface DirectoryDataSettings {
    widgets?: string[];
    referenceUsers: DirectoryDataPrivacy;
    visibility: DirectoryDataPrivacy;
    notifications: DirectoryDataPrivacy;
    guests?: DirectoryDataSettingsGuests;
}

export interface DirectoryDataIndicators {
    purchasingStrategy?: ValueDateWithHistory<DirectoryPurchasingStrategy>;
    salesGrade?: ValueDateWithHistory<DirectoryGrade>;
    qualityGrade?: ValueDateWithHistory<DirectoryGrade>;
    procGrade?: ValueDateWithHistory<DirectoryGrade>;
    otd?: ValueDateWithHistory<number>;
    oqd?: ValueDateWithHistory<number>;
}

export interface DirectoryMessage {
    _id: string;
    priority: number;
    message: string;
    date: Date;
}

export interface DirectoryTopic {
    _id: string;
    topic: string;
    date: Date;
    part?: string;
    project?: string;
}

export interface DirectoryDate {
    _id: string;
    name: string;
    description?: string;
    date: Date;
    notificationDate?: Date;
}

export interface DirectoryRseProject {
    _id: string;
    name: string;
    leader: string;
    impactEmission: number;
    impactCost: number;
    progress: ValueDate<number>[];
    status: ProjectStatus;
}

export interface DirectoryRevenue {
    _id: string;
    segmentationId: string;
    segmentation?: Segmentation;
    year: number;
    amount: number;
    closingDate: Date;
}

export interface DirectoryEmissions {
    goal: number;
    emissions: ValueDate<number>[];
}

export interface DirectoryDataDashboardDate {
    _id: string;
    name: string;
    date: DirectoryDate;
}

export interface DirectoryDataDashboardTops
    extends ValueDateWithHistory<number> {
    directoryItem: DirectoryItem;
    average?: number;
}

export interface DirectoryDataDashboardClassification {
    directoryItem: DirectoryItem;
    complexity: number;
    grade: DirectoryPurchasingStrategy;
}

export interface DirectoryDataDashboard {
    otd?: {
        tops: DirectoryDataDashboardTops[];
        flops: DirectoryDataDashboardTops[];
    };
    oqd?: {
        tops: DirectoryDataDashboardTops[];
        flops: DirectoryDataDashboardTops[];
    };
    classifications: DirectoryDataDashboardClassification[];
}

export interface DirectoryDataDashboardRevenues {
    segmentationsRevenues: DirectoryRevenue[];
    directoryItemsRevenues: {
        directoryItem: DirectoryItem;
        amount: number;
    }[];
}

export interface DirectoryDataDashboardItem {
    _id: string;
    directoryItem: {
        _id: string;
        name: string;
        type: DirectoryItemType[];
        logo?: Image;
        address?: string;
    };
    segmentationsCount: SegmentationsCount[];
    salesIndicators?: {
        purchasingStrategy?: DirectoryPurchasingStrategy;
        salesGrade?: DirectoryGrade;
        qualityGrade?: DirectoryGrade;
        procGrade?: DirectoryGrade;
        otd?: number;
        oqd?: number;
    };
    updatedAt: number;
    createdAt: number;
}

export enum ProjectStatus {
    Planned = "PLANNED",
    InProgress = "IN_PROGRESS",
    Delayed = "DELAYED",
    Completed = "COMPLETED",
    Abandonned = "ABANDONNED",
}

export enum DirectoryPurchasingStrategy {
    Target = "TARGET",
    PotentialTarget = "POTENTIAL_TARGET",
    Maintain = "MAINTAIN",
    Probation = "PROBATION",
    Exit = "EXIT",
    BlackListed = "BLACK_LISTED",
}

export enum DirectoryGrade {
    Excellent = "EXCELLENT",
    Good = "GOOD",
    Mediocre = "MEDIOCRE",
    Bad = "BAD",
    Unacceptable = "UNACCEPTABLE",
}

export enum DirectoryDataWidget {
    SalesIndicators = "SALES_INDICATORS",
    Revenue = "REVENUE",
    Emissions = "EMISSIONS",
    ReductionProjects = "REDUCTION_PROJETS",
    Dates = "DATES",
    Messages = "MESSAGES",
    Topics = "TOPICS",
    Contacts = "CONTACTS",
    Segmentations = "SEGMENTATIONS",
    Specifications = "SPECIFICATIONS",
    Parts = "PARTS",
}

export const getGradeColor = (grade?: string): Colors => {
    if (grade === "TARGET" || grade === "POTENTIAL_TARGET") {
        return Colors.PRISTINE;
    } else if (
        grade === "MAINTAIN" ||
        grade === "GOOD" ||
        grade === "EXCELLENT"
    ) {
        return Colors.SUCCESS;
    } else if (grade === "PROBATION" || grade === "MEDIOCRE") {
        return Colors.WARNING;
    } else if (grade === "EXIT" || grade === "BAD") {
        return Colors.ERROR;
    } else if (grade === "BLACK_LISTED" || grade === "UNACCEPTABLE") {
        return Colors.BLACK;
    }

    return Colors.WHITE;
};

export const getIndicatorColor = (
    value: number,
    limits?: { color: string; limit: number }[]
): string => {
    if (!limits?.length) return "";
    if (limits.length === 1) return limits[0].color;

    return (
        limits
            .sort((l1, l2) => (l1.limit > l2.limit ? 1 : -1))
            .find((l, i, arr) => l.limit > value || i === arr.length - 1)
            ?.color ?? ""
    );
};
