import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { DirectoryItem } from '../../models/directory-item';
import { useRequest, useWorkspaceContext } from '../../sg-react/context';
import { useDebounce, useOutsideClick } from '../../sg-react/hooks';
import { CloseIcon, SearchGlobeIcon } from '../../sg-react/icons';
import { Spinner } from '../../sg-react/ui';
import ValueDescription from '../../sg-react/ui/ValueDescription';
import { conditionnalClassnames } from '../../sg-react/utils/helpers';
import DirectoryItemIcon from '../DirectoryItemIcon';
import './index.scss';

const HeaderSearch = () => {
    const { currentWorkspace } = useWorkspaceContext();
    const { t } = useTranslation();
    const [keyword, setKeyword] = useState<string | undefined>();
    const [isLoading, setLoading] = useState<boolean>(false);
    const [selectedResult, setSelectedResult] = useState<string | undefined>();
    const [isResultsPanelVisible, setResultsPanelVisible] = useState<boolean>(false);
    const [results, setResults] = useState<DirectoryItem[] | null>(null);
    const request = useRequest();
    useDebounce(() => getSuggestions(keyword), 500, [keyword]);
    const ref = useOutsideClick(() => setResultsPanelVisible(false));
    const navigate = useNavigate();

    const handleKeyword = useCallback((k: string) => {
        setKeyword(k);

        if (!!k && k.length >= 3) {
            setLoading(true);
        } else {
            setResults(null);
            setLoading(false);
        }
    }, []);

    const handleGoTo = useCallback((d: DirectoryItem) => {
        setResultsPanelVisible(false);
        navigate(`/view/${d._id}`);
    }, []);

    const clearSearch = useCallback(() => {
        setKeyword('');
        setResults(null);
        setSelectedResult('');
    }, []);

    const getSuggestions = useCallback(async (k?: string) => {
        if (!k || k.length < 3) return;
        setLoading(true);

        request.get<DirectoryItem[]>('/directory/search', { params: { keyword: k }, withWorkspace: true, errorMessage: true })
            .then(setResults)
            .catch(() => null)
            .finally(() => setLoading(false));

    }, [currentWorkspace, request]);

    useEffect(() => {
        setResults(null);
        setSelectedResult('');
    }, [currentWorkspace]);

    return (
        <div id="header-search-wrapper">
            <div id="header-search" className={conditionnalClassnames({ focus: isResultsPanelVisible })} ref={ref}>
                <div id="header-search-input">
                    <SearchGlobeIcon />
                    <input
                        type="text"
                        autoComplete="off"
                        id="search"
                        placeholder={t('data:quick_search')}
                        value={!isResultsPanelVisible && !!selectedResult ? selectedResult : keyword ?? ''}
                        onChange={(e) => !selectedResult || e.target.value !== selectedResult ? handleKeyword(e.target.value) : null}
                        onFocus={() => setResultsPanelVisible(true)}
                    />
                    {!!isLoading && <Spinner small />}
                    {!!keyword && <CloseIcon onClick={clearSearch} id="header-search-clear" />}
                </div>
                {isResultsPanelVisible && !!keyword && keyword.length > 3 && (
                    <div id="header-search-results">
                        {results?.map(d => (
                            <div key={d._id} className="row" onClick={() => handleGoTo(d)}>
                                <DirectoryItemIcon type={d.type} />
                                <ValueDescription wrap value={d.name} description={d.location?.address} />
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    )
}

export default HeaderSearch;