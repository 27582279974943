import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { DirectoryItemViewPatchProps } from "..";
import DirectoryDataDate from "../../../components/DirectoryDataDate";
import { DirectoryDate } from "../../../models/directory-data";
import { useRequest } from "../../../sg-react/context";
import { ModalDelete } from "../../../sg-react/data";
import { DatePicker, ScrollableList, TextArea, TextField } from "../../../sg-react/form";
import Validation, { ObjectValidation } from "../../../sg-react/form/Validation/class";
import { useForm } from "../../../sg-react/hooks";
import { EditIcon, MenuIcon, PlusIcon, TrashIcon } from "../../../sg-react/icons";
import { Button, Card, Modal } from "../../../sg-react/ui";
import DropdownMenu from "../../../sg-react/ui/DropdownMenu";

const VALIDATION = new ObjectValidation({
    name: Validation.string().required().minLength(3).maxLength(100),
    description: Validation.string().minLength(10).maxLength(250),
    date: Validation.date().required()
});

const Dates = ({ directoryItem, directoryData, refresh, canEdit }: DirectoryItemViewPatchProps) => {
    const { t } = useTranslation();
    const [dateToDelete, setDateToDelete] = useState<DirectoryDate | null>(null);
    const { entity, reset, attachInput, validate } = useForm<DirectoryDate & { notificationType?: string }>({}, VALIDATION);
    const request = useRequest();

    const datesSorted = useMemo(() => directoryData.dates?.sort((d1, d2) => d1.date > d2.date ? 1 : -1), [directoryData]);
    const notificationList = useMemo(() => [
        { key: 'day_before', label: t('dates:day_before') },
        { key: 'week_before', label: t('dates:week_before') },
        { key: 'month_before', label: t('dates:month_before') },
        { key: 'manual_date', label: t('dates:manual_date') },
    ], []);

    const createOrUpdateDate = useCallback(() => validate((entity) => {
        if (!entity.date) return;

        if (entity.notificationType && entity.notificationType !== 'manual_date') {
            entity.notificationDate = entity.date;
            if (entity.notificationType === 'day_before') {
                entity.notificationDate.setDate(entity.date.getDate() - 1);
            } else if (entity.notificationType === 'week_before') {
                entity.notificationDate.setDate(entity.date.getDate() - 7);
            } else if (entity.notificationType === 'month_before') {
                entity.notificationDate.setMonth(entity.date.getMonth() - 1);
            }
        }

        const requestMethod = !entity._id ? request.post : request.put;

        requestMethod(`/directory-data/${directoryItem._id}/date`, entity, {
            loader: true,
            withWorkspace: true,
            successMessage: true,
            errorMessage: true,
        })
            .then(() => {
                reset({});
                refresh();
            })
            .catch(() => null);
    }), [request, directoryItem, refresh, validate, reset]);

    const deleteDate = useCallback((date: DirectoryDate) => {
        request.delete(`/directory-data/${directoryItem._id}/date/${date._id}`, {
            loader: true,
            withWorkspace: true,
            successMessage: true,
            errorMessage: true,
        })
            .then(() => {
                setDateToDelete(null);
                refresh();
            })
            .catch(() => null);
    }, [request, directoryItem, refresh]);

    return (
        <Card title="dates" i18n="dates" options={canEdit && <Button color="navigation" icon={<PlusIcon />} i18n="actions" label="add" onClick={() => reset({ date: new Date() })} />}>
            {!!datesSorted?.length && (
                <div id="directory-item-view-dates" className="simple-list">
                    {datesSorted.map(d => (
                        <DirectoryDataDate key={d._id} date={d}>
                            {canEdit && (
                                <DropdownMenu disposition="left" items={[
                                    { itemKey: 'edit', icon: <EditIcon />, i18n: "actions", label: "edit", onClick: () => reset({ ...d }) },
                                    { itemKey: 'delete', icon: <TrashIcon />, i18n: "actions", label: "delete", onClick: () => setDateToDelete(d) }
                                ]}>
                                    <Button color="navigation" icon={<MenuIcon />} />
                                </DropdownMenu>
                            )}
                        </DirectoryDataDate>
                    ))}
                </div>
            )}
            {!!entity.date && (
                <Modal
                    size="small"
                    title="date"
                    i18n="dates"
                    onClose={() => reset({})}
                    onSubmit={createOrUpdateDate}
                >
                    <TextField
                        label
                        i18n="entity"
                        {...attachInput('name')}
                    />
                    <TextArea
                        label
                        i18n="entity"
                        {...attachInput('description')}
                    />
                    <DatePicker
                        label
                        i18n="directory-data"
                        {...attachInput('date')}
                    />
                    <ScrollableList
                        label="reminder"
                        i18n="directory-data"
                        items={notificationList}
                        {...attachInput('notificationType')}
                    />
                    {entity.notificationType === 'manual_date' && (
                        <DatePicker label i18n="directory-data" {...attachInput('notificationDate')} />
                    )}
                </Modal>
            )}
            {!!dateToDelete && <ModalDelete onClose={() => setDateToDelete(null)} onSubmit={() => deleteDate(dateToDelete)} />}
        </Card>
    )
}
export default Dates;