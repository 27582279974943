import { useCallback, useMemo } from "react";
import { PART_STEP_VALIDATION, Part, PartStep } from "../../models/part";
import { TextArea, TextField } from "../../sg-react/form";
import SelectMultiple from "../../sg-react/form/SelectMultiple";
import { useForm } from "../../sg-react/hooks";
import { Modal } from "../../sg-react/ui";

interface PartStepModalProps {
    part: Part;
    step?: Partial<PartStep>;
    onClose: () => void;
    onSubmit: (step: PartStep) => void;
}

const PartStepModal = ({ part, step, onSubmit, onClose }: PartStepModalProps) => {
    const { entity, validate, attachInput, onMultipleChange } = useForm<PartStep & { previousStepId?: string }>({ ...step }, PART_STEP_VALIDATION);

    const availableSteps = useMemo(() => part.steps.filter(s => s._id !== step?._id).map(s => ({ key: s._id, label: s.name })), [step, part]);

    const createOrUpdate = useCallback(() => validate((step) => {
        onSubmit(step);
        onClose();
    }), [validate, onSubmit, onClose]);

    return (
        <Modal
            size="medium"
            i18n="actions"
            title={entity._id ? 'edit' : 'create'}
            onClose={onClose}
            onSubmit={createOrUpdate}
            overflow="visible"
        >
            <div className="form-inline-group">
                <TextField label inline i18n="entity" {...attachInput('name')} />
                <TextArea label inline i18n="entity" rows={5} {...attachInput('description')} />
                {!!entity.previousStepId && <TextField id="previousStepId" disabled label="previous_step" value={availableSteps.find(a => a.key === entity.previousStepId)?.label} inline i18n="parts" onChange={() => null} />}
                <SelectMultiple label="next_steps" inline i18n="parts" items={availableSteps} {...attachInput('nextStepsIds')} />
            </div>
        </Modal>
    )
}
export default PartStepModal;