import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import DirectoryItemIcon from "../../../components/DirectoryItemIcon";
import { DirectoryItemType } from "../../../models/directory-item";
import { BoxIcon, BuildingIcon, LocationIcon, MagnifierIcon, ReloadIcon, TreeIcon, TubeIcon, TypingIcon } from "../../../sg-react/icons";
import { Button } from "../../../sg-react/ui";
import { Colors } from "../../../sg-react/ui/enums";
import { translateEnum } from "../../../sg-react/utils/format";
import { toggleInArray } from "../../../sg-react/utils/objects";
import MainMapFilter from "./MainMapFilter";
import MainMapFilterGeo from "./MainMapFilterGeo";
import MainMapKeywordFilter from "./MainMapFilterKeyword";
import MainMapFilterParts from "./MainMapFilterParts";
import MainMapFilterSegmentations from "./MainMapFilterSegmentations";
import MainMapFilterSpecifications from "./MainMapFilterSpecifications";
import { useMapContext } from "./MapContext";

const MainMapFilters = () => {
    const { t } = useTranslation();
    const { filters, setFilters, getResults, plan } = useMapContext();
    const directoryItemButtons = useMemo(() => translateEnum(DirectoryItemType, 'directory', 'types', t), [t]).map(d => ({ ...d, icon: <DirectoryItemIcon type={[d.key]} /> }));

    return (
        <div id="main-map-filters" className="row row-layout">
            {plan.steps.length > 1 && (
                <div id="main-map-filters-name" style={{ backgroundColor: filters.color }}>
                    <span>{filters.name}</span>
                </div>
            )}
            <MainMapFilter icon={<TypingIcon />} label={t('data:keyword')} count={filters?.keywords?.length}>
                <MainMapKeywordFilter keywords={filters?.keywords} onChange={(keywords) => setFilters({ keywords })} />
            </MainMapFilter>
            <MainMapFilter icon={<LocationIcon />} label={t('location:location')} count={filters?.geosearches?.length}>
                <MainMapFilterGeo geosearches={filters.geosearches} onChange={(geosearches) => setFilters({ geosearches })} />
            </MainMapFilter>
            <MainMapFilter icon={<BuildingIcon />} label={t('directory:type')} count={filters?.types?.length}>
                <div className="row row-wrap">
                    {directoryItemButtons.map(button => (
                        <Button
                            key={String(button.key)}
                            color={filters?.types?.includes(button.key) ? Colors.SUCCESS : "navigation"}
                            icon={button.icon}
                            iconPosition="left"
                            label={button.label}
                            onClick={() => setFilters({ types: toggleInArray(filters.types, button.key) })}
                            small
                        />
                    ))}
                </div>
            </MainMapFilter>
            <MainMapFilter icon={<TreeIcon />} label={t('segmentations:segmentations')} count={filters.segmentationsIds?.length}>
                <MainMapFilterSegmentations segmentationsIds={filters.segmentationsIds} onChange={(segmentationsIds) => setFilters({ segmentationsIds })} />
            </MainMapFilter>
            <MainMapFilter icon={<TubeIcon />} label={t('specifications:specifications')} count={filters?.specificationsIds?.length}>
                <MainMapFilterSpecifications specificationsIds={filters.specificationsIds} onChange={(specificationsIds) => setFilters({ specificationsIds })} />
            </MainMapFilter>
            <MainMapFilter icon={<BoxIcon />} label={t('parts:parts')} count={filters?.partsIds?.length}>
                <MainMapFilterParts partsIds={filters.partsIds} onChange={(partsIds) => setFilters({ partsIds })} />
            </MainMapFilter>
            <div id="main-map-filters-actions" className="row">
                <Button color={Colors.ERROR} icon={<ReloadIcon />} onClick={() => setFilters()} />
                <Button color={Colors.PRIMARY} label="search" icon={<MagnifierIcon />} i18n="actions" onClick={() => getResults()} />
            </div>
        </div>
    )
}

export default MainMapFilters;