// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input[input-type=text-area] {
  width: 100%;
}
.input[input-type=text-area] .input-field {
  height: -moz-fit-content;
  height: fit-content;
}
.input[input-type=text-area] .input-field textarea {
  resize: none;
  max-height: 350px;
  padding: 6px;
  border: none;
}`, "",{"version":3,"sources":["webpack://./src/sg-react/form/TextArea/index.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;AACJ;AACI;EACI,wBAAA;EAAA,mBAAA;AACR;AACQ;EACI,YAAA;EACA,iBAAA;EACA,YAAA;EACA,YAAA;AACZ","sourcesContent":[".input[input-type=\"text-area\"] {\n    width: 100%;\n\n    & .input-field {\n        height: fit-content;\n\n        & textarea {\n            resize: none;\n            max-height: 350px;\n            padding: 6px;\n            border: none;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
