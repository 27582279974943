import { useCallback, useEffect, useRef, useState } from "react";
import { Part } from "../../../models/part";
import { useRequest } from "../../../sg-react/context";
import { Search } from "../../../sg-react/data";
import { SearchItem } from "../../../sg-react/data/Search";
import TagsList from "../../../sg-react/data/TagsList";
import { UseFocusHandleRef } from "../../../sg-react/hooks";
import { useDropdown } from "../../../sg-react/ui/Dropdown";
import List from "../../../sg-react/ui/List";
import ValueDescription from "../../../sg-react/ui/ValueDescription";

interface MainMapFilterPartsProps {
    partsIds?: string[];
    onChange: (partsIds?: string[]) => void;
}

const MainMapFilterParts = ({ partsIds, onChange }: MainMapFilterPartsProps) => {
    const request = useRequest();
    const focusHandle = useRef<UseFocusHandleRef | null>(null);
    const { visible } = useDropdown();
    const [results, setResults] = useState<Part[] | null>(null);
    const [parts, setParts] = useState<Part[]>([]);

    const get = useCallback(async (partsIds: string[]) => {
        request.get<Part[]>(`/parts/multiple`, { params: { partsIds }, withWorkspace: true })
            .then(setParts)
            .catch(() => null);
    }, [request]);

    const getResults = useCallback(async (keyword: string) => {
        request.get<Part[]>(`/parts/search`, { params: { keyword }, withWorkspace: true, errorMessage: true })
            .then(setResults)
            .catch(() => null);
    }, [request]);

    const clearSearch = useCallback(() => {
        setResults(null);
    }, []);

    const handleAddPart = useCallback(async (part: Part) => {
        if (partsIds?.includes(part._id)) return;
        onChange([...(partsIds ?? []), part._id]);
        setParts((parts) => [...parts, part]);
        clearSearch();
    }, [onChange, clearSearch, partsIds]);

    const handleRemovePart = useCallback(async (part: Part) => {
        if (!partsIds?.includes(part._id)) return;
        onChange(partsIds?.filter(_id => _id !== part._id));
        setParts((parts) => parts.filter(p => p._id !== part._id));
    }, [onChange, partsIds]);

    useEffect(() => {
        if (visible) focusHandle.current?.focus();
    }, [visible]);

    useEffect(() => {
        const toFetch = partsIds?.filter(_id => !parts.some(p => p._id === _id));
        if (toFetch?.length) {
            get(partsIds ?? []);
        } else {
            setParts(parts => parts.filter(s => partsIds?.includes(s._id)))
        }
    }, [partsIds]);

    return (
        <div className="col">
            <Search
                id="parts"
                onClear={clearSearch}
                onChange={getResults}
                focusHandle={focusHandle}
            >
                {!!results?.length && results.map(part => <SearchItem key={part._id} label={part.name} description={part.description} onClick={() => handleAddPart(part)} />)}
            </Search>
            {!!parts?.length && (
                <List>
                    {parts.map((part) => (
                        <List.Item key={part._id} onRemove={() => handleRemovePart(part)}>
                            <ValueDescription
                                value={part.name}
                                description={part.description}
                                tags={<TagsList tagsIds={part.tagsIds} />}
                            />
                        </List.Item>
                    ))}
                </List>
            )}
        </div>
    );
}

export default MainMapFilterParts;