import { Fragment, useMemo } from "react";
import { DirectoryItem } from "../../../models/directory-item";
import DirectoryItemContactsForm from "../../../pages/DirectoryItemForm/components/DirectoryItemContactsForm";
import DirectoryItemInformationsForm from "../../../pages/DirectoryItemForm/components/DirectoryItemInformationsForm";
import { useAuthContext, useWorkspaceContext } from "../../../sg-react/context";
import { Permission } from "../../../sg-react/models/permissions";
import Info from "../../../sg-react/ui/Info";
import { WizardContentComponentProps } from "../../../sg-react/ui/Wizard";
import { Role } from "../../../sg-react/utils/enums";

const DirectoryItemCreateData = ({ entity, attachInput, onMultipleChange }: WizardContentComponentProps<DirectoryItem>) => {
    const { currentWorkspace } = useWorkspaceContext();
    const { currentRole, currentUser } = useAuthContext();

    const canEdit = useMemo(() => !entity._id
        || !!entity.permissions?.workspaces.find(w => w.workspaceId === currentWorkspace._id && (w.permission === Permission.Edit || w.permission === Permission.Admin))
        || entity.permissions?.users?.some(u => u.userId === currentUser._id && u.permission === Permission.Edit)
        || currentRole !== Role.User, [entity, currentRole, currentWorkspace]);

    return (
        <Fragment>
            {!canEdit && <Info type="warning" i18n="entity" label="revision_required_tooltip" />}
            <DirectoryItemInformationsForm entity={entity} attachInput={attachInput} onMultipleChange={onMultipleChange} />
            <DirectoryItemContactsForm entity={entity} attachInput={attachInput} onMultipleChange={onMultipleChange} />
        </Fragment>
    )
}
export default DirectoryItemCreateData;