import { Part, PartSize } from "../../models/part";
import { Select } from "../../sg-react/form";
import { WizardContentComponentProps } from "../../sg-react/ui/Wizard";


const PartWizardDefinition = ({ entity, attachInput }: WizardContentComponentProps<Part>) => {

    return (
        <div className="form-inline-group">
            <Select label inline items={Object.keys(PartSize).map((s) => ({ key: s, label: s }))} i18n="parts" {...attachInput('size')} />
            <Select label="shape" inline items={Array.from({ length: 3 }).map((_, i) => ({ key: i + 1, label: i + 1 }))} i18n="parts" {...attachInput('complexity.shape')} />
            <Select label="internalExternal" inline items={Array.from({ length: 3 }).map((_, i) => ({ key: i + 1, label: i + 1 }))} i18n="parts" {...attachInput('complexity.internalExternal')} />
            <Select label="blindHoles" inline items={Array.from({ length: 3 }).map((_, i) => ({ key: i + 1, label: i + 1 }))} i18n="parts" {...attachInput('complexity.blindHoles')} />
            <Select label="spType" inline items={Array.from({ length: 5 }).map((_, i) => ({ key: i + 1, label: i + 1 }))} i18n="parts" {...attachInput('complexity.spType')} />
            <Select label="spNumber" inline items={Array.from({ length: 3 }).map((_, i) => ({ key: i + 1, label: i + 1 }))} i18n="parts" {...attachInput('complexity.spNumber')} />
        </div>
    )
}
export default PartWizardDefinition;