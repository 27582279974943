import { useCallback } from 'react';
import { Button } from '../../ui';
import { Colors } from '../../ui/enums';
import { toggleInArray } from '../../utils/objects';
import InputWrapper, { FormPropsInterface } from '../InputWrapper';
import { SelectListItem } from '../Select';
import './index.scss';

export interface ButtonListProps<T> extends FormPropsInterface<T[]> {
    items: SelectListItem<T>[];
    small?: boolean;
}

const ButtonList = <T,>({
    value,
    items,
    small,
    onChange,
    ...rest
}: ButtonListProps<T>) => {
    const handleChange = useCallback((i: SelectListItem<T>, value: T[] | undefined) => {
        if (rest.disabled || !onChange) return;

        if (!value?.length) {
            onChange([getId(i)]);
        } else {
            onChange(toggleInArray(value, getId(i)));
        }
    }, []);

    const getId = useCallback((i: SelectListItem<T>): T =>
        typeof i === 'string' || typeof i === 'number' ? i : i.key
        , []);

    const getLabel = useCallback((i?: SelectListItem<T>): string | undefined => i === undefined
        ? undefined
        : typeof i === 'string' || typeof i === 'number'
            ? String(i)
            : String(i.label) ?? String(i.key)
        , []);

    const isSelected = useCallback((i: SelectListItem<T>, value: T[] | undefined): boolean => !!value?.includes(getId(i))
        , [getId, value]);

    return (
        <InputWrapper type="button-list" {...rest}>
            <div className="row row-wrap">
                {items.map((i) => (
                    <Button
                        small={small}
                        key={String(getId(i) ?? getLabel(i))}
                        label={getLabel(i)}
                        onClick={() => handleChange(i, value)}
                        color={isSelected(i, value) ? Colors.SUCCESS : "navigation"}
                    />
                ))}
            </div>
        </InputWrapper>
    );
}

export default ButtonList;
