import { ReactNode } from "react";
import { CloseIcon } from "../../icons";
import './index.scss';

export interface ListProps {
    children: ReactNode;
    simple?: boolean;
}

const List = ({ children, simple }: ListProps) => (
    <div className={simple ? 'simple-list' : 'list'}>
        {children}
    </div>
);

export interface ListItemProps {
    onRemove?: () => void;
    onClick?: () => void;
    children: ReactNode;
}

const Item = ({ children, onClick, onRemove }: ListItemProps) => {
    return (
        <div className="row list-item" onClick={onClick}>
            {children}
            {!!onRemove && <CloseIcon className="pointer" onClick={onRemove} />}
        </div>
    )
}

List.Item = Item;

export default List;