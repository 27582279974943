import { useEffect, useRef } from "react";
import SegmentationsTree from "../../../components/SegmentationsTree";
import { UseFocusHandleRef } from "../../../sg-react/hooks";
import { useDropdown } from "../../../sg-react/ui/Dropdown";

interface MainMapFilterSegmentationsProps {
    segmentationsIds?: string[];
    onChange: (segmentationsIds?: string[]) => void;
}

const MainMapFilterSegmentations = ({ segmentationsIds, onChange }: MainMapFilterSegmentationsProps) => {
    const focusHandle = useRef<UseFocusHandleRef | null>(null);
    const { visible } = useDropdown();

    useEffect(() => {
        if (visible) focusHandle.current?.focus();
    }, [visible]);

    return (
        <SegmentationsTree
            selected={segmentationsIds}
            onSelect={onChange}
            focusHandle={focusHandle}
        />
    );
}

export default MainMapFilterSegmentations;