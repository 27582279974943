import { WorkspaceSettingsFormProps } from "..";
import { TextArea, TextField } from "../../../sg-react/form";
import ImageField from "../../../sg-react/form/ImageField";

const WorkspaceSettingsInformations = ({ entity, attachInput }: WorkspaceSettingsFormProps) => (
    <div className="form-inline-group">
        <TextField label inline tooltip i18n="workspaces" {...attachInput('name')} />
        <TextArea label inline tooltip i18n="workspaces" rows={5} {...attachInput('description')} />
        <ImageField label inline i18n="ui" endpoint="/workspaces/image" {...attachInput('image')} />
    </div>
);

export default WorkspaceSettingsInformations;