// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.location-picker .row {
  align-items: stretch;
}
.location-picker .row > * {
  align-self: stretch;
}
.location-picker .row > * .map {
  flex-grow: 1;
  border: var(--theme-container-background-color) 1px solid;
  border-radius: var(--theme-container-border-radius);
}`, "",{"version":3,"sources":["webpack://./src/sg-react/form/LocationPicker/index.scss"],"names":[],"mappings":"AACI;EACI,oBAAA;AAAR;AAEQ;EACI,mBAAA;AAAZ;AAEY;EACI,YAAA;EACA,yDAAA;EACA,mDAAA;AAAhB","sourcesContent":[".location-picker {\n    & .row {\n        align-items: stretch;\n\n        & > * {\n            align-self: stretch;\n\n            & .map {\n                flex-grow: 1;\n                border: var(--theme-container-background-color) 1px solid;\n                border-radius: var(--theme-container-border-radius);\n            }\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
