import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRequest } from "../../../context";
import { Select } from "../../../form";
import withData, { WithDataProps } from "../../../hoc/WithData";
import { useForm } from "../../../hooks";
import { User, UserStatus } from "../../../models/user";
import { Modal, UserItem } from "../../../ui";
import { Role } from "../../../utils/enums";
import { translateEnum } from "../../../utils/format";

interface ModalUserProps extends WithDataProps<User> {
    onClose: () => void;
}

const ModalUser = ({ data, onClose }: ModalUserProps) => {
    const { t } = useTranslation();
    const { entity, attachInput, hasChanged } = useForm<User>(data);
    const [isModalDeleteVisible, setModalDeleteVisible] = useState<boolean>(false);
    const request = useRequest();

    const roles = useMemo(() => translateEnum(Role, 'users', 'roles', t), [t]);
    const status = useMemo(() => translateEnum(UserStatus, 'users', 'status', t), [t]);

    const handleSubmit = useCallback(() => {
        if (request.loading) return;
        if (!hasChanged) onClose();

        request.patch(`/users/${entity._id}`, { role: entity.role, status: entity.status }, {
            loader: true,
            successMessage: true,
            errorMessage: true
        })
            .then(() => onClose())
            .catch(() => null);
    }, [request, entity, hasChanged, onClose]);

    return (
        <Modal
            i18n="users"
            title="user"
            size="small"
            onClose={onClose}
            onSubmit={handleSubmit}
            overflow="visible"
        >
            <UserItem user={entity} />
            <Select label items={roles} i18n="users" {...attachInput('role')} />
            <Select label items={status} i18n="users" {...attachInput('status')} />
        </Modal>
    )
}
export default withData(ModalUser);