// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.spinner {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}
.spinner > svg {
  width: 60px;
  height: 60px;
  fill: transparent;
  stroke: var(--theme-spinner-stroke);
  fill: var(--theme-spinner-fill);
  animation: spinner 2.5s ease-in-out infinite;
}
@keyframes spinner {
  0% {
    transform: rotate(0deg);
    opacity: 0.3;
    width: 60%;
  }
  50% {
    opacity: 0.8;
    width: 100%;
  }
  100% {
    transform: rotate(720deg);
    opacity: 0.3;
    width: 60%;
  }
}
.spinner.spinner-small > svg {
  width: 20px;
  height: 20px;
}`, "",{"version":3,"sources":["webpack://./src/sg-react/ui/Spinner/index.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,cAAA;AACJ;AACI;EACI,WAAA;EACA,YAAA;EACA,iBAAA;EACA,mCAAA;EACA,+BAAA;EAGA,4CAAA;AACR;AACQ;EACI;IACI,uBAAA;IACA,YAAA;IACA,UAAA;EACd;EACU;IACI,YAAA;IACA,WAAA;EACd;EACU;IACI,yBAAA;IACA,YAAA;IACA,UAAA;EACd;AACF;AAGI;EACI,WAAA;EACA,YAAA;AADR","sourcesContent":[".spinner {\n    position: relative;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    flex-shrink: 0;\n\n    & > svg {\n        width: 60px;\n        height: 60px;\n        fill: transparent;\n        stroke: var(--theme-spinner-stroke);\n        fill: var(--theme-spinner-fill);\n        -webkit-animation: spinner 2.5s ease-in-out infinite;\n        -moz-animation: spinner 2.5s ease-in-out infinite;\n        animation: spinner 2.5s ease-in-out infinite;\n\n        @keyframes spinner {\n            0% {\n                transform: rotate(0deg);\n                opacity: 0.3;\n                width: 60%;\n            }\n            50% {\n                opacity: 0.8;\n                width: 100%;\n            }\n            100% {\n                transform: rotate(720deg);\n                opacity: 0.3;\n                width: 60%;\n            }\n        }\n    }\n\n    &.spinner-small > svg {\n        width: 20px;\n        height: 20px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
