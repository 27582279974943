import { Ref, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { useRequest } from "../../context";
import { DownIcon, EditIcon, FilterIcon, MenuIcon, TrashIcon, UpIcon } from "../../icons";
import { PaginatedResults, Pagination } from "../../models/pagination";
import { Card, Nothing } from "../../ui";
import Button, { ButtonProps } from "../../ui/Button";
import DropdownMenu from "../../ui/DropdownMenu";
import { filtersFromSearchParams, getStringValue, paginationFromSearchParams, translateLabel } from "../../utils/format";
import { conditionnalClassnames } from "../../utils/helpers";
import { getNestedField, getPrimaryKeysAsString } from "../../utils/objects";
import FiltersPanel, { ListFilterSetting } from "../FiltersPanel";
import ModalDelete from "../ModalDelete";
import PaginationComponent from "../Pagination";
import SearchField from "../SearchField";
import "./index.scss";

export interface DataTableListRefProps {
    refresh: () => void;
}

export interface DataTableListContentProps<T> {
    entity: T;
};

export type DataTableListAction<T> = (e: T) => Promise<void | boolean> | void | boolean;

export type DataTableListColumn<T> = {
    field: string;
    label?: string;
    i18n?: string;
    display?: ((e: T) => JSX.Element | string | undefined);
    sort?: boolean;
    width?: string;
}

export interface DataTableListProps<T> {
    columns: DataTableListColumn<T>[];
    primaryKey: keyof T | (keyof T)[];
    filters?: ListFilterSetting[];
    data?: T[];
    endpoint: string;
    onEdit?: DataTableListAction<T>;
    onDelete?: DataTableListAction<T>;
    globalActions?: ButtonProps[];
    withSearch?: boolean;
    initialPagination?: Partial<Pagination>;
    additionalFilters?: { [k: string]: any };
    onClick?: (e: T) => void;
    refHandle?: Ref<DataTableListRefProps> | null;
}

const DataTableList = <T,>({
    columns,
    filters,
    primaryKey,
    data: dataFromProps,
    endpoint,
    withSearch,
    onEdit,
    onDelete,
    globalActions,
    initialPagination,
    additionalFilters,
    onClick,
    refHandle
}: DataTableListProps<T>) => {
    const { t } = useTranslation();
    const request = useRequest();
    const [isFilterPanelVisible, setFilterPanelVisible] = useState<boolean>(false);
    const [entityToDelete, setEntityToDelete] = useState<T | null>(null);
    const [data, setData] = useState<T[]>([]);
    const [pagination, setPagination] = useState<Partial<Pagination> | null>(null);
    const tableRef = useRef<HTMLDivElement | null>(null);
    const [searchParams, setSearchParams] = useSearchParams();

    const get = useCallback(async (searchParams: URLSearchParams, force?: boolean) => {
        if (request.loading && !force) return;

        const params = {
            ...initialPagination,
            ...filtersFromSearchParams(searchParams, filters),
            ...paginationFromSearchParams(searchParams),
            search: searchParams.get('search'),
            ...additionalFilters
        }

        request.get<PaginatedResults<T>>(endpoint, { params, errorMessage: true, loader: true })
            .then((result) => {
                if (!Array.isArray(result?.data)) {
                    throw new Error();
                }
                setData(result.data);
                setPagination(result.pagination);
                if (tableRef.current) {
                    tableRef.current.scrollTo(0, 0);
                }
            })
            .catch(() => null);
    }, [endpoint, initialPagination, filters, request, dataFromProps, additionalFilters]);

    useImperativeHandle(refHandle, () => ({ refresh: () => get(searchParams, true) }), [get, searchParams]);

    const onSearch = useCallback((search: string | undefined) => {
        if (!!search) {
            searchParams.set('search', search);
        } else {
            searchParams.delete('search');
        }
        setSearchParams(searchParams, { replace: true });
    }, [searchParams]);

    const onPageChange = useCallback((page: number) => {
        searchParams.set('page', String(page));
        setSearchParams(searchParams, { replace: true });
    }, [searchParams]);

    const onTakeChange = useCallback((take: number) => {
        searchParams.set('take', String(take));
        searchParams.delete('page');
        setSearchParams(searchParams, { replace: true });
    }, [searchParams]);

    const onSort = useCallback((field: string, direction: -1 | 1) => {
        const sort = { ...pagination?.sort };

        if (sort[field] === direction) {
            delete sort[field];
        } else {
            sort[field] = direction;
        }

        searchParams.set('sort', JSON.stringify(sort));
        setSearchParams(searchParams, { replace: true });
    }, [searchParams, pagination]);

    const handleDelete = useCallback(async (e: T) => {
        if (!onDelete) return;

        const result = await onDelete(e);

        if (result === true) get(searchParams);
        setEntityToDelete(null);
    }, [onDelete, get, searchParams]);

    const columnHeaders = useMemo(() => columns.map((column) => (
        <th key={column.field} style={column.width ? { width: column.width } : { maxWidth: '0px' }}>
            <div>
                <span title={column.label ? translateLabel(column.label, t, column.i18n) : ''}>{column.label ? translateLabel(column.label, t, column.i18n) : ''}</span>
                {column.sort && (
                    <div>
                        <UpIcon onClick={() => onSort(column.field, -1)} className={pagination?.sort?.[column.field] === -1 ? 'active' : ''} />
                        <DownIcon onClick={() => onSort(column.field, 1)} className={pagination?.sort?.[column.field] === 1 ? 'active' : ''} />
                    </div>
                )}
            </div>
        </th>
    )), [columns, onSort, pagination]);

    const rows = useMemo(() => data?.map(entity => (
        <tr
            className={conditionnalClassnames({ pointer: !!onClick })}
            key={getPrimaryKeysAsString(entity, primaryKey)}
            onClick={onClick ? () => onClick(entity) : undefined}
        >
            {columns.map(column => {
                const value = column.display ? column.display(entity) : getStringValue(getNestedField(entity, column.field));
                return (
                    <td key={column.field} style={{ width: column.width }}>
                        <div title={typeof value === 'string' ? value : ''}>{value}</div>
                    </td>
                );
            })}
            {(onEdit || onDelete) && (
                <td className="data-table-list-table-action">
                    <DropdownMenu disposition="left" items={[
                        ...(onEdit ? [{ itemKey: 'edit', icon: <EditIcon />, i18n: "actions", label: "edit", onClick: () => onEdit(entity) }] : []),
                        ...(onDelete ? [{ itemKey: 'delete', icon: <TrashIcon />, i18n: "actions", label: "delete", onClick: () => setEntityToDelete(entity) }] : []),
                    ]}>
                        <Button color="navigation" icon={<MenuIcon />} />
                    </DropdownMenu>
                </td>
            )}
        </tr>
    ))
        , [data, primaryKey, columns, onClick, setEntityToDelete, onEdit, onDelete]);

    const globalActionsComponents = useMemo(() =>
        !!globalActions?.length && globalActions.map((a) => (
            <Button key={a.label} {...a} />
        ))
        , [globalActions]);

    useEffect(() => {
        get(searchParams);
    }, [searchParams, endpoint, dataFromProps]);

    return (
        <div className="layout-container row row-layout data-table-list">
            <div className="col col-layout">
                {(!!globalActions?.length || withSearch || !filters?.length) && (
                    <Card className="data-table-list-header">
                        <div className="data-table-list-header-left">
                            {!!withSearch && <SearchField onChange={onSearch} />}
                        </div>
                        <div className="data-table-list-header-right">
                            {!!filters?.length && <Button
                                color="navigation"
                                icon={<FilterIcon />}
                                i18n="filters"
                                label="filters"
                                iconPosition="left"
                                onClick={() => setFilterPanelVisible(!isFilterPanelVisible)}
                            />}
                            {globalActionsComponents}
                        </div>
                    </Card>
                )}
                <Card className="data-table-list-container" overflow="hidden">
                    <div className="data-table-list-table" ref={tableRef}>
                        <table>
                            <thead>
                                <tr>
                                    {columnHeaders}
                                    {(onEdit || onDelete) && <th className="data-table-list-table-action" />}
                                </tr>
                            </thead>
                            <tbody>
                                {rows}
                            </tbody>
                        </table>
                        {!!data && !data.length && <Nothing />}
                    </div>
                    <PaginationComponent pagination={pagination ?? undefined} onPageChange={onPageChange} />
                </Card >
            </div>
            {!!filters?.length && isFilterPanelVisible && <FiltersPanel filtersSettings={filters} />}
            {!!entityToDelete && (
                <ModalDelete withDebounce onClose={() => setEntityToDelete(null)} onSubmit={() => handleDelete(entityToDelete)} />
            )}
        </div>
    )
}

export default DataTableList;