// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#plan-analysis .step-pill {
  content: "";
  width: 12px;
  height: 12px;
  border-radius: 50%;
  flex-shrink: 0;
  flex-grow: 0;
}
#plan-analysis #plan-analysis-qualifiers-emissions > .card-content {
  padding: 12px 24px 24px 24px;
}`, "",{"version":3,"sources":["webpack://./src/pages/PlanAnalysis/index.scss"],"names":[],"mappings":"AACI;EACI,WAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;EACA,cAAA;EACA,YAAA;AAAR;AAGI;EACI,4BAAA;AADR","sourcesContent":["#plan-analysis {\n    & .step-pill {\n        content: \"\";\n        width: 12px;\n        height: 12px;\n        border-radius: 50%;\n        flex-shrink: 0;\n        flex-grow: 0;\n    }\n\n    & #plan-analysis-qualifiers-emissions > .card-content {\n        padding: 12px 24px 24px 24px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
