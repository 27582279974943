import { useEffect } from "react";
import { MenuContextItem, MenuContextMenu, useMenuContext } from "../../context/MenuContext";
import { Outlet } from "react-router-dom";
import { useWorkspaceContext } from "../../context";

interface MenuContextOutletProps {
    menus: MenuContextMenu[];
    items: MenuContextItem[];
}

const MenuContextOutlet = ({ menus, items }: MenuContextOutletProps) => {
    const { setMenus, setItems, clear } = useMenuContext();
    const { currentWorkspace } = useWorkspaceContext();

    useEffect(() => {
        setMenus(menus);
        setItems(items);

        return () => {
            clear();
        }
    }, [currentWorkspace._id]);

    return <Outlet />;
}
export default MenuContextOutlet;