import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { useRequest } from "../../../context";
import { Form, TextField } from "../../../form";
import Validation, { ObjectValidation } from "../../../form/Validation/class";
import { useForm } from "../../../hooks";
import { LoginCredentials } from "../../../models/auth";
import { Button } from "../../../ui";
import { Colors } from "../../../ui/enums";
import PortalPromoWrapper from "./PortalPromoWrapper";

const VALIDATION = new ObjectValidation({
    email: Validation.string().required().isEmail(),
})

const Recovery = () => {
    const { t } = useTranslation();
    const { validate, attachInput } = useForm<LoginCredentials>({}, VALIDATION);
    const request = useRequest();
    const navigate = useNavigate();

    const handleRecovery = useCallback(() => validate((entity) => {
        if (request.loading) return;

        request.post('/auth/recovery', entity, {
            loader: true,
        })
            .then(() => navigate('/auth?message=success.recovery'))
            .catch(() => null);
    }), [request, validate]);

    return (
        <PortalPromoWrapper>
            <Form onSubmit={handleRecovery}>
                <span>{t('auth:recover_tooltip')}</span>
                <TextField
                    {...attachInput('email')}
                    i18n="users"
                    label
                    autoComplete
                />
                <div className="col col-double-gap col-vertical-padding">
                    <Button color={Colors.ACCENT} i18n="auth" label="recover" type="submit" loading={request.loading} />
                    <Link to='/' className="center">{t('auth:back_to_login')}</Link>
                </div>
            </Form>
        </PortalPromoWrapper>
    )
}

export default Recovery;