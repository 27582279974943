import { MouseEvent, ReactNode, createContext, useCallback, useContext, useEffect, useState } from 'react';
import { Button } from '..';
import { useOutsideClick } from '../../hooks';
import { CloseIcon, DownIcon, UpIcon } from '../../icons';
import { conditionnalClassnames } from '../../utils/helpers';
import { Colors } from '../enums';
import './index.scss';


interface DropdownProps {
    children: ReactNode;
    disabled?: boolean;
    className?: string;
    openOn?: 'over' | 'click'
}

interface DropdownContextReturn {
    visible: boolean;
    setVisible: (v: boolean) => void;
    toggle: () => void;
};

export const DropDownContext = createContext<DropdownContextReturn>({
    visible: false,
    setVisible: () => null,
    toggle: () => null,
});

const Dropdown = ({
    children,
    disabled,
    className,
    openOn
}: DropdownProps) => {
    const [visible, setVisible] = useState<boolean>(false);

    return (
        <DropDownContext.Provider value={{ visible, setVisible, toggle: () => setVisible(!visible) }}>
            <div
                className={conditionnalClassnames({
                    dropdown: true,
                    className,
                    "dropdown-visible": visible
                })}
                onMouseEnter={openOn === 'over' && !disabled ? () => setVisible(true) : undefined}
                onMouseLeave={openOn === 'over' && !disabled ? () => setVisible(false) : undefined}
            >
                {children}
            </div>
        </DropDownContext.Provider >
    )
}

const useDropdown = () => {
    return useContext(DropDownContext);
};

interface DropdownHeaderProps {
    children: ReactNode;
    withIcon?: boolean;
}

const Header = ({ children, withIcon }: DropdownHeaderProps) => {
    const { visible, setVisible, toggle } = useDropdown();

    return (
        <div
            className={`dropdown-header ${visible ? 'focus' : ''}`}
            onClick={toggle}
        >
            {children}
            {visible && withIcon && <UpIcon className="dropdown-icon" />}
            {!visible && withIcon && <DownIcon className="dropdown-icon" />}
        </div>
    );
}

interface DropdownContentProps {
    children: ReactNode;
    closeOnClick?: boolean;
    disposition?: 'right' | 'left';
    maxHeight?: string;
}

const Content = ({ children, closeOnClick, disposition, maxHeight }: DropdownContentProps) => {
    const { visible, setVisible, toggle } = useDropdown();
    const ref = useOutsideClick(useCallback(() => setVisible(false), [visible]));

    const handleClick = useCallback((e: MouseEvent) => {
        e.stopPropagation();

        if (closeOnClick) {
            setVisible(false);
        }
    }, [closeOnClick]);

    useEffect(() => {
        if (!disposition && ref.current) {
            const bound = ref.current.getBoundingClientRect();

            if (bound.top + bound.height > window.innerHeight) {
                ref.current.style.bottom = '0';
                ref.current.style.top = 'auto';
            } else {
                ref.current.style.top = '100%';
                ref.current.style.bottom = 'auto';
            }
            if (bound.left + bound.width > window.innerWidth) {
                ref.current.style.right = '0';
                ref.current.style.left = 'auto';
            } else {
                ref.current.style.left = '0';
                ref.current.style.right = 'auto';
            }
        }
    }, [visible, ref, disposition]);
    /* 
        "dropdown-left": disposition === 'left',
        "dropdown-right": disposition === 'right', */
    return (
        <div
            className="dropdown-content"
            style={maxHeight ? { maxHeight } : undefined}
            ref={ref}
            onClick={handleClick}
        >
            {children}
        </div>
    );
}

Dropdown.Header = Header;
Dropdown.Content = Content;

export { useDropdown };
export default Dropdown;