// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dropdown .dropdown-header {
  display: flex;
  align-items: center;
}
.dropdown .dropdown-header > .dropdown-icon {
  justify-self: flex-end;
  width: 12px;
  height: 12px;
  fill: var(--theme-color-grey-medium);
}`, "",{"version":3,"sources":["webpack://./src/sg-react/ui/Dropdown/index.scss"],"names":[],"mappings":"AACI;EACI,aAAA;EACA,mBAAA;AAAR;AAEQ;EACI,sBAAA;EACA,WAAA;EACA,YAAA;EACA,oCAAA;AAAZ","sourcesContent":[".dropdown {\n    .dropdown-header {\n        display: flex;\n        align-items: center;\n\n        & > .dropdown-icon {\n            justify-self: flex-end;\n            width: 12px;\n            height: 12px;\n            fill: var(--theme-color-grey-medium);\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
