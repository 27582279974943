import { useTranslation } from 'react-i18next';
import { FormPropsInterface } from '../InputWrapper';
import './index.scss';
import { ChangeEvent, MouseEvent, useCallback } from 'react';

const Checkbox = ({
    id,
    value,
    i18n,
    label,
    className,
    disabled,
    onChange,
}: FormPropsInterface<boolean>) => {
    const { t } = useTranslation();

    const handleChange = useCallback((e: MouseEvent | ChangeEvent) => {
        if (disabled || !onChange) return;

        e.stopPropagation();
        onChange(!value);
    }, [onChange, disabled, value]);

    return (
        <div
            className={`checkbox ${value ? 'checked' : ''} ${disabled ? 'disabled' : ''} ${className ?? ''}`}
            onClick={handleChange}
        >
            <input
                type="checkbox"
                checked={!!value}
                onChange={handleChange}
                id={id}
                name={id}
            />
            <div className="mark" />
            {!!label && <label className={disabled ? 'disabled' : ''}>{i18n ? t(`${i18n}:${label === true ? id : label}`) : label}</label>}
        </div>
    );
}

export default Checkbox;