import { BulletIcon, CloseIcon, DownloadIcon, EditIcon, KeyIcon, NotificationIcon, PlusIcon, SettingIcon, SquareFourIcon, UserBriefcaseIcon, UsersIcon } from "../../../sg-react/icons";
import { LayoutMenuItem, LayoutMenuMenu } from "../../../sg-react/ui/LayoutMenu";
import MenuContextOutlet from "../../../sg-react/ui/MenuContextOutlet";
import { Action } from "../../../sg-react/utils/enums";

const ITEMS: LayoutMenuItem[] = [
    { path: '/directory', label: 'list', i18n: 'data', icon: <BulletIcon /> },
];

const MENUS: LayoutMenuMenu[] = [{
    path: '/directory',
    label: 'menu',
    i18n: 'ui',
    items: [
        { itemKey: Action.Create, icon: <PlusIcon />, i18n: "actions", label: "create" },
        { itemKey: Action.Import, icon: <DownloadIcon />, i18n: "actions", label: "import_from_file" },
    ]
}, {
    path: '/directory/view',
    pathStartsWith: true,
    label: 'file',
    i18n: 'ui',
    items: [
        { itemKey: Action.Edit, icon: <EditIcon />, i18n: "actions", label: "edit" },
        {
            itemKey: Action.Settings,
            icon: <SettingIcon />,
            i18n: "ui",
            label: "settings",
            items: [
                { itemKey: Action.Widgets, icon: <SquareFourIcon />, i18n: "workspaces", label: "widgets" },
                { itemKey: Action.Users, icon: <UsersIcon />, i18n: "workspaces", label: "reference_users" },
                { itemKey: Action.Privacy, icon: <KeyIcon />, i18n: "workspaces", label: "visibility" },
                { itemKey: Action.Notifications, icon: <NotificationIcon />, i18n: "workspaces", label: "notifications" },
                { itemKey: Action.Guests, icon: <UserBriefcaseIcon />, i18n: "workspaces", label: "guests" },
            ]
        },
        { itemKey: Action.Export, icon: <DownloadIcon />, i18n: "actions", label: "export" },
        { itemKey: Action.Close, icon: <CloseIcon />, i18n: "actions", label: "close" },
    ]
}];


const DirectoryListLayoutWrapper = () => <MenuContextOutlet items={ITEMS} menus={MENUS} />;

export default DirectoryListLayoutWrapper;