import { ReactNode } from 'react';
import './index.scss';
import { UserTieIcon } from '../../icons';

interface BubbleHeadProps {
    image?: string;
    className?: string;
    alt?: string;
    size?: 'xs' | 'small' | 'medium' | 'large' | 'xl';
    onClick?: () => void;
    icon?: ReactNode;
}

const BubbleHead = ({ image, alt, size = 'large', className, onClick, icon }: BubbleHeadProps) => {
    return (
        <div className={`bubble-head bubble-head-${size} ${className ?? ''}`} onClick={onClick}>
            {image ? <img src={image} alt={alt ?? ''} /> : <UserTieIcon />}
            {!!icon && <div className="bubble-head-icon">{icon}</div>}
        </div>
    )
}

export default BubbleHead;