import { ReactNode, useState } from "react";
import { Trans } from "react-i18next";
import { useUiContext } from "../../../context";
import { EmailIcon, PlusIcon } from "../../../icons";
import { Button } from "../../../ui";
import ContactModal from "../../../ui/ContactModal";
import DepthContainer from "../../../ui/DepthContainer";
import { Colors } from "../../../ui/enums";

interface PortalPromoWrapperProps {
    children: ReactNode;
}

const PortalPromoWrapper = ({ children }: PortalPromoWrapperProps) => {
    const [isModalVisible, setModalVisibile] = useState<boolean>(false);
    const { isDarkMode } = useUiContext();

    return (
        <div className="row row-layout">
            <div id="portal-form" className="col">
                <img src="/images/logo/logo_white_legend.png" alt="" id="portal-logo" />
                {children}
            </div>
            <DepthContainer id="portal-promo">
                <img src={`/images/promo_${isDarkMode ? 'dark' : 'light'}.png`} alt="" />
                <div id="portal-promo-message">
                    <span><Trans i18nKey="ui:all_your_suppliers" /></span>
                    <div className="row">
                        <Button icon={<PlusIcon />} i18n="ui" label="more_info" color={Colors.PRIMARY} onClick={() => window.open('http://www.supply-graph.io', '_blank')?.focus()} />
                        <Button icon={<EmailIcon />} i18n="ui" label="contact_us" color={Colors.SECONDARY} onClick={() => setModalVisibile(true)} />
                    </div>
                </div>
            </DepthContainer>
            {isModalVisible && <ContactModal onClose={() => setModalVisibile(false)} />}
        </div>
    )
}
export default PortalPromoWrapper;