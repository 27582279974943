import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { SuccessIcon } from "../../icons";
import { translateLabel } from "../../utils/format";
import Modal from "../Modal";
import { Colors } from "../enums";

interface ModalConfirmationProps {
    i18n?: string;
    label: string;
    onClose: () => void;
    onSubmit: () => void;
}

const ModalConfirmation = ({ i18n, label, onClose, onSubmit }: ModalConfirmationProps) => {
    const { t } = useTranslation();

    const handleSubmit = useCallback(() => {
        onSubmit();
        onClose();
    }, [onSubmit, onClose]);

    return (
        <Modal
            id="modal-confirm"
            size="small"
            title="confirmation"
            i18n="ui"
            actions={[
                { i18n: 'actions', label: 'cancel', onClick: onClose, color: Colors.SECONDARY },
                { i18n: 'actions', label: 'confirm', onClick: handleSubmit, color: Colors.SUCCESS, icon: <SuccessIcon /> },
            ]}
        >
            <span style={{ whiteSpace: 'pre-line' }}>{translateLabel(label, t, i18n)}</span>
        </Modal >
    );
}

export default ModalConfirmation;