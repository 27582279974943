import { Fragment, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { DirectoryImportProps } from "..";
import { DirectoryItemsListItem } from "../../../components/DirectoryItemsList";
import { Checkbox } from "../../../sg-react/form";
import { useForm } from "../../../sg-react/hooks";
import { SuccessIcon, WarningIcon } from "../../../sg-react/icons";
import { Card } from "../../../sg-react/ui";
import Info from "../../../sg-react/ui/Info";
import ListItem from "../../../sg-react/ui/ListItem";
import { joinOptionnalStrings } from "../../../sg-react/utils/format";
import { addOrReplaceInArray } from "../../../sg-react/utils/objects";

const DirectoryImportDuplication = ({ directoryImport, onChange }: DirectoryImportProps) => {
    const { entity, attachInput, reset, setErrors, validate } = useForm<Record<string, any> & { _id: string }>({});
    const { t } = useTranslation();

    const handleSelectDuplicate = useCallback((_id: string, behaviour: { duplicateId?: string, create?: boolean, skip?: boolean }) => {
        const duplicatesValidation = addOrReplaceInArray(directoryImport.duplicatesValidation, { _id, ...behaviour }, (d1, d2) => d1._id === d2._id);

        onChange({ ...directoryImport, duplicatesValidation });
        reset(entity, false);
    }, [validate, directoryImport, reset, onChange]);

    const setEntityToEdit = useCallback((_id: string) => {
        const entityToEdit = directoryImport.dataValidated?.find(d => d._id === _id);

        if (entityToEdit) {
            reset(entityToEdit, false);
        }
    }, [reset, directoryImport.dataValidated]);

    useEffect(() => {
        if (directoryImport.dataValidated?.length) {
            setEntityToEdit(directoryImport.dataValidated[0]._id);
        }
    }, []);

    return (
        <Fragment>
            <Card id="directory-import-data-validation-data" className="col col-lg-25" overflow="hidden">
                <Info noIcon label="import.duplicates_check_tooltip" i18n="data" />
                <div className="list">
                    {directoryImport.dataValidated?.map((d) => (
                        <ListItem
                            key={d._id}
                            value={d.name}
                            description={joinOptionnalStrings([d.location?.city, d.location?.country])}
                            active={entity._id === d._id}
                            onClick={() => setEntityToEdit(d._id)}
                        >
                            {!directoryImport.duplicates?.[d._id]?.length || directoryImport.duplicatesValidation?.find(dv => dv._id === d._id) ? <SuccessIcon className="icon icon-success" /> : <WarningIcon className="icon icon-warning" />}
                        </ListItem>
                    ))}
                </div>
            </Card>
            {!!entity._id && (
                <Card
                    className="col col-lg-75"
                    title={entity.name ?? ''}
                    overflow="hidden"
                >
                    <Info type={directoryImport.duplicates?.[entity._id]?.length ? 'warning' : 'success'} i18n="data" label={directoryImport.duplicates?.[entity._id]?.length ? 'import.duplicates_found' : 'import.no_duplicate_found'} />
                    {!!directoryImport.duplicates?.[entity._id]?.length && (
                        <Fragment>
                            <Checkbox
                                id={entity._id + '_skip'}
                                i18n="data"
                                label="import.duplicate_create"
                                value={directoryImport.duplicatesValidation?.find(dv => dv._id === entity._id)?.create}
                                onChange={() => handleSelectDuplicate(entity._id!, { create: true })}
                            />
                            <Checkbox
                                id={entity._id + '_create'}
                                i18n="data"
                                label="import.duplicate_skip"
                                value={directoryImport.duplicatesValidation?.find(dv => dv._id === entity._id)?.skip}
                                onChange={() => handleSelectDuplicate(entity._id!, { skip: true })}
                            />
                            <span>{t('data:import.duplicate_select')}</span>
                            <div className="list">
                                {directoryImport.duplicates?.[entity._id].map(d => (
                                    <div key={d._id} className="row pointer" onClick={() => handleSelectDuplicate(entity._id!, { duplicateId: d._id })}>
                                        <Checkbox id={d._id} value={directoryImport.duplicatesValidation?.find(dv => dv._id === entity._id)?.duplicateId === d._id} />
                                        <DirectoryItemsListItem entity={d} />
                                    </div>
                                ))}
                            </div>
                        </Fragment>
                    )}
                </Card>
            )}
        </Fragment>
    )
}
export default DirectoryImportDuplication;