// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button-big {
  font-size: 14px;
  cursor: pointer;
}
.button-big > svg {
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  fill: var(--theme-color-grey-medium);
}
.button-big:hover {
  background-color: var(--theme-soft-container-hover-background-color);
  box-shadow: var(--theme-soft-container-hover-box-shadow);
  border: var(--theme-soft-container-hover-border-color) 1px solid;
}`, "",{"version":3,"sources":["webpack://./src/sg-react/ui/ButtonBig/index.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,eAAA;AACJ;AACI;EACI,cAAA;EACA,WAAA;EACA,YAAA;EACA,oCAAA;AACR;AAEI;EACI,oEAAA;EACA,wDAAA;EACA,gEAAA;AAAR","sourcesContent":[".button-big {\n    font-size: 14px;\n    cursor: pointer;\n\n    & > svg {\n        flex-shrink: 0;\n        width: 24px;\n        height: 24px;\n        fill: var(--theme-color-grey-medium);\n    }\n\n    &:hover {\n        background-color: var(--theme-soft-container-hover-background-color);\n        box-shadow: var(--theme-soft-container-hover-box-shadow);\n        border: var(--theme-soft-container-hover-border-color) 1px solid;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
