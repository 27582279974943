import { ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import './index.scss';

export interface PieChartCssProps {
    values: { count: number, color?: string }[];
    withTotal?: boolean;
    withCenter?: boolean;
    size?: number;
    children?: ReactNode;
}

const PieChartCss = ({ values, size, withTotal, children, withCenter }: PieChartCssProps) => {
    const [background, setBackground] = useState<string>('');

    const total = useMemo(() => values.reduce((t, v) => t + v.count, 0), [values])

    const getBackground = useCallback((values: { count: number, color?: string }[]): string => {
        if (values.length === 0) return 'transparent';

        const slices: { count: number, color?: string }[] = [];

        for (const v of values) {
            if (!v.count) continue;
            const index = slices.findIndex(s => s.color === v.color);
            if (index >= 0) {
                slices[index].count += v.count;
            } else {
                slices.push(v);
            }
        }

        if (slices.length === 1) return slices[0].color ?? 'transparent';

        const total = slices.reduce((t, v) => t + v.count, 0);

        const { gradient } = slices
            .reduce((acc, v, i) => {
                const angle = Math.round(v.count * 360 / total);
                const endAngle = angle + acc.deg;
                const grad = i === 0
                    ? `${v.color} ${angle}deg, `
                    : i === slices.length - 1
                        ? `${v.color} ${acc.deg}deg`
                        : `${v.color} ${acc.deg}deg ${endAngle}deg, `

                return { gradient: acc.gradient + grad, deg: acc.deg + angle };
            }, { gradient: '', deg: 0 });

        return `conic-gradient(${gradient})`;
    }, []);

    useEffect(() => {
        setBackground(getBackground(values));
    }, [values]);

    return (
        <div
            className="pie-chart-css"
            style={size !== undefined ? { background, width: size + 'px', height: size + 'px' } : { background }}
        >
            {!!withCenter && <div className="pie-chart-css-center" />}
            {withTotal && <div className="pie-chart-css-total">{total}</div>}
            {!withTotal && children}
        </div>
    )
}

export default PieChartCss;