import { Fragment, ReactNode, useMemo } from "react";

interface SpannedTextProps {
    text?: string;
    boldedWords: string;
}

const SpannedText = ({ text, boldedWords }: SpannedTextProps) => {

    const spanned = useMemo(() => {
        const re = new RegExp(boldedWords.replace('|', ' ').split(' ').join("|"), 'gi');
        if (!text) return '';

        const matches = text.match(re);

        if (!matches?.length) return text;

        let spannedElements: ReactNode[] = [];
        const splittedString = text.split(re);

        splittedString.forEach((s, i) => {
            spannedElements.push(<Fragment key={'text' + i}>{s}</Fragment>);
            if (matches.length >= i + 1) {
                spannedElements.push(<span key={'match' + i}>{matches[i]}</span>);
            }
        })

        return <Fragment>{spannedElements}</Fragment>;
    }, [text, boldedWords]);

    return <Fragment>{spanned}</Fragment>;
}

export default SpannedText;