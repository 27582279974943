import { useWorkspaceContext } from "../../context";
import { User } from "../../models/user";
import EntitySearch from "../EntitySearch";

interface UserSearchProps {
    onClose: () => void;
    onSubmit: (u: User[]) => void;
    multiple?: boolean;
}

const UserSearch = ({ onClose, onSubmit }: UserSearchProps) => {
    const { currentWorkspace } = useWorkspaceContext();

    return (
        <EntitySearch
            endpoint={`/workspaces/${currentWorkspace._id}/users/search`}
            onClose={onClose}
            onSubmit={onSubmit}
            primaryKey="_id"
            columns={[
                { field: 'firstName', label: 'firstName', i18n: 'users' },
                { field: 'lastName', label: 'lastName', i18n: 'users' },
                { field: 'position', label: 'position', i18n: 'users' },
                { field: 'company', label: 'company', i18n: 'users' },
            ]}
            sort={{ field: "firstName", direction: 1 }}
        />
    )
}

export default UserSearch;