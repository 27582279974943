import { FeatureCollection } from "geojson";
import { CircleLayer, LngLatBounds, MapMouseEvent } from 'mapbox-gl';
import { ReactElement, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FillLayer, Layer, Marker, Popup, Source } from "react-map-gl";
import useSuperCluster from "use-supercluster";
import DepencyIcon from '../../components/DependencyIcon';
import DirectoryItemMapCard from "../../components/DirectoryItemMapCard";
import DirectoryItemMarker from "../../components/DirectoryItemMarker";
import DirectoryItemsList from '../../components/DirectoryItemsList';
import { Dependency } from "../../models/dependency";
import { DirectoryItemForMap } from '../../models/directory-item';
import { MapAction } from "../../models/enums";
import { useMenuContext, useRequest } from "../../sg-react/context";
import { Map } from '../../sg-react/data';
import { MapRefProps } from "../../sg-react/data/Map";
import UserDataModal from "../../sg-react/data/UserDataModal";
import { LocatedEntity } from '../../sg-react/models/geo';
import { UserData } from "../../sg-react/models/user-data";
import LabelValue from "../../sg-react/ui/LabelValue";
import { joinOptionnalStrings } from "../../sg-react/utils/format";
import { conditionnalClassnames } from '../../sg-react/utils/helpers';
import MainMapFilters from "./components/MainMapFilters";
import MainMapLayers from "./components/MainMapLayers";
import MainMapResults from './components/MainMapResults';
import MainMapSteps from "./components/MainMapSteps";
import { MAP_SCHEMA_VERSION, useMapContext } from "./components/MapContext";
import './index.scss';
import { MainMapLayer } from "./types";
import { checkUserDataSchemaForMap } from "./utils";

//minPoints: 10000,
const clusterOptions = {
    radius: 50,
    maxZoom: 15,
    map: (props: any) => ({
        directoryItems: [props.directoryItem]
    }),
    reduce: (acc: any, props: any) => {
        acc.directoryItems = [...acc.directoryItems, ...props.directoryItems];
        return acc;
    }
};

const MainMap = () => {
    const {
        filters,
        setFilters,
        plan,
        setPlan,
        results,
        planResults,
        currentStepId,
        panels,
        togglePanel,
        layer,
    } = useMapContext();
    const { action, setActionDone } = useMenuContext();
    const [selectedDirectoryItem, setSelectedDirectoryItem] = useState<DirectoryItemForMap | null>(null);
    const [userDataModal, setUserDataModal] = useState<{ type: 'map-query' | 'map-plan', mode: 'open' | 'save', userData?: Partial<UserData> } | null>(null);
    const [popup, setPopup] = useState<{ lng: number, lat: number, directoryItems?: DirectoryItemForMap[], dependency?: Dependency } | null>(null);
    const [bounds, setBounds] = useState<LngLatBounds | null>(null);
    const [dataLayer, setDataLayer] = useState<{ data: FeatureCollection, style: FillLayer } | null>(null);
    const request = useRequest();
    const mapRef = useRef<MapRefProps>(null);

    const { clusters } = useSuperCluster({
        points: results?.data.items.filter(directoryItem => !!directoryItem.location).map(directoryItem => ({
            type: "Feature",
            properties: { cluster: false, point_count: 1, directoryItem },
            geometry: { "type": "Point", "coordinates": [directoryItem.location!.longitude, directoryItem.location!.latitude] }
        })) ?? [],
        bounds: bounds ? [
            bounds.getSouthWest().lng,
            bounds.getSouthWest().lat,
            bounds.getNorthEast().lng,
            bounds.getNorthEast().lat
        ] : undefined,
        zoom: mapRef.current?.getZoom() ?? 12,
        options: clusterOptions,
    });

    const getLayer = useCallback((layer: MainMapLayer) => {
        let layerName = layer.category === 'emissions'
            ? layer.name
            : `wri_${!layer.parameters?.projection && !layer.parameters?.scenario ? '' : joinOptionnalStrings([layer.parameters?.projection, layer.parameters?.scenario], '_') + '_'}${layer.name}_${layer.parameters?.weight ?? 'one'}`

        request.get<{ features: any[], min: number, max: number }>('/geo/layer/' + layerName, { loader: true })
            .then((data) => {
                setDataLayer({
                    data: {
                        type: 'FeatureCollection' as 'FeatureCollection',
                        features: data.features
                    },
                    style: {
                        id: 'data',
                        type: 'fill',
                        paint: {
                            'fill-color': [
                                "rgb",
                                255,
                                ["-", 255, ["*", 255 / (data.max - data.min), ["-", ["get", "data"], data.min]]],
                                0
                            ],
                            'fill-opacity': 0.3
                        },
                    }
                })
            })
            .catch(() => setDataLayer(null));

    }, [request]);

    const handleMouseMove = useCallback((e: MapMouseEvent) => {
        if ((e as any).features?.length) {
            const directoryItems: DirectoryItemForMap[] = (e as any).features?.map((f: any) => {
                try {
                    const directoryItem = JSON.parse(f.properties.directoryItem ?? '{}');
                    return directoryItem;
                } catch {
                    return null;
                }
            }).filter((b: any) => !!b);

            if (directoryItems.length) {
                setPopup((popup) => {
                    if (directoryItems.some(d1 => !popup?.directoryItems?.some(d2 => d2._id === d1._id))) {
                        return ({
                            lng: directoryItems[0].location?.longitude!,
                            lat: directoryItems[0].location?.latitude!,
                            directoryItems
                        })
                    }
                    return popup;
                });
            }
        }
    }, []);

    const planLayers = useMemo(() => !!planResults?.length && planResults?.map((s, i) => {
        const geojson: FeatureCollection = {
            type: 'FeatureCollection' as 'FeatureCollection',
            features: s.data.items.filter(d => d.location?.latitude && d.location?.longitude).map(d => ({
                type: 'Feature',
                geometry: { type: 'Point', coordinates: [d.location?.longitude!, d.location?.latitude!] },
                properties: {
                    directoryItem: d,
                    stepName: s.name,
                    stepColor: s.color
                },
            }))
        };

        const layerStyle: CircleLayer = {
            id: 'point',
            type: 'circle',
            paint: {
                'circle-radius': 10,
                'circle-color': s.color ?? '#ffffff',
                'circle-opacity': 0.7,
                'circle-stroke-color': '#303030',
                'circle-stroke-opacity': 1,
                'circle-stroke-width': 1,
            }
        };

        return (
            <Source key={s.name} id={s.name} type="geojson" data={geojson}>
                <Layer {...layerStyle} id={s.name} />
            </Source>
        );
    }), [planResults]);

    const markers: ReactElement[] = useMemo<ReactElement[]>(() => {
        if (!clusters.length) return [];

        const maxCluster = Math.max(...clusters.map(c => c.properties.point_count));

        return clusters.map((c => {
            if (c.properties.cluster) {
                return (<Marker
                    key={`cluster-${c.id}`}
                    longitude={c.geometry.coordinates[0]}
                    latitude={c.geometry.coordinates[1]}
                    anchor="center"
                    onClick={() => { mapRef.current?.fitToEntities((c.properties as any).directoryItems as LocatedEntity[], { animate: true }); }}
                >
                    <div
                        className={`marker-cluster`}
                        style={!!results?.data.items.length ? { width: `${30 + (c.properties.point_count / maxCluster) * 40}px`, height: `${30 + (c.properties.point_count / maxCluster) * 40}px` } : undefined}
                        onMouseEnter={() => setPopup({
                            lng: c.geometry.coordinates[0],
                            lat: c.geometry.coordinates[1],
                            directoryItems: (c.properties as any).directoryItems as DirectoryItemForMap[]
                        })}
                    >
                        {c.properties.point_count}
                    </div>
                </Marker >
                );
            }

            const directoryItem = (c.properties as any).directoryItem as DirectoryItemForMap;
            const markers = directoryItem.dependencies?.filter(d => !!d.location?.latitude && !!d.location?.longitude).map(d => (
                <Marker
                    key={`marker-dependency-${d._id}`}
                    longitude={d.location?.longitude!}
                    latitude={d.location?.latitude!}
                    anchor="center"
                >
                    <div
                        className={conditionnalClassnames({
                            "marker-dependency": true,
                            "marker-dependency-selected": selectedDirectoryItem?._id === directoryItem._id

                        })}
                        onMouseEnter={() => setPopup({
                            lng: d.location?.longitude!,
                            lat: d.location?.latitude!,
                            dependency: d
                        })}
                    >
                        <DepencyIcon depency={d} />
                    </div>
                </Marker>
            )) ?? [];

            markers.push(
                <DirectoryItemMarker
                    key={`marker-${directoryItem._id}`}
                    directoryItem={directoryItem}
                    segmentationsCount={directoryItem.segmentationsCount}
                    longitude={c.geometry.coordinates[0]}
                    latitude={c.geometry.coordinates[1]}
                    onClick={() => setSelectedDirectoryItem(directoryItem)}
                    selected={selectedDirectoryItem?._id === directoryItem._id}
                    onMouseEnter={() => setPopup({
                        lng: c.geometry.coordinates[0],
                        lat: c.geometry.coordinates[1],
                        directoryItems: [directoryItem]
                    })}
                />
            );

            return markers;
        })).flat()
    },
        [clusters, selectedDirectoryItem]);

    const handleUserDataSubmit = useCallback((userData: UserData, type: 'map-query' | 'map-plan') => {
        const ud = checkUserDataSchemaForMap(userData, type);
        if (type === 'map-query') {
            setFilters({ ...ud.data, name: ud.name, _id: ud._id, schemaVersion: ud.schemaVersion, stepId: currentStepId }, true)
        } else if (type === 'map-plan') {
            if (!ud.data?.steps?.length) return;
            setPlan({ name: ud.name, _id: ud._id, schemaVersion: ud.schemaVersion, steps: ud.data?.steps });
        }
    }, [setFilters, setPlan, currentStepId]);

    useEffect(() => {
        setPopup(null);
        setSelectedDirectoryItem(null);

        if (results?.data.items.length === 1) {
            setSelectedDirectoryItem(results.data.items[0]);
            mapRef.current?.fitToEntities([results.data.items[0]] as LocatedEntity[]);
        } else if (results?.data.items.length) {
            mapRef.current?.fitToEntities(results.data.items as LocatedEntity[])
        }
    }, [results]);

    useEffect(() => {
        if (action?.action) {
            switch (action.action) {
                case MapAction.QuerySave:
                    setUserDataModal({
                        type: 'map-query',
                        mode: 'save',
                        userData: {
                            _id: filters._id,
                            name: filters.name,
                            data: filters
                        }
                    });
                    break;
                case MapAction.QuerySaveAs:
                    setUserDataModal({
                        type: 'map-query',
                        mode: 'save',
                        userData: {
                            name: filters.name,
                            data: filters
                        }
                    });
                    break;
                case MapAction.QueryOpen:
                    setUserDataModal({ type: 'map-query', mode: 'open' });
                    break;
                case MapAction.PlanSave:
                    setUserDataModal({
                        type: 'map-plan',
                        mode: 'save',
                        userData: {
                            _id: plan._id,
                            name: plan.name,
                            data: { steps: plan.steps }
                        }
                    });
                    break;
                case MapAction.PlanSaveAs:
                    setUserDataModal({
                        type: 'map-plan',
                        mode: 'save',
                        userData: {
                            data: { steps: plan.steps }
                        }
                    });
                    break;
                case MapAction.PlanOpen:
                    setUserDataModal({ type: 'map-plan', mode: 'open' });
                    break;
                case MapAction.WindowFilters:
                    togglePanel('filters');
                    break;
                case MapAction.WindowResults:
                    togglePanel('results');
                    break;
                case MapAction.WindowPlan:
                    togglePanel('steps');
                    break;
                case MapAction.WindowLayers:
                    togglePanel('layers');
                    break;
                default:
                    break;
            }
            setActionDone(action.timestamp);
        }
    }, [action?.timestamp]);

    useEffect(() => {
        if (layer) {
            getLayer(layer);
        } else {
            setDataLayer(null);
        }
    }, [layer]);

    useEffect(() => {
        if (selectedDirectoryItem && mapRef.current) {
            mapRef.current.fitToEntities([selectedDirectoryItem] as LocatedEntity[], { keepZoom: true });
        }
    }, [selectedDirectoryItem]);

    return (
        <div id="main-map">
            <MainMapSteps />
            <div className="col">
                <div className="row">
                    <MainMapResults onClick={(d) => setSelectedDirectoryItem(d as DirectoryItemForMap)} />
                    <div id="main-map-container">
                        {!!panels.filters && <MainMapFilters />}
                        <div id="main-map-float">
                            {selectedDirectoryItem && <DirectoryItemMapCard directoryItemForMap={selectedDirectoryItem} onClose={() => setSelectedDirectoryItem(null)} />}
                        </div>
                        <Map
                            onMoveEnd={(e) => setBounds(e.target.getBounds())}
                            ref={mapRef}
                            interactiveLayerIds={planResults?.map((p) => p.name)}
                            onMouseMove={planResults?.length ? handleMouseMove : undefined}
                            disableReuse
                        >
                            {!!dataLayer && (
                                <Source type="geojson" data={dataLayer.data}>
                                    <Layer {...dataLayer.style} />
                                </Source>
                            )}
                            {planLayers}
                            {markers}
                            {!!selectedDirectoryItem && (
                                <Marker
                                    longitude={selectedDirectoryItem.location?.longitude!}
                                    latitude={selectedDirectoryItem.location?.latitude!}
                                    anchor="center"
                                    style={{ zIndex: 30 }}
                                />
                            )}
                            {!!popup && (
                                <Popup
                                    longitude={popup.lng}
                                    latitude={popup.lat}
                                    anchor="left"
                                    offset={10}
                                    className="main-map-popup"
                                    onClose={() => setPopup(null)}
                                >
                                    {!!popup.directoryItems?.length ? (
                                        <DirectoryItemsList directoryItems={popup.directoryItems} onClick={(d) => setSelectedDirectoryItem(d as DirectoryItemForMap)} />
                                    ) : (
                                        <div className="container col">
                                            <LabelValue label="name" i18n="entity" value={popup.dependency?.name} />
                                            <LabelValue label="description" i18n="entity" value={popup.dependency?.description} />
                                            <LabelValue label="modele" i18n="dependencies" value={popup.dependency?.data?.modele} />
                                            <LabelValue label="constructeur" i18n="dependencies" value={popup.dependency?.data?.constructeur} />
                                            <LabelValue label="puissance" i18n="dependencies" value={popup.dependency?.data?.puissance} />
                                            <LabelValue label="hauteur_max_mat" i18n="dependencies" value={popup.dependency?.data?.hauteur_max_mat} />
                                        </div>
                                    )}
                                </Popup>
                            )}
                        </Map>
                        {panels.layers && <MainMapLayers />}
                    </div>
                </div>
            </div>
            {userDataModal && (
                <UserDataModal
                    type={userDataModal.type}
                    schemaVersion={MAP_SCHEMA_VERSION}
                    userData={userDataModal.userData}
                    mode={userDataModal.mode}
                    onClose={() => setUserDataModal(null)}
                    onSubmit={(ud) => handleUserDataSubmit(ud, userDataModal.type)}
                />
            )}
        </div>
    )
}

export default MainMap;