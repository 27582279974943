import { useEffect, useRef, useState } from "react";

const useInterval = (
    callback: () => void,
    duration: number,
    states: any[],
    callOnInit?: boolean
) => {
    const [isInit, setInit] = useState<boolean>(false);
    const interval = useRef<NodeJS.Timeout | null>(null);

    useEffect(() => {
        if (interval.current) {
            clearInterval(interval.current);
        }

        interval.current = setInterval(callback, duration);
        if (!isInit) {
            setInit(true);
            if (callOnInit) callback();
        }

        return () => {
            interval.current && clearTimeout(interval.current);
        };
    }, states);
};

export default useInterval;
