import { ReactNode } from 'react';
import './index.scss';

interface IconValueProps {
    icon: ReactNode;
    value?: ReactNode;
}

const IconValue = ({ icon, value }: IconValueProps) => {
    if (!value) return null;

    return (
        <div className="icon-value">{icon}<span>{value}</span></div>
    )
}
export default IconValue;