import { MouseEvent, useCallback, useMemo, useRef, useState } from 'react';
import './index.scss';

export interface SliderProps {
    value?: number;
    onChange: (value?: number) => void;
    range: [number, number];
    steps?: number | number[];
}

const Slider = ({
    value,
    steps,
    range,
    onChange,
}: SliderProps) => {
    const [isSliding, setSliding] = useState<boolean>(false);
    const ref = useRef<HTMLDivElement | null>(null);

    const handleChange = useCallback((v: number) => {
        const min = Math.min(range[0], range[1]);
        const max = Math.max(range[0], range[1]);

        onChange(v <= min ? min : v >= max ? max : v);
    }, [onChange, range]);

    const left = useMemo(() => {
        if (!value || value <= range[0]) return '0%';
        if (value >= range[1]) return '100%';

        return (value * 100 / (Math.max(range[0], range[1]) - Math.min(range[0], range[1]))) + '%';
    }, [value, range]);

    const handleChangeOnEvent = useCallback((e: MouseEvent) => {
        if (!ref.current) return;

        const bbox = ref.current.getBoundingClientRect();
        const pos = e.clientX - bbox.left;

        handleChange(Math.round(pos * Math.max(range[0], range[1]) / bbox.width));
    }, [range, handleChange]);

    return (
        <div className="slider" onMouseMove={isSliding ? handleChangeOnEvent : undefined}>
            <div className="slider-bar" onClick={handleChangeOnEvent} ref={ref} />
            <div
                className="slider-handle"
                onMouseDown={() => setSliding(true)}
                onMouseUp={() => setSliding(false)}
                style={{ left }}
            />
        </div>
    )
}

export default Slider;