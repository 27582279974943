import { ReactNode, Ref, useCallback, useRef } from 'react';
import InputWrapper, { FormPropsInterface } from '../InputWrapper';
import { UseFocusHandleRef, useFocusHandle } from '../../hooks';

export interface TextFieldProps extends FormPropsInterface<string> {
    types?: string,
    maxLength?: number,
    placeholder?: string,
    icon?: ReactNode;
    onBlur?: (value: string) => void,
    onFocus?: (value: string) => void,
    onBlurValidationError?: (value: string) => void,
    onBlurValidationWarning?: (value: string) => void,
    autoComplete?: boolean,
    autoFocus?: boolean,
    focusHandle?: Ref<UseFocusHandleRef>,
}

const TextField = ({
    types,
    value,
    maxLength,
    placeholder,
    disabled,
    onBlur,
    onFocus,
    onChange,
    onBlurValidationError,
    onBlurValidationWarning,
    autoComplete,
    autoFocus,
    focusHandle,
    ...rest
}: TextFieldProps) => {
    const inputRef = useFocusHandle(focusHandle);

    return (
        <InputWrapper type="text" {...rest}>
            <input
                autoFocus={autoFocus}
                autoComplete={autoComplete ? "on" : "off"}
                id={rest.id}
                type="text"
                readOnly={!!disabled}
                onBlur={onBlur && !disabled ? (e) => onBlur(e.target.value) : undefined}
                onFocus={onFocus && !disabled ? (e) => onFocus(e.target.value) : undefined}
                onChange={onChange && !disabled ? (e) => onChange(!e.target.value ? undefined : e.target.value) : undefined}
                value={value ?? ''}
                placeholder={placeholder ?? ''}
                maxLength={maxLength ? maxLength : undefined}
                ref={inputRef}
            />
        </InputWrapper>
    );
}

export default TextField;