import { MutableRefObject, Ref, useImperativeHandle, useRef } from "react";

export interface UseFocusHandleRef {
    focus: () => void;
}

const useFocusHandle = (ref: Ref<UseFocusHandleRef> | undefined, setFocused?: (f: boolean) => void): MutableRefObject<HTMLInputElement | null> => {
    const focusRef = useRef<HTMLInputElement | null>(null);

    useImperativeHandle(ref, () => ({
        focus: () => {
            if (focusRef.current) {
                focusRef.current?.focus();
                if (setFocused) setFocused(true);
            }
        },
    }), []);

    return focusRef;
};

export default useFocusHandle;
