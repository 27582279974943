import { createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { WorkspaceForUser } from "../models/workspace";
import '../styles/index.scss';
import { useAuthContext } from "./AuthContext";
import { useRequest } from "./RequestContext";

interface WorkspaceProviderProps {
    children: ReactNode;
}

export type WorkspaceContextType = {
    workspaces: WorkspaceForUser[],
    currentWorkspace: WorkspaceForUser,
    refreshWorkspaces: () => void,
    setCurrentWorkspace: (_id: string) => void;
};

export const WorkspaceContext = createContext<WorkspaceContextType>({
    workspaces: [],
    currentWorkspace: {} as WorkspaceForUser,
    refreshWorkspaces: () => null,
    setCurrentWorkspace: (_) => null,
});

const WorkspaceProvider = ({ children }: WorkspaceProviderProps) => {
    const { currentUser } = useAuthContext();
    const [workspaces, setWorkspaces] = useState<WorkspaceForUser[]>([]);
    const request = useRequest();
    const navigate = useNavigate();

    const currentWorkspace = useMemo(() => workspaces.find(w => w.current), [workspaces]);

    const refreshWorkspaces = useCallback((_id?: string) => {
        request.get<WorkspaceForUser[]>(`/workspaces/me/${_id ?? currentWorkspace?._id ?? ''}`)
            .then((workspaces) => {
                setWorkspaces(workspaces);

                const newCurrentWorkspace = workspaces.find(w => w.current) ?? null;
                request.setWorkspaceId(newCurrentWorkspace?._id);

                if (newCurrentWorkspace && currentWorkspace?._id !== newCurrentWorkspace?._id) {
                    localStorage.setItem(`sg-workspace`, newCurrentWorkspace?._id ?? '');
                    navigate('/');
                }
            })
            .catch(() => setWorkspaces([]))
    }, [request, currentWorkspace]);

    useEffect(() => {
        refreshWorkspaces(localStorage.getItem('sg-workspace') ?? undefined);
    }, [currentUser]);

    if (!currentWorkspace) return null;

    return (
        <WorkspaceContext.Provider value={{
            workspaces,
            currentWorkspace,
            refreshWorkspaces,
            setCurrentWorkspace: refreshWorkspaces,
        }}>
            {children}
        </WorkspaceContext.Provider>
    );
};

const useWorkspaceContext = () => {
    return useContext(WorkspaceContext);
};

export { useWorkspaceContext, WorkspaceProvider };
export default WorkspaceContext;