import { DirectoryItem } from "../../../models/directory-item";
import { WizardContentComponentProps } from "../../../sg-react/ui/Wizard";
import DirectoryItemCreateDuplicates from "./DirectoryItemCreateDuplicates";

const DirectoryItemCreateDuplicatesIdentification = (props: WizardContentComponentProps<DirectoryItem>) => (
    <DirectoryItemCreateDuplicates
        endpoint="/directory/search-by-location/global"
        params={props.entity.location}
        {...props}
    />
);

export default DirectoryItemCreateDuplicatesIdentification;