import { Fragment, useCallback } from "react";
import { Checkbox, ScrollableList } from "../../../sg-react/form";
import { Card } from "../../../sg-react/ui";
import Collapse from "../../../sg-react/ui/Collapse";
import { useMapContext } from "./MapContext";

interface MainMapLayersProps {
}

const MainMapLayers = ({ }: MainMapLayersProps) => {
    const { layer, setLayer } = useMapContext();

    const handleWaterLayer = useCallback((name?: string) => {
        if (!name) {
            setLayer(undefined);
            return;
        }

        setLayer({
            name,
            category: 'water',
            parameters: layer?.category !== 'water'
                ? undefined
                : {
                    ...layer?.parameters,
                    projection: name === 'bws' ? layer?.parameters?.projection : undefined,
                    scenario: name === 'bws' ? layer?.parameters?.scenario : undefined,
                },
        });
    }, [setLayer, layer]);

    const handleWaterParameter = useCallback((parameter: Record<string, any>) => {
        if (!layer) return;
        setLayer({
            ...layer,
            parameters: {
                ...layer?.parameters,
                ...parameter
            }

        });
    }, [setLayer, layer]);

    return (
        <Card id="main-map-options">
            <Collapse title="Emissions">
                <div className="simple-list">
                    <Checkbox
                        id="carbon_intensity_of_electricity_generation"
                        label="Carbon intensity of electricity generation - eqCO2/kWh"
                        value={layer?.name === 'carbon_intensity_of_electricity_generation'}
                        onChange={(b) => setLayer(b ? { name: 'carbon_intensity_of_electricity_generation', category: 'emissions' } : undefined)}
                    />
                </div>
            </Collapse>
            <Collapse title="Water">
                <div className="simple-list">
                    <Checkbox
                        id="bws"
                        label="Baseline water stress"
                        value={layer?.name === 'bws'}
                        onChange={(b) => handleWaterLayer(b ? 'bws' : undefined)}
                    />
                    <Checkbox
                        id="rfr"
                        label="Riverine flood risk"
                        value={layer?.name === 'rfr'}
                        onChange={(b) => handleWaterLayer(b ? 'rfr' : undefined)}
                    />
                    <Checkbox
                        id="drr"
                        label="Drought risk"
                        value={layer?.name === 'drr'}
                        onChange={(b) => handleWaterLayer(b ? 'drr' : undefined)}
                    />
                    {layer?.category === 'water' && (
                        <div className="col">
                            <span>Settings</span>
                            <ScrollableList
                                id="weight"
                                label="Weight"
                                items={[
                                    { key: undefined, label: 'None' },
                                    { key: 'tot', label: 'Total gross withdrawal' },
                                    { key: 'dom', label: 'Domestic gross withdrawal' },
                                    { key: 'ind', label: 'Industrial gross withdrawal' },
                                    { key: 'irr', label: 'Irrigation gross withdrawal' },
                                    { key: 'liv', label: 'Livestock gross withdrawal' },
                                    { key: 'pop', label: 'Population' }
                                ]}
                                value={layer?.parameters?.weight}
                                onChange={(weight) => handleWaterParameter({ weight })}
                            />
                            {layer.name === 'bws' && (
                                <Fragment>
                                    <ScrollableList
                                        id="projection"
                                        label="Projection"
                                        items={[{ key: undefined, label: 'None' }, { key: '2030', label: '2030' }, { key: '2050', label: '2050' }, { key: '2080', label: '2080' }]}
                                        value={layer?.parameters?.projection}
                                        onChange={(projection) => handleWaterParameter({ projection })}
                                    />
                                    {!!layer.parameters?.projection && (
                                        <ScrollableList
                                            id="scenario"
                                            label="Scenario"
                                            items={[{ key: 'opt', label: 'Optimistic' }, { key: 'bau', label: 'Business as Usual' }, { key: 'pes', label: 'Pessimistic' }]}
                                            value={layer?.parameters?.scenario}
                                            onChange={(scenario) => handleWaterParameter({ scenario })}
                                        />
                                    )}
                                </Fragment>
                            )}
                        </div>
                    )}
                </div>
            </Collapse>
        </Card>
    )
}
export default MainMapLayers;