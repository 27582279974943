import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { DirectoryDataIndicators, DirectoryGrade, DirectoryPurchasingStrategy } from '../../../models/directory-data';
import StringValueDateModal from '../../../sg-react/data/StringValueDateModal';
import ValueDateModal from '../../../sg-react/data/ValueDateModal';
import { ValueDate } from '../../../sg-react/models/data';
import { translateEnum } from '../../../sg-react/utils/format';

interface DirectoryViewIndicatorEditModalProps {
    indicator: keyof DirectoryDataIndicators;
    onSubmit: (v: ValueDate<string | number>) => void;
    onClose: () => void;
}

const DirectoryViewIndicatorEditModal = ({ indicator, onSubmit, onClose }: DirectoryViewIndicatorEditModalProps) => {
    const { t } = useTranslation();

    const directoryItemPurchasingStrategy = useMemo(() => translateEnum(DirectoryPurchasingStrategy, 'directory-data', 'purchasing_strategy', t), [t]);
    const directoryItemGrades = useMemo(() => translateEnum(DirectoryGrade, 'directory-data', 'grades', t), [t]);

    const modal = useMemo(() => {
        if (['otd', 'oqd'].includes(indicator)) {
            return <ValueDateModal title={indicator} i18n="directory-data" onClose={onClose} onSubmit={onSubmit} />;
        } else if (indicator === 'purchasingStrategy') {
            return <StringValueDateModal title={indicator} i18n="directory-data" items={directoryItemPurchasingStrategy} onClose={onClose} onSubmit={onSubmit} />;
        }
        return <StringValueDateModal title={indicator} i18n="directory-data" items={directoryItemGrades} onClose={onClose} onSubmit={onSubmit} />
    }, [indicator]);

    return modal;
}

export default DirectoryViewIndicatorEditModal;