import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { DirectoryItemsListItem } from "../../components/DirectoryItemsList";
import { DIRECTORY_ITEM_CONTACTS_VALIDATION, DIRECTORY_ITEM_INFORMATION_VALIDATION, DirectoryItem } from "../../models/directory-item";
import { useAuthContext, useMenuContext, useRequest, useWorkspaceContext } from "../../sg-react/context";
import { RevisionTableField } from "../../sg-react/data/RevisionTable";
import RevisionModal from "../../sg-react/data/RevisionsModal";
import { CommentModal, LocationPicker } from "../../sg-react/form";
import { ObjectValidation } from "../../sg-react/form/Validation/class";
import { useForm } from "../../sg-react/hooks";
import { FormHookReturn } from "../../sg-react/hooks/useForm";
import { SaveIcon, ViewIcon } from "../../sg-react/icons";
import { LOCATION_VALIDATION } from "../../sg-react/models/location";
import { Permission } from "../../sg-react/models/permissions";
import { Button, Card } from "../../sg-react/ui";
import Info from "../../sg-react/ui/Info";
import LayoutLeftTabs from "../../sg-react/ui/LayoutLeftTabs";
import { Colors } from "../../sg-react/ui/enums";
import { Action } from "../../sg-react/utils/enums";
import DirectoryItemContactsForm from "./components/DirectoryItemContactsForm";
import DirectoryItemInformationsForm from "./components/DirectoryItemInformationsForm";

export const DIRECTORY_REVISION_FIELDS: RevisionTableField<DirectoryItem>[] = [
    { field: 'name', i18n: 'directory' },
    { field: 'description', i18n: 'directory' },
    { field: 'localBusinessId', i18n: 'directory' },
    { field: 'localBusinessIdType', i18n: 'directory' },
    { field: 'internationalBusinessId', i18n: 'directory' },
    { field: 'internationalBusinessIdType', i18n: 'directory' },
    { field: 'vatId', i18n: 'directory' },
    { field: 'type', i18n: 'directory' },
    {
        field: 'logo',
        i18n: 'directory',
        display: (e) => e.logo?.base64 ? <img src={e.logo?.base64} alt="logo" style={{ height: '60px' }} /> : undefined,
        comparator: (e1, e2) => e1.logo?.base64 !== e2.logo?.base64 || e1.logo?.url !== e2.logo?.url
    },
    { field: 'localPhone', i18n: 'directory' },
    { field: 'internationalPhone', i18n: 'directory' },
    { field: 'website', i18n: 'directory' },
    { field: 'email', i18n: 'directory' },
    { field: 'location.longitude', label: 'longitude', i18n: 'location' },
    { field: 'location.latitude', label: 'latitude', i18n: 'location' },
    { field: 'location.streetNumber', label: 'streetNumber', i18n: 'location' },
    { field: 'location.route', label: 'route', i18n: 'location' },
    { field: 'location.city', label: 'city', i18n: 'location' },
    { field: 'location.region', label: 'region', i18n: 'location' },
    { field: 'location.country', label: 'country', i18n: 'location' },
    { field: 'location.postalCode', label: 'postalCode', i18n: 'location' },
    { field: 'location.address', label: 'address', i18n: 'location' },
];

const ITEMS = [
    {
        id: 'directory-item-informations', i18n: 'directory', label: 'informations', children: [
            { id: 'input-name', i18n: 'directory', label: 'name' },
            { id: 'input-type', i18n: 'directory', label: 'type' },
            { id: 'input-parent', i18n: 'directory', label: 'parent' },
            { id: 'input-logo', i18n: 'directory', label: 'logo' },
            { id: 'input-description', i18n: 'directory', label: 'description' },
        ]
    },
    { id: 'directory-item-location', i18n: 'directory', label: 'location' },
    {
        id: 'directory-item-contacts', i18n: 'contacts', label: 'contacts', children: [
            { id: 'input-website', i18n: 'directory', label: 'website' },
            { id: 'input-localPhone', i18n: 'directory', label: 'localPhone' },
            { id: 'input-internationalPhone', i18n: 'directory', label: 'internationalPhone' },
            { id: 'input-email', i18n: 'directory', label: 'email' }
        ]
    },
    { id: 'directory-item-dependencies', i18n: 'dependencies', label: 'dependencies' },
];

export type DirectoryItemFormProps = Pick<FormHookReturn<DirectoryItem>, 'entity' | 'attachInput' | 'onMultipleChange'>;

const VALIDATION = new ObjectValidation({
    ...DIRECTORY_ITEM_INFORMATION_VALIDATION,
    ...DIRECTORY_ITEM_CONTACTS_VALIDATION,
    location: LOCATION_VALIDATION
})

const DirectoryItemForm = () => {
    const request = useRequest();
    const { currentWorkspace } = useWorkspaceContext();
    const { currentUser } = useAuthContext();
    const { currentItem, currentData, removeCurrentItem, setCurrentItem, setCurrentData, action, setActionDone } = useMenuContext();
    const { entity, attachInput, reset, onMultipleChange, onChange, errors, validate, hasChanged, hasError } = useForm<DirectoryItem>({}, VALIDATION);
    const { t } = useTranslation();
    const [isRevisionsModalVisible, setRevisionsModalVisible] = useState<boolean>(false);
    const [isRevisionMessageModalVisible, setRevisionMessageModalVisible] = useState<boolean>(false);
    const { _id } = useParams();
    const navigate = useNavigate();

    const isAdminWorkspace = useMemo(() => entity.permissions?.workspaces.some(w => w.workspaceId === currentWorkspace._id && w.permission === Permission.Admin), [entity, currentWorkspace]);
    const canWorkspaceEdit = useMemo(() => entity.permissions?.workspaces.some(w => w.workspaceId === currentWorkspace._id && w.permission === Permission.Edit) || entity.permissions?.users.some(u => u.userId === currentUser._id && u.permission === Permission.Edit), [entity, currentUser, currentWorkspace]);

    const put = useCallback(async (isRevision?: boolean, revisionComment?: string) => {
        request.put<DirectoryItem>('/directory', { ...entity, parents: undefined, revisionComment }, {
            withWorkspace: true,
            loader: true,
            successMessage: isRevision ? { i18n: 'entity', message: 'success.revision_sent' } : true,
            errorMessage: true,
        })
            .then((data) => {
                reset(data, false);
                setRevisionMessageModalVisible(false);
            })
            .catch(() => null);
    }, [entity, request, reset]);

    const handleSave = useCallback(() => validate(() => {
        if (isAdminWorkspace || canWorkspaceEdit) {
            put();
        } else {
            setRevisionMessageModalVisible(true);
        }
    }), [validate, put, isAdminWorkspace, canWorkspaceEdit]);

    const get = useCallback(() => {
        request.get<DirectoryItem>(`/directory/${_id}`, {
            withWorkspace: true,
            loader: true,
            errorMessage: true
        })
            .then((data) => {
                setCurrentItem({ label: data.name, loading: false });
                setCurrentData(data);
                reset(data, false);
            })
            .catch(() => navigate(-1));
    }, [request, _id, reset, setCurrentData])

    useEffect(() => {
        setCurrentItem({ error: hasError, warning: hasChanged });
    }, [hasChanged, hasError]);

    useEffect(() => {
        setCurrentData(entity);
    }, [entity]);

    useEffect(() => {
        if (action?.action) {
            switch (action.action) {
                case Action.Close:
                    removeCurrentItem();
                    break;
                default:
                    break;
            }
            setActionDone(action.timestamp);
        }
    }, [action?.timestamp]);

    useEffect(() => {
        if (!currentData) {
            get();
        } else {
            reset(currentData, !!currentItem?.warning);
        }
    }, [_id]);

    if (!currentData) return null;

    return (
        <LayoutLeftTabs items={ITEMS} id="directory-item-form">
            {!!hasChanged && (
                <Card color="warning">
                    <div className="row">
                        <div className="col">{t('form:has_changed')}</div>
                        <Button label="save" i18n="actions" icon={<SaveIcon />} color={Colors.WARNING} onClick={handleSave} />
                    </div>
                </Card>
            )}
            {!!entity.revisionsIds?.length && isAdminWorkspace && (
                <Card color="pristine">
                    <div className="row">
                        <div className="col">{t('entity:has_revisions_tooltip')}</div>
                        <Button label="view" i18n="actions" icon={<ViewIcon />} color={Colors.PRISTINE} onClick={() => setRevisionsModalVisible(true)} />
                    </div>
                </Card>
            )}
            {!isAdminWorkspace && !canWorkspaceEdit && (
                <Card><Info i18n="entity" label="revision_required_tooltip" /></Card>
            )}
            <Card id="directory-item-informations" title="informations" i18n="directory">
                <DirectoryItemInformationsForm entity={entity} attachInput={attachInput} onMultipleChange={onMultipleChange} />
            </Card>
            <Card
                id="directory-item-location"
                title="location"
                i18n="directory"
            >
                <LocationPicker inline entity={entity} onChange={onChange} attachInput={attachInput} />
            </Card>
            <Card id="directory-item-contacts" title="contacts" i18n="contacts">
                <DirectoryItemContactsForm entity={entity} attachInput={attachInput} onMultipleChange={onMultipleChange} />
            </Card>
            {!!isRevisionsModalVisible && (
                <RevisionModal
                    getUrl={`/directory/${entity._id}/revisions`}
                    postUrl="/directory/revision"
                    component={DirectoryItemsListItem}
                    fields={DIRECTORY_REVISION_FIELDS}
                    entity={entity as DirectoryItem}
                    onClose={() => setRevisionsModalVisible(false)}
                    onSubmit={get}
                />
            )}
            {!!isRevisionMessageModalVisible && (
                <CommentModal optional info={{ i18n: 'entity', label: 'revision_comment_tooltip' }} onClose={() => setRevisionMessageModalVisible(false)} onSubmit={(message) => put(true, message)} />
            )}
        </LayoutLeftTabs >
    )
}

export default DirectoryItemForm;