import { ReactNode } from "react";
import PageHeader, { PageHeaderProps } from "../PageHeader";
import './index.scss';

interface LayoutPageProps extends PageHeaderProps {
    children: ReactNode;
    id?: string;
    className?: string;
}

const LayoutPage = ({ breadcrumbs, actions, children, id, className }: LayoutPageProps) => {

    return (
        <div className={`layout-page ${className ?? ''}`} id={id}>
            <PageHeader
                breadcrumbs={breadcrumbs}
                actions={actions}
            />
            {children}
        </div>
    )
}

export default LayoutPage;