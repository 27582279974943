import { ObjectValidationErrors, ValidationError } from "./types";

export class ValidationException extends Error {
    constructor(public errors: ValidationError[]) {
        super();
    }
}

export class ObjectValidationException extends Error {
    constructor(public errors: ObjectValidationErrors) {
        super();
    }
}
