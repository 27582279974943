import { ReactNode, createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Outlet, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useAuthContext } from "../../context";
import { CloseIcon } from "../../icons";
import { Role } from "../../utils/enums";
import { conditionnalClassnames } from "../../utils/helpers";
import Button from "../Button";
import { Colors } from "../enums";
import './index.scss';

export interface LayoutSubNavItem {
    label?: string;
    icon?: ReactNode;
    i18n?: string;
    disabled?: boolean;
    loading?: boolean;
    path: string;
    searchParams?: string;
    roles?: Role[];
    noScroll?: boolean;
    temporary?: boolean;
    hasChanged?: boolean;
    hasError?: boolean;
};

type LayoutSubNavContextType = {
    navigation: LayoutSubNavItem[];
    setCurrentItem: (i: Partial<LayoutSubNavItem>) => void;
    getCurrentItem: () => LayoutSubNavItem | undefined;
    removeCurrentItem: () => void;
    getCurrentData: () => any | undefined;
    setCurrentData: (d: any) => void;
};

const LayoutSubNavContext = createContext<LayoutSubNavContextType>({
    navigation: [],
    setCurrentItem: () => null,
    getCurrentItem: () => undefined,
    removeCurrentItem: () => null,
    getCurrentData: () => undefined,
    setCurrentData: () => null
});

interface LayoutSubNavProps {
    navigation: LayoutSubNavItem[];
}

const LayoutSubNav = ({ navigation: navigationFromProps }: LayoutSubNavProps) => {
    const [navigation, setNavigation] = useState<LayoutSubNavItem[]>([...navigationFromProps]);
    const [data, setData] = useState<Record<string, any>>({});
    const { currentRole } = useAuthContext();
    const { t } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();
    const [searchParms] = useSearchParams();

    const removeItem = useCallback((i: LayoutSubNavItem) => {
        setNavigation((navigation) => navigation.filter(n => n.path !== i.path));
        setData((data) => {
            const _data = { ...data };
            delete _data[i.path];

            return _data;
        });
    }, []);

    const getCurrentItem = useCallback(() => navigation.find(i => i.path === location.pathname.replace(/\/$/, '')), [navigation, location.pathname]);
    const removeCurrentItem = useCallback(() => {
        const currentItem = getCurrentItem();
        if (currentItem) {
            removeItem(currentItem);
            setData((data) => {
                const _data = { ...data };
                delete _data[currentItem.path];
                return _data;
            })
        }
    }, [getCurrentItem, removeItem]);
    const setCurrentItem = useCallback((i: Partial<LayoutSubNavItem>) => {
        setNavigation((navigation) => {
            const index = navigation.findIndex(n => location.pathname.replace(/\/$/, '') === n.path);

            const _navigation = [...navigation];
            if (index < 0) {
                _navigation.push({
                    path: location.pathname,
                    loading: true,
                    temporary: true,
                    ...i
                });
            } else {
                _navigation[index] = { ..._navigation[index], ...i };
            }

            return _navigation;
        });
    }, [location.pathname]);

    const getCurrentData = useCallback(() => data[location.pathname.replace(/\/$/, '')], [data, location.pathname]);
    const setCurrentData = useCallback((newData: any) => setData((data) => ({ ...data, [location.pathname.replace(/\/$/, '')]: newData })), [location.pathname]);

    const noScroll = useMemo(() => !!navigation.find(i => i.path === location.pathname.replace(/\/$/, ''))?.noScroll, [navigation, location.pathname]);

    const navigationItems = useMemo(() => navigation
        .filter(item => !item.roles || (currentRole && item.roles.includes(currentRole)))
        .map((item) => (
            <Button
                key={item.path}
                color={!item.temporary ? 'navigation' : item.hasError ? Colors.ERROR : item.hasChanged ? Colors.WARNING : Colors.PRISTINE}
                onClick={() => navigate(item.path + (item.searchParams ? `?${item.searchParams}` : ''))}
                active={location.pathname.replace(/\/$/, '') === item.path}
                loading={item.loading}
                icon={item.temporary ? <CloseIcon /> : undefined}
                onIconClick={item.temporary ? () => removeItem(item) : undefined}
                {...item}
            />
        )), [navigation, t, removeItem, location.pathname, currentRole]);

    useEffect(() => {
        setNavigation([...navigationFromProps]);
        setData({});
    }, [navigationFromProps]);

    useEffect(() => {
        if (!getCurrentItem()) {
            navigate(navigation[0].path + (navigation[0].searchParams ? `?${navigation[0].searchParams}` : ''), { replace: true });
        }
    }, [navigation]);

    useEffect(() => {
        if (searchParms.size) {
            setCurrentItem({ searchParams: searchParms.toString() });
        }
    }, [searchParms]);

    return (
        <LayoutSubNavContext.Provider value={{ navigation, removeCurrentItem, setCurrentItem, getCurrentItem, getCurrentData, setCurrentData }}>
            <div className={conditionnalClassnames({
                "layout-sub-nav": true,
                "no-scroll": noScroll,
                "no-nav": navigation.length < 2,
            })}>
                {navigation.length > 1 && (
                    <div className="layout-sub-nav-navigation">
                        {navigationItems}
                    </div>
                )}
                <Outlet />
            </div>
        </LayoutSubNavContext.Provider>
    );
}

export const useLayoutSubNavContext = () => {
    return useContext(LayoutSubNavContext);
};


export default LayoutSubNav;