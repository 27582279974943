// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input[input-type=toggle] {
  position: relative;
}
.input[input-type=toggle] .input-field {
  border: none;
  background-color: transparent;
}
.input[input-type=toggle] .input-field .toggle-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  height: 14px;
  width: 38px;
  padding: 2px;
  border-radius: 4px;
  transition: all 0.2s ease-in-out;
  background-color: var(--theme-color-error);
}
.input[input-type=toggle] .input-field .toggle-container > .toggle-button {
  content: "";
  background-color: var(--theme-color-white);
  height: 100%;
  width: 18px;
  border-radius: 3px;
  transition: all 0.2s ease-in-out;
}
.input[input-type=toggle] .input-field .toggle-container.toggle-container-toggled {
  justify-content: flex-end;
  background-color: var(--theme-color-success);
}`, "",{"version":3,"sources":["webpack://./src/sg-react/form/Toggle/index.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;AACJ;AACI;EACI,YAAA;EACA,6BAAA;AACR;AACQ;EACI,aAAA;EACA,mBAAA;EACA,2BAAA;EACA,eAAA;EACA,YAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;EACA,gCAAA;EACA,0CAAA;AACZ;AACY;EACI,WAAA;EACA,0CAAA;EACA,YAAA;EACA,WAAA;EACA,kBAAA;EACA,gCAAA;AAChB;AAEY;EACI,yBAAA;EACA,4CAAA;AAAhB","sourcesContent":[".input[input-type=\"toggle\"] {\n    position: relative;\n\n    & .input-field {\n        border: none;\n        background-color: transparent;\n\n        & .toggle-container {\n            display: flex;\n            align-items: center;\n            justify-content: flex-start;\n            cursor: pointer;\n            height: 14px;\n            width: 38px;\n            padding: 2px;\n            border-radius: 4px;\n            transition: all 0.2s ease-in-out;\n            background-color: var(--theme-color-error);\n\n            & > .toggle-button {\n                content: \"\";\n                background-color: var(--theme-color-white);\n                height: 100%;\n                width: 18px;\n                border-radius: 3px;\n                transition: all 0.2s ease-in-out;\n            }\n\n            &.toggle-container-toggled {\n                justify-content: flex-end;\n                background-color: var(--theme-color-success);\n            }\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
