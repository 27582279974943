import { ReactNode, Ref, useCallback, useEffect, useState } from "react";
import DropdownInput from "../../form/DropdownInput";
import { UseFocusHandleRef, useDebounce } from "../../hooks";
import { MagnifierIcon } from "../../icons";
import { Nothing } from "../../ui";

interface SearchItemProps {
    label: string;
    description?: string;
    onClick: () => void;
}

export const SearchItem = ({ label, description, onClick }: SearchItemProps) => (
    <div className="dropdown-input-item" onClick={() => onClick()}>
        <span>{label}</span>
        {!!description && <span>{description}</span>}
    </div>
);

interface SearchProps {
    id: string;
    label?: string;
    i18n?: string;
    onClear: () => void;
    onChange: (k: string) => Promise<void> | void;
    inline?: boolean;
    value?: string;
    disabled?: boolean;
    placeholder?: string;
    children?: ReactNode;
    focusHandle?: Ref<UseFocusHandleRef>;
}

const Search = ({ id, onClear, onChange, value, inline, label, i18n, placeholder, disabled, children, focusHandle }: SearchProps) => {
    const [keyword, setKeyword] = useState<string | undefined>();
    const [isLoading, setLoading] = useState<boolean>(false);
    const [isFocused, setFocused] = useState<boolean>(false);
    useDebounce(() => getResults(keyword), 500, [keyword]);

    const handleKeyword = useCallback((k?: string) => {
        setKeyword(k);

        if (!!k && k.length >= 3) {
            setLoading(true);
        } else {
            onClear();
            setLoading(false);
        }
    }, []);

    const clearSearch = useCallback(() => {
        setKeyword('');
        onClear();
    }, [onClear]);

    const getResults = useCallback(async (keyword?: string) => {
        if (!keyword || keyword.length < 3) return;

        setLoading(true);
        await onChange(keyword);
        setLoading(false);

    }, [onChange]);

    useEffect(() => {
        clearSearch();
    }, []);

    return (
        <DropdownInput
            id={id}
            type="search"
            value={isFocused ? keyword : value}
            onChange={handleKeyword}
            inline={inline}
            disabled={disabled}
            onClear={!!keyword ? clearSearch : undefined}
            onFocus={() => setFocused(true)}
            onBlur={() => setFocused(false)}
            loading={isLoading}
            placeholder={placeholder}
            label={label}
            i18n={i18n}
            closeOnClick
            icon={<MagnifierIcon />}
            focusHandle={focusHandle}
        >
            {!!keyword && !!children && <div className="simple-list" onClick={clearSearch}>{children}</div>}
            {!!keyword && keyword.length > 3 && !isLoading && !children && <Nothing inline small />}
        </DropdownInput>
    )
}

export default Search;