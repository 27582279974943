// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input[input-type=color-picker] .dropdown {
  height: 100%;
}
.input[input-type=color-picker] .dropdown > .dropdown-content {
  padding: 0;
  display: flex;
}
.input[input-type=color-picker] .dropdown > .dropdown-content > div {
  display: flex;
  flex-direction: column;
  content: "";
  flex-shrink: 0;
  flex-grow: 1;
  width: 20px;
}
.input[input-type=color-picker] .dropdown > .dropdown-content > div > div {
  content: "";
  height: 20px;
  flex-shrink: 1;
  cursor: pointer;
}
.input[input-type=color-picker] .dropdown > .dropdown-content > div > div:hover {
  border: var(--theme-input-focus-border-color) 1px solid;
}
.input[input-type=color-picker] .dropdown > .dropdown-children {
  padding: 0 !important;
}
.input[input-type=color-picker] .dropdown > .dropdown-children > div {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border-radius: 4px;
}
.input[input-type=color-picker] .dropdown > .dropdown-children > div > svg {
  width: var(--theme-input-icon-size);
  height: var(--theme-input-icon-size);
  fill: var(--theme-input-font-color);
}`, "",{"version":3,"sources":["webpack://./src/sg-react/form/ColorPicker/index.scss"],"names":[],"mappings":"AACI;EACI,YAAA;AAAR;AACQ;EACI,UAAA;EACA,aAAA;AACZ;AACY;EACI,aAAA;EACA,sBAAA;EACA,WAAA;EACA,cAAA;EACA,YAAA;EACA,WAAA;AAChB;AACgB;EACI,WAAA;EACA,YAAA;EACA,cAAA;EACA,eAAA;AACpB;AACoB;EACI,uDAAA;AACxB;AAIQ;EACI,qBAAA;AAFZ;AAIY;EACI,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;AAFhB;AAIgB;EACI,mCAAA;EACA,oCAAA;EACA,mCAAA;AAFpB","sourcesContent":[".input[input-type=\"color-picker\"] {\n    & .dropdown {\n        height: 100%;\n        & > .dropdown-content {\n            padding: 0;\n            display: flex;\n\n            & > div {\n                display: flex;\n                flex-direction: column;\n                content: \"\";\n                flex-shrink: 0;\n                flex-grow: 1;\n                width: 20px;\n\n                & > div {\n                    content: \"\";\n                    height: 20px;\n                    flex-shrink: 1;\n                    cursor: pointer;\n\n                    &:hover {\n                        border: var(--theme-input-focus-border-color) 1px solid;\n                    }\n                }\n            }\n        }\n        & > .dropdown-children {\n            padding: 0 !important;\n\n            & > div {\n                display: flex;\n                align-items: center;\n                justify-content: center;\n                width: 100%;\n                height: 100%;\n                border-radius: 4px;\n\n                & > svg {\n                    width: var(--theme-input-icon-size);\n                    height: var(--theme-input-icon-size);\n                    fill: var(--theme-input-font-color);\n                }\n            }\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
