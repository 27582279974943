import { useMemo } from 'react';
import { Marker } from 'react-map-gl';
import { DirectoryItem } from '../../models/directory-item';
import { Map } from '../../sg-react/data';
import DepencyIcon from '../DependencyIcon';
import DirectoryItemIcon from '../DirectoryItemIcon';
import './index.scss';

interface DirectoryItemMapProps {
    directoryItem: Partial<DirectoryItem>;
}

const DirectoryItemMap = ({ directoryItem }: DirectoryItemMapProps) => {
    const markerDirectory = useMemo(() => directoryItem?.location?.latitude && directoryItem?.location?.longitude ? (
        <Marker
            key="marker-directory"
            longitude={directoryItem.location!.longitude}
            latitude={directoryItem.location!.latitude}
            anchor="center"
        >
            <div className="marker-directory">
                <DirectoryItemIcon type={directoryItem.type} />
            </div>
        </Marker>
    ) : null, [directoryItem._id]);

    const markersDependencies = useMemo(() => directoryItem?.dependencies?.filter(d => d.location).map(d => (
        <Marker
            key={`marker-dependency-${d._id}`}
            longitude={d.location!.longitude}
            latitude={d.location!.latitude}
            anchor="center"
        >
            <div className="marker-dependency">
                <DepencyIcon depency={d} />
            </div>
        </Marker>
    )) ?? [], [directoryItem]);

    return (
        <div className="directory-item-map">
            <Map
                markers={markerDirectory ? [...markersDependencies, markerDirectory] : markersDependencies}
                fitToMarkers="always"
            />
        </div>
    )
}
export default DirectoryItemMap;