// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.geosearch .geosearch-result {
  flex-grow: 1;
}
.geosearch .geosearch-result > span {
  flex-grow: 1;
}
.geosearch .geosearch-result .dropdown-header {
  gap: 6px;
}
.geosearch .geosearch-result .dropdown-header > .input {
  width: 60px;
}
.geosearch .geosearch-result .dropdown-content {
  width: 100%;
}
.geosearch .geosearch-result .dropdown-content .slider {
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/sg-react/data/Geosearch/index.scss"],"names":[],"mappings":"AACI;EACI,YAAA;AAAR;AACQ;EACI,YAAA;AACZ;AAEQ;EACI,QAAA;AAAZ;AAEY;EACI,WAAA;AAAhB;AAIQ;EACI,WAAA;AAFZ;AAIY;EACI,WAAA;AAFhB","sourcesContent":[".geosearch {\n    .geosearch-result {\n        flex-grow: 1;\n        & > span {\n            flex-grow: 1;\n        }\n\n        & .dropdown-header {\n            gap: 6px;\n\n            & > .input {\n                width: 60px;\n            }\n        }\n\n        & .dropdown-content {\n            width: 100%;\n\n            & .slider {\n                width: 100%;\n            }\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
