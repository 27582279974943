import { useEffect } from 'react';
import { Outlet, useNavigate, useSearchParams } from 'react-router-dom';
import { useAuthContext } from '../../context';
import { AuthenticationStatus } from '../../models/auth';
import { Modal } from '../../ui';
import './index.scss';

const Portal = () => {
    const [searchParams] = useSearchParams();
    const { status } = useAuthContext();
    const navigate = useNavigate();

    useEffect(() => {
        if (status === AuthenticationStatus.Authenticated) {
            if (searchParams.get('redirect_uri')) {
                window.location.replace(searchParams.get('redirect_uri')!);
            } else {
                navigate('/');
            }
        }
    }, [status]);

    return (
        <Modal id="portal" overflow="auto">
            <Outlet />
        </Modal>
    )
}

export default Portal;
