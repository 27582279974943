import { ReactNode, createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { WorkspaceRole } from "../models/workspace";
import { ButtonProps } from "../ui/Button";
import { MenuItemProps } from "../ui/Menu";
import { Role } from "../utils/enums";
import { useAuthContext } from "./AuthContext";

export interface MenuContextItem extends ButtonProps {
    path: string;
    roles?: Role[];
    workspacesRols?: WorkspaceRole[];
    searchParams?: string;
    warning?: boolean;
    error?: boolean;
};

export interface MenuContextMenu {
    path: string;
    pathStartsWith?: boolean;
    label: string;
    i18n?: string;
    icon?: ReactNode;
    items: MenuItemProps[];
};

type MenuContextType = {
    menus: MenuContextMenu[];
    setMenus: (m: MenuContextMenu[]) => void;
    items: (MenuContextItem & { temporary?: boolean })[];
    setItems: (i: (MenuContextItem & { temporary?: boolean })[]) => void;
    setCurrentItem: (i: Partial<MenuContextItem>) => void;
    currentItem: MenuContextItem | undefined;
    removeItem: (m: MenuContextItem) => void;
    removeCurrentItem: () => void;
    currentData: Record<string, any> | undefined;
    setCurrentData: (d: any) => void;
    action: { action: string, timestamp: number } | null;
    setAction: (a: string) => void;
    setActionDone: (timestamp: number) => void;
    disabledActions: string[];
    setDisabledActions: (actions: string[]) => void;
    clear: () => void;
};

const MenuContext = createContext<MenuContextType>({
    menus: [],
    setMenus: () => null,
    items: [],
    setItems: () => null,
    setCurrentItem: () => null,
    currentItem: undefined,
    removeItem: () => null,
    removeCurrentItem: () => null,
    currentData: undefined,
    setCurrentData: () => null,
    action: null,
    setAction: () => null,
    setActionDone: () => null,
    disabledActions: [],
    setDisabledActions: () => null,
    clear: () => null
});

const MenuProvider = ({ children }: { children: ReactNode }) => {
    const [menus, setMenus] = useState<MenuContextMenu[]>([]);
    const [items, setItems] = useState<(MenuContextItem & { temporary?: boolean })[]>([]);
    const [data, setData] = useState<Record<string, Record<string, any>>>({});
    const [disabledActions, setDisabledActions] = useState<string[]>([]);
    const [action, setAction] = useState<{ action: string, timestamp: number } | null>(null);
    const { currentRole } = useAuthContext();
    const location = useLocation();
    const navigate = useNavigate();
    const [searchParms] = useSearchParams();

    const goToItem = useCallback((i: MenuContextItem) => {
        navigate((i.path ? i.path : '/') + (i.searchParams ? `?${i.searchParams}` : ''), { replace: true });
    }, []);

    const currentItem = useMemo(() => items.find(i => i.path === location.pathname.replace(/\/$/, '')), [items, location.pathname]);

    const handleClear = useCallback(() => {
        setItems([]);
        setMenus([]);
        setData({});
    }, []);

    const removeItem = useCallback((i: MenuContextItem) => {
        setItems((items) => items.filter(n => !n.temporary || n.path !== i.path));
        setData((data) => {
            const _data = { ...data };
            delete _data[i.path];

            return _data;
        });

        if (i.path === currentItem?.path) {
            goToItem(items[0]);
        }
    }, [items, currentItem]);

    const removeCurrentItem = useCallback(() => {
        if (currentItem) {
            removeItem(currentItem);
        }
    }, [currentItem, removeItem]);

    const setCurrentItem = useCallback((i: Partial<MenuContextItem>) => {
        setItems((items) => {
            const index = items.findIndex(n => location.pathname.replace(/\/$/, '') === n.path);

            const _items = [...items];
            if (index < 0) {
                _items.push({
                    path: location.pathname,
                    loading: true,
                    temporary: true,
                    ...i
                });
            } else {
                _items[index] = { ..._items[index], ...i };
            }

            return _items;
        });
    }, [location.pathname, menus]);

    const currentData = useMemo(() => data[location.pathname.replace(/\/$/, '')], [data, location.pathname]);
    const setCurrentData = useCallback((newData: any) => setData((data) => ({ ...data, [location.pathname.replace(/\/$/, '')]: newData })), [location.pathname]);

    const setActionDone = useCallback((t: number) => setAction((action) => action?.timestamp === t ? null : action), []);

    const menusForContext = useMemo(() => menus
        .filter(m => m.path === location.pathname.replace(/\/$/, '') || (m.pathStartsWith && location.pathname.replace(/\/$/, '').startsWith(m.path))), [menus, location.pathname]);

    const itemsForContext = useMemo(() => items
        .filter(item => !item.roles || (currentRole && item.roles.includes(currentRole))),
        [items, currentRole]);

    useEffect(() => {
        if (searchParms.size && currentItem) {
            setCurrentItem({ searchParams: searchParms.toString() });
        }
    }, [searchParms]);

    return (
        <MenuContext.Provider value={{
            menus: menusForContext,
            setMenus,
            items: itemsForContext,
            setItems,
            removeItem,
            removeCurrentItem,
            currentItem,
            setCurrentItem,
            currentData,
            setCurrentData,
            action,
            setAction: (action: string) => setAction({ action, timestamp: Date.now() }),
            setActionDone,
            disabledActions,
            setDisabledActions,
            clear: handleClear
        }}>
            {children}
        </MenuContext.Provider>
    );
}

const useMenuContext = () => useContext<MenuContextType>(MenuContext);

export { MenuProvider, useMenuContext };
export default MenuContext;