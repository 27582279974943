// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.value-add-list > form > .input {
  flex-grow: 1;
}
.value-add-list .value-add-list-item > span {
  flex-grow: 1;
}
.value-add-list .value-add-list-item > svg {
  width: 14px;
  height: 14px;
  cursor: pointer;
  fill: var(--theme-color-grey-medium);
}`, "",{"version":3,"sources":["webpack://./src/sg-react/data/ValueAddList/index.scss"],"names":[],"mappings":"AAEQ;EACI,YAAA;AADZ;AAMQ;EACI,YAAA;AAJZ;AAOQ;EACI,WAAA;EACA,YAAA;EACA,eAAA;EACA,oCAAA;AALZ","sourcesContent":[".value-add-list {\n    & > form {\n        & > .input {\n            flex-grow: 1;\n        }\n    }\n\n    & .value-add-list-item {\n        & > span {\n            flex-grow: 1;\n        }\n\n        & > svg {\n            width: 14px;\n            height: 14px;\n            cursor: pointer;\n            fill: var(--theme-color-grey-medium);\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
