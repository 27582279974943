import { DirectoryItem } from "../../../models/directory-item";
import { WizardContentComponentProps } from "../../../sg-react/ui/Wizard";
import DirectoryItemCreateDuplicates from "./DirectoryItemCreateDuplicates";

const DirectoryItemCreateDuplicatesIdentification = (props: WizardContentComponentProps<DirectoryItem>) => (
    <DirectoryItemCreateDuplicates
        endpoint="/directory/search-by-name-or-business-id/global"
        params={{ name: props.entity.name, localBusinessId: props.entity.localBusinessId }}
        {...props}
    />
);

export default DirectoryItemCreateDuplicatesIdentification;