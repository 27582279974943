import { DirectoryItem } from "../../../models/directory-item";
import { Checkbox } from "../../../sg-react/form";
import { WizardContentComponentProps } from "../../../sg-react/ui/Wizard";

const DirectoryItemCreatePrivacy = ({ entity, attachInput }: WizardContentComponentProps<DirectoryItem>) => {
    return (
        <div className="col">
            <Checkbox label="allowImport" i18n="directory" {...attachInput('permissions.allowImport')} />
            <Checkbox label="allowSg" i18n="directory" {...attachInput('permissions.allowSg')} />
        </div>
    )
}
export default DirectoryItemCreatePrivacy;