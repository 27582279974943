// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.directory-items-small-list {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.directory-items-small-list .directory-items-small-list-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
  gap: 12px;
}
.directory-items-small-list .directory-items-small-list-item > svg {
  width: 18px;
  height: 18px;
  fill: var(--theme-label-color);
  flex-shrink: 0;
}`, "",{"version":3,"sources":["webpack://./src/components/DirectoryItemsList/index.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,SAAA;AACJ;AACI;EACI,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,gBAAA;EACA,SAAA;AACR;AACQ;EACI,WAAA;EACA,YAAA;EACA,8BAAA;EACA,cAAA;AACZ","sourcesContent":[".directory-items-small-list {\n    display: flex;\n    flex-direction: column;\n    gap: 12px;\n\n    & .directory-items-small-list-item {\n        display: flex;\n        flex-direction: row;\n        align-items: center;\n        overflow: hidden;\n        gap: 12px;\n\n        & > svg {\n            width: 18px;\n            height: 18px;\n            fill: var(--theme-label-color);\n            flex-shrink: 0;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
