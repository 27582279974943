import { useEffect, useState } from 'react';
import { areSameDay, getWeekdaysList } from '../../utils/date';

interface CalendarDateProps {
    day: Date;
    selectedDate: Date;
    onClick: (day: Date) => void;
}

const CalendarDate = ({
    day,
    selectedDate,
    onClick,
}: CalendarDateProps) => {
    return (
        <div
            onClick={() => onClick(day)}
            className={
                (areSameDay(day, new Date()) ? 'calendar-day-today ' : '')
                + (areSameDay(day, selectedDate) ? 'calendar-day-selected ' : '')
                + (day.getMonth() !== selectedDate.getMonth() ? 'calendar-day-not-selected-month ' : '')
            }
        >
            {day.getDate()}
        </div>
    );
}

interface CalendarProps {
    value: Date;
    min?: Date;
    max?: Date;
    weekDayStart?: number;
    onDayClick: (day: Date) => void;
}

const Calendar = ({
    value,
    min,
    max,
    weekDayStart,
    onDayClick,
}: CalendarProps) => {
    const [weekdays, setWeekdays] = useState<string[]>([]);
    const [displayedDays, setDisplayedDays] = useState<Date[]>([]);

    const getDisplayedDays = (d: Date) => {
        const _displayedDays: Date[] = Array
            .from({ length: new Date(d.getFullYear(), d.getMonth() + 1, 0).getDate() }, (_, i) => new Date(d.getFullYear(), d.getMonth(), i + 1));

        const previousMonthCursor = new Date(d.getFullYear(), d.getMonth(), 0);
        const nextMonthCursor = new Date(d.getFullYear(), d.getMonth() + 1, 1);

        let guard = 0;
        // Add previous month days
        while (_displayedDays[0].getDay() !== (weekDayStart ?? 0) && guard < 10) {
            _displayedDays.unshift(new Date(previousMonthCursor.getTime()));
            previousMonthCursor.setDate(previousMonthCursor.getDate() - 1);
            guard++;
        }

        guard = 0;
        // Add next month days
        while (_displayedDays[_displayedDays.length - 1].getDay() !== ((weekDayStart ?? 0) + 6) % 7 && guard < 10) {
            _displayedDays.push(new Date(nextMonthCursor.getTime()));
            nextMonthCursor.setDate(nextMonthCursor.getDate() + 1);
            guard++
        }
        setDisplayedDays(_displayedDays);
    }

    useEffect(() => {
        getDisplayedDays(value);
    }, [value]);

    useEffect(() => {
        setWeekdays(getWeekdaysList(weekDayStart ?? 0, 'long'));
    }, [weekDayStart]);

    return (
        <div className="calendar">
            <div className="calendar-week">
                {weekdays.map((weekday, i) => <div key={i}>{weekday[0].toUpperCase()}</div>)}
            </div>
            <div className="calendar-days">
                {displayedDays.map(day => (
                    <CalendarDate
                        key={day.toISOString()}
                        selectedDate={value}
                        onClick={onDayClick}
                        day={day}
                    />
                ))}
            </div>
        </div>
    );
}

export default Calendar;
