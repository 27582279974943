import { Specification } from "../../models/specification";
import { EntitySearch } from "../../sg-react/data";
import TagsList from "../../sg-react/data/TagsList";

interface SpecificationSearchProps {
    multiple?: boolean;
    selected?: Specification[];
    onSubmit: (selected: Specification[]) => void;
    onClose: () => void;
}

const SpecificationSearch = ({ multiple, selected, onClose, onSubmit }: SpecificationSearchProps) => {
    return (
        <EntitySearch<Specification>
            endpoint="/specifications/search"
            onClose={onClose}
            onSubmit={onSubmit}
            multiple={multiple}
            selected={selected}
            primaryKey="_id"
            columns={[
                { field: 'name', label: 'name', i18n: 'entity' },
                { field: 'description', label: 'description', i18n: 'entity' },
                { field: 'tags', label: 'tags', i18n: 'ui', display: (e) => <TagsList tagsIds={e.tagsIds} /> },
            ]}
            sort={{ field: "name", direction: 1 }}
        />
    )
}
export default SpecificationSearch;