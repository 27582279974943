// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.menu-context-menu {
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  gap: 8px;
  padding: 0 8px 4px 8px;
}`, "",{"version":3,"sources":["webpack://./src/sg-react/ui/MenuContextMenu/index.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;EACA,cAAA;EACA,QAAA;EACA,sBAAA;AACJ","sourcesContent":[".menu-context-menu {\n    display: flex;\n    flex-direction: row;\n    flex-shrink: 0;\n    gap: 8px;\n    padding: 0 8px 4px 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
