import { useCallback, useMemo, useState } from 'react';
import './index.scss';

interface LinearDialProps {
    min: number;
    max: number;
    value: number;
    label?: string;
    inverted?: boolean;
}

const LinearDial = ({ min, max, value, inverted }: LinearDialProps) => {
    const [dimensions, setDimensions] = useState<{ width: number; height: number; }>({ width: 0, height: 0 });

    const cursor = useMemo(() => min !== max ? dimensions.width * (value - min) / (max - min) : 0, [dimensions, min, max, value]);

    const onResize = useCallback((ref: HTMLDivElement) => {
        if (ref !== null) {
            setDimensions(ref.getBoundingClientRect());
        }
    }, []);

    return (
        <div ref={onResize} className={`linear-dial ${inverted ? 'linear-dial-inverted' : ''}`}>
            <div className="linear-dial-dial" />
            <div className="linear-dial-cursor" style={{ left: cursor + 'px' }} />
        </div>
    )
}
export default LinearDial;