import { ReactNode } from "react";
import './index.scss';

interface SoftContainerProps {
    children: ReactNode;
    className?: string
    onClick?: () => void;
}

const SoftContainer = ({ children, className, onClick }: SoftContainerProps) => <div onClick={onClick} className={`soft-container ${className ?? ''}`}>{children}</div>

export default SoftContainer;