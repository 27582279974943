import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Switch } from '../../form';
import { WarningIcon } from '../../icons';
import { getStringValue, translateLabel } from '../../utils/format';
import { getNestedField, objectEquals } from '../../utils/objects';
import DataContainerList from '../DataContainerList';
import './index.scss';

interface RevisionTableDatum {
    field: string;
    label?: string;
    value: JSX.Element | string | undefined;
    change: JSX.Element | string | undefined;
    hasChanged: boolean
}

export interface RevisionTableField<T> {
    field: string;
    label?: string;
    i18n?: string;
    display?: ((e: T) => JSX.Element | string | undefined);
    comparator?: (e1: T, e2: Partial<T>) => boolean

}

interface RevisionTableProps<T> {
    entity: T;
    revision: T;
    fields: RevisionTableField<T>[];
    onSelectFields?: (f: string[]) => void;
}

const RevisionTable = <T,>({ entity, revision, fields, onSelectFields }: RevisionTableProps<T>) => {
    const { t } = useTranslation();
    const [showAll, setShowAll] = useState<boolean>(false);

    const data: RevisionTableDatum[] = useMemo(() => fields.map(field => {
        const value = field.display ? field.display(entity) : getStringValue(getNestedField(entity, field.field));
        const change = field.display ? field.display(revision) : getStringValue(getNestedField(revision, field.field));
        return {
            field: field.field,
            label: translateLabel(field.label ?? field.field, t, field.i18n),
            value,
            change,
            hasChanged: field.comparator
                ? field.comparator(entity, revision)
                : !objectEquals(getNestedField(entity, field.field), getNestedField(revision, field.field))
        };
    }), [entity, revision, fields]);

    const dataFiltered = useMemo(() => showAll ? data : data.filter(datum => datum.hasChanged), [data, showAll]);

    return (
        <div className="revision-table col">
            <div className="row row-end">
                <Switch inline id="show-all" items={[{ key: true, label: 'All' }, { key: false, label: 'Updated' }]} value={showAll} onChange={(v) => setShowAll(!!v)} />
            </div>
            <DataContainerList<RevisionTableDatum>
                primaryKey="field"
                data={dataFiltered}
                onSelectMultiple={onSelectFields ? (selected) => onSelectFields(selected.map(s => s.field)) : undefined}
                columns={[
                    { field: 'label' },
                    { field: 'value', label: 'current_value', i18n: 'data', display: (e) => e.value },
                    { field: 'change', label: 'new_value', i18n: 'data', display: (e) => e.change },
                    { field: 'hasChanged', display: (e) => e.hasChanged ? <WarningIcon className="fill-warning" /> : undefined },
                ]}
            />
        </div>
    )
}
export default RevisionTable;