// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.info {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  font-size: var(--theme-label-font-size);
}
.info > span {
  white-space: pre-line;
}
.info > svg {
  width: 20px;
  height: 20px;
  fill: var(--theme-label-color);
  flex-shrink: 0;
}
.info.info-warning {
  color: var(--theme-color-warning);
}
.info.info-warning > svg {
  fill: var(--theme-color-warning);
}
.info.info-error {
  color: var(--theme-color-error);
}
.info.info-error > svg {
  fill: var(--theme-color-error);
}
.info.info-success {
  color: var(--theme-color-success);
}
.info.info-success > svg {
  fill: var(--theme-color-success);
}`, "",{"version":3,"sources":["webpack://./src/sg-react/ui/Info/index.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,QAAA;EACA,uCAAA;AACJ;AACI;EACI,qBAAA;AACR;AAEI;EACI,WAAA;EACA,YAAA;EACA,8BAAA;EACA,cAAA;AAAR;AAGI;EACI,iCAAA;AADR;AAGQ;EACI,gCAAA;AADZ;AAII;EACI,+BAAA;AAFR;AAIQ;EACI,8BAAA;AAFZ;AAKI;EACI,iCAAA;AAHR;AAKQ;EACI,gCAAA;AAHZ","sourcesContent":[".info {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    gap: 8px;\n    font-size: var(--theme-label-font-size);\n\n    & > span {\n        white-space: pre-line;\n    }\n\n    & > svg {\n        width: 20px;\n        height: 20px;\n        fill: var(--theme-label-color);\n        flex-shrink: 0;\n    }\n\n    &.info-warning {\n        color: var(--theme-color-warning);\n\n        & > svg {\n            fill: var(--theme-color-warning);\n        }\n    }\n    &.info-error {\n        color: var(--theme-color-error);\n\n        & > svg {\n            fill: var(--theme-color-error);\n        }\n    }\n    &.info-success {\n        color: var(--theme-color-success);\n\n        & > svg {\n            fill: var(--theme-color-success);\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
