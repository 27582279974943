// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.data-component-list {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.data-component-list > .list {
  flex-grow: 1;
}
.data-component-list > .data-component-list-actions {
  align-items: center;
  justify-content: flex-end;
}
.data-component-list > .data-component-list-actions > .input {
  flex-grow: 1;
}
.data-component-list > .data-component-list-actions .button {
  padding: 4px;
}
.data-component-list .data-component-list-more {
  display: flex;
  flex-direction: row;
  align-items: center;
  border: none;
  justify-content: center;
  cursor: pointer;
  font-size: 11px;
  gap: 12px;
}
.data-component-list .data-component-list-more > svg {
  width: 14px;
  height: 14px;
  fill: var(--theme-label-color);
  flex-shrink: 0;
}`, "",{"version":3,"sources":["webpack://./src/sg-react/data/DataComponentList/index.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,SAAA;AACJ;AACI;EACI,YAAA;AACR;AAEI;EACI,mBAAA;EACA,yBAAA;AAAR;AAEQ;EACI,YAAA;AAAZ;AAGQ;EACI,YAAA;AADZ;AAII;EACI,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,YAAA;EACA,uBAAA;EACA,eAAA;EACA,eAAA;EACA,SAAA;AAFR;AAIQ;EACI,WAAA;EACA,YAAA;EACA,8BAAA;EACA,cAAA;AAFZ","sourcesContent":[".data-component-list {\n    display: flex;\n    flex-direction: column;\n    gap: 12px;\n\n    & > .list {\n        flex-grow: 1;\n    }\n\n    & > .data-component-list-actions {\n        align-items: center;\n        justify-content: flex-end;\n\n        & > .input {\n            flex-grow: 1;\n        }\n\n        & .button {\n            padding: 4px;\n        }\n    }\n    & .data-component-list-more {\n        display: flex;\n        flex-direction: row;\n        align-items: center;\n        border: none;\n        justify-content: center;\n        cursor: pointer;\n        font-size: 11px;\n        gap: 12px;\n\n        & > svg {\n            width: 14px;\n            height: 14px;\n            fill: var(--theme-label-color);\n            flex-shrink: 0;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
