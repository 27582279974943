import { useCallback } from "react";
import { SEGMENTATION_VALIDATION, Segmentation } from "../../models/segmentation";
import { useRequest } from "../../sg-react/context";
import { ColorPicker, TextArea, TextField } from "../../sg-react/form";
import { useForm } from "../../sg-react/hooks";
import { Modal } from "../../sg-react/ui";
import LabelValue from "../../sg-react/ui/LabelValue";
import Tag from "../../sg-react/ui/Tag";

interface SegmentationFormProps {
    workspaceId: string;
    segmentation: Partial<Segmentation>;
    onSubmit: () => void;
    onClose: () => void;
}

const SegmentationForm = ({ workspaceId, segmentation, onSubmit, onClose }: SegmentationFormProps) => {
    const { entity, attachInput, validate } = useForm<Segmentation>(segmentation, SEGMENTATION_VALIDATION);
    const request = useRequest();

    const createOrUpdate = useCallback(() => validate((entity) => {
        const requestMethod = !entity._id ? request.post : request.put;

        requestMethod(`/segmentations`, entity, {
            loader: true,
            withWorkspace: workspaceId,
            successMessage: true,
            errorMessage: true,
        })
            .then(() => {
                onSubmit();
                onClose();
            })
            .catch(() => null);
    }), [request, validate, onSubmit, onClose, workspaceId]);

    return (
        <Modal
            size="small"
            i18n="actions"
            title={entity._id ? 'edit' : 'create'}
            onClose={onClose}
            onSubmit={createOrUpdate}
            overflow="visible"
        >
            {!!entity.parent && (
                <LabelValue
                    inline
                    label="parent"
                    i18n="segmentations"
                    value={<Tag color={entity.parent.color} label={entity.parent.name} />}
                />
            )}
            <TextField label i18n="entity" {...attachInput('name')} />
            <TextArea label i18n="entity" rows={5} {...attachInput('description')} />
            <ColorPicker label i18n="entity" {...attachInput('color')} />
        </Modal>
    );
}

export default SegmentationForm;