import { createContext, useCallback, useContext, useEffect, useMemo, useReducer, useState } from "react";
import { DirectoryMap } from "../../../models/directory-item";
import { useRequest } from "../../../sg-react/context";
import { useWorkspaceContext } from "../../../sg-react/context/WorkspaceContext";
import { MainMapLayer, MapContextType, MapFilters, MapPanels, MapPlan, MapPlanResults, MapProviderProps, MapResults, MapState } from "../types";
import { mapReducer } from "../utils";

export const MAP_SCHEMA_VERSION = 3;
export const DEFAULT_FILTERS: MapFilters = { name: 'Step', color: '#ffffff', stepId: 1, schemaVersion: MAP_SCHEMA_VERSION }
export const DEFAULT_PLAN: MapPlan = { schemaVersion: MAP_SCHEMA_VERSION, steps: [DEFAULT_FILTERS] }

export const MapContext = createContext<MapContextType>({
    filters: DEFAULT_FILTERS,
    setFilters: () => null,
    results: null,
    getResults: () => null,
    plan: DEFAULT_PLAN,
    setPlan: () => null,
    addStep: () => null,
    deleteStep: () => null,
    currentStepId: 1,
    changeCurrentStep: () => null,
    planResults: null,
    getPlanResults: () => null,
    panels: {},
    togglePanel: () => null,
    setLayer: () => null,
});

const INITIAL_MAP_STATE: MapState = {
    plan: DEFAULT_PLAN,
    currentStepId: 1,
    panels: { filters: true, steps: false, results: false, layers: false }
}

const MapProvider = ({ children }: MapProviderProps) => {
    const { currentWorkspace } = useWorkspaceContext();
    const [state, dispatch] = useReducer(mapReducer, INITIAL_MAP_STATE);
    const [results, setResults] = useState<MapResults | null>(null);
    const [planResults, setPlanResults] = useState<MapPlanResults[] | null>(null);
    const request = useRequest();

    const filters = useMemo(() => state.plan.steps.find(s => s.stepId === state.currentStepId), [state.plan, state.currentStepId]);

    const setFilters = useCallback((filters?: Partial<MapFilters>, reset?: boolean) => dispatch({ type: 'filters', payload: { filters, reset } }), [dispatch]);
    const setPlan = useCallback((plan: MapPlan) => dispatch({ type: 'plan', payload: { plan } }), [dispatch]);
    const addStep = useCallback((filters: Partial<MapFilters>) => dispatch({ type: 'addStep', payload: { filters } }), [dispatch]);
    const deleteStep = useCallback((stepId: number) => dispatch({ type: 'deleteStep', payload: { stepId } }), [dispatch]);
    const changeCurrentStep = useCallback((stepId: number) => dispatch({ type: 'currentStep', payload: { stepId } }), [dispatch]);
    const togglePanel = useCallback((panel: keyof MapPanels) => dispatch({ type: 'togglePanel', payload: { panel } }), [dispatch]);
    const setLayer = useCallback((layer?: MainMapLayer) => dispatch({ type: 'layer', payload: { layer } }), [dispatch]);

    const getResults = useCallback((f?: MapFilters) => {
        const _filters = (f ?? filters);
        if (!_filters) return null;

        request.get<DirectoryMap>('/directory/map-search',
            {
                params: _filters,
                withWorkspace: true,
                loader: true
            }
        )
            .then((data) => {
                setPlanResults([]);
                setResults({ ..._filters, data });
            })
            .catch(() => {
                setPlanResults([]);
                setResults(null);
            });
    }, [filters, request]);

    const getPlanResults = useCallback(() => {
        request.get<MapPlanResults[]>('/directory/map-plan',
            {
                params: { plan: state.plan.steps },
                withWorkspace: true,
                loader: true
            }
        )
            .then((data) => {
                setPlanResults(data);
                setResults(null);
            })
            .catch(() => {
                setPlanResults([]);
                setResults(null);
            });
    }, [state.plan, request]);

    useEffect(() => {
        setResults(null);
        setPlanResults(null);

        try {
            const stateFromStorage: MapState = JSON.parse(localStorage.getItem(`sg-map-state-${MAP_SCHEMA_VERSION}-${currentWorkspace._id}`) ?? '');
            dispatch({ type: 'state', payload: { state: stateFromStorage } });
            getResults(stateFromStorage.plan.steps[0]);
        } catch {
            dispatch({ type: 'state', payload: { state: INITIAL_MAP_STATE } });
            getResults(INITIAL_MAP_STATE.plan.steps[0]);
        }
    }, [currentWorkspace._id]);

    useEffect(() => {
        localStorage.setItem(`sg-map-state-${MAP_SCHEMA_VERSION}-${currentWorkspace._id}`, JSON.stringify(state));
    }, [state]);

    if (!filters) return null;

    return (
        <MapContext.Provider value={{
            filters,
            ...state,
            results,
            getResults,
            planResults,
            getPlanResults,
            setFilters,
            setPlan,
            addStep,
            deleteStep,
            changeCurrentStep,
            togglePanel,
            setLayer

        }}>
            {children}
        </MapContext.Provider>
    );
};

const useMapContext = () => {
    return useContext(MapContext);
};

export { MapProvider, useMapContext };
export default MapContext;