import { ReactNode } from 'react';
import Dropdown from '../DropdownLegacy';
import Menu, { MenuProps } from '../Menu';

interface DropdownMenuProps extends MenuProps {
    children: ReactNode;
    disposition?: 'right' | 'left';
    openOnHover?: boolean;
    onAction?: (k: string) => void;
}

const DropdownMenu = ({ openOnHover, disabledKeys, onAction, children, items, disposition }: DropdownMenuProps) => (
    <Dropdown disposition={disposition} closeOnClick openOnHover={openOnHover} dropdown={
        <Menu disabledKeys={disabledKeys} onAction={onAction} items={items} disposition={disposition} />
    }>
        {children}
    </Dropdown>
);

export default DropdownMenu;