import { useCallback, useEffect, useRef, useState } from "react";
import { Specification } from "../../../models/specification";
import { useRequest } from "../../../sg-react/context";
import { Search } from "../../../sg-react/data";
import { SearchItem } from "../../../sg-react/data/Search";
import TagsList from "../../../sg-react/data/TagsList";
import { UseFocusHandleRef } from "../../../sg-react/hooks";
import { useDropdown } from "../../../sg-react/ui/Dropdown";
import List from "../../../sg-react/ui/List";
import ValueDescription from "../../../sg-react/ui/ValueDescription";

interface MainMapFilterSpecificationsProps {
    specificationsIds?: string[];
    onChange: (specificationsIds?: string[]) => void;
}

const MainMapFilterSpecifications = ({ specificationsIds, onChange }: MainMapFilterSpecificationsProps) => {
    const request = useRequest();
    const focusHandle = useRef<UseFocusHandleRef | null>(null);
    const { visible } = useDropdown();
    const [results, setResults] = useState<Specification[] | null>(null);
    const [specifications, setSpecifications] = useState<Specification[]>([]);

    const get = useCallback(async (specificationsIds: string[]) => {
        request.get<Specification[]>(`/specifications/multiple`, { params: { specificationsIds }, withWorkspace: true })
            .then(setSpecifications)
            .catch(() => null);
    }, [request]);

    const getResults = useCallback(async (keyword: string) => {
        request.get<Specification[]>(`/specifications/search`, { params: { keyword }, withWorkspace: true, errorMessage: true })
            .then(setResults)
            .catch(() => null);
    }, [request]);

    const clearSearch = useCallback(() => {
        setResults(null);
    }, []);

    const handleAddSpecification = useCallback(async (specification: Specification) => {
        if (specificationsIds?.includes(specification._id)) return;
        onChange([...(specificationsIds ?? []), specification._id]);
        setSpecifications((specifications) => [...specifications, specification]);
        clearSearch();
    }, [onChange, clearSearch, specificationsIds]);

    const handleRemoveSpecification = useCallback(async (specification: Specification) => {
        if (!specificationsIds?.includes(specification._id)) return;
        onChange(specificationsIds?.filter(_id => _id !== specification._id));
        setSpecifications((specifications) => specifications.filter(p => p._id !== specification._id));
    }, [onChange, specificationsIds]);

    useEffect(() => {
        if (visible) focusHandle.current?.focus();
    }, [visible]);

    useEffect(() => {
        const toFetch = specificationsIds?.filter(_id => !specifications.some(p => p._id === _id));
        if (toFetch?.length) {
            get(specificationsIds ?? []);
        } else {
            setSpecifications(specifications => specifications.filter(s => specificationsIds?.includes(s._id)))
        }
    }, [specificationsIds]);

    return (
        <div className="col">
            <Search
                id="specifications"
                onClear={clearSearch}
                onChange={getResults}
                focusHandle={focusHandle}
            >
                {!!results?.length && results.map(specification => <SearchItem key={specification._id} label={specification.name} description={specification.description} onClick={() => handleAddSpecification(specification)} />)}
            </Search>
            {!!specifications?.length && (
                <List>
                    {specifications.map((specification) => (
                        <List.Item key={specification._id} onRemove={() => handleRemoveSpecification(specification)}>
                            <ValueDescription
                                value={specification.name}
                                description={specification.description}
                                tags={<TagsList tagsIds={specification.tagsIds} />}
                            />
                        </List.Item>
                    ))}
                </List>
            )
            }
        </div >
    );
}

export default MainMapFilterSpecifications;