import { useCallback } from "react";
import Checkbox from "../Checkbox";
import InputWrapper, { FormPropsInterface } from "../InputWrapper";
import './index.scss';

interface RadioItem<T> {
    key: T;
    label?: string;
}

interface RadioProps<T> extends FormPropsInterface<T> {
    label?: string,
    items: RadioItem<T>[],
    className?: string,
}

const Radio = <T,>({
    value,
    items,
    disabled,
    onChange,
    ...rest
}: RadioProps<T>) => {

    const handleChange = useCallback((v: T) => {
        if (disabled || !onChange) return;
        onChange(v as any);
    }, [onChange, disabled]);

    return (
        <InputWrapper type="radio" {...rest}>
            {items.map(i => (
                <Checkbox
                    key={String(i.key)}
                    label={String(i.label ?? i.key)}
                    value={value === i.key}
                    onChange={() => handleChange(i.key)}
                    id={String(i.key)}
                    i18n={rest.i18n}
                />
            ))}
        </InputWrapper>
    )
}

export default Radio;