import { useCallback } from "react";
import { DIRECTORY_ITEM_CONTACTS_VALIDATION, DIRECTORY_ITEM_INFORMATION_VALIDATION, DirectoryItem } from "../../models/directory-item";
import { useRequest } from "../../sg-react/context";
import { ObjectValidation } from "../../sg-react/form/Validation/class";
import { LOCATION_VALIDATION } from "../../sg-react/models/location";
import { Modal } from "../../sg-react/ui";
import Wizard, { WizardStep } from "../../sg-react/ui/Wizard";
import DirectoryItemCreateData from "../DirectoryItemCreate/components/DirectoryItemCreateData";
import DirectoryItemCreateLocation from "../DirectoryItemCreate/components/DirectoryItemCreateLocation";
import DirectoryItemCreatePrivacy from "../DirectoryItemCreate/components/DirectoryItemCreatePrivacy";
import DirectoryItemEditSummary from "./components/DirectoryItemEditSummary";
import './index.scss';

const STEPS: WizardStep<DirectoryItem>[] = [{
    label: 'creation.data',
    tooltip: 'creation.data_text',
    i18n: 'directory',
    validation: new ObjectValidation({ ...DIRECTORY_ITEM_INFORMATION_VALIDATION, ...DIRECTORY_ITEM_CONTACTS_VALIDATION }),
    component: DirectoryItemCreateData,
}, {
    label: 'creation.location',
    tooltip: 'creation.location_text',
    i18n: 'directory',
    validation: new ObjectValidation({ location: LOCATION_VALIDATION }),
    component: DirectoryItemCreateLocation,
}, {
    label: 'creation.privacy',
    tooltip: 'creation.privacy_text',
    i18n: 'directory',
    component: DirectoryItemCreatePrivacy,
}, {
    label: 'creation.summary',
    tooltip: 'creation.summary_text',
    i18n: 'directory',
    component: DirectoryItemEditSummary,
}];

interface DirectoryItemEditProps {
    directoryItem: DirectoryItem;
    onClose: () => void;
}

const DirectoryItemEdit = ({ directoryItem, onClose }: DirectoryItemEditProps) => {
    const request = useRequest();

    const handleSubmit = useCallback((entity: Partial<DirectoryItem>, hasChanged: boolean) => {
        if (!hasChanged) {
            onClose();
            return;
        }
        request.put<DirectoryItem>('/directory', { ...entity, parents: undefined }, { withWorkspace: true, errorMessage: true, successMessage: true, loader: true })
            .then(() => onClose())
            .catch(() => null);
    }, [request, onClose]);

    return (
        <Modal
            id="directory-item-create"
            i18n="directory"
            title="create"
            overflow="hidden"
        >
            <Wizard steps={STEPS} initialMaxStep={STEPS.length - 1} initial={directoryItem} onSubmit={handleSubmit} onClose={onClose} />
        </Modal>
    )
}
export default DirectoryItemEdit;