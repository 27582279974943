import { Card } from '../../sg-react/ui';
import Collapse from '../../sg-react/ui/Collapse';
import ValueDescription from '../../sg-react/ui/ValueDescription';
import { useMapContext } from '../MainMap/components/MapContext';
import DirectoryItemsEmissions from './components/DirectoryItemsEmissions';
import { PlanTree } from './components/PlanTree';
import QualifiersEmissions from './components/QualifiersEmissions';
import './index.scss';

interface PlanAnalysisProps {

}

const PlanAnalysis = ({ }: PlanAnalysisProps) => {
    return (
        <div id="plan-analysis" className="layout-container layout-container-scroll row row-layout">
            <div className="col col-layout col-lg-30">
                <PlanTree />
            </div>
            <div className="col col-layout col-lg-70">
                <QualifiersEmissions />
                <DirectoryItemsEmissions />
            </div>
        </div>
    )
}
export default PlanAnalysis;