import { Link, useNavigate } from 'react-router-dom';
import './index.scss';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { HomeIcon } from '../../icons';
import Button from '../Button';
import { translateLabel } from '../../utils/format';

export type Breadcrumb = { label: string, i18n?: string, href?: string };

export interface BreadcrumbsProps {
    breadcrumbs: Breadcrumb[];
}

const Breadcrumbs = ({
    breadcrumbs
}: BreadcrumbsProps) => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    return (
        <div id="breadcrumbs" className="sm-hide">
            <Button icon={<HomeIcon />} color="navigation" onClick={() => navigate('/')} />
            <div className="breadcrumb-delimiter" />
            {breadcrumbs.map((breadcrumb, i) => {
                return i === breadcrumbs.length - 1
                    ? <Button color="navigation" active={true} key={i} label={translateLabel(breadcrumb.label, t, breadcrumb.i18n)} />
                    : (
                        <Fragment key={i}>
                            <Button color="navigation" active={false} onClick={() => navigate(breadcrumb.href ?? '.')} key={i} label={translateLabel(breadcrumb.label, t, breadcrumb.i18n)} />
                            <div className="breadcrumb-delimiter" />
                        </Fragment>
                    );
            }
            )}
        </div>
    );
}

export default Breadcrumbs;