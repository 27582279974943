import { MouseEvent, ReactNode, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { translateLabel } from '../../utils/format';
import { conditionnalClassnames } from '../../utils/helpers';
import Spinner from '../Spinner';
import './index.scss';
import { Colors } from '../enums';

export interface ButtonProps {
    label?: string;
    icon?: ReactNode;
    className?: string;
    i18n?: string;
    onClick?: () => void;
    onIconClick?: () => void;
    disabled?: boolean;
    loading?: boolean;
    type?: 'button' | 'submit';
    color?: Colors | 'navigation' | 'disabled';
    iconPosition?: 'left' | 'right';
    active?: boolean;
    small?: boolean;
}

const Button = ({
    label,
    icon,
    className,
    i18n,
    onClick,
    onIconClick,
    disabled,
    loading,
    type,
    small,
    color,
    active,
    iconPosition
}: ButtonProps) => {
    const { t } = useTranslation();

    const handleIconClick = useCallback((e: MouseEvent) => {
        if (!onIconClick || !!disabled) return;

        e.stopPropagation();
        onIconClick();
    }, [onIconClick, disabled]);

    return (
        <button
            type={type ?? 'button'}
            className={conditionnalClassnames({
                button: true,
                [`button-${color}`]: color === 'navigation' || color === 'disabled',
                [`background-color-${color}`]: true,
                "button-inactive": active === false,
                "button-active": active === true,
                small,
                disabled: disabled,
                className
            })}
            onClick={!disabled && !loading && type !== 'submit' ? onClick : undefined}
        >
            {!!icon && iconPosition === 'left' && <div onClick={handleIconClick} className="button-icon">{icon}</div>}
            {!!label && <span>{translateLabel(label, t, i18n)}</span>}
            {!!icon && !loading && iconPosition !== 'left' && <div onClick={handleIconClick} className="button-icon">{icon}</div>}
            {loading && <Spinner small />}
        </button >
    );
}

export default Button;