import { ReactNode, useCallback, useEffect, useRef } from "react";
import { useOutsideClick } from "../../hooks";
import './index.scss';

interface FixedWrapperProps {
    children: ReactNode;
    visible?: boolean;
    onClose: () => void;
}

const FixedWrapper = ({ children, visible, onClose }: FixedWrapperProps) => {
    const ref = useOutsideClick(() => onClose());
    const containerRef = useRef<HTMLDivElement>(null);

    const setFixedPosition = useCallback((visible?: boolean) => {
        if (containerRef.current && ref.current) {
            if (visible) {
                const bbox = containerRef.current.getBoundingClientRect();
                const bboxRef = ref.current.getBoundingClientRect();

                if (bbox.top + bboxRef.height > window.innerHeight) {
                    ref.current.style.top = (bbox.top - bbox.height - bboxRef.height) + 'px';
                    ref.current.style.left = bbox.left + 'px';
                } else {
                    ref.current.style.top = bbox.top + 'px';
                    ref.current.style.left = bbox.left + 'px';
                }
            } else {
                ref.current.style.top = 'unset';
                ref.current.style.left = 'unset';
                ref.current.style.bottom = 'unset';
                ref.current.style.right = 'unset';
            }
        }
    }, []);

    useEffect(() => {
        setFixedPosition(visible);

        const listener = () => setFixedPosition(visible);

        window.addEventListener('resize', listener);

        return () => {
            window.removeEventListener('resize', listener);
        }
    }, [visible]);

    return (
        <div className="fixed-wrapper" ref={containerRef}>
            <div className="fixed-wrapper-backdrop" style={visible ? { display: 'block' } : { display: 'none' }} />
            <div className="fixed-wrapper-children" ref={ref}>
                {visible && children}
            </div>
        </div>
    )
}

export default FixedWrapper;