import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import DirectoryItemMarker from '../../components/DirectoryItemMarker';
import DirectoryItemsList from '../../components/DirectoryItemsList';
import SpecificationWizard from '../../components/SpecificationWizard';
import { DirectoryItem } from '../../models/directory-item';
import { Specification } from '../../models/specification';
import { useAuthContext, useMenuContext, useRequest, useWorkspaceContext } from '../../sg-react/context';
import { Map } from '../../sg-react/data';
import TagsList from '../../sg-react/data/TagsList';
import { WorkspaceRole } from '../../sg-react/models/workspace';
import { Card, Nothing } from '../../sg-react/ui';
import { Action } from '../../sg-react/utils/enums';
import './index.scss';

export interface SpecificationViewComponentsProps {
    refresh: () => void;
    specification: Specification;
    canEdit: boolean;
}

const SpecificationView = () => {
    const [specification, setSpecification] = useState<Specification | null>(null);
    const [directoryItems, setDirectoryItems] = useState<DirectoryItem[]>([]);
    const [isWizardVisible, setWizardVisible] = useState<boolean>(false);
    const { currentWorkspace } = useWorkspaceContext();
    const { currentUser } = useAuthContext();
    const request = useRequest();
    const [selectedDirectoryItem, setSelectedDirectoryItem] = useState<DirectoryItem | null>(null);
    const { setCurrentItem, removeCurrentItem, action, setActionDone, setDisabledActions } = useMenuContext();
    const { _id } = useParams();
    const navigate = useNavigate();

    const canEdit = useMemo(() => currentWorkspace.role !== WorkspaceRole.User, [currentWorkspace]);

    const markers = useMemo(() => {
        return directoryItems?.map(directoryItem => (
            <DirectoryItemMarker
                key={directoryItem._id}
                longitude={directoryItem.location.longitude}
                latitude={directoryItem.location.latitude}
                directoryItem={directoryItem}
                selected={directoryItem._id === selectedDirectoryItem?._id}
            />
        ));
    }, [directoryItems, selectedDirectoryItem]);

    const get = useCallback(() => {
        request.get<{ specification: Specification, directoryItems: DirectoryItem[] }>(`/specifications/${_id}/view`, {
            withWorkspace: true,
            loader: true,
            errorMessage: true
        })
            .then((data) => {
                setSpecification(data.specification);
                setCurrentItem({ label: data.specification.name, loading: false });
                setDirectoryItems(data.directoryItems);
                setDisabledActions(canEdit ? [] : [Action.Edit]);
            })
            .catch(() => navigate(-1));
    }, [_id, request, currentWorkspace, currentUser, setCurrentItem, setDisabledActions, canEdit]);

    useEffect(() => {
        if (_id) {
            get();
        }
    }, [_id]);

    useEffect(() => {
        if (action?.action) {
            switch (action.action) {
                case Action.Close:
                    removeCurrentItem();
                    break;
                case Action.Edit:
                    setWizardVisible(true);
                    break;
                default:
                    break;
            }
            setActionDone(action.timestamp);
        }
    }, [action?.timestamp]);

    if (!specification) return null;

    return (
        <div id="specification-view" className="layout-container layout-container-scroll row row-layout">
            <div className="col col-layout col-lg-60">
                <Card id="specification-view-identification">
                    <h3>{specification.name}</h3>
                    {!!specification.description && <span>{specification.description}</span>}
                    <TagsList tagsIds={specification.tagsIds} />
                </Card>
                <Card id="specification-map">
                    <Map markers={markers} fitToMarkers="always" />
                </Card>
            </div>
            <div className="col col-layout col-lg-40">
                <Card overflow="hidden" id="specification-view-list" >
                    <DirectoryItemsList directoryItems={directoryItems} withSort withSearch onClick={setSelectedDirectoryItem} />
                    {!directoryItems?.length && <Nothing />}
                </Card>
            </div>
            {!!isWizardVisible && <SpecificationWizard specification={specification} onClose={() => setWizardVisible(false)} onSubmit={() => get()} />}
        </div >
    )
}
export default SpecificationView;