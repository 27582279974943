import { useCallback, useEffect, useState } from 'react';
import { FilterIcon, PlusIcon, SortDownIcon, SortUpIcon } from '../../icons';
import { Button } from '../../ui';
import DropdownMenu from '../../ui/DropdownMenu';
import SearchField from '../SearchField';
import './index.scss';

interface ListActionsProps<T> {
    data?: T[];
    onSearch?: (entity: T, keyword: string) => boolean;
    onAdd?: () => void;
    sort?: { field: keyof T, label: string, i18n?: string }[];
    onListUpdate: (data?: T[]) => void;
}

const ListActions = <T,>({
    data,
    onSearch,
    onAdd,
    onListUpdate,
    sort,
}: ListActionsProps<T>) => {
    const [search, setSearch] = useState<string | undefined>(undefined);
    const [currentSort, setCurrentSort] = useState<{ field: keyof T, direction: 1 | -1 } | null>(null);

    const handleSort = useCallback((field: keyof T) => {
        setCurrentSort((sort) => ({ field, direction: sort?.field === field && sort.direction === 1 ? -1 : 1 }));
    }, []);

    useEffect(() => {
        onListUpdate(data?.filter(d => !search || !onSearch || onSearch(d, search))
            .sort((d1, d2) => !currentSort ? 1 : d1[currentSort.field] < d2[currentSort.field] ? currentSort.direction : currentSort.direction * -1));
    }, [data, onSearch, search, currentSort, onListUpdate]);

    useEffect(() => {
        setSearch(undefined);
    }, [data]);

    return (
        <div className="row data-component-list-actions">
            {onSearch && <SearchField onChange={setSearch} />}
            {onAdd && <Button color="navigation" icon={<PlusIcon />} onClick={onAdd} />}
            {!!sort?.length && (
                <DropdownMenu disposition="left" items={sort.map(s => (
                    { itemKey: String(s.field), icon: currentSort?.field === s.field ? (currentSort.direction === 1 ? <SortDownIcon /> : <SortUpIcon />) : undefined, i18n: s.i18n, label: s.label, onClick: () => handleSort(s.field) }
                ))}>
                    <Button color="navigation" icon={<FilterIcon />} />
                </DropdownMenu>
            )}
        </div>
    )
}
export default ListActions;