import { useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import { Select } from '../../form';
import { CalendarIcon, LeftIcon, RightIcon } from '../../icons';
import { areSameDay, getMonthsList, getWeekdaysList } from '../../utils/date';
import { conditionnalClassnames } from '../../utils/helpers';
import { Colors } from '../enums';
import './index.scss';

interface CalendarDateProps {
    currentDate: Date,
    day: Date;
    color?: Colors;
    onClick?: (day: Date) => void;
}

const CalendarDate = ({
    currentDate,
    day,
    color,
    onClick,
}: CalendarDateProps) => (
    <div>
        <span
            onClick={onClick ? () => onClick(day) : undefined}
            className={conditionnalClassnames({
                'calendar-day': true,
                'today': areSameDay(day, new Date()),
                [`background-color-${color?.toLowerCase()}`]: color,
                'pointer': !!onClick,
                'not-same-month': day.getMonth() !== currentDate.getMonth()
            })}
        >
            {day.getDate()}
        </span>
    </div>
);

interface CalendarProps {
    initialDate?: Date;
    min?: Date;
    max?: Date;
    getDateColor?: (d: Date) => Colors | undefined;
    weekDayStart?: number;
    onMonthChange?: (date: Date) => void;
    onDateClick?: (day: Date) => void;
    hideDays?: boolean;
}

interface CalendarReducerAction {
    type: 'nextMonth' | 'previousMonth' | 'month' | 'previousYear' | 'nextYear' | 'year';
    payload?: {
        month?: number;
        year?: number;
    }
}

export const calendarReducer = (
    state: Date,
    action: CalendarReducerAction
): Date => {
    switch (action.type) {
        case 'previousMonth':
            const datePreviousMonth = new Date(state);
            datePreviousMonth.setMonth(state.getMonth() - 1);
            return datePreviousMonth;
        case 'nextMonth':
            const dateNextMonth = new Date(state);
            dateNextMonth.setMonth(state.getMonth() + 1);
            return dateNextMonth;
        case 'month':
            if (action.payload?.month === undefined) return state;

            const dateMonth = new Date(state);
            dateMonth.setMonth(action.payload.month);
            return dateMonth;
        case 'previousYear':
            const datePreviousYear = new Date(state);
            datePreviousYear.setFullYear(state.getFullYear() - 1);
            return datePreviousYear;
        case 'nextYear':
            const dateNextYear = new Date(state);
            dateNextYear.setFullYear(state.getFullYear() + 1);
            return dateNextYear;
        case 'year':
            if (action.payload?.year === undefined) return state;

            const dateYear = new Date(state);
            dateYear.setFullYear(action.payload.year);
            return dateYear;
        default:
            return state;
    }
};

const Calendar = ({
    initialDate,
    min,
    max,
    getDateColor,
    onDateClick,
    onMonthChange,
    weekDayStart,
    hideDays
}: CalendarProps) => {
    const [state, dispatch] = useReducer(calendarReducer, initialDate ?? new Date());
    const [weekdays, setWeekdays] = useState<string[]>([]);
    const [displayedDays, setDisplayedDays] = useState<Date[]>([]);
    const [isCalendarVisible, setCalendarVisible] = useState<boolean>(!hideDays);

    const months = useMemo(() => getMonthsList('short').map((m, i) => ({ key: i, label: m })), []);
    const years = useMemo(() => Array.from({ length: 40 }, (_, i) => new Date().getFullYear() + 20 - i).map(y => ({ key: y, label: String(y) })), []);

    const getDisplayedDays = useCallback((d: Date) => {
        const _displayedDays: Date[] = Array
            .from({ length: new Date(d.getFullYear(), d.getMonth() + 1, 0).getDate() }, (_, i) => new Date(d.getFullYear(), d.getMonth(), i + 1));

        const previousMonthCursor = new Date(d.getFullYear(), d.getMonth(), 0);
        const nextMonthCursor = new Date(d.getFullYear(), d.getMonth() + 1, 1);

        let guard = 0;
        // Add previous month days
        while (_displayedDays[0].getDay() !== (weekDayStart ?? 0) && guard < 10) {
            _displayedDays.unshift(new Date(previousMonthCursor.getTime()));
            previousMonthCursor.setDate(previousMonthCursor.getDate() - 1);
            guard++;
        }

        guard = 0;
        // Add next month days
        while (_displayedDays[_displayedDays.length - 1].getDay() !== ((weekDayStart ?? 0) + 6) % 7 && guard < 10) {
            _displayedDays.push(new Date(nextMonthCursor.getTime()));
            nextMonthCursor.setDate(nextMonthCursor.getDate() + 1);
            guard++
        }
        setDisplayedDays(_displayedDays);
    }, [weekDayStart]);

    useEffect(() => {
        getDisplayedDays(state);
        if (onMonthChange) onMonthChange(state);
    }, [state]);

    useEffect(() => {
        setWeekdays(getWeekdaysList(weekDayStart ?? 0, 'long'));
    }, [weekDayStart]);

    return (
        <div className="col calendar">
            <div className="calendar-actions row">
                <div className="row row-equal-cols">
                    <div className="row">
                        <LeftIcon className="icon" onClick={() => dispatch({ type: 'previousMonth' })} />
                        <Select id="month" items={months} value={state.getMonth()} onChange={(month) => dispatch({ type: 'month', payload: { month } })} />
                        <RightIcon className="icon" onClick={() => dispatch({ type: 'nextMonth' })} />
                    </div>
                    <div className="row">
                        <LeftIcon className="icon" onClick={() => dispatch({ type: 'previousYear' })} />
                        <Select id="year" items={years} value={state.getFullYear()} onChange={(year) => dispatch({ type: 'year', payload: { year } })} />
                        <RightIcon className="icon" onClick={() => dispatch({ type: 'nextYear' })} />
                    </div>
                </div>
                {!hideDays && (
                    <CalendarIcon className="icon" onClick={() => setCalendarVisible(!isCalendarVisible)} />
                )}
            </div>
            {!hideDays && !!isCalendarVisible && (
                <div className="calendar-calendar">
                    <div className="calendar-week">
                        {weekdays.map((weekday, i) => <div key={i}><span>{weekday[0].toUpperCase()}</span></div>)}
                    </div>
                    <div className="calendar-days">
                        {displayedDays.map(day => (
                            <CalendarDate
                                key={day.toISOString()}
                                currentDate={state}
                                color={getDateColor ? getDateColor(day) : undefined}
                                onClick={onDateClick}
                                day={day}
                            />
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
}

export default Calendar;
