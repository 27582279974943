import { ReactNode, createContext, useCallback, useContext, useEffect, useReducer, useState } from "react";
import { AuthenticationStatus, TokenResponse, TokenType } from "../models/auth";
import { CurrentUser } from "../models/user";
import AxiosInstance from "../services/Axios";
import { Role } from "../utils/enums";

const DEFAULT_USER = {} as CurrentUser;

interface RefreshProps {
    onToken: (t: TokenResponse) => void;
    onError: () => void;
}

const Refresh = ({ onToken, onError }: RefreshProps) => {
    const [iframeRefresh, setIframeRefresh] = useState<number>(0);
    //const qp = useMemo(() => new URLSearchParams({ r: String(iframeRefresh), audience: window.location.href, api_url: process.env.REACT_APP_AUTH_API ?? '', is_shire: String(!!isShire) }), [iframeRefresh]);

    const getAccessToken = useCallback(async () => {
        try {
            const response = await AxiosInstance.post<TokenResponse>(`${process.env.REACT_APP_API_URL}/auth/refresh`,
                {},
                { withCredentials: true }
            );
            onToken(response.data);
        } catch {
            onError();
        }
    }, [onToken, onError]);

    /*     useEffect(() => {
            window.addEventListener('message', getTokenFromIframe);
    
            return () => {
                window.removeEventListener('message', getTokenFromIframe);
            }
        }, [getTokenFromIframe]); */

    useEffect(() => {
        const interval = setInterval(() => {
            getAccessToken();
            /*             if (internal) {
                            getAccessToken();
                        } else {
                            setIframeRefresh((i) => i + 1);
                        } */
        }, 540000);

        return () => {
            clearInterval(interval);
        }
    }, []);

    /*   return <iframe
          src={`${process.env.REACT_APP_AUTH_PORTAL}/refresh.html?${qp}`}
          title="refresh" id="refresh-iframe"></iframe>; */
    return null;
}

type AuthContextProps = {
    children: ReactNode;
};

type AuthContextType = {
    currentUser: CurrentUser;
    currentRole: Role;
    token?: string;
    status: AuthenticationStatus;
    refreshUser: () => void;
    logout: () => void;
    handleToken: (t: TokenResponse) => void,
    redirectToPortal: () => void;
};

const AuthContext = createContext<AuthContextType>({
    currentUser: DEFAULT_USER,
    currentRole: Role.User,
    status: AuthenticationStatus.Unauthenticated,
    refreshUser: () => null,
    logout: () => null,
    handleToken: () => null,
    redirectToPortal: () => null,
});

export interface AuthReducerAction {
    type: "tokenResponse" | "logout" | "user";
    payload?: {
        tokenReponse?: TokenResponse;
        user?: CurrentUser;
    };
}

interface AuthReducerState {
    user: CurrentUser;
    status: AuthenticationStatus;
    role: Role;
    token?: string;
    isInit: boolean;
}

const INITIAL_REDUCER_STATE = {
    user: DEFAULT_USER,
    status: AuthenticationStatus.Unauthenticated,
    role: Role.User,
    isInit: false,
}

export const authReducer = (
    state: AuthReducerState,
    action: AuthReducerAction
): AuthReducerState => {
    switch (action.type) {
        case 'logout':
            return {
                ...INITIAL_REDUCER_STATE,
                isInit: true
            };
        case 'user':
            if (!action.payload?.user) return state;
            return {
                ...state,
                user: action.payload?.user,
                status: AuthenticationStatus.Authenticated,
                isInit: true,
            };
        case 'tokenResponse':
            if (!action.payload?.tokenReponse) return state;

            let status = state.status;

            if (action.payload.tokenReponse.type === TokenType.TwoFA) {
                status = AuthenticationStatus.TwoFactorNeeded;
            } else if (action.payload.tokenReponse.type === TokenType.Access && status !== AuthenticationStatus.Authenticated) {
                status = AuthenticationStatus.WaitingForUser;
            }

            return {
                ...state,
                token: action.payload.tokenReponse.token,
                status,
                role: action.payload.tokenReponse.tokenParsed.role ?? Role.User
            }
        default:
            return state;
    }
};


const AuthProvider = ({ children }: AuthContextProps) => {
    const [state, dispatch] = useReducer(authReducer, INITIAL_REDUCER_STATE);

    const redirectToPortal = useCallback(() => {
        window.location.replace(`${process.env.REACT_APP_AUTH_PORTAL}?${new URLSearchParams({ redirect_uri: window.location.href })}`);
    }, []);

    const logout = useCallback(() => {
        dispatch({ type: 'logout' });

        AxiosInstance.post(`${process.env.REACT_APP_AUTH_API}/auth/logout`,
            {},
            { withCredentials: true }
        ).catch(() => null);
    }, []);

    const getUser = useCallback(async (t?: string) => {
        try {
            const response = await AxiosInstance.get<CurrentUser>(
                `${process.env.REACT_APP_AUTH_API}/users/me`,
                { headers: { Authorization: `Bearer ${t ?? state.token}` } }
            );
            dispatch({ type: 'user', payload: { user: response.data } });
        } catch {
            logout();
        }
    }, [state.token, logout]);

    useEffect(() => {
        if (state.status === AuthenticationStatus.WaitingForUser) {
            getUser();
        }
    }, [state.status]);

    useEffect(() => {
        AxiosInstance.post<TokenResponse>(
            `${process.env.REACT_APP_AUTH_API}/auth/refresh`,
            {},
            { withCredentials: true }
        )
            .then(response => dispatch({ type: 'tokenResponse', payload: { tokenReponse: response.data } }))
            .catch(() => logout());
    }, []);

    return (
        <AuthContext.Provider value={{
            currentUser: state.user,
            currentRole: state.role,
            token: state.token,
            status: state.status,
            refreshUser: getUser,
            logout,
            handleToken: (tokenReponse: TokenResponse) => dispatch({ type: 'tokenResponse', payload: { tokenReponse } }),
            redirectToPortal,
        }}>
            {state.status === AuthenticationStatus.Authenticated && <Refresh onToken={(tokenReponse) => dispatch({ type: 'tokenResponse', payload: { tokenReponse } })} onError={logout} />}
            {state.isInit ? children : null}
        </AuthContext.Provider>
    );
};


const useAuthContext = () => useContext<AuthContextType>(AuthContext);

export { AuthProvider, useAuthContext };
export default AuthContext;