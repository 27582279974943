import { useCallback, useState } from 'react';
import { useRequest } from '../../context';
import { Modal } from '../../ui';
import DataContainerList, { DataContainerListProps } from '../DataContainerList';
import SearchField from '../SearchField';
import './index.scss';

interface EntitySearchProps<T> extends Pick<DataContainerListProps<T>, 'columns' | 'primaryKey' | 'sort'> {
    endpoint: string;
    multiple?: boolean;
    selected?: T[];
    onSubmit: (e: T[]) => void;
    onClose: () => void;
}


const EntitySearch = <T,>({ endpoint, onSubmit, onClose, columns, primaryKey, sort, selected: selectedFromProps }: EntitySearchProps<T>) => {
    const [results, setResults] = useState<T[]>([]);
    const [selected, setSelected] = useState<T[]>(selectedFromProps ?? []);
    const request = useRequest();

    const getResults = useCallback((keyword: string | undefined) => {
        if (!keyword) {
            setResults([]);
        }

        request.get<T[]>(endpoint, { params: { keyword }, withWorkspace: true })
            .then(setResults)
            .catch(() => setResults([]));
    }, [endpoint, request]);

    const handleSubmit = useCallback(() => {
        if (!selected?.length) return;
        onSubmit(selected);
        onClose();
    }, [onSubmit, onClose, selected]);

    return (
        <Modal
            i18n="actions"
            title="search"
            className="entity-search"
            size="medium"
            onClose={onClose}
            onSubmit={handleSubmit}
        >
            <SearchField onChange={getResults} loading={request.loading} />
            <DataContainerList<T>
                primaryKey={primaryKey}
                data={results}
                selected={selected ?? undefined}
                onSelectMultiple={setSelected}
                columns={columns}
                sort={sort}
            />
        </Modal>
    );
}

export default EntitySearch;