import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { DownIcon, EditIcon, MenuIcon, TrashIcon, UpIcon, ViewIcon } from "../../icons";
import { Button } from "../../ui";
import DropdownMenu from "../../ui/DropdownMenu";
import { getStringValue, translateLabel } from "../../utils/format";
import { conditionnalClassnames } from "../../utils/helpers";
import { getNestedField, getPrimaryKeysAsString, toggleInArray } from "../../utils/objects";
import ModalDelete from "../ModalDelete";
import "./index.scss";

export interface DataContainerListContentProps<T> {
    entity: T;
};

export type DataContainerListColumn<T> = {
    field: string;
    label?: string;
    i18n?: string;
    display?: ((e: T) => JSX.Element | string | undefined);
    width?: string;
}

export interface DataContainerListProps<T> {
    columns: DataContainerListColumn<T>[];
    primaryKey: keyof T | (keyof T)[];
    sort?: { field: keyof T, direction: 1 | -1 };
    selected?: T[];
    data?: T[];
    onClick?: (e: T) => void;
    onView?: (e: T) => void;
    onEdit?: (e: T) => void;
    onDelete?: (e: T) => void;
    onSelect?: (e: T | null) => void;
    onSelectMultiple?: (e: T[]) => void;
}

const DataContainerList = <T,>({
    columns,
    primaryKey,
    selected: selectedFromProps,
    sort: sortFromProps,
    data: dataFromProps,
    onClick,
    onView,
    onEdit,
    onDelete,
    onSelect,
    onSelectMultiple
}: DataContainerListProps<T>) => {
    const { t } = useTranslation();
    const [entityToDelete, setEntityToDelete] = useState<T | null>(null);
    const [sort, setSort] = useState<{ field: keyof T, direction: 1 | -1 } | null>(sortFromProps ?? null)
    const [selected, setSelected] = useState<T[]>(selectedFromProps ?? []);

    const data = useMemo(() => dataFromProps && sort
        ? dataFromProps.sort((e1, e2) => getNestedField(e1, sort.field as string) > getNestedField(e2, sort.field as string) ? sort.direction : sort.direction * -1)
        : dataFromProps, [sort, dataFromProps]);

    const handleDelete = useCallback((e: T) => {
        if (!onDelete) return;
        onDelete(e);
    }, [onDelete]);

    const handleSelect = useCallback(async (e: T) => {
        if (!onSelect && !onSelectMultiple) return;

        if (onSelect) {
            setSelected(selected => selected.length === 0 || getPrimaryKeysAsString(selected[0], primaryKey) !== getPrimaryKeysAsString(e, primaryKey) ? [e] : []);
        } else {
            setSelected((selected) => toggleInArray(selected, e, (e1, e2) => getPrimaryKeysAsString(e1, primaryKey) === getPrimaryKeysAsString(e2, primaryKey)));
        }
    }, [onSelect, onSelectMultiple, primaryKey]);

    const columnHeaders = useMemo(() => columns.map((column) => (
        <th key={column.field} style={{ width: column.width }}>
            <div
                className={sort?.field === column.field ? 'active' : undefined}
                onClick={() => setSort({ field: column.field as keyof T, direction: sort?.field === column.field && sort.direction === -1 ? 1 : -1 })}
            >
                <span>{column.label ? translateLabel(column.label, t, column.i18n) : ''}</span>
                {sort?.field === column.field && sort.direction === -1 && <UpIcon />}
                {sort?.field === column.field && sort.direction === 1 && <DownIcon />}
            </div>
        </th>
    )), [columns, sort]);

    const rows = useMemo(() => data?.map(entity => (
        <tr
            key={getPrimaryKeysAsString(entity, primaryKey)}
            onClick={(onSelect || onSelectMultiple) ? () => handleSelect(entity) : onClick ? () => onClick(entity) : undefined}
            className={conditionnalClassnames({ selected: selected.some(s => getPrimaryKeysAsString(s, primaryKey) === getPrimaryKeysAsString(entity, primaryKey)) })}
        >
            {columns.map(column => {
                const value = column.display ? column.display(entity) : getStringValue(getNestedField(entity, column.field));
                return (
                    <td key={column.field} className={column.field === 'actions' ? 'actions' : undefined} style={{ width: column.width }}>
                        <div title={typeof value === 'string' ? value : ''}>{value}</div>
                    </td>
                );
            })}
            {(onEdit || onDelete || onView) && (
                <td className="data-table-list-action">
                    <DropdownMenu items={[
                        ...(onView ? [{ itemKey: 'edit', icon: <ViewIcon />, i18n: "actions", label: "view", onClick: () => onView(entity) }] : []),
                        ...(onEdit ? [{ itemKey: 'edit', icon: <EditIcon />, i18n: "actions", label: "edit", onClick: () => onEdit(entity) }] : []),
                        ...(onDelete ? [{ itemKey: 'delete', icon: <TrashIcon />, i18n: "actions", label: "delete", onClick: () => setEntityToDelete(entity) }] : []),
                    ]}>
                        <Button color="navigation" icon={<MenuIcon />} />
                    </DropdownMenu>
                </td>
            )}
        </tr>
    ))
        , [data, sort, primaryKey, columns, onEdit, onDelete, onView, onSelect, onSelectMultiple, handleSelect, onClick, selected]);

    useEffect(() => {
        if (onSelect) {
            onSelect(selected.length ? selected[0] : null)
        }
        if (onSelectMultiple) {
            onSelectMultiple(selected);
        }
    }, [selected]);

    useEffect(() => {
        setSelected(selectedFromProps ?? []);
    }, [dataFromProps]);

    return (
        <div className="data-container-list">
            <table>
                <thead>
                    <tr>
                        {columnHeaders}
                        {(onEdit || onDelete || onView) && <th className="data-container-list-action" />}
                    </tr>
                </thead>
                <tbody>
                    {rows}
                </tbody>
            </table>
            {!!entityToDelete && (
                <ModalDelete withDebounce onClose={() => setEntityToDelete(null)} onSubmit={() => handleDelete(entityToDelete)} />
            )}
        </div>
    )
}

export default DataContainerList;