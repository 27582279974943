import { MapAction } from "../../../models/enums";
import { BulletIcon, CloseIcon, DataBarIcon, DownloadIcon, EditIcon, FilterIcon, GlobeIcon, KeyIcon, NotificationIcon, OpenIcon, OptionsIcon, SaveAsIcon, SaveIcon, SettingIcon, SquareFourIcon, UserBriefcaseIcon, UsersIcon } from "../../../sg-react/icons";
import { LayoutMenuItem, LayoutMenuMenu } from "../../../sg-react/ui/LayoutMenu";
import MenuContextOutlet from "../../../sg-react/ui/MenuContextOutlet";
import { Action } from "../../../sg-react/utils/enums";

const ITEMS: LayoutMenuItem[] = [
    { path: '', label: 'map', i18n: 'path', icon: <GlobeIcon /> },
];

const MENUS: LayoutMenuMenu[] = [{
    path: '',
    label: 'menu',
    i18n: 'ui',
    items: [
        { itemKey: 'query-divider', divider: true, i18n: 'filters', label: 'query' },
        { itemKey: MapAction.QuerySave, icon: <SaveIcon />, i18n: "actions", label: "save" },
        { itemKey: MapAction.QuerySaveAs, icon: <SaveAsIcon />, i18n: "actions", label: "save_as" },
        { itemKey: MapAction.QueryOpen, icon: <OpenIcon />, i18n: "actions", label: "open" },
        { itemKey: 'plan-divider', divider: true, i18n: 'actions', label: 'plan' },
        { itemKey: MapAction.PlanSave, icon: <SaveIcon />, i18n: "actions", label: "save" },
        { itemKey: MapAction.PlanSaveAs, icon: <SaveAsIcon />, i18n: "actions", label: "save_as" },
        { itemKey: MapAction.PlanOpen, icon: <OpenIcon />, i18n: "actions", label: "open" },
        { itemKey: 'results-divider', divider: true, i18n: 'filters', label: 'results' },
        { itemKey: Action.Export, icon: <DownloadIcon />, i18n: "actions", label: "export" },
    ]
}, {
    path: '',
    label: 'window',
    i18n: 'ui',
    items: [
        { itemKey: MapAction.WindowFilters, icon: <FilterIcon />, i18n: "filters", label: "filters" },
        { itemKey: MapAction.WindowResults, icon: <BulletIcon />, i18n: "filters", label: "results" },
        { itemKey: MapAction.WindowPlan, icon: <DataBarIcon />, i18n: "actions", label: "plan" },
        { itemKey: MapAction.WindowLayers, icon: <OptionsIcon />, i18n: "geo", label: "layers" },
    ]
}, {
    path: '/view',
    pathStartsWith: true,
    label: 'file',
    i18n: 'ui',
    items: [
        { itemKey: Action.Edit, icon: <EditIcon />, i18n: "actions", label: "edit" },
        {
            itemKey: Action.Settings,
            icon: <SettingIcon />,
            i18n: "ui",
            label: "settings",
            items: [
                { itemKey: Action.Widgets, icon: <SquareFourIcon />, i18n: "workspaces", label: "widgets" },
                { itemKey: Action.Users, icon: <UsersIcon />, i18n: "workspaces", label: "reference_users" },
                { itemKey: Action.Privacy, icon: <KeyIcon />, i18n: "workspaces", label: "visibility" },
                { itemKey: Action.Notifications, icon: <NotificationIcon />, i18n: "workspaces", label: "notifications" },
                { itemKey: Action.Guests, icon: <UserBriefcaseIcon />, i18n: "workspaces", label: "guests" },
            ]
        },
        { itemKey: Action.Export, icon: <DownloadIcon />, i18n: "actions", label: "export" },
        { itemKey: Action.Close, icon: <CloseIcon />, i18n: "actions", label: "close" },
    ]
}];


const MainMapLayoutWrapper = () => <MenuContextOutlet menus={MENUS} items={ITEMS} />

export default MainMapLayoutWrapper;