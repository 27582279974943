import { Fragment, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PolarAngleAxis, PolarGrid, PolarRadiusAxis, Radar, RadarChart, ResponsiveContainer } from 'recharts';
import { DirectoryItemViewPatchProps } from '..';
import SpecificationSearch from '../../../components/SpecificationSearch';
import { useRequest, useWorkspaceContext } from '../../../sg-react/context';
import TagsList from '../../../sg-react/data/TagsList';
import { Switch } from '../../../sg-react/form';
import { PlusIcon, TrashIcon } from '../../../sg-react/icons';
import { Button, Card, Nothing } from '../../../sg-react/ui';
import ValueDescription from '../../../sg-react/ui/ValueDescription';

const DirectoryDataSpecifications = ({ directoryItem, directoryData, refresh, canEdit }: DirectoryItemViewPatchProps) => {
    const request = useRequest();
    const { currentWorkspace } = useWorkspaceContext();
    const [isSpecificationsModalVisible, setSpecificationsModalVisible] = useState<boolean>(false);
    const [mode, setMode] = useState<string | undefined>('chart');
    const { t } = useTranslation();

    const modes = useMemo(() => [{ key: 'chart', label: t('charts:chart') }, { key: 'list', label: t('data:list') }], []);

    const chartData = useMemo(() => currentWorkspace.tags
        ?.filter(tag => tag.category === 'specifications')
        .map(tag => ({
            ...tag,
            count: directoryData.specifications?.filter(s => s.tagsIds.includes(tag._id)).length ?? 0
        }))
        .filter(tag => !!tag.count), [directoryData, currentWorkspace]);

    const handleChange = useCallback(async (specificationsIds: string[]) => {
        request.patch(`/directory-data/${directoryItem._id}/specifications`, { specificationsIds }, {
            loader: true,
            withWorkspace: true,
            errorMessage: true,
        })
            .then(() => refresh())
            .catch(() => null);
    }, [request, refresh, directoryItem._id]);

    return (
        <Card
            id="directory-view-specifications"
            title="specifications"
            i18n="specifications"
            overflow="hidden"
            options={(
                <Fragment>
                    {canEdit && <Button color="navigation" icon={<PlusIcon />} i18n="actions" label="edit" onClick={() => setSpecificationsModalVisible(true)} />}
                    <Switch id="specifications-switch" items={modes} value={mode} onChange={setMode} />
                </Fragment>
            )}
        >
            {!directoryData.specifications?.length && <Nothing />}
            {!!directoryData.specifications?.length && mode === 'chart' && (
                <ResponsiveContainer minWidth="100%" width="100%" height={280}>
                    <RadarChart
                        cx="50%"
                        cy="50%"
                        outerRadius="80%"
                        data={chartData}
                        margin={{
                            top: 0,
                            right: 20,
                            bottom: 0,
                            left: 20,
                        }}
                    >
                        <PolarGrid />
                        <PolarAngleAxis dataKey="name" />
                        <PolarRadiusAxis />
                        <Radar dataKey="count" stroke="#269ac8" fill="#269ac8" fillOpacity={0.6} />
                    </RadarChart>
                </ResponsiveContainer>
            )}
            {!!directoryData.specifications?.length && mode === 'list' && (
                <div className="simple-list">
                    {directoryData.specifications?.map(specification => (
                        <div className="row" key={specification._id}>
                            <ValueDescription
                                value={specification.name}
                                description={specification.description}
                                tags={<TagsList tagsIds={specification.tagsIds} />}
                            />
                            {canEdit && <Button color="navigation" icon={<TrashIcon />} onClick={() => handleChange(directoryData.specificationsIds?.filter(s => s !== specification._id) ?? [])} />}
                        </div>
                    ))}
                </div>
            )}
            {!!isSpecificationsModalVisible && (
                <SpecificationSearch
                    onClose={() => setSpecificationsModalVisible(false)}
                    onSubmit={(s) => handleChange(s?.map(s => s._id) ?? [])}
                    selected={directoryData?.specifications}
                    multiple
                />
            )}
        </Card>
    )
}
export default DirectoryDataSpecifications