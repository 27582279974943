import Validation from "../form/Validation/class";

export type Location = {
    longitude: number;
    latitude: number;
    streetNumber?: string;
    route?: string;
    city: string;
    region?: string;
    country: string;
    postalCode?: string;
    address?: string;
    geonameId?: number;
    geonamesIds?: number[];
};

export interface CoordinatesXY {
    x: number;
    y: number;
}

export const LOCATION_VALIDATION = {
    longitude: Validation.number()
        .required()
        .greaterThan(-180, true)
        .lesserThan(180, true),
    latitude: Validation.number()
        .required()
        .greaterThan(-90, true)
        .lesserThan(80, true),
    streetNumber: Validation.string().minLength(1).maxLength(50),
    route: Validation.string().required().minLength(5).maxLength(75),
    city: Validation.string().required().minLength(2).maxLength(150),
    region: Validation.string().minLength(2).maxLength(150),
    country: Validation.string().required().minLength(2).maxLength(150),
    postalCode: Validation.string().minLength(3).maxLength(50),
    address: Validation.string().required().minLength(10).maxLength(250),
};
