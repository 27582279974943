import { DirectoryItemFormProps } from "..";
import { TextField } from "../../../sg-react/form";

const DirectoryItemContactsForm = ({ attachInput }: DirectoryItemFormProps) => {

    return (
        <div className="form-inline-group">
            <TextField label inline i18n="directory" {...attachInput('website')} />
            <TextField label inline tooltip i18n="directory" {...attachInput('localPhone')} />
            <TextField label inline tooltip i18n="directory" {...attachInput('internationalPhone')} />
            <TextField label inline tooltip i18n="directory" {...attachInput('email')} />
        </div>
    )
}

export default DirectoryItemContactsForm;