import { Fragment } from "react";
import { TextField } from "../../../form";
import ImageField from "../../../form/ImageField";
import Validation, { ObjectValidation } from "../../../form/Validation/class";
import { FormHookReturn } from "../../../hooks/useForm";
import { User } from "../../../models/user";

export const PROFILE_VALIDATION = new ObjectValidation({
    firstName: Validation.string().required().minLength(3).maxLength(150),
    lastName: Validation.string().required().minLength(3).maxLength(150),
    company: Validation.string().required().minLength(3).maxLength(150),
    position: Validation.string().required().minLength(3).maxLength(150),
    field: Validation.string().required().minLength(3).maxLength(150),
})

const ProfileForm = <T extends User>({ attachInput }: Pick<FormHookReturn<T>, 'attachInput'>) => (
    <Fragment>
        <TextField label inline i18n="users" {...attachInput('firstName')} />
        <TextField label inline i18n="users" {...attachInput('lastName')} />
        <ImageField label inline i18n="users" endpoint="/users/avatar" {...attachInput('avatar')} />
        <TextField label inline i18n="users" {...attachInput('company')} />
        <TextField label inline i18n="users" {...attachInput('field')} />
        <TextField label inline i18n="users" {...attachInput('position')} />
        <TextField label inline tooltip i18n="users" {...attachInput('phone')} />
        <TextField label inline i18n="users" {...attachInput('linkedIn')} />
    </Fragment>
);
export default ProfileForm;