import { getNestedField } from "../../utils/objects";
import { changeFieldValue } from "./../../utils/objects";
import { ObjectValidationException, ValidationException } from "./exceptions";
import {
    ObjectValidationErrors,
    ValidationError,
    ValidationForType,
    ValidationRule,
} from "./types";

class Validation<T = any> {
    private validations: ValidationRule<T>[] = [];
    protected typeValidation?: ValidationForType<T>;
    protected isRequired: boolean = false;

    public validate(value: any): T {
        let transformedValue = value;

        const errors: ValidationError[] = [];

        // Cast to type
        if (this.typeValidation?.transform) {
            try {
                transformedValue = this.typeValidation.transform(value);
            } catch {
                throw new ValidationException([
                    { type: this.typeValidation.type },
                ]);
            }
        }

        // Check if required
        if (transformedValue === null || transformedValue === undefined) {
            if (this.isRequired) {
                throw new ValidationException([{ type: "REQUIRED" }]);
            }
            return transformedValue;
        }

        // Validate
        const validations = this.typeValidation
            ? [this.typeValidation, ...this.validations]
            : this.validations;
        validations.forEach((v) => {
            try {
                if (!v.test(transformedValue)) {
                    throw new Error();
                }
            } catch {
                errors.push({ type: v.type, data: v.data });
            }
        });

        if (errors.length) throw new ValidationException(errors);

        return transformedValue;
    }

    public isValid(value: any): boolean {
        try {
            this.validate(value);
            return true;
        } catch {
            return false;
        }
    }

    public getValidations() {
        return this.validations;
    }

    protected addValidation(validation: ValidationRule<T>): this {
        this.validations.push(validation);
        return this;
    }

    static string(): ValidationString {
        return new ValidationString();
    }

    static number(): ValidationNumber {
        return new ValidationNumber();
    }

    static array<U>(validation: Validation<U>): ValidationArray<U> {
        return new ValidationArray(validation);
    }

    static date(): ValidationDate {
        return new ValidationDate();
    }
}

class ValidationArray<T> extends Validation<T[]> {
    constructor(validation: Validation<T>) {
        super();
        this.typeValidation = {
            type: "ARRAY",
            test: (value) => Array.isArray(value),
        };
        validation.getValidations().forEach((v) =>
            this.addValidation({
                ...v,
                test: (arrayValue) => arrayValue?.every((av) => v.test(av)),
                type: v.type,
                data: { each: true, ...v.data },
            })
        );
    }

    public required(): this {
        this.isRequired = true;
        return this;
    }

    public notEmpty(): this {
        return this.minLength(1, "ARRAY_NOT_EMPTY");
    }

    public minLength(min: number, type?: string): this {
        return this.addValidation({
            type: type ?? "ARRAY_MIN_LENGTH",
            test: (value) => value.length >= min,
            data: { compareTo: min },
        });
    }

    public maxLength(max: number): this {
        return this.addValidation({
            type: "ARRAY_MAX_LENGTH",
            test: (value) => value.length <= max,
            data: { compareTo: max },
        });
    }

    public each(validation: Validation<T>): this {
        validation.getValidations().forEach((v) =>
            this.addValidation({
                ...v,
                test: (arrayValue) => arrayValue?.every((av) => v.test(av)),
                type: v.type,
                data: { each: true, ...v.data },
            })
        );
        return this;
    }
}

class ValidationNumber extends Validation<number> {
    constructor() {
        super();
        this.typeValidation = {
            type: "NUMBER",
            test: (value) => typeof value === "number" || !isNaN(Number(value)),
            transform: (value) =>
                value !== undefined && value !== null
                    ? Number(value)
                    : undefined,
        };
    }

    public required(): this {
        this.isRequired = true;
        return this;
    }

    public notZero(): this {
        return this.addValidation({
            type: "NUMBER_NOT_ZERO",
            test: (value) => !!value,
        });
    }

    public greaterThan(min: number, equals?: boolean): this {
        return this.addValidation({
            type: "NUMBER_GREATER",
            test: (value) => (equals ? value >= min : value > min),
            data: { compareTo: min },
        });
    }

    public lesserThan(max: number, equals?: boolean): this {
        return this.addValidation({
            type: "NUMBER_LESSER",
            test: (value) => (equals ? value <= max : value < max),
            data: { compareTo: max },
        });
    }

    public integer(): this {
        return this.addValidation({
            type: "NUMBER_INTEGER",
            test: (value) => Math.floor(value) === value,
        });
    }

    public positive(): this {
        return this.addValidation({
            type: "NUMBER_POSITIVE",
            test: (value) => value >= 0,
        });
    }

    public negative(): this {
        return this.addValidation({
            type: "NUMBER_NEGATIVE",
            test: (value) => value <= 0,
        });
    }

    public equals(compareTo: number): this {
        return this.addValidation({
            type: "NUMBER_EQUALS",
            test: (value) => value === compareTo,
            data: { compareTo },
        });
    }
}

class ValidationString extends Validation<string> {
    constructor() {
        super();
        this.typeValidation = {
            type: "STRING",
            test: (value) =>
                typeof value === "string" || String(value) === value,
            transform: (value) =>
                !!value
                    ? String(value)
                          .trim()
                          .replace(/[ \t]+/g, " ")
                    : undefined,
        };
    }

    public required(): this {
        this.isRequired = true;
        return this;
    }

    public notEmpty(): this {
        return this.minLength(1, "STRING_NOT_EMPTY");
    }

    public minLength(min: number, type?: string): this {
        return this.addValidation({
            type: type ?? "STRING_MIN_LENGTH",
            test: (value) => value.length >= min,
            data: { compareTo: min },
        });
    }

    public maxLength(max: number): this {
        return this.addValidation({
            type: "STRING_MAX_LENGTH",
            test: (value) => value.length <= max,
            data: { compareTo: max },
        });
    }

    public regex(regex: string | RegExp, type?: string): this {
        const re = typeof regex === "string" ? new RegExp(regex) : regex;
        return this.addValidation({
            type: type ?? "STRING_REGEX",
            test: (value) => re.test(value),
        });
    }

    public isEnum(enumRecord: Record<string, string>): this {
        return this.addValidation({
            type: "STRING_ENUM",
            test: (value) =>
                Object.keys(enumRecord).includes(value) ||
                Object.values(enumRecord).includes(value),
        });
    }

    public isEmail(): this {
        return this.regex(
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            "STRING_EMAIL"
        );
    }

    public isUrl(): this {
        return this.regex(
            /^(https?:\/\/)?(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/,
            "STRING_URL"
        );
    }

    public isMongoId(): this {
        return this.regex(
            /^(?=[a-f\d]{24}$)(\d+[a-f]|[a-f]+\d)/i,
            "STRING_MONGO_ID"
        );
    }

    public equals(compareTo: string): this {
        return this.addValidation({
            type: "STRING_EQUALS",
            test: (value) => value === compareTo,
            data: { compareTo },
        });
    }
}

class ValidationDate extends Validation<Date> {
    constructor() {
        super();
        this.typeValidation = {
            type: "DATE",
            test: (value) => value instanceof Date,
        };
    }

    public required(): this {
        this.isRequired = true;
        return this;
    }
}

export class ObjectValidation<T = any> {
    private validations: Record<
        string,
        Validation | ObjectValidation | Record<string, Validation>
    >;

    constructor(
        validations: Record<
            string,
            Validation | ObjectValidation | Record<string, Validation>
        >
    ) {
        this.validations = validations;
    }

    public validate(entity: Record<string, any>, whiteList: boolean = true): T {
        let isValid = true;

        const errors: ObjectValidationErrors = {};
        let validatedEntity: Partial<T> = whiteList
            ? {}
            : ({ ...entity } as Partial<T>);

        for (const field in this.validations) {
            const validation = this.validations[field];
            if (validation instanceof Validation) {
                try {
                    validatedEntity = changeFieldValue(
                        validatedEntity,
                        field,
                        validation.validate(getNestedField(entity, field))
                    );
                } catch (e) {
                    if (e instanceof ValidationException) {
                        errors[field] = e.errors;
                    }
                    isValid = false;
                }
            } else {
                const objectValidation =
                    validation instanceof ObjectValidation
                        ? validation
                        : new ObjectValidation(validation);
                try {
                    const nestedValue = getNestedField(entity, field);
                    validatedEntity = changeFieldValue(
                        validatedEntity,
                        field,
                        objectValidation.validate(nestedValue)
                    );
                } catch (e) {
                    if (e instanceof ObjectValidationException) {
                        for (const errorField in e.errors) {
                            errors[field + "." + errorField] =
                                e.errors[errorField];
                        }
                    }
                    isValid = false;
                }
            }
        }

        if (!isValid) throw new ObjectValidationException(errors);

        return validatedEntity as T;
    }

    public isValid(
        entity: Record<string, any>,
        whiteList: boolean = true
    ): boolean {
        try {
            this.validate(entity, whiteList);
            return true;
        } catch {
            return false;
        }
    }

    public getValidations() {
        return this.validations;
    }

    public merge(
        validations:
            | Record<
                  string,
                  Validation | ObjectValidation | Record<string, Validation>
              >
            | ObjectValidation<Partial<T>>
    ): this {
        if (validations instanceof ObjectValidation) {
            this.validations = {
                ...this.validations,
                ...validations.getValidations(),
            };
        } else {
            this.validations = { ...this.validations, ...validations };
        }

        return this;
    }
}

export default Validation;
