import { AxiosRequestConfig, ResponseType } from "axios";
import { ReactNode, createContext, useCallback, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useUiContext } from ".";
import AxiosInstance from "../services/Axios";
import { requestErrorToToast, translateLabel } from "../utils/format";
import { useAuthContext } from "./AuthContext";

export type RequestMethod = "get" | "post" | "put" | "patch" | "delete";
export interface RequestOptions {
    authorization: boolean;
    params: any;
    loader: boolean;
    successMessage:
    | boolean
    | {
        i18n?: true | string;
        message: string;
        variables?: Record<string, string>;
    };
    errorMessage:
    | boolean
    | {
        i18n?: true | string;
        message: string;
        variables?: Record<string, string>;
    };
    withWorkspace: boolean | string;
    withCredentials: boolean;
    responseType: ResponseType;
    timeout: number;
    headers: Record<string, string>;
}

interface RequestProviderProps {
    children: ReactNode;
}

export type RequestContextType = {
    get: <T>(url: string, options?: Partial<RequestOptions>) => Promise<T>;
    post: <T>(
        url: string,
        body: any,
        options?: Partial<RequestOptions>
    ) => Promise<T>;
    put: <T>(
        url: string,
        body: any,
        options?: Partial<RequestOptions>
    ) => Promise<T>;
    patch: <T>(
        url: string,
        body: any,
        options?: Partial<RequestOptions>
    ) => Promise<T>;
    delete: <T>(url: string, options?: Partial<RequestOptions>) => Promise<T>;
    loading: boolean;
    setWorkspaceId: (id?: string) => void;
};

export const RequestContext = createContext<RequestContextType>({
    get: () => Promise.resolve({} as any),
    post: () => Promise.resolve({} as any),
    put: () => Promise.resolve({} as any),
    patch: () => Promise.resolve({} as any),
    delete: () => Promise.resolve({} as any),
    setWorkspaceId: () => null,
    loading: false
});

const RequestProvider = ({ children }: RequestProviderProps) => {
    const { addToast, setSpinnerVisible } = useUiContext();
    const { token } = useAuthContext();
    const [workspaceId, setWorkspaceId] = useState<string | undefined>();
    const { t } = useTranslation();
    const [loaderCount, setLoaderCount] = useState<number>(0);

    const request = useCallback(
        async <T,>(
            method: "get" | "post" | "put" | "patch" | "delete",
            url: string,
            body: any,
            options?: Partial<RequestOptions>
        ): Promise<T> => {
            if (options?.loader) {
                setSpinnerVisible(true);
            }

            try {
                setLoaderCount((loaderCount) => loaderCount + 1);
                let data: T;
                const _options: AxiosRequestConfig = {
                    params: options?.withWorkspace
                        ? {
                            ...options?.params,
                            workspaceId: options?.withWorkspace === true ? workspaceId : options?.withWorkspace,
                        }
                        : options?.params,
                    responseType: options?.responseType,
                    withCredentials: options?.withCredentials,
                    timeout: options?.timeout,
                    headers: {
                        ...options?.headers,
                        Authorization: `Bearer ${token}`,
                    },
                };

                switch (method) {
                    case "get":
                        const getResponse = await AxiosInstance.get<T>(
                            url,
                            _options
                        );
                        data = getResponse.data;
                        break;
                    case "post":
                        const postResponse = await AxiosInstance.post<T>(
                            url,
                            body,
                            _options
                        );
                        data = postResponse.data;
                        break;
                    case "put":
                        const putResponse = await AxiosInstance.put<T>(
                            url,
                            body,
                            _options
                        );
                        data = putResponse.data;
                        break;
                    case "patch":
                        const patchResponse = await AxiosInstance.patch<T>(
                            url,
                            body,
                            _options
                        );
                        data = patchResponse.data;
                        break;
                    default:
                        const deleteResponse = await AxiosInstance.delete<T>(
                            url,
                            _options
                        );
                        data = deleteResponse.data;
                        break;
                }

                if (options?.successMessage) {
                    addToast({
                        type: "success",
                        message:
                            options.successMessage === true
                                ? translateLabel(
                                    "success." + method,
                                    t,
                                    "messages"
                                )
                                : translateLabel(
                                    options.successMessage.message,
                                    t,
                                    options.successMessage.i18n === true
                                        ? "messages"
                                        : options.successMessage.i18n,
                                    options.successMessage.variables
                                ),
                    });
                }

                return data;
            } catch (e) {
                if (options?.errorMessage) {
                    addToast(
                        options.errorMessage === true
                            ? requestErrorToToast(e as Error)
                            : {
                                type: "error",
                                message: translateLabel(
                                    options.errorMessage.message,
                                    t,
                                    options.errorMessage.i18n === true
                                        ? "messages"
                                        : options.errorMessage.i18n,
                                    options.errorMessage.variables
                                ),
                                error: e as Error,
                            }
                    );
                }

                throw e;
            } finally {
                if (options?.loader) {
                    setSpinnerVisible(false);
                }
                setLoaderCount((loaderCount) => loaderCount - 1);
            }
        },
        [token, workspaceId, t]
    );

    return (
        <RequestContext.Provider value={{
            get: <T,>(url: string, options?: Partial<RequestOptions>) =>
                request<T>("get", url, {}, options),
            post: <T,>(url: string, body: any, options?: Partial<RequestOptions>) =>
                request<T>("post", url, body, options),
            put: <T,>(url: string, body: any, options?: Partial<RequestOptions>) =>
                request<T>("put", url, body, options),
            patch: <T,>(url: string, body: any, options?: Partial<RequestOptions>) =>
                request<T>("patch", url, body, options),
            delete: <T,>(url: string, options?: Partial<RequestOptions>) =>
                request<T>("delete", url, {}, options),
            loading: !!loaderCount,
            setWorkspaceId
        }}>
            {children}
        </RequestContext.Provider>
    );
};

const useRequest = () => {
    return useContext(RequestContext);
};

export { RequestProvider, useRequest };
export default RequestContext;