import { Fragment, useCallback, useEffect, useState } from "react";
import { useRequest } from "../../../sg-react/context";
import RevisionTable, { RevisionTableField } from "../../../sg-react/data/RevisionTable";
import { BackwardIcon, CalendarIcon, ErrorIcon, SuccessIcon, TypingIcon, WorkspaceIcon } from "../../../sg-react/icons";
import { Modal, UserItem } from "../../../sg-react/ui";
import IconValue from "../../../sg-react/ui/IconValue";
import ListItem from "../../../sg-react/ui/ListItem";
import { Colors } from "../../../sg-react/ui/enums";
import { dateToLocaleString } from "../../../sg-react/utils/date";
import { Checkbox } from "../../form";
import { Entity, RevisionEntity } from "../../models/entity";

interface RevisionModalProps<T extends Entity> {
    entity: T;
    getUrl: string;
    postUrl: string;
    fields: RevisionTableField<T>[];
    component: (props: { entity: T }) => JSX.Element;
    onClose: () => void;
    onSubmit: () => void;
}

const RevisionModal = <T extends Entity>({
    entity,
    component: ItemComponent,
    getUrl,
    postUrl,
    fields,
    onClose,
    onSubmit
}: RevisionModalProps<T>) => {
    const [revisions, setRevisions] = useState<RevisionEntity<T>[]>([]);
    const [selectedRevision, setSelectedRevision] = useState<RevisionEntity<T> | null>(null);
    const [selectedFields, setSelectedFields] = useState<string[]>([]);
    const [allowUserEdit, setAllowUserEdit] = useState<boolean>(false);
    const [allowWorkspaceEdit, setAllowWorkspaceEdit] = useState<boolean>(false);
    const request = useRequest();

    const handleSubmit = useCallback((approve: boolean, fields?: string[]) => {
        if (!selectedRevision) return;

        request.post(postUrl, {
            _id: selectedRevision._id,
            approve,
            approveAll: !fields?.length,
            fields,
            allowUserEdit,
            allowWorkspaceEdit
        }, {
            withWorkspace: true,
            loader: true,
            errorMessage: true,
            successMessage: { i18n: 'entity', message: approve ? 'success.revision_validated' : 'success.revision_rejected' }
        })
            .then(() => {
                onSubmit();
                onClose();
            })
            .catch(() => null);
    }, [request, allowWorkspaceEdit, onSubmit, onClose, allowUserEdit, selectedRevision]);

    const handleChangeRevision = useCallback((revision?: RevisionEntity<T>) => {
        setSelectedRevision(revision ?? null);
        setSelectedFields([]);
    }, []);

    useEffect(() => {
        request.get<RevisionEntity<T>[]>(getUrl, {
            withWorkspace: true,
            loader: true,
            errorMessage: true,
        })
            .then((data) => {
                if (!data.length) onClose();
                if (data.length === 1) {
                    setSelectedRevision(data[0]);
                }
                setRevisions(data);
            }).catch(() => onClose());

    }, [entity]);

    if (!revisions.length) return null;

    return (
        <Modal
            size={selectedRevision ? 'medium' : 'small'}
            title="revisions"
            i18n="entity"
            overflow="hidden"
            onClose={onClose}
            actions={selectedRevision ? [
                { i18n: 'actions', label: 'previous', onClick: () => handleChangeRevision(), color: Colors.PRIMARY, icon: <BackwardIcon /> },
                { i18n: 'actions', label: 'reject', onClick: () => handleSubmit(false), color: Colors.ERROR, icon: <ErrorIcon /> },
                { i18n: 'actions', label: 'accept_all', onClick: () => handleSubmit(true), color: Colors.SUCCESS, icon: <SuccessIcon /> },
                ...(!!selectedFields.length ? [{ i18n: 'actions', label: 'accept_selected', onClick: () => handleSubmit(true, selectedFields), color: Colors.SUCCESS, icon: <SuccessIcon /> }] : [])
            ] : []}
        >
            {!selectedRevision ? (
                <div className="list">
                    {revisions.map(revision => (
                        <ListItem
                            key={revision._id}
                            value={dateToLocaleString(revision.createdAt, true)}
                            description={revision.comment}
                            onClick={() => handleChangeRevision(revision)}
                        />
                    ))}
                </div>
            ) : (
                <Fragment>
                    <div className="row row-equal-cols">
                        <ItemComponent entity={entity} />
                        <UserItem user={selectedRevision.createdBy} />
                    </div>
                    <div className="row row-equal-cols">
                        <IconValue icon={<CalendarIcon />} value={dateToLocaleString(selectedRevision.createdAt)} />
                        <IconValue icon={<WorkspaceIcon />} value={selectedRevision.workspace?.name} />
                        <IconValue icon={<TypingIcon />} value={selectedRevision.comment} />
                    </div>
                    <RevisionTable
                        entity={entity}
                        revision={selectedRevision.entity}
                        fields={fields}
                        onSelectFields={setSelectedFields}
                    />
                    <Checkbox id="allow_user_edit" label="allow_user_edit" i18n="data" value={allowUserEdit} onChange={(v) => setAllowUserEdit(!!v)} />
                    <Checkbox id="allow_workspace_edit" label="allow_workspace_edit" i18n="data" value={allowWorkspaceEdit} onChange={(v) => setAllowWorkspaceEdit(!!v)} />
                </Fragment>
            )}
        </Modal>
    )
}
export default RevisionModal;