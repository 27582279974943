// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input[input-type=scrollable-list] {
  overflow: hidden;
  height: 100%;
}
.input[input-type=scrollable-list] .input-field {
  height: unset;
  overflow: hidden;
  height: 100%;
}
.input[input-type=scrollable-list] .input-field > .scrollable-list {
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  border: var(--theme-input-border-color) 1px solid;
  background-color: var(--theme-input-background-color);
  border-radius: 4px;
  height: 100%;
  width: 100%;
}
.input[input-type=scrollable-list] .input-field > .scrollable-list > * {
  padding: 4px 16px 4px 8px;
  cursor: pointer;
}
.input[input-type=scrollable-list] .input-field > .scrollable-list > *:hover {
  background-color: var(--theme-input-hover-background-color);
}
.input[input-type=scrollable-list] .input-field > .scrollable-list > *.selected {
  background-color: var(--theme-color-success);
}`, "",{"version":3,"sources":["webpack://./src/sg-react/form/ScrollableList/index.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,YAAA;AACJ;AACI;EACI,aAAA;EACA,gBAAA;EACA,YAAA;AACR;AACQ;EACI,gBAAA;EACA,aAAA;EACA,sBAAA;EACA,iDAAA;EACA,qDAAA;EACA,kBAAA;EACA,YAAA;EACA,WAAA;AACZ;AACY;EACI,yBAAA;EACA,eAAA;AAChB;AACgB;EACI,2DAAA;AACpB;AAEgB;EACI,4CAAA;AAApB","sourcesContent":[".input[input-type=\"scrollable-list\"] {\n    overflow: hidden;\n    height: 100%;\n\n    & .input-field {\n        height: unset;\n        overflow: hidden;\n        height: 100%;\n\n        & > .scrollable-list {\n            overflow-y: auto;\n            display: flex;\n            flex-direction: column;\n            border: var(--theme-input-border-color) 1px solid;\n            background-color: var(--theme-input-background-color);\n            border-radius: 4px;\n            height: 100%;\n            width: 100%;\n\n            & > * {\n                padding: 4px 16px 4px 8px;\n                cursor: pointer;\n\n                &:hover {\n                    background-color: var(--theme-input-hover-background-color);\n                }\n\n                &.selected {\n                    background-color: var(--theme-color-success);\n                }\n            }\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
