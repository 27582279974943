// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input[input-type=image] .input-field {
  border: none;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.input[input-type=image] .input-field > .input-actions {
  display: flex;
  font-size: 12px;
}
.input[input-type=image] .input-field > .input-actions > span {
  margin-right: 6px;
  color: var(--theme-color-grey-medium);
  cursor: pointer;
}
.input[input-type=image].input-small .input-field {
  height: -moz-fit-content;
  height: fit-content;
}
.input[input-type=image].input-inline > .input-field {
  height: -moz-fit-content;
  height: fit-content;
}`, "",{"version":3,"sources":["webpack://./src/sg-react/form/ImageField/index.scss"],"names":[],"mappings":"AACI;EACI,YAAA;EACA,6BAAA;EACA,aAAA;EACA,mBAAA;EACA,8BAAA;AAAR;AAEQ;EACI,aAAA;EACA,eAAA;AAAZ;AAEY;EACI,iBAAA;EACA,qCAAA;EACA,eAAA;AAAhB;AAMQ;EACI,wBAAA;EAAA,mBAAA;AAJZ;AAQI;EACI,wBAAA;EAAA,mBAAA;AANR","sourcesContent":[".input[input-type=\"image\"] {\n    & .input-field {\n        border: none;\n        background-color: transparent;\n        display: flex;\n        align-items: center;\n        justify-content: space-between;\n\n        & > .input-actions {\n            display: flex;\n            font-size: 12px;\n\n            & > span {\n                margin-right: 6px;\n                color: var(--theme-color-grey-medium);\n                cursor: pointer;\n            }\n        }\n    }\n\n    &.input-small {\n        & .input-field {\n            height: fit-content;\n        }\n    }\n\n    &.input-inline > .input-field {\n        height: fit-content;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
