import { createContext, ReactNode, useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { v4 } from 'uuid';
import '../styles/index.scss';
import { Spinner, ToastQueue } from "../ui";
import { ToastData } from "../ui/Toast";
import { useAuthContext } from "./AuthContext";

interface UiProviderProps {
    children: ReactNode;
    theme?: string;
}

export type UIContextType = {
    toasts: ToastData[];
    addToast: (t: Omit<ToastData, '_id'>) => void;
    removeToast: (t: ToastData) => void;
    isNavToggled: boolean,
    toggleNav: () => void,
    setNavToggled: (toggled: boolean) => void,
    setSpinnerVisible: (_isSpinnerVisible: boolean) => void,
    isDarkMode: boolean,
};

export const UiContext = createContext<UIContextType>({
    toasts: [],
    addToast: () => null,
    removeToast: () => null,
    isNavToggled: false,
    toggleNav: () => null,
    setNavToggled: (_) => null,
    setSpinnerVisible: (_) => null,
    isDarkMode: false,
});

const UiProvider = ({ children, theme }: UiProviderProps) => {
    const { currentUser } = useAuthContext();
    const { i18n } = useTranslation();
    const [isNavToggled, setNavToggled] = useState<boolean>(false);
    const [isSpinnerVisible, setSpinnerVisible] = useState<boolean>(false);
    const [isDarkMode, setDarkMode] = useState<boolean>(false);
    const [isInit, setInit] = useState<boolean>(false);
    const [toasts, setToasts] = useState<ToastData[]>([]);

    const toggleNav = useCallback(() => setNavToggled(isNavToggled => !isNavToggled), []);
    const addToast = useCallback((toast: Omit<ToastData, '_id'>) => setToasts(toasts => [...toasts, { ...toast, _id: v4() }]), []);
    const removeToast = useCallback((toast: ToastData) => setToasts(toasts => toasts.filter(t => t._id !== toast._id)), []);

    useEffect(() => {
        const language = currentUser?.settings?.language ?? (['en', 'fr'].includes(navigator.language) ? navigator.language : 'en');

        if (i18n.language !== language) {
            i18n.changeLanguage(language)
        }

        if (currentUser?.settings?.darkMode !== undefined) {
            setDarkMode(currentUser.settings.darkMode);
        } else if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
            setDarkMode(true);
        }

        if (!isInit) setInit(true);
    }, [currentUser]);

    if (!isInit) return null;

    return (
        <UiContext.Provider value={{
            toasts,
            addToast,
            removeToast,
            isNavToggled,
            setNavToggled,
            toggleNav,
            setSpinnerVisible,
            isDarkMode
        }}>
            <div id="app" className={`theme-${theme} theme-${isDarkMode ? 'dark' : 'light'}`}>
                {children}
                <ToastQueue />
                <div id="spinner" className={!!isSpinnerVisible ? 'visible' : ''}>
                    <Spinner />
                </div>
            </div>
        </UiContext.Provider>
    );
};

const useUiContext = () => {
    return useContext(UiContext);
};

export { UiProvider, useUiContext };
export default UiContext;