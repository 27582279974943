import { useCallback } from 'react';
import { DirectoryItemViewPatchProps } from '..';
import SegmentationsTree from '../../../components/SegmentationsTree';
import { useRequest } from '../../../sg-react/context';
import { Card } from '../../../sg-react/ui';

const DirectoryDataSegmentations = ({ directoryItem, directoryData, refresh, canEdit }: DirectoryItemViewPatchProps) => {
    const request = useRequest();

    const handleChange = useCallback(async (segmentationsIds: string[]) => {
        request.patch(`/directory-data/${directoryItem._id}/segmentations`, { segmentationsIds }, {
            loader: true,
            withWorkspace: true,
            errorMessage: true,
        })
            .then(() => refresh())
            .catch(() => null);
    }, [request, refresh, directoryItem._id]);

    return (
        <Card title="segmentations" i18n="segmentations">
            <SegmentationsTree defaultMode="selected" onSelect={canEdit ? handleChange : undefined} selected={directoryData.segmentationsIds} />
        </Card>
    )
}
export default DirectoryDataSegmentations