import { User } from "../sg-react/models/user";

export type DirectoryContact = {
    _id: string;
    userId?: string;
    user?: User;
    contact?: DirectoryExternalContact;
    internal: boolean;
};

export type DirectoryExternalContact = {
    firstName: string;
    lastName: string;
    position: string;
    company: string;
    service: ContactService;
    phone?: string;
    email?: string;
};

export enum ContactService {
    Marketing = "MARKETING",
    Quality = "QUALITY",
    Customer = "CUSTOMER",
    ExecutiveManagement = "EXECUTIVE_MANAGEMENT",
}
