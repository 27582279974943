import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { translateLabel } from '../../utils/format';
import SoftContainer from '../SoftContainer';
import './index.scss';

interface ButtonBigProps {
    label: string;
    icon?: ReactNode;
    className?: string;
    i18n?: string;
    onClick?: () => void;
}

const ButtonBig = ({ label, icon, i18n, onClick, className }: ButtonBigProps) => {
    const { t } = useTranslation();

    return (
        <SoftContainer className={`button-big ${className ?? ''}`} onClick={onClick ? () => onClick() : undefined}>
            {icon}
            <span>{translateLabel(label, t, i18n)}</span>
        </SoftContainer>
    );
}

export default ButtonBig;