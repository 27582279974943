import { Fragment, ReactNode, useMemo, useState } from "react";
import { getStringValue } from "../../utils/format";
import { Diff, DiffArrayItem, DiffField, objectDiff } from "../../utils/objects";
import './index.scss';
import { DownIcon, RightIcon } from "../../icons";

interface FieldDiffProps<T, U extends keyof T> {
    field: U;
    diff?: DiffField<T[U]>;
    mapper?: (e?: T[U]) => ReactNode;
}

const FieldDiff = <T, U extends keyof T>({ field, diff, mapper }: FieldDiffProps<T, U>) => {
    const [isCollapsed, setCollapsed] = useState<boolean>(true);

    const row = useMemo(() => {
        if (!diff) return (null);
        if (diff.items) {
            return (
                <Fragment>
                    <div
                        className={`array ${diff.status}`}
                        onClick={() => setCollapsed(!isCollapsed)}
                    >
                        <div>
                            {!!isCollapsed ? <RightIcon /> : <DownIcon />}
                            {field as string}
                        </div>
                    </div>
                    {!isCollapsed && diff.items.map(i => {
                        const mappedItem = mapper ? mapper([i.item] as T[U]) : getStringValue(i.item);
                        return (
                            <div key={JSON.stringify(i.item)} className={i.status}>
                                <div></div>
                                <div>{i.status !== 'added' && mappedItem}</div>
                                <div>{i.status !== 'deleted' && mappedItem}</div>
                            </div>
                        );
                    })}
                </Fragment>
            )
        }

        return (
            <div className={diff.status}>
                <div>{field as string}</div>
                <div>{mapper ? mapper(diff.current) : getStringValue(diff.current)}</div>
                <div>{mapper ? mapper(diff.updated) : getStringValue(diff.updated)}</div>
            </div>
        )
    }, [field, diff, isCollapsed, mapper]);

    return row;
}


interface ObjectDiffProps<T> {
    current?: T;
    updated?: T;
    diff?: Diff<T>;
    mappers?: { [key in keyof T]?: (e?: T[key]) => ReactNode }
}

const ObjectDiff = <T,>({ current, updated, diff, mappers }: ObjectDiffProps<T>) => {
    const diffComponent = useMemo(() => {
        const _diff: Diff<T> | null = diff ? diff : current && updated ? objectDiff(current, updated) : null;

        if (!_diff) return (null);

        return Object.keys(_diff).map(key => (
            <FieldDiff
                key={key}
                field={key as keyof T}
                diff={_diff[key as keyof T]}
                mapper={mappers?.[key as keyof T]}
            />
        ));

    }, [current, updated, diff, mappers]);

    return (
        <div className="object-diff">
            <div className="object-diff-header">
                <div>Field</div>
                <div>Current data</div>
                <div>Revision Data</div>
            </div>
            {diffComponent}
        </div>
    )
}

export default ObjectDiff;