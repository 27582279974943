import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { DirectoryDataDashboardDate } from "../../../models/directory-data";
import { useRequest } from "../../../sg-react/context";
import { CalendarSearchIcon } from "../../../sg-react/icons";
import { Card, Nothing } from "../../../sg-react/ui";
import Calendar from "../../../sg-react/ui/Calendar";
import ValueDescription from "../../../sg-react/ui/ValueDescription";
import { Colors } from "../../../sg-react/ui/enums";
import { areSameDay, dateToLocaleString } from "../../../sg-react/utils/date";

const DashboardDates = () => {
    const request = useRequest();
    const [dates, setDates] = useState<DirectoryDataDashboardDate[]>([]);
    const [currentDate, setCurrentDate] = useState<Date>(new Date());
    const { t } = useTranslation();
    const navigate = useNavigate();

    const getDateColor = useCallback((date: Date) => dates.some(d => areSameDay(d.date.date, date)) ? Colors.SUCCESS : undefined, [dates]);

    useEffect(() => {
        request.get<DirectoryDataDashboardDate[]>('/directory-data/dashboard/dates', { params: { date: currentDate }, withWorkspace: true })
            .then(setDates)
            .catch(() => setDates([]));
    }, [currentDate]);

    return (
        <Card>
            <Calendar weekDayStart={1} initialDate={currentDate} onMonthChange={setCurrentDate} getDateColor={getDateColor} />
            {dates.length ? (
                <div className="simple-list">
                    {dates.map(d => {
                        const days = Math.round((d.date.date.getTime() - Date.now()) / (1000 * 3600 * 24));
                        return (
                            <div key={d.date._id} className="pointer" onClick={() => navigate(`/view/${d._id}`)}>
                                <ValueDescription
                                    wrap
                                    tag={{ label: `${days} ${days > 1 ? t('dates:days').toLowerCase() : t('dates:day').toLowerCase()}`, color: days < 30 ? Colors.ERROR : days < 90 ? Colors.WARNING : Colors.PRIMARY }}
                                    value={`${dateToLocaleString(d.date.date)} ${d.name}`} description={d.date.name}
                                />
                            </div>
                        );
                    })}
                </div>
            ) : (
                <Nothing inline icon={<CalendarSearchIcon />}>
                    <span>{t('dates:no_date')}</span>
                </Nothing>
            )}
        </Card >
    )
}
export default DashboardDates;