import { User } from "./user";
import { Workspace } from "./workspace";

export type Permissions = {
    workspaces: WorkspacePermission[];
    users: UserPermission[];
    allowImport: boolean;
    allowSg: boolean;
};

export enum Permission {
    View = "VIEW",
    Edit = "EDIT",
    Delete = "DELETE",
    Create = "CREATE",
    Admin = "ADMIN",
}

export type WorkspacePermission = {
    workspaceId: string;
    workspace?: Workspace;
    permission: Permission;
};

export type UserPermission = {
    userId: string;
    user?: User;
    permission: Permission;
};
