import { DirectoryItem } from "../../../models/directory-item";
import { TextField } from "../../../sg-react/form";
import { WizardContentComponentProps } from "../../../sg-react/ui/Wizard";

const DirectoryItemCreateIdentification = ({ entity, attachInput }: WizardContentComponentProps<DirectoryItem>) => {
    return (
        <div className="form-inline-group">
            <TextField label inline i18n="directory" {...attachInput('name')} />
            <TextField label inline i18n="directory" {...attachInput('localBusinessId')} />
        </div>
    )
}
export default DirectoryItemCreateIdentification;