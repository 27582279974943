// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input[input-type=switch] .input-field {
  display: flex;
  padding: 3px !important;
  border-radius: 4px;
  background-color: var(--theme-input-background-color);
  overflow: hidden;
  width: -moz-fit-content;
  width: fit-content;
  flex-grow: 0;
}
.input[input-type=switch] .input-field:hover {
  background-color: var(--theme-input-hover-background-color);
}
.input[input-type=switch] .input-field > * {
  display: flex;
  flex-basis: 1;
  padding: 2px 6px;
  height: 100%;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: var(--theme-input-font-color);
  letter-spacing: var(--theme-input-letter-spacing);
  border-radius: var(--theme-input-border-radius);
  font-size: var(--theme-input-font-size);
  font-family: var(--theme-input-font-family);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.input[input-type=switch] .input-field > *.switch-item-selected {
  background-color: var(--theme-container-background-color);
}`, "",{"version":3,"sources":["webpack://./src/sg-react/form/Switch/index.scss"],"names":[],"mappings":"AACI;EACI,aAAA;EACA,uBAAA;EACA,kBAAA;EACA,qDAAA;EACA,gBAAA;EACA,uBAAA;EAAA,kBAAA;EACA,YAAA;AAAR;AAEQ;EACI,2DAAA;AAAZ;AAGQ;EACI,aAAA;EACA,aAAA;EACA,gBAAA;EACA,YAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;EACA,eAAA;EACA,oCAAA;EACA,iDAAA;EACA,+CAAA;EACA,uCAAA;EACA,2CAAA;EACA,gBAAA;EACA,mBAAA;EACA,uBAAA;AADZ;AAGY;EACI,yDAAA;AADhB","sourcesContent":[".input[input-type=\"switch\"] {\n    & .input-field {\n        display: flex;\n        padding: 3px !important;\n        border-radius: 4px;\n        background-color: var(--theme-input-background-color);\n        overflow: hidden;\n        width: fit-content;\n        flex-grow: 0;\n\n        &:hover {\n            background-color: var(--theme-input-hover-background-color);\n        }\n\n        & > * {\n            display: flex;\n            flex-basis: 1;\n            padding: 2px 6px;\n            height: 100%;\n            box-sizing: border-box;\n            align-items: center;\n            justify-content: center;\n            cursor: pointer;\n            color: var(--theme-input-font-color);\n            letter-spacing: var(--theme-input-letter-spacing);\n            border-radius: var(--theme-input-border-radius);\n            font-size: var(--theme-input-font-size);\n            font-family: var(--theme-input-font-family);\n            overflow: hidden;\n            white-space: nowrap;\n            text-overflow: ellipsis;\n\n            &.switch-item-selected {\n                background-color: var(--theme-container-background-color);\n            }\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
