import { Fragment, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Bar, BarChart, LabelList, Legend, Line, LineChart, ResponsiveContainer, XAxis, YAxis } from "recharts";
import { DirectoryItemViewPatchProps } from "..";
import SegmentationsTree from "../../../components/SegmentationsTree";
import { DirectoryRevenue } from "../../../models/directory-data";
import { useRequest } from "../../../sg-react/context";
import { ModalDelete } from "../../../sg-react/data";
import DataContainerList, { DataContainerListColumn } from "../../../sg-react/data/DataContainerList";
import { DatePicker, NumberField, Select, Switch } from "../../../sg-react/form";
import Validation, { ObjectValidation } from "../../../sg-react/form/Validation/class";
import { useForm } from "../../../sg-react/hooks";
import { EditIcon, PlusIcon, TrashIcon } from "../../../sg-react/icons";
import { Button, Card, Modal } from "../../../sg-react/ui";
import { COLORS_FOR_CHARTS } from "../../../sg-react/utils/constants";
import { dateToLocaleString } from "../../../sg-react/utils/date";

const VALIDATION = new ObjectValidation<DirectoryRevenue>({
    segmentationId: Validation.string().required(),
    year: Validation.number().required().integer().greaterThan(1970).lesserThan(2040),
    amount: Validation.number().required().positive(),
    closingDate: Validation.date().required(),
})

interface RevenueActionsProps {
    onEdit: () => void;
    onDelete: () => void;
}

const RevenueActions = ({ onEdit, onDelete }: RevenueActionsProps) => (
    <div className="row no-gap">
        <Button color="navigation" icon={<TrashIcon />} small onClick={onDelete} />
        <Button color="navigation" icon={<EditIcon />} small onClick={onEdit} />
    </div>
)

const DirectoryViewRevenues = ({ directoryItem, directoryData, canEdit, refresh }: DirectoryItemViewPatchProps) => {
    const { t } = useTranslation();
    const { entity, reset, attachInput, validate, onChange } = useForm<DirectoryRevenue>({}, VALIDATION);
    const [selectedYear, setSelectedYear] = useState<number>(new Date().getFullYear());
    const [revenueToDelete, setRevenueToDelete] = useState<DirectoryRevenue | null>(null);
    const [mode, setMode] = useState<string | undefined>('chart');
    const request = useRequest();

    const modes = useMemo(() => [{ key: 'chart', label: t('charts:chart') }, { key: 'list', label: t('data:list') }, { key: 'hist', label: t('data:history') }], []);

    const availableYears = useMemo(() => {
        const years = [new Date().getFullYear()];

        for (const revenue of directoryData.revenues ?? []) {
            if (!years.includes(revenue.year)) {
                years.push(revenue.year);
            }
        }
        return years.sort((y1, y2) => y1 > y2 ? -1 : 1).map(y => ({ key: y, label: String(y) }));
    }, [directoryData]);

    const revenuesForYear = useMemo(() => directoryData.revenues?.filter(r => r.year === selectedYear), [directoryData, selectedYear]);

    const revenuesForYearChartData = useMemo(() => revenuesForYear?.map((r, i) => ({
        name: r.segmentation?.name ?? r.segmentationId,
        amount: r.amount,
        fill: COLORS_FOR_CHARTS[i % COLORS_FOR_CHARTS.length]
    })), [revenuesForYear]);

    const revenuesHistoryChartData = useMemo(() => {
        const data: { name: string, color: string, values: { year: number, amount: number }[] }[] = [];

        for (const revenue of directoryData.revenues ?? []) {
            const index = data.findIndex(r => r.name === (revenue.segmentation?.name ?? revenue.segmentationId));

            if (index === -1) {
                data.push({
                    name: revenue.segmentation?.name ?? revenue.segmentationId,
                    color: COLORS_FOR_CHARTS[data.length % COLORS_FOR_CHARTS.length],
                    values: [revenue]
                })
            } else {
                data[index].values.push(revenue);
            }
        }
        return {
            domain: [Math.min(...availableYears.map(a => a.key)), Math.max(...availableYears.map(a => a.key))],
            data
        };
    }, [directoryData, availableYears]);

    const createOrUpdateProject = useCallback(() => validate((entity) => {
        const requestMethod = !entity._id ? request.post : request.put;

        requestMethod(`/directory-data/${directoryItem._id}/revenue`, entity, {
            loader: true,
            withWorkspace: true,
            successMessage: true,
            errorMessage: true,
        })
            .then(() => {
                reset({});
                refresh();
            })
            .catch(() => null);
    }), [request, directoryItem, refresh, validate, reset]);

    const handleDelete = useCallback((revenue: DirectoryRevenue) => {
        request.delete(`/directory-data/${directoryItem._id}/revenue/${revenue._id}`, {
            loader: true,
            withWorkspace: true,
            successMessage: true,
            errorMessage: true,
        })
            .then(() => {
                setRevenueToDelete(null);
                refresh();
            })
            .catch(() => null);
    }, [request, directoryItem, refresh]);

    return (
        <Card
            i18n="application"
            title="widgets.REVENUE"
            options={(
                <Fragment>
                    {canEdit && <Button color="navigation" label="create" i18n="actions" icon={<PlusIcon />} onClick={() => reset({ year: selectedYear })} />}
                    <Switch id="specifications-switch" items={modes} value={mode} onChange={setMode} />
                </Fragment>
            )}
        >
            {mode !== 'hist' && (
                <Select id="selectedYear" items={availableYears} value={selectedYear} onChange={(y) => y ? setSelectedYear(y) : null} />
            )}
            {mode === 'list' && (
                <DataContainerList<DirectoryRevenue>
                    primaryKey="_id"
                    data={revenuesForYear}
                    columns={[
                        { field: 'name', label: 'name', i18n: 'entity', display: (e) => e.segmentation?.name ?? e.segmentationId },
                        { field: 'year', label: 'year', i18n: 'dates' },
                        { field: 'amount', label: 'amount', i18n: 'directory-data' },
                        { field: 'closingDate', label: 'closingDate', i18n: 'directory-data', display: (e) => dateToLocaleString(e.closingDate) },
                        ...(canEdit ? [
                            { field: 'actions', display: (e) => <RevenueActions onDelete={() => setRevenueToDelete(e)} onEdit={() => reset(e)} /> },
                        ] as DataContainerListColumn<DirectoryRevenue>[] : [])
                    ]}
                    sort={{ field: "segmentationId", direction: 1 }}
                />
            )}
            {mode === 'chart' && !!revenuesForYearChartData?.length && (
                <ResponsiveContainer minWidth="100%" width="100%" height={300}>
                    <BarChart
                        data={revenuesForYearChartData}
                        layout="horizontal"
                        margin={{ top: 30 }}
                    >
                        <LabelList dataKey="name" position="right" />
                        <XAxis type="category" dataKey="name" />
                        <YAxis type="number" />
                        <Bar dataKey="amount" barSize={20}>
                            <LabelList dataKey="amount" position="top" style={{ fill: "white" }} />
                        </Bar>
                    </BarChart>
                </ResponsiveContainer>
            )}
            {mode === 'hist' && (
                <ResponsiveContainer minWidth="100%" width="100%" height={250}>
                    <LineChart>
                        <XAxis
                            dataKey="year"
                            type="category"
                            allowDuplicatedCategory={false}
                            domain={revenuesHistoryChartData.domain}
                        />
                        <YAxis dataKey="amount" domain={[0, 'auto']} />
                        <Legend formatter={(value) => <label>{value}</label>} />
                        {revenuesHistoryChartData.data.map(r => (
                            <Line dataKey="amount" data={r.values} name={r.name} key={r.name} strokeWidth={2} stroke={r.color} dot={false} />
                        ))}
                    </LineChart>
                </ResponsiveContainer>
            )}
            {
                !!entity?.year && (
                    <Modal
                        size="medium"
                        title={entity._id ? entity.segmentation?.name ?? entity.segmentationId : t('actions:create')}
                        onClose={() => reset({})}
                        onSubmit={createOrUpdateProject}
                    >
                        <div className="row row-equal-cols">
                            <NumberField label i18n="dates" {...attachInput('year')} />
                            <NumberField label i18n="directory-data" {...attachInput('amount')} />
                            <DatePicker label i18n="directory-data" {...attachInput('closingDate')} />
                        </div>
                        <SegmentationsTree selected={entity.segmentationId ? [entity.segmentationId] : []} hideSearch onSelect={(s) => s?.length ? onChange('segmentationId', s[s.length - 1]) : onChange('segmentationId', undefined)} />
                    </Modal>
                )
            }
            {!!revenueToDelete && <ModalDelete onClose={() => setRevenueToDelete(null)} onSubmit={() => handleDelete(revenueToDelete)} />}
        </Card >
    )
}
export default DirectoryViewRevenues;