export enum Role {
    SuperAdmin = "SUPER_ADMIN",
    Admin = "ADMIN",
    User = "USER",
}
export enum Action {
    Edit = "EDIT",
    Import = "IMPORT",
    Export = "EXPORT",
    Close = "CLOSE",
    Create = "CREATE",
    Settings = "SETTINGS",
    Privacy = "PRIVACY",
    Widgets = "WIDGETS",
    Notifications = "NOTIFICATIONS",
    Users = "USERS",
    Guests = "GUESTS",
}
