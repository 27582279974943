import { ReactNode } from 'react';
import './index.scss';

interface FakeInputProps {
    children: ReactNode;
}

const FakeInput = ({ children }: FakeInputProps) => (
    <div className="row fake-input">
        {children}
    </div>
);

export default FakeInput;