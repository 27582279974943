import { useState } from 'react';
import TagsList from '../../data/TagsList';
import { TagsPickerModal } from '../../data/TagsPicker';
import { EditIcon } from '../../icons';
import { Button } from '../../ui';
import { toggleInArray } from '../../utils/objects';
import InputWrapper, { FormPropsInterface } from '../InputWrapper';
import './index.scss';

interface TagsInputProps extends FormPropsInterface<string[]> {
    className?: string;
    category: string;
}

const TagsInput = ({
    value,
    disabled,
    category,
    onChange,
    ...rest
}: TagsInputProps) => {
    const [isTagsPickerVisible, setTagsPickerVisible] = useState<boolean>(false);

    return (
        <InputWrapper type="tags" {...rest}>
            <div className="row">
                <Button color="navigation" label="edit" i18n="actions" icon={<EditIcon />} onClick={() => setTagsPickerVisible(true)} />
                <TagsList tagsIds={value} onClose={onChange && !disabled ? (t) => onChange(toggleInArray(value, t._id)) : undefined} />
            </div>
            {isTagsPickerVisible && (
                <TagsPickerModal category={category} onClose={() => setTagsPickerVisible(false)} onSubmit={(tags) => onChange && !disabled ? onChange(tags) : null} />
            )}
        </InputWrapper>
    );
}

export default TagsInput;