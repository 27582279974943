// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.depth-container {
  height: 100%;
  overflow: hidden;
  border-radius: var(--theme-container-border-radius);
  background-color: var(--theme-container-background-color);
  background-image: var(--theme-container-background-image);
  border: var(--theme-container-border-color) 1px solid;
  position: relative;
}
.depth-container::after {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  box-shadow: var(--theme-container-inner-box-shadow);
  content: "";
}`, "",{"version":3,"sources":["webpack://./src/sg-react/ui/DepthContainer/index.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,gBAAA;EACA,mDAAA;EACA,yDAAA;EACA,yDAAA;EACA,qDAAA;EACA,kBAAA;AACJ;AACI;EACI,kBAAA;EACA,WAAA;EACA,YAAA;EACA,MAAA;EACA,OAAA;EACA,mDAAA;EACA,WAAA;AACR","sourcesContent":[".depth-container {\n    height: 100%;\n    overflow: hidden;\n    border-radius: var(--theme-container-border-radius);\n    background-color: var(--theme-container-background-color);\n    background-image: var(--theme-container-background-image);\n    border: var(--theme-container-border-color) 1px solid;\n    position: relative;\n\n    &::after {\n        position: absolute;\n        width: 100%;\n        height: 100%;\n        top: 0;\n        left: 0;\n        box-shadow: var(--theme-container-inner-box-shadow);\n        content: \"\";\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
