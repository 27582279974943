import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ModalNewPassword from "../../../auth/ModalNewPassword";
import { useAuthContext, useRequest } from "../../../context";
import { EditIcon } from "../../../icons";
import { TwoFactorMethod } from "../../../models/user";
import { Button, Card } from "../../../ui";
import Info from "../../../ui/Info";
import ListItem from "../../../ui/ListItem";
import { Colors } from "../../../ui/enums";
import { dateToLocaleString } from "../../../utils/date";

interface AuthData {
    passwordDate: Date;
    passwordDaysBeforeUpdate: number;
    twoFactorEnabled: boolean;
    twoFactorMethod: TwoFactorMethod;
    recoveryEmail: string | undefined;
    revoceryEmailVerified: boolean;
}

const Authentication = () => {
    const { t } = useTranslation();
    const { currentUser } = useAuthContext();
    const request = useRequest();
    const [authData, setAuthData] = useState<AuthData | null>(null);
    const [displayedModal, setDisplayedModal] = useState<'password' | 'twoFactor' | 'recovery' | 'email' | null>(null);

    const get = useCallback(() => {
        request.get<AuthData>('/users/auth-data')
            .then(setAuthData)
            .catch(() => null);
    }, [request]);

    useEffect(() => {
        get()
    }, []);

    return (
        <Card title="authentication" id="acount-authentication" i18n="users" loading={request.loading}>
            <Info type="warning" i18n="ui" label="degraded_feature" />
            {!!authData && (
                <div className="simple-list">
                    <ListItem
                        value={t('auth:password')}
                        description={t('data:set_on', { date: dateToLocaleString(authData.passwordDate) })}
                        tag={{ label: t('auth:update_password_days', { days: authData.passwordDaysBeforeUpdate }), color: authData.passwordDaysBeforeUpdate < 10 ? Colors.WARNING : Colors.PRIMARY }}
                    >
                        <Button small color="navigation" icon={<EditIcon />} onClick={() => setDisplayedModal('password')} />
                    </ListItem>
                    <ListItem
                        value={t('auth:twoFactor')}
                        description={authData.twoFactorEnabled
                            ? t(authData.twoFactorMethod === TwoFactorMethod.Email ? 'auth:email' : 'auth:qrcode')
                            : undefined}
                        tag={authData.twoFactorEnabled
                            ? { i18n: 'entity', label: 'enabled', color: Colors.SUCCESS }
                            : { i18n: 'entity', label: 'disabled', color: Colors.ERROR }}
                    >
                        <Button small color="navigation" icon={<EditIcon />} />
                    </ListItem>
                    <ListItem value={t('auth:email')} description={currentUser.email}>
                        <Button small color="navigation" icon={<EditIcon />} />
                    </ListItem>
                    <ListItem
                        value={t('auth:recovery_email')}
                        description={authData.recoveryEmail}
                        tag={authData.revoceryEmailVerified
                            ? { i18n: 'data', label: 'verified', color: Colors.SUCCESS }
                            : authData.recoveryEmail
                                ? { i18n: 'data', label: 'not_verified', color: Colors.WARNING }
                                : { i18n: 'data', label: 'unset', color: Colors.ERROR }
                        }
                    >
                        <Button small color="navigation" icon={<EditIcon />} />
                    </ListItem>
                </div>
            )}
            {displayedModal === 'password' && <ModalNewPassword onClose={() => setDisplayedModal(null)} />}
        </Card>
    )
}
export default Authentication;