import { ReactNode } from 'react';
import InputWrapper, { FormPropsInterface } from '../InputWrapper';

export interface NumberFieldProps extends FormPropsInterface<number> {
    placeholder?: string,
    icon?: ReactNode;
    onBlur?: (value: string) => void,
    onFocus?: (value: string) => void,
    onBlurValidationError?: (value: string) => void,
    onBlurValidationWarning?: (value: string) => void,
}

const NumberField = ({
    id,
    value,
    placeholder,
    disabled,
    onBlur,
    onFocus,
    onChange,
    onBlurValidationError,
    onBlurValidationWarning,
    ...rest
}: NumberFieldProps) => {
    return (
        <InputWrapper id={id} type="number" {...rest}>
            <input
                id={id}
                type="number"
                readOnly={!!disabled}
                onBlur={onBlur ? (e) => onBlur(e.target.value) : undefined}
                onFocus={onFocus ? (e) => onFocus(e.target.value) : undefined}
                onChange={onChange ? (e) => onChange(e.target.value ? Number(e.target.value) : undefined) : undefined}
                value={value ?? ''}
                placeholder={placeholder ?? ''}
            />
        </InputWrapper>
    );
}

export default NumberField;