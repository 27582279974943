import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { TrashIcon } from "../../icons";
import { Modal } from "../../ui";
import { Colors } from "../../ui/enums";

interface ModalDeleteProps {
    withDebounce?: boolean;
    onClose: () => void;
    onSubmit: () => void;
}

const ModalDelete = ({ withDebounce, onClose, onSubmit }: ModalDeleteProps) => {
    const { t } = useTranslation();
    const [isDebounced, setDebounced] = useState<boolean>(!withDebounce);

    useEffect(() => {
        let timeout: string | number | NodeJS.Timeout | undefined = undefined;
        if (withDebounce) timeout = setTimeout(() => setDebounced(true), 5000);

        return () => {
            timeout && clearTimeout(timeout);
        };
    }, [withDebounce]);

    return (
        <Modal
            id="modal-delete"
            size="small"
            title={t('actions:delete')}
            actions={[
                { i18n: 'actions', label: 'cancel', onClick: onClose, color: Colors.SECONDARY },
                { i18n: 'actions', label: 'submit', onClick: onSubmit, color: Colors.ERROR, icon: <TrashIcon />, disabled: !isDebounced },
            ]}
        >
            <span style={{ whiteSpace: 'pre-line' }}>{t('actions:delete_warning')}</span>
            <span style={{ whiteSpace: 'pre-line' }}>{t('actions:delete_warning_timeout')}</span>
        </Modal >
    );
}

export default ModalDelete;