import { Fragment, useCallback, useMemo, useRef, useState } from "react";
import { MapLayerMouseEvent, Marker } from "react-map-gl";
import { Geosearch, Map } from "../../data";
import { GeosearchResult } from "../../data/Geosearch";
import { MapRefProps } from "../../data/Map";
import { FormHookReturn } from "../../hooks/useForm";
import { LocatedEntity } from "../../models/geo";
import { Location } from "../../models/location";
import Info from "../../ui/Info";
import { conditionnalClassnames } from "../../utils/helpers";
import Checkbox from "../Checkbox";
import NumberField from "../NumberField";
import TextField from "../TextField";
import './index.scss';

interface LocationPickerProps extends Pick<FormHookReturn<Location>, 'onChange' | 'attachInput'> {
    inline?: boolean;
    entity?: { location?: Partial<Location> };
    manual?: boolean;
}

const LocationPicker = ({ entity, inline, onChange, attachInput, manual }: LocationPickerProps) => {
    const [isManual, setManual] = useState<boolean>(manual === true);
    const mapRef = useRef<MapRefProps>(null);

    const marker = useMemo(() => entity?.location?.latitude && entity?.location?.longitude ? (
        <Marker
            key="marker-directory"
            longitude={entity.location!.longitude}
            latitude={entity.location!.latitude}
            anchor="center"
        />
    ) : undefined, [entity]);

    const handleGeosearch = useCallback((e: GeosearchResult) => {
        onChange('location', {
            ...entity?.location,
            city: e.name,
            region: e.admin1 ?? e.admin2,
            country: e.country,
            longitude: e.longitude,
            latitude: e.latitude,
            geonameId: e.id,
            geonamesIds: e.geonamesIds
        });
        mapRef.current?.fitToEntities([{
            location: {
                longitude: e.longitude,
                latitude: e.latitude,
            }
        } as LocatedEntity])
    }, [onChange, entity?.location]);

    const handleMapClick = useCallback((e: MapLayerMouseEvent) => {
        onChange('location', {
            ...entity?.location,
            longitude: e.lngLat.lng,
            latitude: e.lngLat.lat,
        })
    }, [onChange, entity]);

    return (
        <div className="col col-layout location-picker">
            <Info i18n="location" label="address_tooltip" noIcon />
            <div className="row row-layout">
                <div className="col">
                    <Checkbox id="manual" label="manual" i18n="location" onChange={(v) => setManual(!!v)} value={isManual} />
                    <div className={conditionnalClassnames({ col: true, "form-inline-group": inline })}>
                        <TextField
                            i18n="location"
                            label="streetNumber"
                            tooltip="streetNumber_tooltip"
                            inline={inline}
                            disabled={!isManual}
                            {...attachInput('location.streetNumber')}
                        />
                        <TextField
                            i18n="location"
                            label="route"
                            tooltip="route_tooltip"
                            inline={inline}
                            disabled={!isManual}
                            {...attachInput('location.route')}
                        />
                        {isManual ? (
                            <Geosearch
                                i18n="location"
                                label="city"
                                value={entity?.location?.city}
                                inline={inline}
                                onResult={handleGeosearch}
                                populateResponse
                            />
                        ) : (
                            <TextField
                                i18n="location"
                                label="city"
                                inline={inline}
                                disabled
                                {...attachInput('location.city')}
                            />
                        )}
                        <TextField
                            i18n="location"
                            label="region"
                            inline={inline}
                            disabled
                            {...attachInput('location.region')}
                        />
                        <TextField
                            i18n="location"
                            label="country"
                            inline={inline}
                            disabled
                            {...attachInput('location.country')}
                        />
                        {!!isManual && (
                            <Fragment>
                                <TextField
                                    i18n="location"
                                    label="address"
                                    inline={inline}
                                    {...attachInput('location.address')}
                                />
                                <NumberField
                                    i18n="location"
                                    label="longitude"
                                    inline={inline}
                                    {...attachInput('location.longitude')}
                                />
                                <NumberField
                                    i18n="location"
                                    label="latitude"
                                    inline={inline}
                                    {...attachInput('location.latitude')}
                                />
                            </Fragment>
                        )}
                    </div>
                </div>
                <div className="col col-lg-40">
                    <Map
                        ref={mapRef}
                        markers={marker ? [marker] : undefined}
                        withGeocoder={!isManual}
                        onMapClick={isManual ? handleMapClick : undefined}
                        onGeocoderSelectResponse={(l) => onChange('location', l)}
                        fitToMarkers="onLoad"
                    />
                </div>
            </div>
        </div>
    );
}

export default LocationPicker;