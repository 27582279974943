import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SegmentationsTree from '../../components/SegmentationsTree';
import { DirectoryDataWidget } from '../../models/directory-data';
import { useRequest, useWorkspaceContext } from '../../sg-react/context';
import useForm, { FormHookReturn } from '../../sg-react/hooks/useForm';
import { WORKSPACE_VALIDATION, Workspace } from '../../sg-react/models/workspace';
import { Modal } from '../../sg-react/ui';
import { translateLabel } from '../../sg-react/utils/format';
import { conditionnalClassnames } from '../../sg-react/utils/helpers';
import WorkspaceSettingsInformations from './components/WorkspaceSettingsInformations';
import WorkspaceSettingsSalesIndactors from './components/WorkspaceSettingsSalesIndactors';
import WorkspaceSettingsUsers from './components/WorkspaceSettingsUsers';
import WorkspaceSettingsWidgets from './components/WorkspaceSettingsWidgets';
import './index.scss';

const ITEMS = [
    { id: 'workspace-settings-infos', i18n: 'workspaces', label: 'workspace' },
    { id: 'workspace-settings-users', i18n: 'users', label: 'users' },
    { id: 'workspace-settings-segmentations', i18n: 'segmentations', label: 'segmentations' },
    { id: 'workspace-settings-widgets', i18n: 'workspaces', label: 'widgets' },
    { id: 'workspace-settings-indicators', i18n: 'application', label: 'widgets.SALES_INDICATORS' },
];

export interface WorkspaceSettingsPatchProps {
    patch: (dto: any) => Promise<boolean>;
}

export interface WorkspaceSettingsFormProps extends Pick<FormHookReturn<Workspace>, 'entity' | 'attachInput' | 'onChange'> { }

export interface WorkspaceSettingsProps {
    workspaceId?: string;
    onClose: () => void;
}

const WorkspaceSettings = ({ workspaceId, onClose }: WorkspaceSettingsProps) => {
    const { currentWorkspace, refreshWorkspaces } = useWorkspaceContext();
    const { t } = useTranslation();
    const request = useRequest();
    const [currentItem, setCurrentItem] = useState<string>(ITEMS[0].id);
    const { entity, attachInput, reset, validate, hasChanged, onChange } = useForm<Workspace>({}, WORKSPACE_VALIDATION);

    const itemsComponents = useMemo(() => ITEMS.map(i => (
        <div
            key={i.label}
            className={conditionnalClassnames({
                active: i.id === currentItem,
                disabled: i.id === 'workspace-settings-indicators' && !entity.widgets?.includes(DirectoryDataWidget.SalesIndicators),
            })}
            onClick={() => setCurrentItem(i.id)}
        >
            {translateLabel(i.label, t, i.i18n)}
        </div>
    )), [currentItem, entity]);

    const handleSubmit = useCallback(() => !hasChanged
        ? onClose()
        : validate((entity) => {
            return request.put<Workspace>('/workspaces', entity, {
                loader: true,
                successMessage: true,
                errorMessage: true
            })
                .then(() => {
                    refreshWorkspaces();
                    onClose();
                })
                .catch(() => null);
        }), [currentWorkspace, request, validate, onClose, hasChanged, refreshWorkspaces]);

    const get = useCallback(() => {
        request.get<Workspace>(`/workspaces/${workspaceId ?? currentWorkspace._id}/edit`)
            .then(reset)
            .catch(() => onClose());
    }, [request, currentWorkspace, workspaceId, onClose, reset]);
    /* <SegmentationsTree editable /> */

    useEffect(() => {
        get();
    }, []);

    if (!entity._id) return null;

    return (
        <Modal
            id="workspace-settings"
            title="settings"
            i18n="ui"
            size="medium"
            overflow="hidden"
            onClose={onClose}
            onSubmit={handleSubmit}
        >
            <div className="col" id="workspace-settings-items">
                {itemsComponents}
            </div>
            <div className="col col-layout" id="workspace-settings-content">
                {currentItem === ITEMS[0].id && <WorkspaceSettingsInformations entity={entity} attachInput={attachInput} onChange={onChange} />}
                {currentItem === ITEMS[1].id && <WorkspaceSettingsUsers entity={entity} attachInput={attachInput} onChange={onChange} />}
                {currentItem === ITEMS[2].id && <SegmentationsTree editable />}
                {currentItem === ITEMS[3].id && <WorkspaceSettingsWidgets entity={entity} attachInput={attachInput} onChange={onChange} />}
                {currentItem === ITEMS[4].id && <WorkspaceSettingsSalesIndactors entity={entity} attachInput={attachInput} onChange={onChange} />}
            </div>
        </Modal>
    )
}

export default WorkspaceSettings;