import { DirectoryItemType } from "../../models/directory-item";
import { BuildingIcon, EnergyIcon, FactoryIcon, MarketIcon, OfficeIcon } from "../../sg-react/icons";

interface DirectoryItemIconProps {
    type?: DirectoryItemType[];
}

const DirectoryItemIcon = ({ type }: DirectoryItemIconProps) => {
    if (type?.includes(DirectoryItemType.Headquarters)) {
        return <OfficeIcon className="icon directory-item-icon" />;
    } else if (type?.includes(DirectoryItemType.Factory)) {
        return <FactoryIcon className="icon directory-item-icon" />;
    } else if (type?.includes(DirectoryItemType.Distributor)) {
        return <MarketIcon className="icon directory-item-icon" />;
    } else if (type?.includes(DirectoryItemType.PowerStation)) {
        return <EnergyIcon className="icon directory-item-icon" />;
    } else {
        return <BuildingIcon className="icon directory-item-icon" />;
    }
}

export default DirectoryItemIcon;