import { ReactNode, useMemo } from 'react';
import { ButtonProps } from '../Button';
import Card from '../Card';
import './index.scss';
import { Colors } from '../enums';

interface ModalProps {
    className?: string;
    id?: string;
    children?: ReactNode;
    title?: string;
    i18n?: string;
    size?: 'xs' | 'small' | 'medium' | 'large';
    onSubmit?: () => void;
    onClose?: () => void;
    actions?: ButtonProps[];
    overflow?: 'visible' | 'hidden' | 'clip' | 'scroll' | 'auto';
    options?: ReactNode;
}

const Modal = ({ className, title, i18n, children, id, onClose, onSubmit, size = 'medium', overflow, actions, options }: ModalProps) => {
    const actionsProps = useMemo(() => {
        const actionsProps: ButtonProps[] = [];

        if (onSubmit && onClose) actionsProps.push({
            color: Colors.SECONDARY,
            onClick: onClose,
            i18n: 'actions',
            label: 'close'
        });

        if (actions?.length) actionsProps.push(...actions);

        if (onSubmit) actionsProps.push({
            color: Colors.PRIMARY,
            onClick: onSubmit,
            i18n: 'actions',
            label: 'submit'
        });

        return actionsProps
    }, [actions, onSubmit, onClose]);

    return (
        <div className={`modal-backdrop ${children ? 'modal-backdrop-visible' : ''}`}>
            <Card
                id={id}
                i18n={i18n}
                title={title}
                className={`modal modal-${size} ${className ?? ''}`}
                onClose={onClose}
                actions={actionsProps}
                options={options}
                overflow={overflow ?? 'auto'}
            >
                {children}
            </Card>
        </div>
    )
}

export default Modal;