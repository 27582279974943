// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input[input-type=checkbox-list] .input-field {
  height: -moz-fit-content;
  height: fit-content;
  flex-direction: column;
  background-color: transparent;
  align-items: flex-start;
  gap: 4px;
}`, "",{"version":3,"sources":["webpack://./src/sg-react/form/CheckboxList/index.scss"],"names":[],"mappings":"AACI;EACI,wBAAA;EAAA,mBAAA;EACA,sBAAA;EACA,6BAAA;EACA,uBAAA;EACA,QAAA;AAAR","sourcesContent":[".input[input-type=\"checkbox-list\"] {\n    & .input-field {\n        height: fit-content;\n        flex-direction: column;\n        background-color: transparent;\n        align-items: flex-start;\n        gap: 4px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
