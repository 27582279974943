import { useTranslation } from "react-i18next";
import { DirectoryImportProps } from "..";
import { CloudSuccessIcon } from "../../../sg-react/icons";

const DirectoryImportComplete = ({ }: DirectoryImportProps) => {
    const { t } = useTranslation();

    return (
        <div id="directory-import-complete" className="col">
            <CloudSuccessIcon className="icon" />
            <span>{t('data:import.complete')}</span>
        </div>
    )
}
export default DirectoryImportComplete;