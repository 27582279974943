import { Fragment, ReactNode, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { DirectoryItem } from "../../../models/directory-item";
import { useAuthContext, useWorkspaceContext } from "../../../sg-react/context";
import { Permission } from "../../../sg-react/models/permissions";
import { BubbleHead } from "../../../sg-react/ui";
import Info from "../../../sg-react/ui/Info";
import LabelValue from "../../../sg-react/ui/LabelValue";
import { WizardContentComponentProps } from "../../../sg-react/ui/Wizard";
import { Role } from "../../../sg-react/utils/enums";

interface DirectoryItemEditSummaryItemProps {
    i18n: string;
    label: string;
    initialValue?: ReactNode;
    value?: ReactNode;
    hasChanged?: boolean;
}

const DirectoryItemEditSummaryItem = ({ i18n, label, value, initialValue, hasChanged }: DirectoryItemEditSummaryItemProps) => (
    <LabelValue
        inline
        fixed
        displayIfNull
        i18n={i18n}
        label={label}
        value={(hasChanged === undefined && value === initialValue) || (hasChanged === false)
            ? value
            : !value
                ? <span className="color-error">{initialValue}</span>
                : !initialValue
                    ? <span className="color-success">{value}</span>
                    : <span>{initialValue} &gt;&gt; <span className="color-warning">{value}</span></span>
        }
    />
)

const DirectoryItemEditSummary = ({ entity, initial, hasChanged }: WizardContentComponentProps<DirectoryItem>) => {
    const { t } = useTranslation();
    const { currentWorkspace } = useWorkspaceContext();
    const { currentUser, currentRole } = useAuthContext();

    const canEdit = useMemo(() => !!entity.permissions?.workspaces.find(w => w.workspaceId === currentWorkspace._id && (w.permission === Permission.Edit || w.permission === Permission.Admin))
        || entity.permissions?.users?.some(u => u.userId === currentUser._id && u.permission === Permission.Edit)
        || currentRole !== Role.User, [entity, currentUser, currentRole, currentWorkspace]);

    return (
        <Fragment>
            {!canEdit && <Info type="warning" i18n="entity" label="revision_required_tooltip" />}
            {!hasChanged
                ? <Info type="success" i18n="entity" label="no_change" />
                : (
                    <div className="col">
                        <h3>{t('directory:informations')}</h3>
                        <div className="simple-list">
                            <DirectoryItemEditSummaryItem i18n="entity" label="name" value={entity.name} initialValue={initial?.name} />
                            <DirectoryItemEditSummaryItem i18n="entity" label="description" value={entity.description} initialValue={initial?.description} />
                            <DirectoryItemEditSummaryItem i18n="directory" label="localBusinessId" value={entity.localBusinessId} initialValue={initial?.localBusinessId} />
                            <DirectoryItemEditSummaryItem i18n="directory" label="localBusinessIdType" value={entity.localBusinessIdType} initialValue={initial?.localBusinessIdType} />
                            <DirectoryItemEditSummaryItem i18n="directory" label="internationalBusinessId" value={entity.internationalBusinessId} initialValue={initial?.internationalBusinessId} />
                            <DirectoryItemEditSummaryItem i18n="directory" label="internationalBusinessIdType" value={entity.internationalBusinessIdType} initialValue={initial?.internationalBusinessIdType} />
                            <DirectoryItemEditSummaryItem i18n="directory" label="vatId" value={entity.vatId} initialValue={initial?.vatId} />

                            <DirectoryItemEditSummaryItem i18n="directory" label="parent" value={entity.parents?.map(p => p.name).join(', ')} initialValue={initial?.parents?.map(p => p.name).join(', ')} />
                            <DirectoryItemEditSummaryItem i18n="directory" label="type" value={entity.type ? t('directory:enums.types.' + entity.type) : undefined} initialValue={initial?.type ? t('directory:enums.types.' + initial.type) : undefined} />
                            <DirectoryItemEditSummaryItem i18n="directory" label="sectors" value={entity.sectors?.join(', ')} initialValue={initial?.sectors?.join(', ')} />
                            <DirectoryItemEditSummaryItem i18n="directory" label="logo" value={entity.logo?.base64 ? <BubbleHead image={entity.logo?.base64} size="medium" /> : undefined} initialValue={initial?.logo?.base64 ? <BubbleHead image={initial?.logo?.base64} size="medium" /> : undefined} hasChanged={entity.logo?.base64 !== initial?.logo?.base64} />

                        </div>
                        <h3>{t('location:location')}</h3>
                        <div className="simple-list">
                            <DirectoryItemEditSummaryItem i18n="location" label="streetNumber" value={entity.location?.streetNumber} initialValue={initial?.location?.streetNumber} />
                            <DirectoryItemEditSummaryItem i18n="location" label="route" value={entity.location?.route} initialValue={initial?.location?.route} />
                            <DirectoryItemEditSummaryItem i18n="location" label="city" value={entity.location?.city} initialValue={initial?.location?.city} />
                            <DirectoryItemEditSummaryItem i18n="location" label="region" value={entity.location?.region} initialValue={initial?.location?.region} />
                            <DirectoryItemEditSummaryItem i18n="location" label="country" value={entity.location?.country} initialValue={initial?.location?.country} />
                        </div>
                        <h3>{t('contacts:contacts')}</h3>
                        <div className="simple-list">
                            <DirectoryItemEditSummaryItem i18n="directory" label="localPhone" value={entity.localPhone} initialValue={initial?.localPhone} />
                            <DirectoryItemEditSummaryItem i18n="directory" label="internationalPhone" value={entity.internationalPhone} initialValue={initial?.internationalPhone} />
                            <DirectoryItemEditSummaryItem i18n="directory" label="website" value={entity.website} initialValue={initial?.website} />
                            <DirectoryItemEditSummaryItem i18n="directory" label="email" value={entity.email} initialValue={initial?.email} />
                        </div>
                        <h3>{t('entity:privacy')}</h3>
                        <div className="simple-list">
                            <LabelValue inline fixed displayIfNull i18n="directory" label="allowImport" value={t('forms:' + entity.permissions?.allowImport ? t('form:yes') : t('form:no'))} />
                            <LabelValue inline fixed displayIfNull i18n="directory" label="allowSg" value={t('forms:' + entity.permissions?.allowSg ? t('form:yes') : t('form:no'))} />
                        </div>
                    </div>
                )
            }
        </Fragment>
    )
}
export default DirectoryItemEditSummary;