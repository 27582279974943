import { Part } from "../../models/part";
import { WizardContentComponentProps } from "../../sg-react/ui/Wizard";
import SegmentationsTree from "../SegmentationsTree";


const PartWizardSegmentation = ({ entity, onChange }: WizardContentComponentProps<Part>) => {
    return (
        <SegmentationsTree
            selected={entity.segmentationsIds}
            onSelect={(s) => onChange('segmentationsIds', s)}
        />
    )
}
export default PartWizardSegmentation;