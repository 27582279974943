import Validation from "../sg-react/form/Validation/class";
import { ValidationError } from "../sg-react/form/Validation/types";
import { Entity, RevisionEntity } from "../sg-react/models/entity";
import { Image } from "../sg-react/models/image";
import { Location } from "../sg-react/models/location";
import { Permissions } from "../sg-react/models/permissions";
import { Dependency } from "./dependency";
import { SegmentationsCount } from "./segmentation";

export type DirectoryItem = Entity & {
    name: string;
    description?: string;
    localBusinessId?: string;
    localBusinessIdType?: string;
    internationalBusinessId?: string;
    internationalBusinessIdType?: string;
    sectors: string[];
    vatId?: string;
    type: DirectoryItemType[];
    logo?: Image;
    location: Location;
    parentsIds?: string[];
    parents?: DirectoryItem[];
    children?: DirectoryItem[];
    localPhone?: string;
    internationalPhone?: string;
    website?: string;
    email?: string;
    dependenciesIds: string[];
    dependencies?: Dependency[];
    data?: Record<string, any>;
    revisionsIds: string[];
    revisions?: DirectoryRevision[];
    permissions: Permissions;
};

export interface DirectoryItemForMap extends DirectoryItem {
    segmentationsIds?: string[];
    segmentationsCount?: SegmentationsCount[];
    indicators: {
        otd?: number;
        oqd?: number;
        purchasingStrategy?: string;
        salesGrade?: string;
        qualityGrade?: string;
        procGrade?: string;
    };
}

export interface DirectoryMapIndicator {
    chartData: {
        value: number;
        count: number;
        index: number;
        color: string;
    }[];
    domain: number[];
    ticks: number[];
}

export interface DirectoryMap {
    items: DirectoryItemForMap[];
    indicators: {
        segmentationsCount: SegmentationsCount[];
        otd?: DirectoryMapIndicator;
        oqd?: DirectoryMapIndicator;
    };
}

export type DirectoryRevision = RevisionEntity<DirectoryItem> & {
    originalEntity?: DirectoryItem;
};

export interface DirectoryItemInformationsForm {
    name: string;
    description?: string;
    businessId?: string;
    businessIdType?: string;
    vatId?: string;
    type?: string;
    logo?: string;
    parentId?: string;
    parent?: DirectoryItem;
    hasChanged?: boolean;
}

export interface DirectoryItemLocationForm {
    location?: Location;
    hasChanged?: boolean;
}

export interface DirectoryItemContactsForm {
    localPhone?: string;
    internationalPhone?: string;
    website?: string;
    email?: string;
    hasChanged?: boolean;
}

export enum DirectoryItemType {
    Factory = "FACTORY",
    Distributor = "DISTRIBUTOR",
    Headquarters = "HEADQUARTERS",
    PowerStation = "POWER_STATION",
}

export interface DirectoryImport extends Entity {
    _id: string;
    sourceColumns: string[];
    targetColumns: string[];
    step: DirectoryImportStep;
    data: Record<string, any>[];
    mapping?: Record<string, string | undefined>;
    dataMapped: ({ _id: string } & Record<string, any>)[];
    dataValidated: (Partial<DirectoryItem> & { _id: string })[];
    duplicates: Record<string, DirectoryItem[]>;
    duplicatesValidation: {
        _id: string;
        create?: boolean;
        skip?: boolean;
        duplicateId?: string;
    }[];
    permissions?: Permissions;
}

export interface DirectoryItemForImport extends DirectoryItem {
    isValid: boolean;
    errors: Record<string, ValidationError[]>;
}

export enum DirectoryImportStep {
    Mapping = "MAPPING",
    DataValidation = "DATA_VALIDATION",
    DuplicationCheck = "DUPLICATION_CHECK",
    Confimation = "CONFIRMATION",
    Complete = "COMPLETE",
}

export const DIRECTORY_ITEM_INFORMATION_VALIDATION = {
    name: Validation.string().required().minLength(3).maxLength(150),
    description: Validation.string().minLength(20).maxLength(250),
    localBusinessId: Validation.string().required().minLength(5).maxLength(75),
    localBusinessIdType: Validation.string().minLength(3).maxLength(75),
    internationalBusinessId: Validation.string().minLength(5).maxLength(75),
    internationalBusinessIdType: Validation.string().minLength(3).maxLength(75),
    sectors: Validation.array(
        Validation.string().required().minLength(2).maxLength(100)
    ).minLength(1),
    vatId: Validation.string().minLength(5).maxLength(75),
    type: Validation.array(Validation.string().isEnum(DirectoryItemType)),
    parentId: Validation.string().isMongoId(),
};

export const DIRECTORY_ITEM_CONTACTS_VALIDATION = {
    localPhone: Validation.string().minLength(3).maxLength(20),
    internationalPhone: Validation.string().minLength(3).maxLength(20),
    website: Validation.string().isUrl(),
    email: Validation.string().isEmail(),
};
