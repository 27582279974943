import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import DirectoryItemMarker from '../../components/DirectoryItemMarker';
import PartWizard from '../../components/PartWizard';
import { DirectoryItem } from '../../models/directory-item';
import { Part } from '../../models/part';
import { useAuthContext, useMenuContext, useRequest, useWorkspaceContext } from '../../sg-react/context';
import LinearDial from '../../sg-react/data/LinearDial';
import TagsList from '../../sg-react/data/TagsList';
import { WorkspaceRole } from '../../sg-react/models/workspace';
import { Card } from '../../sg-react/ui';
import LabelValue from '../../sg-react/ui/LabelValue';
import { Action } from '../../sg-react/utils/enums';
import PartViewSteps from './components/PartViewSteps';
import './index.scss';

export interface PartViewComponentsProps {
    refresh: () => void;
    part: Part;
    canEdit: boolean;
}

const PartView = () => {
    const [part, setPart] = useState<Part | null>(null);
    const [isWizardVisible, setWizardVisible] = useState<boolean>(false);
    const { currentWorkspace } = useWorkspaceContext();
    const { currentUser } = useAuthContext();
    const request = useRequest();
    const [selectedDirectoryItem, setSelectedDirectoryItem] = useState<DirectoryItem | null>(null);
    const { setCurrentItem, removeCurrentItem, action, setActionDone, setDisabledActions } = useMenuContext();
    const { _id } = useParams();
    const navigate = useNavigate();

    const canEdit = useMemo(() => currentWorkspace.role !== WorkspaceRole.User, [currentWorkspace]);

    const markers = useMemo(() => {
        const directoryItems = part?.steps
            .map(s => s.businesses.map(b => b.directoryItem))
            .flat()
            .filter((b, i, arr) => !!b && !arr.some((bp, ip) => bp?._id === b._id && ip < i)) as DirectoryItem[] | undefined;

        return directoryItems?.map(directoryItem => (
            <DirectoryItemMarker
                key={directoryItem._id}
                longitude={directoryItem.location.longitude}
                latitude={directoryItem.location.latitude}
                directoryItem={directoryItem}
                selected={directoryItem._id === selectedDirectoryItem?._id}
            />
        ));
    }, [part, selectedDirectoryItem]);

    const get = useCallback(() => {
        request.get<Part>(`/parts/${_id}/view`, {
            withWorkspace: true,
            loader: true,
            errorMessage: true
        })
            .then((data) => {
                setPart(data);
                setCurrentItem({ label: data.name, loading: false });
                setDisabledActions(canEdit ? [] : [Action.Edit]);
            })
            .catch(() => navigate(-1));
    }, [_id, request, currentWorkspace, currentUser, setCurrentItem, setDisabledActions, canEdit]);

    useEffect(() => {
        if (_id) {
            get();
        }
    }, [_id]);

    useEffect(() => {
        if (action?.action) {
            switch (action.action) {
                case Action.Close:
                    removeCurrentItem();
                    break;
                case Action.Edit:
                    setWizardVisible(true);
                    break;
                default:
                    break;
            }
            setActionDone(action.timestamp);
        }
    }, [action?.timestamp]);

    if (!part) return null;

    return (
        <div id="part-view" className="layout-container layout-container-scroll row row-layout">
            <div className="col col-layout col-lg-30" id="part-view-first">
                <Card id="part-view-identification">
                    <div className="row row-layout">
                        <span>{part.name}</span>
                        <TagsList tagsIds={part.tagsIds} />
                    </div>
                    {!!part.description && <span>{part.description}</span>}
                    {!!part.image?.base64 && <img src={part.image.base64} alt="" />}
                    <div className="row">
                        <LabelValue label="Type" value="Cardans" />
                        <LabelValue label="Material" value="Steel" />
                        <LabelValue label="Size" value="XL" />
                    </div>
                </Card>
                {!!part.complexity && (
                    <Card id="part-view-complexity">
                        <div className="row">
                            <LabelValue
                                inline
                                label="Complexity"
                                value={part.complexity.total <= 8 ? 'Low' : part.complexity.total <= 12 ? 'Average low' : part.complexity.total <= 15 ? 'Average high' : 'High'}
                                displayIfNull
                            />
                            <LinearDial inverted min={1} max={15} value={part.complexity.total} label="test" />
                        </div>
                        <div className="row">
                            <LabelValue
                                inline
                                label="Shape"
                                displayIfNull
                            />
                            <LinearDial inverted min={1} max={3} value={part.complexity.shape} label="test" />
                        </div>
                        <div className="row">
                            <LabelValue
                                inline
                                label="Internal / external"
                                displayIfNull
                            />
                            <LinearDial inverted min={1} max={3} value={part.complexity.internalExternal} label="test" />
                        </div>
                        <div className="row">
                            <LabelValue
                                inline
                                label="Blind holes"
                                displayIfNull
                            />
                            <LinearDial inverted min={1} max={3} value={part.complexity.blindHoles} label="test" />
                        </div>
                        <div className="row">
                            <LabelValue
                                inline
                                label="Type of SP"
                                displayIfNull
                            />
                            <LinearDial inverted min={1} max={5} value={part.complexity.spType} label="test" />
                        </div>
                        <div className="row">
                            <LabelValue
                                inline
                                label="Number of SP"
                                displayIfNull
                            />
                            <LinearDial inverted min={1} max={3} value={part.complexity.spNumber} label="test" />
                        </div>
                    </Card>
                )}
            </div>
            <PartViewSteps onChange={get} part={part} />
            {!!isWizardVisible && <PartWizard part={part} onClose={() => setWizardVisible(false)} onSubmit={() => get()} />}
        </div >
    )
}

export default PartView;