import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRequest } from "../../context";
import { ObjectValidation } from "../../form/Validation/class";
import { useDebounce } from "../../hooks";
import useForm, { FormHookReturn, UseFormConstraint } from "../../hooks/useForm";
import { CloseIcon, MergeIcon, PlusIcon } from "../../icons";
import { PaginatedResults, Pagination } from "../../models/pagination";
import { Button, Modal } from "../../ui";
import { Colors } from "../../ui/enums";
import { getPrimaryKeysAsString, hasPrimaryKeys, primaryKeysEquals, toggleInArray } from "../../utils/objects";
import PaginationComponent from "../Pagination";
import ColumnHeader from "./ColumnHeader";
import Row from "./Row";
import "./index.scss";

export type DataListColumnFilters = {
    search?: string;
    startsWith?: string;
    ilike?: string;
    equals?: string | number | boolean;
    in?: (string | number | boolean)[];
    from?: Date;
    to?: Date;
    null?: boolean;
}
export type DataListFilters = {
    [key: string]: DataListColumnFilters;
}

export type DataListColumn<T> = {
    field: string;
    label: string;
    display?: ((e: T) => JSX.Element | string | undefined) | 'textinput' | 'textarea' | 'select' | 'select-multiple' | 'date';
    values?: { key: string | number | boolean | undefined, label: string }[];
    options?: {
        edit?: boolean;
        order?: boolean;
        search?: boolean;
        ilike?: boolean;
        startsWith?: boolean;
        equals?: boolean;
        in?: boolean;
        between?: boolean;
        null?: boolean;
    },
    i18n?: string;
    width?: number | string;
    hidden?: boolean;
}

export type DataListColumnWithWidth<T> = (DataListColumn<T> & { widthPx: number });
export type DataListFormProps<T> = Pick<FormHookReturn<T>, 'entity' | 'onChange' | 'errors' | 'attachInput' | 'onMultipleChange'>;

export interface DataListProps<T> {
    schema: DataListColumn<T>[];
    primaryKey: keyof T | (keyof T)[];
    validation?: ObjectValidation;
    title?: string;
    endpoint: string;
    actions?: {
        view?: boolean | ((e: T) => void);
        select?: boolean | ((e: T[]) => void);
        merge?: boolean | ((e: T[]) => void);
    }
    createForm?: (props: DataListFormProps<T>) => JSX.Element;
    updateForm?: (props: DataListFormProps<T>) => JSX.Element;
}

const SELECT_WIDTH = 50;
/* 
const dataListSchemaToSimpleListSchema = <T,>(columns: DataListColumn<T>[]): SimpleListSchemaColumn<T>[] => columns.map(column => ({
    field: column.field,
    label: column.label,
    display: !column.display || typeof column.display === 'function'
        ? column.display
        : ['select', 'select-multiple'].includes(column.display)
            ? (e: T) => getStringValue(e[column.field as keyof T])
            : undefined
})); */

const DataList = <T extends UseFormConstraint>({
    schema,
    primaryKey,
    validation,
    title,
    endpoint,
    actions,
    createForm: CreateForm,
    updateForm: UpdateForm
}: DataListProps<T>) => {
    const { t } = useTranslation();
    const request = useRequest();
    const tableRef = useRef<HTMLDivElement | null>(null);
    const [isLoading, setLoading] = useState<boolean>(false);
    const [columns, setColumns] = useState<DataListColumnWithWidth<T>[]>([]);
    const [data, setData] = useState<T[]>([]);
    const [selectedData, setSelectedData] = useState<T[]>([]);
    const [mergeTarget, setMergeTarget] = useState<T | null>(null);
    const [isMergeModalVisible, setMergeModalVisible] = useState<boolean>(false);
    const [pagination, setPagination] = useState<Pagination | null>(null);
    const [filters, setFilters] = useState<DataListFilters>({});
    const [formMode, setFormMode] = useState<'create' | 'update' | null>(null);
    const { entity: formEntity, validate, hasChanged, reset, ...formProps } = useForm<T>({}, validation);
    useDebounce(() => get(), 600, [filters]);

    //const simpleListSchema = useMemo(() => dataListSchemaToSimpleListSchema(schema), [schema]);
    const hasFilter = useMemo(() => filters && Object.keys(filters).some(k => !!filters[k]), [filters]);

    const handleColumnResize = useCallback((index: number, width: number) => {
        const _columns = [...columns];
        if (index === -1) return columns;

        if (index < columns.length - 1) {
            const diff = (_columns[index]!.widthPx ?? 0) - width;
            _columns[index + 1]!.widthPx = (_columns[index + 1]!.widthPx ?? 0) + diff;
        }

        _columns[index]!.widthPx = width;

        setColumns(_columns);
    }, [columns]);

    const get = useCallback(async (_pagination?: Partial<Pagination>) => {
        if (isLoading) return;

        setLoading(true);
        request.get<PaginatedResults<T>>(endpoint, {
            params: { ...(_pagination ? _pagination : pagination), ...filters },
            loader: true,
            errorMessage: true
        })
            .then((data) => {
                setData(data.data);
                setPagination(data.pagination);
            })
            .catch(() => null)
            .finally(() => setLoading(false))
    }, [isLoading, pagination, filters, endpoint, request]);

    const handleFormSubmit = useCallback(async () => {
        if (!hasChanged) {
            reset({});
            setFormMode(null);
            return;
        };

        validate((entity) => {
            const requestMethod = !hasPrimaryKeys(entity, primaryKey) ? request.post : request.put;

            requestMethod<T>(endpoint, entity, {
                loader: true,
                successMessage: true,
                errorMessage: true
            })
                .then(() => {
                    reset({});
                    get();
                    setFormMode(null);
                })
                .catch(() => null);
        })

    }, [validate, request, endpoint, get, reset, primaryKey, hasChanged]);

    const handleParamsChange = useCallback((field: keyof Pagination, value: any) => {
        get({ ...pagination, [field]: value });
    }, [pagination, get]);

    const handleOrder = useCallback((key: string, direction: 1 | -1) => {
        get({
            ...pagination,
            sort: {
                ...pagination?.sort,
                [key]: direction
            }
        });
    }, [pagination, get]);

    const handleFilterChange = useCallback((field: string, columnFilters: DataListColumnFilters) => {
        setFilters((filters) => ({ ...filters, [field]: columnFilters }));
    }, []);

    const handleView = useCallback((e: T) => {
        if (typeof actions?.view === 'function') {
            actions.view(e);
        } else if (actions?.view && UpdateForm) {
            reset(e);
            setFormMode('update');
        }
    }, [actions, UpdateForm, reset]);

    const handleSelect = useCallback((e: T) => {
        setSelectedData((selectedData) => {
            const _selectedData = toggleInArray(selectedData, e, (e1, e2) => primaryKeysEquals(e1, e2, primaryKey))
            if (typeof actions?.select === 'function') actions.select(_selectedData);
            return _selectedData;
        })
    }, [actions, schema]);

    const handleMerge = useCallback(() => {
        if (selectedData?.length) {
            if (!isMergeModalVisible) {
                setMergeModalVisible(true);
            } else if (mergeTarget) {
                //
                setMergeModalVisible(false);
                setMergeTarget(null);
                setSelectedData([]);
            }
        }
    }, [actions, selectedData, isMergeModalVisible, mergeTarget]);

    const columnHeaders = useMemo(() => columns.filter(column => !column.hidden).map((column, index) => (
        <ColumnHeader<T>
            key={column.field}
            onResize={(s) => handleColumnResize(index, s)}
            column={column}
            order={!!pagination?.sort?.[column.field] ? pagination.sort[column.field] : undefined}
            onOrder={(direction) => handleOrder(column.field, direction)}
            onFilterChange={(f) => handleFilterChange(column.field, f)}
            filters={filters[column.field]}
        />
    )), [columns, handleColumnResize, handleOrder, handleFilterChange, filters, pagination]);

    const rows = useMemo(() => data.map(d => (
        <Row
            key={getPrimaryKeysAsString(d, primaryKey)}
            entity={d}
            selected={selectedData.some(s => primaryKeysEquals(s, d, primaryKey))}
            columns={columns}
            endpoint={endpoint}
            validation={validation}
            onView={actions?.view ? handleView : undefined}
            onSelect={actions?.select || actions?.merge ? handleSelect : undefined}
        />
    )), [columns, endpoint, schema, actions, handleView, UpdateForm, handleSelect, selectedData, data, primaryKey, validation]);

    useEffect(() => {
        if (tableRef.current) {
            const handleResize = () => {
                if (!tableRef.current) return;

                const actionWidth = actions?.select || actions?.merge ? SELECT_WIDTH : 0;
                const tableWidth = tableRef.current!.getBoundingClientRect().width - actionWidth;

                setColumns((columns) => {
                    const _columns = [...columns];
                    const oldTableWidth = _columns.reduce((w, column) => w + column.widthPx, 0);

                    for (const column of _columns) {
                        column.widthPx = column.widthPx * tableWidth / oldTableWidth
                    }
                    return _columns;
                });
            };
            window.addEventListener('resize', handleResize);

            const _columns: DataListColumnWithWidth<T>[] = [];
            const actionWidth = actions?.select || actions?.merge ? SELECT_WIDTH : 0;
            const tableWidth = tableRef.current!.getBoundingClientRect().width - actionWidth;

            for (const column of schema) {
                let w = tableWidth / schema.length;

                if (column.width) {
                    if (typeof column.width === "number") {
                        w = column.width as number;
                    } else {
                        const wString = column.width as string;
                        if (wString.includes('px')) {
                            w = parseInt(wString.replace('px', ''));
                        } else {
                            const percentage = parseInt(wString.replace('%', ''));
                            w = tableWidth * percentage / 100;
                        }
                    }
                }
                _columns.push({ ...column, widthPx: w });
            }

            const totalWidth = _columns.reduce((w, column) => w + column.widthPx, 0);

            if (totalWidth !== tableRef.current!.getBoundingClientRect().width) {
                for (const column of _columns) {
                    column.widthPx = column.widthPx * tableWidth / totalWidth
                }
            }
            setColumns(_columns);

            return () => {
                window.removeEventListener(
                    'onresize',
                    handleResize,
                );
            };
        }
    }, [schema, actions]);

    useEffect(() => {
        setData([]);
        get({});
    }, [endpoint]);

    return (
        <div className="data-list">
            <div className="data-list-header">
                <div className="data-list-header-title">{title}</div>
                <div className="data-list-header-actions">
                    {!!selectedData?.length && (
                        <Button
                            label={t('data:clear_selection')}
                            icon={<CloseIcon />}
                            color={Colors.ERROR}
                            onClick={() => setSelectedData([])}
                        />
                    )}
                    {!!actions?.merge && selectedData?.length > 1 && (
                        <Button
                            label={t('data:merge')}
                            color={Colors.ACCENT}
                            icon={<MergeIcon />}
                            onClick={handleMerge}
                        />
                    )}
                    {!!hasFilter && (
                        <Button
                            label={t('data:clear_filters')}
                            color={Colors.PRIMARY}
                            icon={<CloseIcon />}
                            onClick={() => setFilters({})}
                        />
                    )}
                    {!!CreateForm && (
                        <Button
                            label={t('actions:new')}
                            icon={<PlusIcon />}
                            color={Colors.SUCCESS}
                            onClick={() => { reset({}); setFormMode('create'); }}
                        />
                    )}
                </div>
            </div>
            <div className="data-list-table" ref={tableRef}>
                <div className="data-list-table-header">
                    {(actions?.select || actions?.merge) && (
                        <div className="data-list-table-header-select" />
                    )}
                    {columnHeaders}
                </div>
                <div className="data-list-table-rows">
                    {rows}
                </div>
            </div>
            <div className="data-list-footer">
                <div className="data-list-pagination-control">
                    <span>Results per page: </span>
                    <select value={pagination?.take ?? 20} onChange={(e) => handleParamsChange('take', e.target.value)}>
                        <option value="5">5</option>
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="30">30</option>
                        <option value="40">40</option>
                        <option value="50">50</option>
                        <option value="75">75</option>
                        <option value="100">100</option>
                    </select>
                </div>
                <PaginationComponent pagination={pagination ?? undefined} onPageChange={(page) => handleParamsChange('page', page)} />
            </div>
            {
                !!formMode && (
                    <Modal
                        size="small"
                        title={
                            hasPrimaryKeys(formEntity, primaryKey)
                                ? t('actions:edit')
                                : t('actions:new')
                        }
                        onClose={() => setFormMode(null)}
                        onSubmit={handleFormSubmit}
                    >
                        {(formMode === 'update' && UpdateForm) && <UpdateForm entity={formEntity} {...formProps} />}
                        {(formMode === 'create' && CreateForm) && <CreateForm entity={formEntity} {...formProps} />}
                    </Modal>
                )
            }
            {!!isMergeModalVisible && (
                <Modal
                    title={t('data:merge_select_target')}
                    onClose={() => setMergeModalVisible(false)}
                    onSubmit={mergeTarget ? handleMerge : undefined}
                >
                    TODO
                </Modal>
            )}
        </div >
    )
}

export default DataList;