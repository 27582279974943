// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fixed-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
}
.fixed-wrapper > .fixed-wrapper-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  background-color: transparent;
  z-index: 100;
}
.fixed-wrapper > .fixed-wrapper-children {
  position: fixed;
  z-index: 100;
}`, "",{"version":3,"sources":["webpack://./src/sg-react/ui/FixedWrapper/index.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,WAAA;EACA,YAAA;AACJ;AACI;EACI,eAAA;EACA,MAAA;EACA,OAAA;EACA,YAAA;EACA,aAAA;EACA,aAAA;EACA,6BAAA;EACA,YAAA;AACR;AAEI;EACI,eAAA;EACA,YAAA;AAAR","sourcesContent":[".fixed-wrapper {\n    position: relative;\n    width: 100%;\n    height: 100%;\n\n    & > .fixed-wrapper-backdrop {\n        position: fixed;\n        top: 0;\n        left: 0;\n        width: 100vw;\n        height: 100vh;\n        display: flex;\n        background-color: transparent;\n        z-index: 100;\n    }\n\n    & > .fixed-wrapper-children {\n        position: fixed;\n        z-index: 100;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
