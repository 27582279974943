import { Fragment, useCallback, useEffect, useState } from "react";
import { DirectoryItem } from "../../../models/directory-item";
import { useRequest } from "../../../sg-react/context";
import DataContainerList from "../../../sg-react/data/DataContainerList";
import Info from "../../../sg-react/ui/Info";
import { WizardContentComponentProps } from "../../../sg-react/ui/Wizard";

interface DirectoryItemCreateDuplicatesProps extends WizardContentComponentProps<DirectoryItem> {
    endpoint: string;
    params: any;
}

const DirectoryItemCreateDuplicates = ({ entity, reset, endpoint, params }: DirectoryItemCreateDuplicatesProps) => {
    const request = useRequest();
    const [results, setResults] = useState<DirectoryItem[] | null>(null);
    const [initial, setInital] = useState<Partial<DirectoryItem>>(entity);

    const getSuggestions = useCallback(() => {
        request.get<DirectoryItem[]>(endpoint, { params, withWorkspace: true, loader: true })
            .then(setResults)
            .catch(() => null);
    }, [request, endpoint, params]);

    const handleSelect = useCallback((d?: DirectoryItem | null) => {
        reset(d ?? initial);
    }, [initial]);

    useEffect(() => {
        getSuggestions();
    }, []);

    return (
        <Fragment>
            {!!results && results?.length === 0 && <Info type="success" i18n="directory" label="creation.no_match" />}
            {!!results?.length && <Info type="warning" i18n="directory" label="creation.possible_matches" />}
            {!!results?.length && (
                <DataContainerList<DirectoryItem>
                    primaryKey="_id"
                    data={results}
                    onSelect={handleSelect}
                    columns={[
                        { field: 'name', label: 'name', i18n: 'entity' },
                        { field: 'location.city', label: 'city', i18n: 'location' },
                        { field: 'location.country', label: 'country', i18n: 'location' },
                    ]}
                    sort={{ field: "name", direction: 1 }}
                />
            )}
        </Fragment >
    )
}
export default DirectoryItemCreateDuplicates;