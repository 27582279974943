import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import CheckboxList from '../../form/CheckboxList';
import { RightIcon } from '../../icons';
import { translateLabel } from '../../utils/format';
import { conditionnalClassnames } from '../../utils/helpers';
import './index.scss';

interface MenuListProps<T> {
    label?: string;
    items: { key: T, label: string }[];
    value?: T[];
    onChange: (v?: T[]) => void;
}

export const MenuList = <T,>({ value, label, items, onChange }: MenuListProps<T>) => (
    <div className="menu-list">
        {!!label && <label>{label}</label>}
        <CheckboxList<T[]> id="menu-list" value={value} items={items} onChange={(v) => onChange(v)} multiple />
    </div>
);

interface MenuTextFieldProps {
    placeholder?: string;
    label?: string;
    value?: string;
    onChange: (v: string) => void;
}

export const MenuTextField = ({ value, label, placeholder, onChange }: MenuTextFieldProps) => (
    <div className="menu-text-field">
        {!!label && <label>{label}</label>}
        <input
            type="text"
            onChange={(e) => onChange(e.target.value)}
            value={value ?? ''}
            placeholder={placeholder ?? ''}
            onClick={(e) => e.stopPropagation()}
        />
    </div>
);

export interface MenuItemProps {
    itemKey: string;
    label?: string;
    icon?: ReactNode;
    i18n?: string;
    divider?: boolean;
    onAction?: (k: string) => void;
    onClick?: () => void;
    to?: string;
    target?: string;
    items?: MenuItemProps[];
    disposition?: 'right' | 'left';
}

export const MenuItem = ({
    itemKey,
    label,
    icon,
    onAction,
    onClick,
    disposition,
    i18n,
    to,
    target,
    divider,
    items
}: MenuItemProps) => {
    const { t } = useTranslation();

    if (divider) {
        return (
            <div className={`menu-divider ${label ? 'menu-divider-label' : ''}`}>
                {label ? translateLabel(label, t, i18n) : ''}
            </div>
        );
    } else if (items) {
        return (
            <div className="menu-item">
                {icon}
                <div className="menu-item-label">{label ? translateLabel(label, t, i18n) : ''}</div>
                <RightIcon />
                <Menu onAction={onAction} disposition={disposition} items={items} />
            </div>
        );
    } else if (!to) {
        return (
            <div className="menu-item" onClick={onAction ? () => onAction(itemKey) : onClick}>
                {icon}
                <div className="menu-item-label">{label ? translateLabel(label, t, i18n) : ''}</div>
            </div>
        );
    } else if (!target) {
        return (
            <Link className="menu-item" to={to}>
                {icon}
                <div className="menu-item-label">{label ? translateLabel(label, t, i18n) : ''}</div>
            </Link>
        );
    } else {
        return (
            <a className="menu-item" href={to} target={target}>
                {!!icon && <div className="menu-item-icon">{icon}</div>}
                <div className="menu-item-label">{label ? translateLabel(label, t, i18n) : ''}</div>
            </a>
        );
    }
}

export interface MenuProps {
    items: MenuItemProps[];
    disposition?: 'right' | 'left';
    onAction?: (k: string) => void;
    disabledKeys?: string[];
}

const Menu = ({ items, disposition, onAction, disabledKeys }: MenuProps) => {

    return (
        <div className={conditionnalClassnames({ menu: true, "menu-left": disposition === 'left', "menu-right": disposition === 'right' })}>
            {items.filter(i => !disabledKeys?.length || !disabledKeys.includes(i.itemKey)).map(i => <MenuItem key={i.itemKey} onAction={onAction} disposition={disposition} {...i} />)}
        </div>
    )
}

export default Menu;