// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pill {
  content: "";
  width: 16px;
  height: 16px;
  border-radius: 50%;
  flex-shrink: 0;
  flex-grow: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--theme-color-grey-lightest);
  font-size: 10px;
}`, "",{"version":3,"sources":["webpack://./src/sg-react/ui/Pill/index.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;EACA,cAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,uCAAA;EACA,eAAA;AACJ","sourcesContent":[".pill {\n    content: \"\";\n    width: 16px;\n    height: 16px;\n    border-radius: 50%;\n    flex-shrink: 0;\n    flex-grow: 0;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    color: var(--theme-color-grey-lightest);\n    font-size: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
