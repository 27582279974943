// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.directory-item-map-card {
  max-height: 70vh;
  width: 375px;
}
.directory-item-map-card .directory-item-map-card-header {
  display: flex;
  flex-direction: column;
  position: relative;
}
.directory-item-map-card .directory-item-map-card-header > img {
  width: 100%;
  height: 70px;
  object-fit: contain;
}
.directory-item-map-card .directory-item-map-card-header > h2 {
  flex-grow: 1;
}
.directory-item-map-card .directory-item-map-card-content {
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
}
.directory-item-map-card .directory-item-map-card-content a {
  white-space: nowrap;
  text-overflow: ellipsis;
}
.directory-item-map-card .directory-item-map-card-additional-data {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.directory-item-map-card .directory-item-map-card-additional-data > div {
  font-size: 12px;
}
.directory-item-map-card .directory-item-map-card-additional-data > div > span {
  color: var(--theme-color-grey-medium);
}
.directory-item-map-card .directory-item-map-card-data {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.directory-item-map-card .directory-item-map-card-data > span {
  font-size: 12px;
}
.directory-item-map-card .directory-item-map-card-data > .directory-item-map-card-data-actions {
  display: flex;
  gap: 6px;
}
.directory-item-map-card .directory-item-map-card-data > .directory-item-map-card-data-actions > * {
  flex-grow: 1;
}
.directory-item-map-card .simple-list {
  max-height: 300px;
}`, "",{"version":3,"sources":["webpack://./src/components/DirectoryItemMapCard/index.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,YAAA;AACJ;AACI;EACI,aAAA;EACA,sBAAA;EACA,kBAAA;AACR;AACQ;EACI,WAAA;EACA,YAAA;EACA,mBAAA;AACZ;AAEQ;EACI,YAAA;AAAZ;AAII;EACI,aAAA;EACA,sBAAA;EACA,QAAA;EACA,WAAA;AAFR;AAIQ;EACI,mBAAA;EACA,uBAAA;AAFZ;AAMI;EACI,aAAA;EACA,sBAAA;EACA,QAAA;AAJR;AAMQ;EACI,eAAA;AAJZ;AAMY;EACI,qCAAA;AAJhB;AASI;EACI,aAAA;EACA,sBAAA;EACA,QAAA;AAPR;AASQ;EACI,eAAA;AAPZ;AAUQ;EACI,aAAA;EACA,QAAA;AARZ;AAUY;EACI,YAAA;AARhB;AAaI;EACI,iBAAA;AAXR","sourcesContent":[".directory-item-map-card {\n    max-height: 70vh;\n    width: 375px;\n\n    & .directory-item-map-card-header {\n        display: flex;\n        flex-direction: column;\n        position: relative;\n\n        & > img {\n            width: 100%;\n            height: 70px;\n            object-fit: contain;\n        }\n\n        & > h2 {\n            flex-grow: 1;\n        }\n    }\n\n    & .directory-item-map-card-content {\n        display: flex;\n        flex-direction: column;\n        gap: 4px;\n        width: 100%;\n\n        & a {\n            white-space: nowrap;\n            text-overflow: ellipsis;\n        }\n    }\n\n    & .directory-item-map-card-additional-data {\n        display: flex;\n        flex-direction: column;\n        gap: 8px;\n\n        & > div {\n            font-size: 12px;\n\n            & > span {\n                color: var(--theme-color-grey-medium);\n            }\n        }\n    }\n\n    & .directory-item-map-card-data {\n        display: flex;\n        flex-direction: column;\n        gap: 8px;\n\n        & > span {\n            font-size: 12px;\n        }\n\n        & > .directory-item-map-card-data-actions {\n            display: flex;\n            gap: 6px;\n\n            & > * {\n                flex-grow: 1;\n            }\n        }\n    }\n\n    & .simple-list {\n        max-height: 300px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
