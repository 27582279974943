import { useCallback, useEffect, useMemo, useState } from "react";
import { Legend, Line, LineChart, ResponsiveContainer, XAxis, YAxis } from "recharts";
import { DirectoryItemViewPatchProps } from "..";
import { useRequest } from "../../../sg-react/context";
import ValueDateModal from "../../../sg-react/data/ValueDateModal";
import { NumberField } from "../../../sg-react/form";
import { EditIcon, PlusIcon } from "../../../sg-react/icons";
import { ValueDate } from "../../../sg-react/models/data";
import { Button, Card, Modal } from "../../../sg-react/ui";
import LabelValue from "../../../sg-react/ui/LabelValue";
import { COLORS_FOR_CHARTS } from "../../../sg-react/utils/constants";
import { dateToLocaleString } from "../../../sg-react/utils/date";
import { formatNumberToFixedDecimal } from "../../../sg-react/utils/format";

const RseEmissions = ({ directoryData, directoryItem, refresh, canEdit }: DirectoryItemViewPatchProps) => {
    const [isEmissionModalVisible, setModalEmissionVisible] = useState<boolean>(false);
    const [isGoalModalVisible, setGoalModalVisible] = useState<boolean>(false);
    const [goal, setGoal] = useState<number | undefined>(directoryData.emissions?.goal);
    const request = useRequest();

    const data = useMemo(() => {
        const minMax = [
            new Date(new Date().getFullYear(), 0, 1).getTime(),
            new Date(new Date().getFullYear(), 11, 31).getTime()
        ];
        const currentYear = directoryData.emissions?.emissions
            ?.filter(e => e.date.getFullYear() === new Date().getFullYear())
            .map(e => ({ value: e.value, date: e.date.getTime() }))
            .sort((e1, e2) => e1.value < e2.value ? 1 : -1);
        const lastYear = directoryData.emissions?.emissions
            ?.filter(e => e.date.getFullYear() === new Date().getFullYear() - 1)
            .map(e => ({ value: e.value, date: new Date(new Date().getFullYear(), e.date.getMonth(), e.date.getDate()).getTime() }))
            .sort((e1, e2) => e1.value < e2.value ? 1 : -1);
        const currentYearValue = currentYear?.length ? currentYear[0] : undefined;
        const lastYearValue = lastYear?.length ? lastYear[0] : undefined;
        const goalDiff = directoryData.emissions?.goal && lastYearValue && currentYearValue ? ((lastYearValue.value - directoryData.emissions.goal) * 100) / lastYearValue.value : undefined;
        const goalDiffString = goalDiff ? ` (${goalDiff > 0 ? '-' : '+'}${formatNumberToFixedDecimal(goalDiff, 0)}%)` : '';

        let diff: number | undefined = undefined;

        if (lastYear?.length && currentYearValue) {
            const lastYearValueForDiff = lastYear.find(l => l.value === currentYearValue.value);

            if (lastYearValueForDiff) {
                diff = currentYearValue.value - lastYearValueForDiff.value;
            } else {
                let i = lastYear.length - lastYear?.filter(l => l.date <= currentYearValue.date).length - 1;
                if (i === lastYear.length - 1) {
                    diff = currentYearValue.value - lastYear[i].value;
                } else {
                    const timeDiff = lastYear[i + 1].date - lastYear[i].date;
                    const valueDiff = lastYear[i + 1].value - lastYear[i].value;
                    diff = currentYearValue.value - (lastYear[i].value + (currentYearValue.date - lastYear[i].date) * valueDiff / timeDiff)
                }
            }
        }
        return {
            minMax,
            currentYear,
            currentYearValue,
            lastYear,
            lastYearValue,
            goalDiff,
            goalDiffString,
            diff: diff ? formatNumberToFixedDecimal(diff, 0, true) : undefined
        }
    }, [directoryData]);

    const handleEmission = useCallback(async (dto: ValueDate<number>) => {
        request.post(`/directory-data/${directoryItem._id}/emission`, dto, {
            withWorkspace: true,
            loader: true,
            errorMessage: true
        })
            .then(() => {
                refresh();
                setModalEmissionVisible(false);
            })
            .catch(() => null);

    }, [request, directoryItem, refresh]);

    const handleGoal = useCallback(async (goal: number) => {
        request.put(`/directory-data/${directoryItem._id}/emission-goal`, { value: goal }, {
            withWorkspace: true,
            loader: true,
            errorMessage: true
        })
            .then(() => {
                refresh();
                setGoalModalVisible(false);
            })
            .catch(() => null);

    }, [request, directoryItem, refresh]);

    useEffect(() => {
        setGoal(directoryData.emissions?.goal);
    }, [directoryData]);

    return (
        <Card
            i18n="rse"
            title="emissions"
            options={canEdit && <Button color="navigation" label="add" i18n="actions" icon={<PlusIcon />} onClick={() => setModalEmissionVisible(true)} />}
        >
            <div className="row">
                <div className="col">
                    <LabelValue displayIfNull label="Total émissions 2023" value={data.lastYearValue?.value} />
                </div>
                <div className="col">
                    <div className="row">
                        <LabelValue displayIfNull label="Objectif 2024" value={directoryData.emissions?.goal ? `${directoryData.emissions?.goal}${data.goalDiffString}` : undefined} />
                        {canEdit && <Button color="navigation" icon={<EditIcon />} onClick={() => setGoalModalVisible(true)} />}
                    </div>
                </div>
                <div className="col">
                    <LabelValue displayIfNull label="Emissions à date" value={data.currentYearValue?.value} />
                </div>
                <div className="col">
                    <LabelValue displayIfNull label="Variation à date" value={data.diff} />
                </div>
            </div>
            <ResponsiveContainer minWidth="100%" width="100%" height={250}>
                <LineChart>
                    <XAxis
                        dataKey="date"
                        type="number"
                        allowDuplicatedCategory={false}
                        domain={data.minMax}
                        tickFormatter={(time) => dateToLocaleString(new Date(time))}
                    />
                    <YAxis dataKey="value" domain={[0, 'auto']} />
                    <Legend formatter={(value) => <label>{value}</label>} />
                    <Line dataKey="value" data={data.lastYear} name={String(new Date().getFullYear() - 1)} strokeWidth={2} stroke={COLORS_FOR_CHARTS[0]} dot={false} />
                    <Line dataKey="value" data={data.currentYear} name={String(new Date().getFullYear())} strokeWidth={2} stroke={COLORS_FOR_CHARTS[2]} dot={false} />
                </LineChart>
            </ResponsiveContainer>
            {isEmissionModalVisible && (
                <ValueDateModal
                    title="emission"
                    i18n="rse"
                    onClose={() => setModalEmissionVisible(false)}
                    onSubmit={handleEmission}
                />
            )}
            {isGoalModalVisible && (
                <Modal
                    title="goal"
                    i18n="rse"
                    size="small"
                    onClose={() => setGoalModalVisible(false)}
                    onSubmit={() => goal !== undefined ? handleGoal(goal) : undefined}
                >
                    <NumberField
                        id="goal"
                        label
                        i18n="rse"
                        value={goal}
                        onChange={setGoal}
                    />
                </Modal>
            )}
        </Card>
    )
}
export default RseEmissions;