import { useCallback, useState } from 'react';
import WorkspaceSettings from '../../../pages/WorkspaceSettings';
import WorkspaceSettingsInformations from '../../../pages/WorkspaceSettings/components/WorkspaceSettingsInformations';
import { useRequest, useWorkspaceContext } from '../../context';
import { useForm } from '../../hooks';
import { KeyIcon, PlusIcon } from '../../icons';
import { WORKSPACE_VALIDATION, Workspace } from '../../models/workspace';
import { Button, Card, Modal } from '../../ui';
import DepthContainer from '../../ui/DepthContainer';
import Info from '../../ui/Info';
import ListItem from '../../ui/ListItem';
import SoftContainer from '../../ui/SoftContainer';
import './index.scss';

const Workspaces = () => {
    const { workspaces, refreshWorkspaces } = useWorkspaceContext();
    const [workspaceToEdit, setWorkspaceToEdit] = useState<Workspace | null>(null);
    const { entity, onChange, validate, attachInput, reset } = useForm<Workspace>({}, WORKSPACE_VALIDATION);
    const [isFormModalVisible, setFormModalVisible] = useState<boolean>(false);
    const request = useRequest();

    const handleSubmit = useCallback(() => validate((entity) => {
        request.post(`/workspaces`, entity, {
            loader: true,
            successMessage: true,
            errorMessage: true
        })
            .then(() => {
                refreshWorkspaces();
                reset({});
                setFormModalVisible(false);
            })
            .catch(() => null);
    }), [request, reset, validate, refreshWorkspaces]);

    return (
        <div id="workspaces" className="layout-container layout-container-grow layout-container-scroll row row-layout">
            <div className="col col-layout col-lg-60">
                <Card title="workspaces" i18n="workspaces" options={<Button color="navigation" icon={<PlusIcon />} i18n="actions" label="create" onClick={() => setFormModalVisible(true)} />}>
                    <div className="list" id="workspaces-list">
                        {workspaces.map(w => (
                            <SoftContainer key={w._id}>
                                <div className="row row-layout">
                                    {!!w.image?.base64 && (
                                        <DepthContainer id="portal-promo">
                                            <img src={w.image?.base64} alt="" />
                                        </DepthContainer>
                                    )}
                                    <div className="col col-layout">
                                        <h2>{w.name}</h2>
                                        {!!w.description && <span>{w.description}</span>}
                                    </div>
                                </div>
                            </SoftContainer>
                        ))}
                    </div>
                </Card>
            </div>
            <div className="col col-layout col-lg-40">
                <Card title="external_workspaces" i18n="workspaces">
                    <Info i18n="workspaces" label="external_workspaces_tooltip" />
                    <Info type="warning" i18n="ui" label="degraded_feature" />
                    <div className="list">
                        {([] as Workspace[]).map(w => (
                            <ListItem
                                key={w._id}
                                value={w.name}
                                description={w.description}
                                preline
                            >
                                <Button color="navigation" icon={<KeyIcon />} label="Request access" />
                            </ListItem>
                        ))}
                    </div>
                </Card>
            </div>
            {workspaceToEdit && <WorkspaceSettings workspaceId={workspaceToEdit._id} onClose={() => setWorkspaceToEdit(null)} />}
            {isFormModalVisible && (
                <Modal
                    size="medium"
                    title="create"
                    i18n="actions"
                    onClose={() => setFormModalVisible(false)}
                    onSubmit={handleSubmit}
                >
                    <WorkspaceSettingsInformations entity={entity} onChange={onChange} attachInput={attachInput} />
                </Modal>
            )}
        </div>
    )
}
export default Workspaces;