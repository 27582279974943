// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.directory-item-map .marker-dependency {
  content: "";
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: var(--theme-color-pristine);
  border: var(--theme-color-white) 1px solid;
}
.directory-item-map .marker-dependency > svg {
  width: 14px;
  height: 14px;
  fill: var(--theme-color-white);
}
.directory-item-map .marker-directory {
  content: "";
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background-color: var(--theme-color-pristine);
  border: var(--theme-color-white) 1px solid;
}
.directory-item-map .marker-directory > svg {
  width: 18px;
  height: 18px;
  fill: var(--theme-color-white);
}`, "",{"version":3,"sources":["webpack://./src/components/DirectoryItemMap/index.scss"],"names":[],"mappings":"AACI;EACI,WAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EAEA,WAAA;EACA,YAAA;EACA,kBAAA;EACA,6CAAA;EACA,0CAAA;AADR;AAGQ;EACI,WAAA;EACA,YAAA;EACA,8BAAA;AADZ;AAII;EACI,WAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EAEA,WAAA;EACA,YAAA;EACA,kBAAA;EACA,6CAAA;EACA,0CAAA;AAHR;AAKQ;EACI,WAAA;EACA,YAAA;EACA,8BAAA;AAHZ","sourcesContent":[".directory-item-map {\n    & .marker-dependency {\n        content: \"\";\n        display: flex;\n        align-items: center;\n        justify-content: center;\n\n        width: 20px;\n        height: 20px;\n        border-radius: 50%;\n        background-color: var(--theme-color-pristine);\n        border: var(--theme-color-white) 1px solid;\n\n        & > svg {\n            width: 14px;\n            height: 14px;\n            fill: var(--theme-color-white);\n        }\n    }\n    & .marker-directory {\n        content: \"\";\n        display: flex;\n        align-items: center;\n        justify-content: center;\n\n        width: 28px;\n        height: 28px;\n        border-radius: 50%;\n        background-color: var(--theme-color-pristine);\n        border: var(--theme-color-white) 1px solid;\n\n        & > svg {\n            width: 18px;\n            height: 18px;\n            fill: var(--theme-color-white);\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
