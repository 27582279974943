// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tag {
  display: flex;
  flex-grow: 0;
  gap: 4px;
  align-items: center;
  font-size: 11px;
  padding: 2px 6px;
  border-radius: 4px;
  color: var(--theme-color-grey-lightest);
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
}
.tag.tag-transparent {
  color: var(--theme-font-color-main);
}`, "",{"version":3,"sources":["webpack://./src/sg-react/ui/Tag/index.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,YAAA;EACA,QAAA;EACA,mBAAA;EACA,eAAA;EACA,gBAAA;EACA,kBAAA;EACA,uCAAA;EACA,mBAAA;EACA,uBAAA;EAAA,kBAAA;AACJ;AACI;EACI,mCAAA;AACR","sourcesContent":[".tag {\n    display: flex;\n    flex-grow: 0;\n    gap: 4px;\n    align-items: center;\n    font-size: 11px;\n    padding: 2px 6px;\n    border-radius: 4px;\n    color: var(--theme-color-grey-lightest);\n    white-space: nowrap;\n    width: fit-content;\n\n    &.tag-transparent {\n        color: var(--theme-font-color-main);\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
