// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#directory-item-create {
  height: 600px;
  max-width: 90%;
  width: 1000px;
  max-width: 90%;
}`, "",{"version":3,"sources":["webpack://./src/components/DirectoryItemCreate/index.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,cAAA;EACA,aAAA;EACA,cAAA;AACJ","sourcesContent":["#directory-item-create {\n    height: 600px;\n    max-width: 90%;\n    width: 1000px;\n    max-width: 90%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
