import { Fragment, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRequest } from "../../context";
import { DataTableList } from "../../data";
import { FilterType } from "../../data/FiltersPanel";
import { User, UserStatus } from "../../models/user";
import { dateToLocaleString } from "../../utils/date";
import { Role } from "../../utils/enums";
import { translateEnum } from "../../utils/format";
import ModalUser from "./components/ModalUser";

const Users = () => {
    const { t } = useTranslation();
    const [userToEditId, setUserToEditId] = useState<string | null>(null);
    const request = useRequest();

    const roles = useMemo(() => translateEnum(Role, 'users', 'roles', t), [t]);
    const status = useMemo(() => translateEnum(UserStatus, 'users', 'status', t), [t]);

    const handleDelete = useCallback(async (user: User) => {
        if (request.loading) return;

        return request.delete(`/users/${user._id}`, {
            loader: true,
            successMessage: true,
            errorMessage: true
        })
            .then(() => true)
            .catch(() => false);
    }, [request]);

    return (
        <Fragment>
            <DataTableList<User>
                primaryKey="_id"
                endpoint="/users"
                onEdit={(d) => setUserToEditId(d._id)}
                onDelete={handleDelete}
                filters={[
                    { field: 'firstName', label: 'firstName', i18n: 'users', type: FilterType.Search },
                    { field: 'lastName', label: 'lastName', i18n: 'users', type: FilterType.Search },
                    { field: 'company', label: 'company', i18n: 'users', type: FilterType.Search },
                    { field: 'status', label: 'status', i18n: 'entity', type: FilterType.In, items: status },
                    { field: 'role', label: 'role', i18n: 'users', type: FilterType.In, items: roles },
                ]}
                columns={[
                    { field: 'firstName', label: 'firstName', i18n: 'users', sort: true },
                    { field: 'lastName', label: 'lastName', i18n: 'users', sort: true },
                    { field: 'company', label: 'company', i18n: 'users', sort: true },
                    { field: 'status', label: 'status', i18n: 'entity', sort: true, display: (e) => status.find(s => s.key === e.status)?.label },
                    { field: 'role', label: 'role', i18n: 'users', sort: true, display: (e) => roles.find(r => r.key === e.role)?.label },
                    {
                        field: 'connectedAt',
                        label: 'connectedAt',
                        i18n: 'users',
                        sort: true,
                        display: (e) => e.connectedAt ? dateToLocaleString(new Date(e.connectedAt), true) : undefined
                    },
                ]}
                initialPagination={{ sort: { firstName: 1 } }}
                withSearch
            />
            {!!userToEditId && (
                <ModalUser
                    request={{
                        endpoint: `/users/${userToEditId}`,
                        options: { errorMessage: true },
                        onError: () => setUserToEditId(null)
                    }}
                    onClose={() => setUserToEditId(null)}
                />
            )}
        </Fragment>
    );
}
export default Users;