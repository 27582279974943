import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { DirectoryImport, DirectoryImportStep } from "../../models/directory-item";
import { useRequest } from "../../sg-react/context";
import { RightIcon } from "../../sg-react/icons";
import { Button } from "../../sg-react/ui";
import ModalConfirmation from "../../sg-react/ui/ModalConfirmation";
import { Colors } from "../../sg-react/ui/enums";
import { translateEnum } from "../../sg-react/utils/format";
import { conditionnalClassnames } from "../../sg-react/utils/helpers";
import DirectoryImportComplete from "./components/DirectoryImportComplete";
import DirectoryImportConfirmation from "./components/DirectoryImportConfirmation";
import DirectoryImportDataValidation from "./components/DirectoryImportDataValidation";
import DirectoryImportDuplication from "./components/DirectoryImportDuplication";
import DirectoryImportMapColumns from "./components/DirectoryImportMapColumns";
import './index.scss';

export const MAPPING_LABELS: Record<string, { label: string, i18n: string }> = {
    name: { label: 'name', i18n: 'entity' },
    description: { label: 'description', i18n: 'entity' },
    localBusinessId: { label: 'localBusinessId', i18n: 'directory' },
    localBusinessIdType: { label: 'localBusinessIdType', i18n: 'directory' },
    internationalBusinessId: { label: 'internationalBusinessId', i18n: 'directory' },
    internationalBusinessIdType: { label: 'internationalBusinessIdType', i18n: 'directory' },
    vatId: { label: 'vatId', i18n: 'directory' },
    type: { label: 'type', i18n: 'directory' },

    'location.longitude': { label: 'longitude', i18n: 'location' },
    'location.latitude': { label: 'latitude', i18n: 'location' },
    'location.streetNumber': { label: 'streetNumber', i18n: 'location' },
    'location.route': { label: 'route', i18n: 'location' },
    'location.city': { label: 'city', i18n: 'location' },
    'location.region': { label: 'region', i18n: 'location' },
    'location.country': { label: 'country', i18n: 'location' },
    'location.postalCode': { label: 'postalCode', i18n: 'location' },
    'location.address': { label: 'address', i18n: 'location' },

    localPhone: { label: 'localPhone', i18n: 'directory' },
    internationalPhone: { label: 'internationalPhone', i18n: 'directory' },
    website: { label: 'website', i18n: 'directory' },
    email: { label: 'email', i18n: 'directory' },
}


export interface DirectoryImportProps {
    directoryImport: DirectoryImport;
    onChange: (d: DirectoryImport) => void;
}

const DirectoryImportEdit = () => {
    const { t } = useTranslation();
    const [directoryImport, setDirectoryImport] = useState<DirectoryImport | null>(null);
    const [currentStep, setCurrentStep] = useState<DirectoryImportStep>(DirectoryImportStep.Mapping);
    const [isConfirmModalVisible, setConfirmModalVisible] = useState<boolean>(false);
    const request = useRequest();
    const { _id } = useParams();
    const navigate = useNavigate();

    const steps = useMemo(() => translateEnum(DirectoryImportStep, 'directory', 'import_steps', t), [t]);

    const patch = useCallback((directoryImport: DirectoryImport, step: DirectoryImportStep) => {
        let dto: Record<string, any> = { mapping: directoryImport.mapping };

        if (step === DirectoryImportStep.DataValidation) {
            dto = { dataValidated: directoryImport.dataValidated };
        } else if (step === DirectoryImportStep.DuplicationCheck) {
            dto = { duplicatesValidation: directoryImport.duplicatesValidation };
        } else if (step === DirectoryImportStep.Confimation) {
            dto = { permissions: directoryImport.permissions };
        }

        request.patch<DirectoryImport>(`/directory/import/${directoryImport._id}`, dto, {
            withWorkspace: true,
            loader: true,
            errorMessage: true
        })
            .then((data) => {
                setDirectoryImport(data);
                setCurrentStep(data.step);
            })
            .catch(() => null);
    }, [request]);

    const handleNext = useCallback(() => {
        if (!directoryImport) return;

        if (currentStep === DirectoryImportStep.DataValidation && directoryImport?.dataMapped?.length !== directoryImport?.dataValidated?.length) {
            setConfirmModalVisible(true);
        } else if (currentStep === DirectoryImportStep.DuplicationCheck && directoryImport?.dataValidated?.some(d => !directoryImport.duplicatesValidation?.find(dv => dv._id === d._id))) {
            setConfirmModalVisible(true);
        } else {
            patch(directoryImport, currentStep);
        }
    }, [directoryImport, patch, currentStep]);

    const get = useCallback((_id: string) => {
        request.get<DirectoryImport>(`/directory/import/${_id}`, {
            withWorkspace: true,
            loader: true,
            errorMessage: true
        })
            .then((data) => {
                setDirectoryImport(data);
                setCurrentStep(data.step);
            })
            .catch(() => navigate(-1));
    }, [request, setDirectoryImport]);

    useEffect(() => {
        if (_id) {
            get(_id);
        }
    }, [_id]);

    if (!directoryImport) return null;

    return (
        <div id="directory-import">
            <div className="row" id="directory-import-header">
                <div className="row" id="directory-import-steps">
                    {steps.map((s, i) => (
                        <Fragment key={s.key}>
                            <div
                                className={conditionnalClassnames({
                                    current: currentStep === s.key,
                                    disabled: steps.findIndex(s2 => s2.key === directoryImport.step) < i,
                                    done: steps.findIndex(s2 => s2.key === directoryImport.step) > i
                                })}
                                onClick={steps.findIndex(s2 => s2.key === directoryImport.step) >= i ? () => setCurrentStep(s.key) : undefined}
                            >
                                {s.label}
                            </div>
                            {i < steps.length - 1 && <RightIcon className="icon icon-small" />}
                        </Fragment>
                    ))}
                </div>
                {currentStep !== DirectoryImportStep.Complete && (
                    <Button color={Colors.PRIMARY} i18n="actions" label="next" icon={<RightIcon />} onClick={handleNext} />
                )}
            </div>
            <div id="directory-import-content" className="layout-container row row-layout">
                {currentStep === DirectoryImportStep.Mapping && (
                    <DirectoryImportMapColumns directoryImport={directoryImport} onChange={setDirectoryImport} />
                )}
                {currentStep === DirectoryImportStep.DataValidation && (
                    <DirectoryImportDataValidation directoryImport={directoryImport} onChange={setDirectoryImport} />
                )}
                {currentStep === DirectoryImportStep.DuplicationCheck && (
                    <DirectoryImportDuplication directoryImport={directoryImport} onChange={setDirectoryImport} />
                )}
                {currentStep === DirectoryImportStep.Confimation && (
                    <DirectoryImportConfirmation directoryImport={directoryImport} onChange={setDirectoryImport} />
                )}
                {currentStep === DirectoryImportStep.Complete && (
                    <DirectoryImportComplete directoryImport={directoryImport} onChange={setDirectoryImport} />
                )}
            </div>
            {!!isConfirmModalVisible && (
                <ModalConfirmation i18n="data" label="import.data_non_validated_skip_tooltip" onClose={() => setConfirmModalVisible(false)} onSubmit={() => patch(directoryImport, currentStep)} />
            )}
        </div>
    )
}

export default DirectoryImportEdit;