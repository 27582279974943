import { Fragment } from "react";
import { WorkspaceSettingsFormProps } from "..";
import { useWorkspaceContext } from "../../../sg-react/context/WorkspaceContext";
import { DialChartSvgSettings } from "../../../sg-react/data/DialChartSvg";
import Info from "../../../sg-react/ui/Info";

const WorkspaceSettingsSalesIndactors = ({ entity, onChange }: WorkspaceSettingsFormProps) => {
    const { currentWorkspace, refreshWorkspaces } = useWorkspaceContext();

    return (
        <Fragment>
            <Info noIcon i18n="directory-data" label="indicators_settings_tooltip" />
            <div className="simple-list">
                <div className="col">
                    <h3>OTD</h3>
                    <DialChartSvgSettings limits={entity.data?.otd} onChange={(otd) => onChange('data.otd', otd)} />
                </div>
                <div className="col">
                    <h3>OQD</h3>
                    <DialChartSvgSettings limits={entity.data?.oqd} onChange={(oqd) => onChange('data.oqd', oqd)} />
                </div>
            </div>
        </Fragment>
    )
}
export default WorkspaceSettingsSalesIndactors;