import { Fragment, ReactNode, useEffect, useMemo, useState } from 'react';
import { conditionnalClassnames } from '../../utils/helpers';
import './index.scss';

export const MOCK_TREE: WorkflowItem[] = [{
    _id: 'mock_tree_1',
    name: 'mock_tree_1',
    nextStepsIds: ['mock_tree_2'],
    color: 'green',
}, {
    _id: 'mock_tree_2',
    name: 'mock_tree_2',
    nextStepsIds: ['mock_tree_3', 'mock_tree_3b', 'mock_tree_3c'],
    color: 'green',
}, {
    _id: 'mock_tree_3',
    name: 'mock_tree_3',
    nextStepsIds: ['mock_tree_4'],
    color: 'orange',
}, {
    _id: 'mock_tree_3b',
    name: 'mock_tree_3b',
    nextStepsIds: ['mock_tree_4'],
    color: 'green',
}, {
    _id: 'mock_tree_3c',
    name: 'mock_tree_3c',
    nextStepsIds: ['mock_tree_3c_2'],
    color: 'orange',
}, {
    _id: 'mock_tree_3c_2',
    name: 'mock_tree_3c_2',
    nextStepsIds: ['mock_tree_3'],
    color: 'red',
}, {
    _id: 'mock_tree_4',
    name: 'mock_tree_4',
    color: 'orange',
}
]

export type WorkflowItemLocalized<T extends WorkflowItem> = T & {
    x: number;
    y: number;
    isRoot?: boolean;
    disabled?: boolean;
}

export interface WorkflowItem {
    _id: string;
    name: string;
    color?: string;
    nextStepsIds?: string[];
}

const getItemParents = <T extends WorkflowItem>(items: WorkflowItemLocalized<T>[], localizedItems: WorkflowItemLocalized<T>[]): WorkflowItemLocalized<T>[] => {
    const _ids = items.map(i => i._id);
    const parents = localizedItems.filter(i => i.nextStepsIds?.some(a => _ids.includes(a)));

    if (!parents.length) return [];

    const results = getItemParents(parents, localizedItems);

    for (const parent of parents) {
        if (!results.some(r => r._id === parent._id)) {
            results.push(parent);
        }
    }
    return results;
}

const getX = <T extends WorkflowItem>(item: WorkflowItemLocalized<T>) => GRAPH_PADDING + item.x * GRAPH_GRID_X;
const getY = <T extends WorkflowItem>(item: WorkflowItemLocalized<T>) => GRAPH_PADDING + item.y * GRAPH_GRID_Y;

const getLinkPath = <T extends WorkflowItem>(item: WorkflowItemLocalized<T>, link: WorkflowItemLocalized<T>) => {
    const stop = link.y > item.y + 1 ? { ...item, y: link.y - 1 } : item;

    return item.x === link.x
        ? `M ${getX(item)} ${getY(item)} L ${getX(link)} ${getY(link)}`
        : `M ${getX(item)} ${getY(item)} L ${getX(stop)} ${getY(stop)} C ${getX(stop)} ${getY(stop) + GRAPH_GRID_Y * (link.y - stop.y) * 0.7} ${getX(link)} ${getY(link) - GRAPH_GRID_Y * (link.y - stop.y) * 0.7} ${getX(link)} ${getY(link)}`;
}

const getNewBranchPath = <T extends WorkflowItem>(item: WorkflowItemLocalized<T>, link: WorkflowItemLocalized<T>) => {
    return item.x === link.x
        ? `M ${getX(item)} ${getY(item)} L ${getX(link)} ${getY(link)}`
        : `M ${getX(item)} ${getY(item)} C ${getX(item)} ${getY(item) + GRAPH_GRID_Y * (link.y - item.y) * 0.7} ${getX(link) - GRAPH_GRID_X * 0.3} ${getY(link)} ${getX(link)} ${getY(link)}`;
}


const GRAPH_GRID_X = 25;
const GRAPH_GRID_Y = 50;
const GRAPH_CIRCLE_RADIUS = 8;
const GRAPH_PADDING = 20;

export interface WorkflowGraphProps<T> {
    items: T[];
    onAdd?: (links?: { nextStepId?: string, previousStepId?: string }) => void;
    onSelect?: (item?: T) => void;
    label?: (item: T) => ReactNode;
    firstStep?: boolean;
    lastStep?: boolean;
}


const WorkflowGraph = <T extends WorkflowItem>({ items, firstStep, lastStep, onAdd, onSelect, label }: WorkflowGraphProps<T>) => {
    const [selectedItemId, setSelectedItemId] = useState<string | null>(null);

    const localizedItems = useMemo(() => {
        let itemQueue: (T & { isRoot?: boolean })[] = [];

        const roots = items.filter(i => !items.filter(i2 => i2.nextStepsIds?.includes(i._id)).length);

        if (firstStep) {
            const firstStepItem: WorkflowItemLocalized<T> = { _id: 'internal_first_step', disabled: true, x: 0, y: 0, nextStepsIds: roots.map(r => r._id) } as WorkflowItemLocalized<T>;
            itemQueue = [firstStepItem, ...itemQueue];
        } else {
            itemQueue = roots;
        }

        const localizedItems: WorkflowItemLocalized<T>[] = [];
        let maxX = 0;


        while (itemQueue.length) {
            const item = itemQueue.shift();

            if (!item || localizedItems.find(i => item._id === i._id)) continue;
            const children = items.filter(i => item.nextStepsIds?.includes(i._id)
                && !localizedItems.some(li => li._id === i._id)
            );

            itemQueue = [...children, ...itemQueue];

            let y = 0;
            let x = maxX;
            if (item.isRoot) {
                const rootsY = localizedItems.filter(i => i.isRoot).map(i => i.y);
                y = rootsY.length ? Math.max(...rootsY) + 1 : 0;
            } else {
                const parent = localizedItems.find(i => i?.nextStepsIds?.includes(item?._id));
                y = parent ? parent.y + 1 : 0;

                const siblings = localizedItems.filter(i => parent?.nextStepsIds?.includes(i._id));

                x = parent?.x ?? 0;
                if (siblings.length) {
                    if (siblings.some(s => s.x >= x)) {
                        x = Math.max(...siblings.map(s => s.x)) + 1;
                    }
                }

                // Move if x already in use
                if (localizedItems.find(i => i.x === x && localizedItems.filter(next => i.nextStepsIds?.includes(next._id)).some(next => next.y >= y))) {
                    localizedItems.forEach(i => {
                        if (i.x >= x) {
                            i.x += 1;
                        }
                    })
                }

                maxX = Math.max(maxX, x);

            }

            // Push everything down
            localizedItems.forEach(i => {
                if (i.y >= y) {
                    i.y += 1;
                }
            })

            localizedItems.push({ ...item, x, y, isRoot: item.isRoot });
        }

        return localizedItems;
    }, [items, firstStep, lastStep]);

    const selectedItem = useMemo(() => selectedItemId ? localizedItems?.find(i => i._id === selectedItemId) : undefined, [selectedItemId, localizedItems]);

    const selected = useMemo(() => {
        if (!selectedItem || !localizedItems) return;

        return [...getItemParents([selectedItem], localizedItems), selectedItem];
    }, [localizedItems, selectedItem]);

    const newBranchItem = useMemo(() => {
        if (!selectedItem || !localizedItems?.length) return;

        const links = localizedItems.filter(i => selectedItem.nextStepsIds?.includes(i._id));
        const x = !links.length ? selectedItem.x : selectedItem.x + 0.7;

        return { ...selectedItem, x, y: selectedItem.y + 0.4 };
    }, [selectedItem, localizedItems]);

    useEffect(() => {
        if (onSelect) {
            const selected = items.find(i => i._id === selectedItemId);
            onSelect(selected ?? undefined);
        }
    }, [selectedItemId]);

    useEffect(() => {
        if (items.length && !selectedItemId) {
            const roots = items.filter(i => !items.filter(i2 => i2.nextStepsIds?.includes(i._id)).length);
            if (roots.length) {
                setSelectedItemId(roots[0]._id);
            }
        }
    }, [items]);

    if (!localizedItems) return null;

    return (
        <div className="workflow-graph row">
            <svg width={GRAPH_PADDING + (1 + Math.max(...localizedItems.map(l => l.x))) * GRAPH_GRID_X} viewBox={`0 0 ${GRAPH_PADDING + (1 + Math.max(...localizedItems.map(l => l.x))) * GRAPH_GRID_X} ${GRAPH_PADDING + localizedItems.length * GRAPH_GRID_Y}`}>
                <g className="workflow-graph-base">
                    {localizedItems.map((item) => (
                        <Fragment key={item._id + 'link'}>
                            {localizedItems.filter(i => item.nextStepsIds?.includes(i._id)).map(link => (
                                <path
                                    key={link._id}
                                    stroke={item.color}
                                    d={getLinkPath(item, link)}
                                />
                            ))}
                        </Fragment>
                    ))}
                    {localizedItems.map((item) => {
                        return (
                            <circle
                                key={item._id + 'item'}
                                onClick={() => setSelectedItemId(item._id)}
                                cx={getX(item)}
                                cy={getY(item)}
                                r={GRAPH_CIRCLE_RADIUS}
                                stroke={item.color}
                            />
                        );
                    })}
                </g>
                {!!selected?.length && (
                    <g className="selected">
                        {selected.map((item) => (
                            <Fragment key={item._id + '_selected_path'}>
                                {selected.filter(i => item.nextStepsIds?.includes(i._id)).map(link => (
                                    <path
                                        key={link._id + item._id}
                                        stroke={item.color}
                                        d={getLinkPath(item, link)}
                                    />
                                ))}
                            </Fragment>
                        ))}
                        {!!newBranchItem && !!selectedItem && onAdd && (
                            <g className="workflow-graph-add">
                                <g className="workflow-graph-add-branch">
                                    <path d={getNewBranchPath(selectedItem, newBranchItem)} />
                                    <circle
                                        onClick={() => onAdd(selectedItem._id !== 'internal_first_step' ? { previousStepId: selectedItem._id } : {})}
                                        cx={getX(newBranchItem)}
                                        cy={getY(newBranchItem)}
                                        r={GRAPH_CIRCLE_RADIUS / 2}
                                    />
                                </g>
                                {selected.map((item) => (
                                    <Fragment key={item._id + '_selected_new_item'}>
                                        {selected.filter(i => item.nextStepsIds?.includes(i._id)).map(link => (
                                            <circle
                                                key={item._id + link._id + '_selected_new_item_circle'}
                                                onClick={() => onAdd({ previousStepId: selectedItem._id !== 'internal_first_step' ? item._id : undefined, nextStepId: link._id })}
                                                className="workflow-graph-add-item"
                                                cx={getX({ ...item, x: (item.x + link.x) / 2 })}
                                                cy={getY({ ...item, y: item.x === link.x ? (item.y + link.y) / 2 : link.y - 0.5 })}
                                                r={GRAPH_CIRCLE_RADIUS / 2}
                                            />
                                        ))}
                                    </Fragment>
                                ))}
                            </g>
                        )}
                        {selected.map((item) => {
                            return (
                                <circle
                                    key={item._id + '_selected_item'}
                                    className={item._id === selectedItem?._id ? 'workflow-graph-selected-item' : ''}
                                    onClick={() => setSelectedItemId(item._id)}
                                    cx={(item.x * GRAPH_GRID_X) + GRAPH_PADDING}
                                    cy={(item.y * GRAPH_GRID_Y) + GRAPH_PADDING}
                                    r={GRAPH_CIRCLE_RADIUS}
                                    stroke={item.color}
                                />
                            );
                        })}
                    </g>
                )}
            </svg>
            <div className="workflow-graph-labels">
                {localizedItems.sort((i1, i2) => i1.y > i2.y ? 1 : -1).map(i => (
                    <div
                        key={i._id}
                        style={{ height: GRAPH_GRID_Y }}
                        onClick={() => setSelectedItemId(i._id)}
                        className={conditionnalClassnames({
                            "workflow-graph-label-in-selection": selected?.some(s => s._id === i._id),
                            "workflow-graph-label-selected": selectedItemId === i._id
                        })}
                    >
                        {i.disabled ? '' : label ? label(i) : i.name}
                    </div>
                ))}
            </div>
        </div>
    )
}
export default WorkflowGraph;
