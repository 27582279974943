import { Fragment, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Legend, Line, LineChart, ResponsiveContainer, XAxis, YAxis } from 'recharts';
import { DirectoryItemViewPatchProps } from '..';
import { DirectoryDataIndicators, DirectoryGrade, DirectoryPurchasingStrategy, getGradeColor } from '../../../models/directory-data';
import { useRequest } from '../../../sg-react/context';
import { useWorkspaceContext } from '../../../sg-react/context/WorkspaceContext';
import DialChartSvg, { DialChartSvgLimit } from '../../../sg-react/data/DialChartSvg';
import { EditIcon, MenuIcon } from '../../../sg-react/icons';
import { ValueDate } from '../../../sg-react/models/data';
import { Button, Card, Modal } from '../../../sg-react/ui';
import DropdownMenu from '../../../sg-react/ui/DropdownMenu';
import LabelValue from '../../../sg-react/ui/LabelValue';
import { COLORS_FOR_CHARTS } from '../../../sg-react/utils/constants';
import { dateToLocaleString } from '../../../sg-react/utils/date';
import { translateEnum } from '../../../sg-react/utils/format';
import DirectoryViewIndicatorAddModal from './IndicatorsEditModal';

interface DirectoryViewHistoryProps {
    indicators: DirectoryDataIndicators;
    onClose: () => void;
}

const DirectoryViewDialsHistory = ({ onClose, indicators }: DirectoryViewHistoryProps) => {
    const { currentWorkspace } = useWorkspaceContext();

    const otdLimits: DialChartSvgLimit[] | undefined = useMemo(() => currentWorkspace.data?.otd, []);
    const oqdLimits: DialChartSvgLimit[] | undefined = useMemo(() => currentWorkspace.data?.oqd, []);
    const chartData = useMemo(() => {
        const otd: ValueDate<number>[] = indicators.otd?.history.sort((h1, h2) => h1.date > h2.date ? -1 : 1) ?? [];
        if (indicators.otd?.date) {
            otd.push(indicators.otd);
        }
        const oqd: ValueDate<number>[] = indicators.oqd?.history.sort((h1, h2) => h1.date > h2.date ? -1 : 1) ?? [];
        if (indicators.oqd?.date) {
            oqd.push(indicators.oqd);
        }

        return {
            otd: {
                data: otd.map(h => ({ ...h, date: h.date.getTime() })) ?? [],
                domain: otdLimits?.length ? [0, Math.max(...otdLimits.map(l => l.limit))] : undefined,
                ticks: [0, ...otdLimits?.map(l => l.limit) ?? []].sort((t1, t2) => t1 > t2 ? 1 : -1)
            },
            oqd: {
                data: oqd.map(h => ({ ...h, date: h.date.getTime() })) ?? [],
                domain: oqdLimits?.length ? [0, Math.max(...oqdLimits.map(l => l.limit))] : undefined,
                ticks: [0, ...oqdLimits?.map(l => l.limit) ?? []].sort((t1, t2) => t1 > t2 ? 1 : -1)
            },
        }
    }, [indicators, otdLimits, oqdLimits]);

    return (
        <Modal size="medium" onClose={onClose}>
            <ResponsiveContainer minWidth="100%" width="100%" height={250}>
                <LineChart>
                    <XAxis
                        dataKey="date"
                        type="number"
                        allowDuplicatedCategory={false}
                        tickFormatter={(time) => dateToLocaleString(new Date(time))}
                        domain={['min', 'max']}
                    />
                    <YAxis yAxisId="otd" type="number" domain={chartData.otd.domain} ticks={chartData.otd.ticks} />
                    <YAxis yAxisId="oqd" type="number" domain={chartData.oqd.domain} ticks={chartData.oqd.ticks} orientation="right" />
                    <Legend formatter={(value) => <label>{value}</label>} />
                    <Line yAxisId="otd" data={chartData.otd.data} type="monotone" name="OTD" dataKey="value" stroke={COLORS_FOR_CHARTS[5]} activeDot={{ r: 8 }} />
                    <Line yAxisId="oqd" data={chartData.oqd.data} type="monotone" name="OQD" dataKey="value" stroke={COLORS_FOR_CHARTS[9]} activeDot={{ r: 8 }} />
                </LineChart>
            </ResponsiveContainer>
        </Modal>
    );
}

const DirectoryViewGradesHistory = ({ onClose, indicators }: DirectoryViewHistoryProps) => {
    const { t } = useTranslation();

    const purchasingStrategy = useMemo(() => translateEnum(DirectoryPurchasingStrategy, 'directory-data', 'purchasing_strategy', t), [t]);
    const grades = useMemo(() => translateEnum(DirectoryGrade, 'directory-data', 'grades', t), [t]);
    const gradeTicks = useMemo(() => grades.map(g => g.key as string).reverse(), [grades]);
    const psTicks = useMemo(() => purchasingStrategy.map(g => g.key as string).reverse(), [purchasingStrategy]);
    const chartData = useMemo(() => {
        const salesGrade: ValueDate<DirectoryGrade>[] = indicators.salesGrade?.history.sort((h1, h2) => h1.date > h2.date ? -1 : 1) ?? [];
        if (indicators.salesGrade?.date) {
            salesGrade.push(indicators.salesGrade);
        }
        const qualityGrade: ValueDate<DirectoryGrade>[] = indicators.qualityGrade?.history.sort((h1, h2) => h1.date > h2.date ? -1 : 1) ?? [];
        if (indicators.qualityGrade?.date) {
            qualityGrade.push(indicators.qualityGrade);
        }
        const procGrade: ValueDate<DirectoryGrade>[] = indicators.procGrade?.history.sort((h1, h2) => h1.date > h2.date ? -1 : 1) ?? [];
        if (indicators.procGrade?.date) {
            procGrade.push(indicators.procGrade);
        }
        const purchasingStrategy: ValueDate<DirectoryPurchasingStrategy>[] = indicators.purchasingStrategy?.history.sort((h1, h2) => h1.date > h2.date ? -1 : 1) ?? [];
        if (indicators.purchasingStrategy?.date) {
            purchasingStrategy.push(indicators.purchasingStrategy);
        }
        return {
            salesGrade: salesGrade.map(h => ({ ...h, date: h.date.getTime() })) ?? [],
            qualityGrade: qualityGrade.map(h => ({ ...h, date: h.date.getTime() })) ?? [],
            procGrade: procGrade.map(h => ({ ...h, date: h.date.getTime() })) ?? [],
            purchasingStrategy: purchasingStrategy.map(h => ({ ...h, date: h.date.getTime() })) ?? [],
        }
    }, [indicators])

    return (
        <Modal size="medium" onClose={onClose}>
            <ResponsiveContainer minWidth="100%" width="100%" height={250}>
                <LineChart>
                    <XAxis
                        dataKey="date"
                        type="number"
                        allowDuplicatedCategory={false}
                        tickFormatter={(time) => dateToLocaleString(new Date(time))}
                        domain={['min', 'max']}
                    />
                    <YAxis width={100} yAxisId="grade" type="category" domain={gradeTicks} tickFormatter={(v) => grades.find(g => g.key === v)?.label ?? ''} />
                    <YAxis width={100} yAxisId="ps" orientation="right" type="category" domain={psTicks} tickFormatter={(v) => purchasingStrategy.find(g => g.key === v)?.label ?? ''} />
                    <Legend formatter={(value) => <label>{value}</label>} />
                    <Line yAxisId="grade" data={chartData.salesGrade} type="monotone" name={t('directory-data:salesGrade')} dataKey="value" stroke={COLORS_FOR_CHARTS[1]} activeDot={{ r: 8 }} />
                    <Line yAxisId="grade" data={chartData.qualityGrade} type="monotone" name={t('directory-data:qualityGrade')} dataKey="value" stroke={COLORS_FOR_CHARTS[2]} activeDot={{ r: 8 }} />
                    <Line yAxisId="grade" data={chartData.procGrade} type="monotone" name={t('directory-data:procGrade')} dataKey="value" stroke={COLORS_FOR_CHARTS[4]} activeDot={{ r: 8 }} />
                    <Line yAxisId="ps" data={chartData.purchasingStrategy} name={t('directory-data:purchasingStrategy')} type="monotone" dataKey="value" stroke={COLORS_FOR_CHARTS[6]} />
                </LineChart>
            </ResponsiveContainer>
        </Modal>
    );
}

interface DirectoryViewIndicatorMenuProps {
    onHistory: () => void;
    onEdit: () => void;
    canEdit?: boolean;
}

const DirectoryViewIndicatorMenu = ({ onHistory, onEdit, canEdit }: DirectoryViewIndicatorMenuProps) => {
    return (
        <DropdownMenu
            items={[
                ...(canEdit ? [{ itemKey: 'edit', i18n: "actions", label: "edit", icon: <EditIcon />, onClick: onEdit }] : []),
                { itemKey: 'history', i18n: "data", label: "history", onClick: onHistory },
            ]}>
            <Button color="navigation" icon={<MenuIcon />} small />
        </DropdownMenu>
    )
}

const DirectoryViewIndicators = ({ directoryItem, directoryData, refresh, canEdit }: DirectoryItemViewPatchProps) => {
    const { t } = useTranslation();
    const { currentWorkspace } = useWorkspaceContext();
    const [indicatorForHistory, setIndicatorForHistory] = useState<keyof DirectoryDataIndicators | null>(null);
    const [indicatorForEdit, setIndicatorForEdit] = useState<keyof DirectoryDataIndicators | null>(null);
    const request = useRequest();

    const directoryItemPurchasingStrategy = useMemo(() => translateEnum(DirectoryPurchasingStrategy, 'directory-data', 'purchasing_strategy', t), [t]);
    const directoryItemGrades = useMemo(() => translateEnum(DirectoryGrade, 'directory-data', 'grades', t), [t]);

    const handleEditIndicator = useCallback(async (v: ValueDate<string | number>) => {
        if (!indicatorForEdit) return;
        const dto = {
            [indicatorForEdit]: v.value,
            date: v.date
        }

        request.post(`/directory-data/${directoryItem._id}/indicators`, dto, {
            withWorkspace: true,
            loader: true,
            errorMessage: true
        })
            .then(() => {
                refresh();
                setIndicatorForEdit(null);
            })
            .catch(() => null);

    }, [request, directoryItem, indicatorForEdit, refresh]);

    return (
        <Fragment>
            <div id="directory-view-grades">
                <Card className="col">
                    <div className={`row row-padding border-color-${getGradeColor(directoryData.indicators?.purchasingStrategy?.value)}`}>
                        <LabelValue displayIfNull label="purchasingStrategy" i18n="directory-data" value={directoryItemPurchasingStrategy.find(p => p.key === directoryData.indicators?.purchasingStrategy?.value)?.label} />
                        <DirectoryViewIndicatorMenu canEdit={canEdit} onEdit={() => setIndicatorForEdit('purchasingStrategy')} onHistory={() => setIndicatorForHistory('purchasingStrategy')} />
                    </div>
                </Card>
                <Card className="col">
                    <div className={`row row-padding border-color-${getGradeColor(directoryData.indicators?.salesGrade?.value)}`}>
                        <LabelValue displayIfNull label="salesGrade" i18n="directory-data" value={directoryItemGrades.find(p => p.key === directoryData.indicators?.salesGrade?.value)?.label} />
                        <DirectoryViewIndicatorMenu canEdit={canEdit} onEdit={() => setIndicatorForEdit('salesGrade')} onHistory={() => setIndicatorForHistory('salesGrade')} />
                    </div>
                </Card>
                <Card className="col">
                    <div className={`row row-padding border-color-${getGradeColor(directoryData.indicators?.qualityGrade?.value)}`}>
                        <LabelValue displayIfNull label="qualityGrade" i18n="directory-data" value={directoryItemGrades.find(p => p.key === directoryData.indicators?.qualityGrade?.value)?.label} />
                        <DirectoryViewIndicatorMenu canEdit={canEdit} onEdit={() => setIndicatorForEdit('qualityGrade')} onHistory={() => setIndicatorForHistory('qualityGrade')} />
                    </div>
                </Card>
                <Card className="col">
                    <div className={`row row-padding border-color-${getGradeColor(directoryData.indicators?.procGrade?.value)}`}>
                        <LabelValue displayIfNull label="procGrade" i18n="directory-data" value={directoryItemGrades.find(p => p.key === directoryData.indicators?.procGrade?.value)?.label} />
                        <DirectoryViewIndicatorMenu canEdit={canEdit} onEdit={() => setIndicatorForEdit('procGrade')} onHistory={() => setIndicatorForHistory('procGrade')} />
                    </div>
                </Card>
            </div>
            <div id="directory-view-dials">
                {!!currentWorkspace.data?.otd?.length && (
                    <Card
                        className="col directory-item-view-dial"
                        title="OTD"
                        options={<DirectoryViewIndicatorMenu canEdit={canEdit} onEdit={() => setIndicatorForEdit('otd')} onHistory={() => setIndicatorForHistory('otd')} />}
                    >
                        <DialChartSvg value={directoryData.indicators?.otd?.value ?? 0} limits={currentWorkspace.data.otd} withLegend />
                    </Card>
                )}
                {!!currentWorkspace.data?.oqd?.length && (
                    <Card
                        className="col directory-item-view-dial"
                        title="OQD"
                        options={<DirectoryViewIndicatorMenu canEdit={canEdit} onEdit={() => setIndicatorForEdit('oqd')} onHistory={() => setIndicatorForHistory('oqd')} />}
                    >
                        <DialChartSvg value={directoryData.indicators?.oqd?.value ?? 0} limits={currentWorkspace.data.oqd} withLegend />
                    </Card>
                )}
            </div>
            {!!indicatorForHistory && ['otd', 'oqd'].includes(indicatorForHistory) && !!directoryData.indicators?.[indicatorForHistory] && (
                <DirectoryViewDialsHistory onClose={() => setIndicatorForHistory(null)} indicators={directoryData.indicators} />
            )}
            {!!indicatorForHistory && !['otd', 'oqd'].includes(indicatorForHistory) && !!directoryData.indicators?.[indicatorForHistory] && (
                <DirectoryViewGradesHistory onClose={() => setIndicatorForHistory(null)} indicators={directoryData.indicators} />
            )}
            {!!indicatorForEdit && <DirectoryViewIndicatorAddModal indicator={indicatorForEdit} onClose={() => setIndicatorForEdit(null)} onSubmit={handleEditIndicator} />}
        </Fragment >
    )
}
export default DirectoryViewIndicators;