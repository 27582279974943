import { useCallback } from "react";
import { useAuthContext, useRequest } from "../../../context";
import { useForm } from "../../../hooks";
import { SaveIcon } from "../../../icons";
import { CurrentUser } from "../../../models/user";
import { Button, Card } from "../../../ui";
import { Colors } from "../../../ui/enums";
import ProfileForm, { PROFILE_VALIDATION } from "./ProfileForm";

const Profile = () => {
    const { currentUser, refreshUser } = useAuthContext();
    const { validate, attachInput, hasChanged, reset } = useForm<CurrentUser>({ ...currentUser });
    const request = useRequest();

    const handleSubmit = useCallback(() => validate(
        (entity) => {
            request.patch('/users/me', { profile: entity }, {
                loader: true,
                successMessage: true,
                errorMessage: true
            })
                .then(() => {
                    reset(entity, false);
                    refreshUser();
                })
                .catch(() => null);

        },
        PROFILE_VALIDATION
    ), [request, validate, reset]);

    return (
        <Card
            id="account-profile"
            title="profile"
            i18n="users"
            color={hasChanged ? 'warning' : undefined}
            options={hasChanged ? <Button label="save" i18n="actions" icon={<SaveIcon />} color={Colors.WARNING} onClick={handleSubmit} /> : undefined}
        >
            <div className="form-inline-group">
                <ProfileForm attachInput={attachInput} />
            </div>
        </Card>
    )
}
export default Profile;