import { useTranslation } from 'react-i18next';
import { ErrorIcon, InfoIcon, SuccessIcon, WarningIcon } from '../../icons';
import './index.scss';
import { translateLabel } from '../../utils/format';

interface InfoProps {
    label: string;
    i18n?: string;
    type?: 'info' | 'warning' | 'error' | 'success';
    noIcon?: boolean;
}

const Info = ({ label, i18n, type, noIcon }: InfoProps) => {
    const { t } = useTranslation();

    return (
        <div className={`info info-${type}`}>
            {!noIcon && (type === 'info' || !type) && <InfoIcon />}
            {!noIcon && type === 'error' && <ErrorIcon />}
            {!noIcon && type === 'success' && <SuccessIcon />}
            {!noIcon && type === 'warning' && <WarningIcon />}
            <span>{translateLabel(label, t, i18n)}</span>
        </div>
    )
}
export default Info;