import { Colors } from '../enums';
import './index.scss';

export interface PillProps {
    value?: number;
    color: Colors | string;
}

const Pill = ({ value, color }: PillProps) => (
    <div className={`pill background-color-${color}`} style={color.startsWith('#') ? { backgroundColor: color } : undefined}>
        {value !== undefined ? value : ''}
    </div>
);

export default Pill;