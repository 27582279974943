import { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { User } from "../../models/user";
import { joinOptionnalStrings } from "../../utils/format";
import BubbleHead from "../BubbleHead";
import ValueDescription from "../ValueDescription";
import './index.scss';

interface UserItemProps {
    user?: Partial<User>;
    withContactInfos?: boolean;
    children?: ReactNode;
}

const UserItem = ({ user, children }: UserItemProps) => {
    const { t } = useTranslation();

    return (
        <div className="user-item row">
            {!!user?.avatar?.base64 && <BubbleHead image={user?.avatar?.base64} size="xs" />}
            <ValueDescription
                wrap
                value={user?.fullName ?? (user?.firstName || user?.lastName ? joinOptionnalStrings([user.firstName, user.lastName], ' ') : t('users:user_inactive'))}
                description={joinOptionnalStrings([user?.company, user?.field, user?.position])}
            />
            {children}
        </div>
    )
}

export default UserItem;