import { Fragment } from "react";
import { Password } from "../../../form";
import Validation, { ObjectValidation } from "../../../form/Validation/class";
import { FormHookReturn } from "../../../hooks/useForm";
import { NewPassword } from "../../../models/auth";

export const PASSWORD_VALIDATION = new ObjectValidation({
    password: Validation.string().required().regex(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/),
    passwordVerify: Validation.string().required().regex(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/),
})

const NewPasswordForm = <T extends NewPassword>({ attachInput, inline, entity }: Pick<FormHookReturn<T>, 'attachInput' | 'entity'> & { inline?: boolean }) => (
    <Fragment>
        <Password
            {...attachInput('password')}
            inline={inline}
            label
            i18n="auth"
            withProgressBar
            withWarnings
            regex
            withShowIcon
        />
        <Password
            {...attachInput('passwordVerify')}
            inline={inline}
            label="password_verify"
            i18n="auth"
            compareTo={entity.password}
            withShowIcon
        />
    </Fragment>
);

export default NewPasswordForm;