import { useState } from 'react';
import { useUiContext } from '../../context';
import Toast, { ToastData } from '../Toast';
import './index.scss';
import ToastInfoModal from '../ToastInfoModal';

const ToastQueue = () => {
    const { toasts, removeToast } = useUiContext();
    const [toastForInfo, setToastForInfo] = useState<ToastData | null>(null);

    if (!toasts.length) return null;

    return (
        <div id="toast-queue">
            {toasts.map(toast => (
                <Toast
                    key={toast._id}
                    toast={toast}
                    onClose={() => removeToast(toast)}
                    onClick={() => setToastForInfo(toast)}
                />
            ))}
            {!!toastForInfo && <ToastInfoModal toast={toastForInfo} onClose={() => setToastForInfo(null)} />}
        </div>
    )
}
export default ToastQueue;