import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRequest } from '../../context';
import { useWorkspaceContext } from '../../context/WorkspaceContext';
import { TextField } from '../../form';
import { ValidationError } from '../../form/Validation/types';
import { UserData } from '../../models/user-data';
import { Modal } from '../../ui';
import { dateToLocaleString } from '../../utils/date';
import DataContainerList from '../DataContainerList';
import './index.scss';

interface UserDataModalProps {
    mode: 'open' | 'save';
    type: string;
    schemaVersion: number;
    userData?: Partial<UserData>;
    onClose: () => void;
    onSubmit: (ud: UserData) => void;
}

const UserDataModal = ({ userData, type, schemaVersion, mode, onClose, onSubmit }: UserDataModalProps) => {
    const { t } = useTranslation();
    const [name, setName] = useState<string | undefined>(userData?.name);
    const { currentWorkspace } = useWorkspaceContext();
    const [userDataList, setUserDataList] = useState<UserData[] | null>(null);
    const [selected, setSelected] = useState<UserData | null>(null);
    const [errors, setErrors] = useState<ValidationError[]>([]);
    const request = useRequest();

    const createOrUpdate = useCallback((entity: Partial<UserData>) => {
        if (request.loading) return;

        if (!entity.name || !entity.schemaVersion) {
            setErrors([{ type: 'REQUIRED' }]);
            return;
        };

        const requestMethod = !entity._id ? request.post : request.put;

        requestMethod<UserData>('/users-data', entity, {
            loader: true,
            successMessage: true,
            errorMessage: true
        })
            .then((userData) => {
                onSubmit({ ...userData, data: entity.data });
                onClose();
            })
            .catch(() => onClose());
    }, [request, onSubmit, onClose]);

    const get = useCallback(() => {
        if (request.loading) return;

        request.get<UserData[]>(`/users-data/me/${currentWorkspace._id}/${type}`, {
            errorMessage: true
        })
            .then(setUserDataList)
            .catch(() => onClose);
    }, [request, type, currentWorkspace, onClose]);

    const handleSubmit = useCallback(() => {
        if (mode === 'save') {
            createOrUpdate({ ...userData, name, type, workspaceId: currentWorkspace._id, schemaVersion, _id: selected?._id ?? userData?._id })
        } else if (selected) {
            // Fetch selected
            request.get<UserData>(`/users-data/${selected._id}`, {
                errorMessage: true
            })
                .then(onSubmit)
                .catch(() => onClose);
            onSubmit(selected);
            onClose();
        }
    }, [mode, name, type, schemaVersion, onClose, onSubmit, request, createOrUpdate, userData, selected, currentWorkspace]);

    useEffect(() => {
        setName(selected?.name);
    }, [selected]);

    useEffect(() => {
        if (mode === 'save' && userData?._id && userData.name) {
            createOrUpdate({ ...userData, type, workspaceId: currentWorkspace._id, schemaVersion });
        } else {
            get();
        }
    }, []);

    if (!userDataList) return null;

    return (
        <Modal
            size="medium"
            title={mode === 'open' ? t('actions:open') : t('actions:save')}
            onClose={onClose}
            onSubmit={handleSubmit}
        >
            <DataContainerList<UserData>
                primaryKey="_id"
                onSelect={setSelected}
                data={userDataList}
                columns={[
                    { field: 'name', label: 'name', i18n: 'entity' },
                    { field: 'createdAt', label: 'createdAt', i18n: 'entity', display: (e) => dateToLocaleString(new Date(e.createdAt), true) },
                    { field: 'updatedAt', label: 'updatedAt', i18n: 'entity', display: (e) => dateToLocaleString(new Date(e.updatedAt), true) },
                ]}
                sort={{ field: "updatedAt", direction: -1 }}
            />
            {mode === 'save' ? (
                <TextField
                    id="user-data-name"
                    value={name}
                    onChange={setName}
                    label={selected ? 'rename' : 'create_new'}
                    i18n="actions"
                    errors={errors}
                />
            ) : (<span></span>)}
        </Modal>
    )
}
export default UserDataModal;