import { useCallback } from 'react';
import { useForm } from '../../hooks';
import { Modal } from '../../ui';
import Info from '../../ui/Info';
import TextArea from '../TextArea';
import Validation, { ObjectValidation } from '../Validation/class';

interface CommentModalProps {
    onClose: () => void;
    onSubmit: (comment?: string) => void;
    optional?: boolean;
    info?: { i18n?: string, label: string }
}

const OPTIONAL_VALIDATION = new ObjectValidation({
    comment: Validation.string().minLength(20).maxLength(100)
})

const VALIDATION = new ObjectValidation({
    comment: Validation.string().required().minLength(20).maxLength(100)
})

const CommentModal = ({ onClose, onSubmit, optional, info }: CommentModalProps) => {
    const { validate, attachInput } = useForm<{ comment?: string }>({});

    const handleSubmit = useCallback(() => validate(
        (entity) => onSubmit(entity.comment),
        optional ? OPTIONAL_VALIDATION : VALIDATION
    ), [onSubmit, validate, optional]);

    return (
        <Modal
            size="small"
            onClose={onClose}
            onSubmit={handleSubmit}
            i18n="action"
            title="comment"
        >
            {!!info && <Info i18n={info.i18n} label={info.label} />}
            <TextArea
                label
                i18n="actions"
                {...attachInput('comment')}
            />
        </Modal>
    )
}
export default CommentModal;