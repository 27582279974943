// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.icon-value {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  font-size: 11px;
}
.icon-value > svg {
  width: 16px;
  height: 16px;
  fill: var(--theme-label-color);
  flex-shrink: 0;
}
.icon-value > span {
  white-space: wrap;
  overflow: hidden;
}
.icon-value a {
  white-space: nowrap;
  text-overflow: ellipsis;
}`, "",{"version":3,"sources":["webpack://./src/sg-react/ui/IconValue/index.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,QAAA;EACA,eAAA;AACJ;AACI;EACI,WAAA;EACA,YAAA;EACA,8BAAA;EACA,cAAA;AACR;AAEI;EACI,iBAAA;EACA,gBAAA;AAAR;AAGI;EACI,mBAAA;EACA,uBAAA;AADR","sourcesContent":[".icon-value {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    gap: 8px;\n    font-size: 11px;\n\n    & > svg {\n        width: 16px;\n        height: 16px;\n        fill: var(--theme-label-color);\n        flex-shrink: 0;\n    }\n\n    & > span {\n        white-space: wrap;\n        overflow: hidden;\n    }\n\n    & a {\n        white-space: nowrap;\n        text-overflow: ellipsis;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
