import { ReactNode, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { DirectoryDate } from "../../models/directory-data";
import Tag from "../../sg-react/ui/Tag";
import ValueDescription from "../../sg-react/ui/ValueDescription";
import { Colors } from "../../sg-react/ui/enums";
import { dateToLocaleString } from "../../sg-react/utils/date";
import './index.scss';

interface DirectoryDataDateProps {
    date: DirectoryDate;
    directoryItemName?: string;
    children?: ReactNode;
}

const DirectoryDataDate = ({ date, directoryItemName, children }: DirectoryDataDateProps) => {
    const days = useMemo(() => Math.round((date.date.getTime() - Date.now()) / (1000 * 3600 * 24)), [date]);
    const { t } = useTranslation();

    return (
        <div className="directory-item-view-date">
            <ValueDescription wrap value={`${dateToLocaleString(date.date)} ${directoryItemName ? directoryItemName + ' - ' : ''}${date.name}`} description={date.description} />
            <Tag
                label={`${days} ${days > 1 ? t('dates:days').toLowerCase() : t('dates:day').toLowerCase()}`}
                color={days < 30 ? Colors.ERROR : days < 90 ? Colors.WARNING : Colors.PRIMARY}
            />
            {children}
        </div>
    )
}
export default DirectoryDataDate;