import Breadcrumbs, { BreadcrumbsProps } from "../Breadcrumbs";
import Button, { ButtonProps } from "../Button";
import './index.scss';

export interface PageHeaderProps extends BreadcrumbsProps {
    actions?: ButtonProps[];
}

const PageHeader = ({ breadcrumbs, actions }: PageHeaderProps) => {

    return (
        <div className="page-header">
            <Breadcrumbs breadcrumbs={breadcrumbs} />
            <div className="page-header-actions">
                {actions?.map(a => <Button key={a.label} {...a} />)}
            </div>
        </div>
    )
}

export default PageHeader;