import Validation, {
    ObjectValidation,
} from "../sg-react/form/Validation/class";
import {
    NAMED_ENTITY_VALIDATION,
    NamedEntity,
} from "../sg-react/models/entity";
import { Image } from "../sg-react/models/image";
import { WorkspaceTag } from "../sg-react/models/workspace";
import { DirectoryItem } from "./directory-item";
import { Specification } from "./specification";

export interface Part extends NamedEntity {
    workspaceId: string;
    size: PartSize;
    complexity?: {
        shape: number;
        internalExternal: number;
        blindHoles: number;
        spType: number;
        spNumber: number;
        total: number;
    };
    image?: Image;
    tagsIds: string[];
    tags?: WorkspaceTag[];
    segmentationsIds?: string[];
    steps: PartStep[];
}

export interface PartStep {
    _id: string;
    name: string;
    description?: string;
    businesses: PartStepBusiness[];
    specificationsIds: string[];
    specifications?: Specification[];
    nextStepsIds: string[];
    parentsIds?: string[];
}

export interface PartStepBusiness {
    directoryItemId: string;
    directoryItem?: DirectoryItem;
    isAlternative: boolean;
}

export interface PartsDashboard {
    total: number;
    noSource: number;
    noSourceRatio?: number;
    monoSource: number;
    monoSourceRatio?: number;
    complexity?: {
        min: number;
        max: number;
        average?: number;
    };
}

export enum PartSize {
    XXS = "XXS",
    XS = "XS",
    S = "S",
    M = "M",
    L = "L",
    XL = "XL",
    XXL = "XXL",
}

export const PART_VALIDATION = new ObjectValidation<Part>({
    tagsIds: Validation.array(Validation.string().required().isMongoId()),
    size: Validation.string().required().isEnum(PartSize),
    complexity: {
        shape: Validation.number().required(),
        internalExternal: Validation.number().required(),
        blindHoles: Validation.number().required(),
        spType: Validation.number().required(),
        spNumber: Validation.number().required(),
    },
}).merge(NAMED_ENTITY_VALIDATION);

export const PART_STEP_VALIDATION = new ObjectValidation<PartStep>({}).merge(
    NAMED_ENTITY_VALIDATION
);
