import { ReactNode, Ref, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRequest } from "../../context";
import { NumberField } from "../../form";
import Slider from "../../form/Slider";
import { UseFocusHandleRef } from "../../hooks";
import { Geoname } from "../../models/geo";
import { Card } from "../../ui";
import Dropdown from "../../ui/Dropdown";
import Search, { SearchItem } from "../Search";
import './index.scss';

export interface GeosearchResult {
    id: number;
    name: string;
    parents?: string;
    longitude: number;
    latitude: number;
    radius?: number;
    geonamesIds: number[];
    admin2?: string;
    admin1?: string;
    country?: string;
}

interface GeosearchProps {
    label?: string;
    i18n?: string;
    placeholder?: string;
    value?: string;
    inline?: boolean;
    onResult: (e: GeosearchResult) => void;
    disabled?: boolean;
    children?: ReactNode
    focusHandle?: Ref<UseFocusHandleRef>;
    populateResponse?: boolean;
}

const Geosearch = ({ onResult, disabled, inline, value, children, focusHandle, i18n, label, placeholder, populateResponse }: GeosearchProps) => {
    const request = useRequest();
    const { t, i18n: i18next } = useTranslation();
    const [results, setResults] = useState<Geoname[] | null>(null);

    const getResults = useCallback(async (keyword: string) => {
        await request.get<Geoname[]>(`/geo/search/${i18next.language}`, { params: { keyword }, errorMessage: true })
            .then(setResults)
            .catch(() => null);
    }, [request, i18next]);

    const getPopulatedResult = useCallback(async (id: number): Promise<Geoname> => {
        return request.get<Geoname>(`/geo/${id}/${i18next.language}`, { errorMessage: true });
    }, [request, i18next]);

    const clearSearch = useCallback(() => {
        setResults(null);
    }, []);

    const handleClick = useCallback(async (g: Geoname) => {
        let geoname = g;
        if (populateResponse) {
            try {
                geoname = await getPopulatedResult(g.id);
            } catch {
                return;
            }
        }
        onResult({
            id: g.id,
            name: g.name,
            parents: g.parents,
            longitude: g.longitude,
            latitude: g.latitude,
            radius: 0,
            geonamesIds: [g.id, ...geoname.parentsIds ?? []],
            admin2: geoname.admin2?.name,
            admin1: geoname.admin1?.name,
            country: geoname.country?.name,
        });
        clearSearch();
    }, [onResult, clearSearch, populateResponse, getPopulatedResult]);

    return (
        <div className="col geosearch">
            <Search
                id="geosearch"
                onClear={clearSearch}
                onChange={getResults}
                inline={inline}
                disabled={disabled}
                value={value}
                label={label}
                i18n={i18n}
                placeholder={placeholder ?? t('location:search')}
                focusHandle={focusHandle}
            >
                {!!results?.length && results.map(r => <SearchItem key={r.id} label={r.name} description={r.parents} onClick={() => handleClick(r)} />)}
            </Search>
            {children}
        </div>
    )
}

export interface GeosearchResultProps {
    geosearch: GeosearchResult;
    onRadiusChange?: (r?: number) => void;
}

const Result = ({ geosearch, onRadiusChange }: GeosearchResultProps) => {
    const { t } = useTranslation();

    return (
        <div className="row geosearch-result">
            <span>{geosearch.name}</span>
            {!!onRadiusChange && (
                <Dropdown openOn="click">
                    <Dropdown.Header>
                        <span>{t('filters:radius')}</span>
                        <NumberField
                            inline
                            id="radius"
                            value={geosearch.radius}
                            onChange={onRadiusChange}
                        />
                        <span>km</span>
                    </Dropdown.Header>
                    <Dropdown.Content>
                        <Card>
                            <Slider
                                value={geosearch.radius}
                                onChange={onRadiusChange}
                                range={[0, 1000]}
                            />
                        </Card>
                    </Dropdown.Content>
                </Dropdown>
            )}
        </div>
    );
}

Geosearch.Result = Result;

export default Geosearch;