// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#toast-queue {
  position: fixed;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 12px;
  width: 400px;
  z-index: 110;
}`, "",{"version":3,"sources":["webpack://./src/sg-react/ui/ToastQueue/index.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,QAAA;EACA,SAAA;EACA,aAAA;EACA,sBAAA;EACA,SAAA;EACA,aAAA;EACA,YAAA;EACA,YAAA;AACJ","sourcesContent":["#toast-queue {\n    position: fixed;\n    right: 0;\n    bottom: 0;\n    display: flex;\n    flex-direction: column;\n    gap: 12px;\n    padding: 12px;\n    width: 400px;\n    z-index: 110;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
