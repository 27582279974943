import './index.scss';
import { SpinngerIcon } from '../../icons';

interface SpinnerProps {
    small?: boolean;
}

const Spinner = ({ small }: SpinnerProps) => {

    return (
        <div className={`spinner ${small ? 'spinner-small' : ''}`}>
            <SpinngerIcon />
        </div>
    )
}

export default Spinner;