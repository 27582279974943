import { Specification } from "../../models/specification";
import { TextArea, TextField } from "../../sg-react/form";
import { WizardContentComponentProps } from "../../sg-react/ui/Wizard";


const SpecificationiWizardIdentification = ({ entity, attachInput }: WizardContentComponentProps<Specification>) => {
    return (
        <div className="form-inline-group">
            <TextField label inline i18n="entity" {...attachInput('name')} />
            <TextArea label inline i18n="entity" rows={5} {...attachInput('description')} />
        </div>
    )
}
export default SpecificationiWizardIdentification;