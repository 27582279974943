import Validation, { ObjectValidation } from "../form/Validation/class";
import { Entity, NAMED_ENTITY_VALIDATION } from "./entity";
import { Image } from "./image";
import { User } from "./user";

export type Workspace = Entity & {
    name: string;
    description?: string;
    internal: boolean;
    users: WorkspaceUser[];
    image?: Image;
    widgets: string[];
    data?: Record<string, any>;
    tags?: WorkspaceTag[];
};

export interface WorkspaceUser {
    userId: string;
    user?: User;
    role: WorkspaceRole;
}

export interface WorkspaceForUser extends Workspace {
    current: boolean;
    role: WorkspaceRole;
}

export const WORKSPACE_VALIDATION = new ObjectValidation<Workspace>({}).merge(
    NAMED_ENTITY_VALIDATION
);

export const WORKSPACE_INVITE_VALIDATION = new ObjectValidation<{
    email: string;
    role: WorkspaceRole;
}>({
    email: Validation.string().required().isEmail(),
});

export interface WorkspaceTag {
    _id: string;
    name: string;
    description: string;
    category: string;
    color: string;
}

export const WORKSPACE_TAG_VALIDATION = new ObjectValidation<WorkspaceTag>({
    category: Validation.string().required().minLength(3).maxLength(150),
    color: Validation.string().minLength(6).maxLength(7),
}).merge(NAMED_ENTITY_VALIDATION);

export enum WorkspaceRole {
    Owner = "OWNER",
    Maintainer = "MAINTAINER",
    User = "USER",
}

export enum WorkspacePrivacy {
    NoOne = "NO_ONE",
    Writers = "WRITERS",
    UsersWorkspace = "USERS_WORKSPACE",
    UsersSelection = "USERS_SELECTION",
}
