import { useCallback, useState } from "react";
import { ObjectValidation } from "../form/Validation/class";
import { ObjectValidationException } from "../form/Validation/exceptions";
import { ObjectValidationErrors } from "../form/Validation/types";
import { changeFieldValue, getNestedField } from "./../utils/objects";

export interface FormHookReturn<T> {
    entity: Partial<T>;
    hasChanged: boolean;
    hasError: boolean;
    attachInput: (field: string) => any;
    onChange: (field: string, value: any) => void;
    onMultipleChange: (changes: Record<string, any>) => void;
    setEntity: (_entity: Partial<T>) => void;
    reset: (_entity?: Partial<T>, hasChanged?: boolean) => void;
    validate: (cb: (e: T) => void, validation?: ObjectValidation) => void;
    setErrors: (e: ObjectValidationErrors) => void;
    setWarnings: (e: ObjectValidationErrors) => void;
    errors: ObjectValidationErrors;
    warnings: ObjectValidationErrors;
}

export type UseFormConstraint = Record<string, any>;

export const useForm = <T extends UseFormConstraint>(
    initialEntity: Partial<T> | undefined,
    validation?: ObjectValidation<T>
): FormHookReturn<T> => {
    const [cleanEntity, setCleanEntity] = useState<Partial<T>>(
        initialEntity ?? {}
    );
    const [entity, setEntity] = useState<Partial<T>>(initialEntity ?? {});
    const [errors, setErrors] = useState<ObjectValidationErrors>({});
    const [warnings, setWarnings] = useState<ObjectValidationErrors>({});
    const [hasChanged, setChanged] = useState<boolean>(false);

    const onChange = useCallback((field: string, value: any): void => {
        setEntity((entity) => {
            setErrors((errors) => ({ ...errors, [field]: [] }));
            setChanged(true);

            return changeFieldValue(entity, field, value);
        });
    }, []);

    const onMultipleChange = useCallback(
        (changes: Record<string, any>): void => {
            setEntity((entity) => {
                if (!Object.keys(changes).length) return entity;

                let _entity = { ...entity };
                let hasChanged = false;

                for (const key in changes) {
                    _entity = changeFieldValue(_entity, key, changes[key]);
                    hasChanged = true;
                }

                setErrors((errors) => {
                    const _errors = { ...errors };

                    for (const key in changes) {
                        _errors[key] = [];
                    }
                    return _errors;
                });

                if (hasChanged) {
                    setChanged(true);
                    return _entity;
                }
                return entity;
            });
        },
        []
    );

    const reset = useCallback(
        (_entity?: Partial<T>, hasChanged?: boolean) => {
            if (_entity) {
                setCleanEntity(_entity);
                setEntity(_entity);
            } else {
                setEntity(cleanEntity);
            }
            setChanged(hasChanged !== undefined ? hasChanged : false);
            setErrors({});
        },
        [cleanEntity]
    );

    const validate = useCallback(
        (cb: (e: T) => void, optionalValidation?: ObjectValidation) => {
            if (!validation && !optionalValidation) {
                setErrors({});
                cb(entity as T);
            } else {
                let validatedEntity: T = entity as T;
                if (validation) {
                    try {
                        validatedEntity = {
                            ...validatedEntity,
                            ...validation.validate(validatedEntity),
                        };
                    } catch (e) {
                        if (e instanceof ObjectValidationException) {
                            setErrors(e.errors);
                            return;
                        }
                    }
                }
                if (optionalValidation) {
                    try {
                        validatedEntity = {
                            ...validatedEntity,
                            ...optionalValidation.validate(validatedEntity),
                        };
                    } catch (e) {
                        if (e instanceof ObjectValidationException) {
                            setErrors(e.errors);
                            return;
                        }
                    }
                }

                cb(validatedEntity);
            }
        },
        [entity, validation]
    );

    const attachInput = useCallback(
        (field: string) => ({
            id: field,
            onChange: (v: any) => onChange(field, v),
            value: getNestedField(entity, field),
            errors: errors[field],
            entity,
            onMultipleChange,
        }),
        [entity, errors, onChange, onMultipleChange]
    );

    return {
        entity,
        hasChanged,
        reset,
        attachInput,
        onChange,
        onMultipleChange,
        validate,
        setEntity,
        setErrors,
        setWarnings,
        hasError: Object.keys(errors).some((e) => !!errors[e]?.length),
        errors,
        warnings,
    };
};

export default useForm;
