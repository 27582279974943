import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useRequest } from "../../context";
import { CheckboxList, Select, TextArea, TextField } from "../../form";
import Validation, { ObjectValidation } from "../../form/Validation/class";
import { useForm } from "../../hooks";
import { User } from "../../models/user";
import { translateEnum } from "../../utils/format";
import Modal from "../Modal";

interface ContactModalProps {
    onClose: () => void;
}

enum Subject {
    DemoRequest = 'DEMO_REQUEST',
    Bug = 'BUG',
    AuthenticationIssue = 'AUTHENTICATION_ISSUE',
    Partnership = 'PARTNERSHIP'
}

interface Contact extends User {
    message: string;
    subject: Subject;
    contactMode: string[];
}

const VALIDATION = new ObjectValidation({
    firstName: Validation.string().required().minLength(3).maxLength(150),
    lastName: Validation.string().required().minLength(3).maxLength(150),
    company: Validation.string().required().minLength(3).maxLength(150),
    email: Validation.string().required().isEmail(),
    phone: Validation.string().minLength(3).maxLength(20),
    subject: Validation.string().required().isEnum(Subject),
    message: Validation.string().required().minLength(50).maxLength(500),
})

const ContactModal = ({ onClose }: ContactModalProps) => {
    const { entity, attachInput, validate } = useForm<Contact>({});
    const { t } = useTranslation();
    const request = useRequest();

    const subjects = useMemo(() => translateEnum(Subject, 'ui', 'subjects', t), [t]);
    const contactModes = useMemo(() => [{
        key: 'EMAIL',
        label: t('users:email')
    }, {
        key: 'PHONE',
        label: t('users:phone')
    }], [t]);

    const handleSubmit = useCallback(() => validate(
        (dto: Contact) => {
            if (request.loading) return;

            request.post('/auth/contact_us', dto, { loader: true, errorMessage: true, successMessage: { i18n: 'ui', message: 'success.message_sent' } })
                .then(onClose)
                .catch(() => null);
        },
        VALIDATION
    ), [validate, request]);

    return (
        <Modal
            title="contact_us"
            i18n="ui"
            size="medium"
            overflow="auto"
            onClose={onClose}
            onSubmit={handleSubmit}
        >
            <div className="row row-layout">
                <div className="col">
                    <TextField label i18n="users" {...attachInput('firstName')} />
                    <TextField label i18n="users" {...attachInput('lastName')} />
                    <TextField label i18n="users" {...attachInput('company')} />
                    <TextField label i18n="users" {...attachInput('phone')} />
                    <TextField label i18n="users" {...attachInput('email')} />
                </div>
                <div className="col col-lg-60">
                    <Select label items={subjects} i18n="ui" {...attachInput('subject')} />
                    <TextArea rows={10} label i18n="ui" {...attachInput('message')} />
                    <CheckboxList
                        multiple
                        items={contactModes}
                        label="contact_mode"
                        i18n="ui"
                        {...attachInput('contactMode')}
                    />
                </div>
            </div>
        </Modal>
    )
}
export default ContactModal;