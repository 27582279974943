import { useMemo } from "react";
import { LabelList, ResponsiveContainer, Treemap } from "recharts";
import { Card } from "../../../sg-react/ui";
import Info from "../../../sg-react/ui/Info";
import { useMapContext } from "../../MainMap/components/MapContext";

const QualifiersEmissions = () => {
    const { planResults, plan } = useMapContext();

    const data = useMemo(() => plan.steps.map((s) => ({
        name: s.name,
        children: s.segmentationsIds?.map(segmentationId => ({
            name: segmentationId,
            size: Math.floor(Math.random() * 1000),
            fill: s.color
        }))
    })), [plan]);

    return (
        <Card title="Steps emissions" id="plan-analysis-qualifiers-emissions">
            <Info type="warning" i18n="ui" label="degraded_feature" />
            <ResponsiveContainer width="99%" height={220}>
                <Treemap data={data} dataKey="size" aspectRatio={6 / 3} stroke="#fff" fill="#8884d8">
                    <LabelList dataKey="size" position="insideRight" style={{ fill: "white" }} />
                </Treemap>
            </ResponsiveContainer>
        </Card>
    )
}
export default QualifiersEmissions;