import { ReactNode } from 'react';
import { DirectoryItem } from '../../models/directory-item';
import DataComponentList from '../../sg-react/data/DataComponentList';
import ListItem from '../../sg-react/ui/ListItem';
import { joinOptionnalStrings } from '../../sg-react/utils/format';
import DirectoryItemIcon from '../DirectoryItemIcon';
import './index.scss';

interface DirectoryItemsListItemProps {
    entity: DirectoryItem;
    onClick?: (c: DirectoryItem) => void;
    children?: ReactNode;
}

export const DirectoryItemsListItem = ({ entity: directoryItem, onClick, children }: DirectoryItemsListItemProps) => (
    <ListItem
        value={directoryItem.name}
        description={joinOptionnalStrings([directoryItem.location?.city, directoryItem.location?.country])}
        onClick={onClick ? () => onClick(directoryItem) : undefined}
        icon={<DirectoryItemIcon type={directoryItem.type} />}
    >
        {children}
    </ListItem>
)

interface DirectoryItemsListProps {
    directoryItems: (DirectoryItem & { color?: string })[];
    onClick?: (c: DirectoryItem) => void;
    withSearch?: boolean;
    withSort?: boolean;
}

const DirectoryItemsList = ({ directoryItems, onClick, withSearch, withSort }: DirectoryItemsListProps) => {

    return (
        <DataComponentList
            component={DirectoryItemsListItem}
            data={directoryItems}
            primaryKey="_id"
            onClick={onClick}
            onSearch={withSearch ? (d, k) => d.name?.toLowerCase().includes(k.toLowerCase()) : undefined}
            className="directory-items-small-list"
            sort={withSort ? [{ field: 'name', label: 'name', i18n: 'entity' }] : undefined}
            simple
        />
    )
}

export default DirectoryItemsList;