import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { INCIDENT_VALIDATION, Incident } from "../../../models/incident";
import { useRequest } from "../../../sg-react/context";
import DataComponentList from "../../../sg-react/data/DataComponentList";
import { Select, TagsInput, TextArea, TextField } from "../../../sg-react/form";
import { useForm } from "../../../sg-react/hooks";
import { TrashIcon } from "../../../sg-react/icons";
import { Button, Card, Modal } from "../../../sg-react/ui";
import ValueDescription from "../../../sg-react/ui/ValueDescription";
import { Colors } from "../../../sg-react/ui/enums";
import { joinOptionnalStrings } from "../../../sg-react/utils/format";

const DashboardIncidents = ({ }) => {
    const [incidents, setIncidents] = useState<Incident[]>([]);
    const { entity, reset, attachInput, validate } = useForm<Incident>({}, INCIDENT_VALIDATION);
    const [isEditModalVisible, setEditModalVisible] = useState<boolean>(false);
    const [incidentToDelete, setIncidentToDelete] = useState<Incident | null>(null);
    const request = useRequest();
    const { t } = useTranslation();

    const get = useCallback(() => {
        request.get<Incident[]>('/incidents', { withWorkspace: true })
            .then(setIncidents)
            .catch(() => null);
    }, [request]);

    const createOrUpdate = useCallback(() => validate((entity) => {
        const requestMethod = !entity._id ? request.post : request.put;

        requestMethod('incidents', entity, {
            loader: true,
            withWorkspace: true,
            errorMessage: true,
            successMessage: true,
        })
            .then(() => {
                reset({});
                setEditModalVisible(false);
                get();
            })
            .catch(() => null);
    }), [request, get, validate, reset]);

    const deleteIncident = useCallback((incident: Incident) => {
        request.delete(`/incidents/${incident._id}`, {
            loader: true,
            withWorkspace: true,
            errorMessage: true
        })
            .then(() => {
                setIncidentToDelete(null);
                get();
            })
            .catch(() => null);
    }, [request, get]);

    useEffect(() => {
        get();
    }, []);

    return (
        <Card i18n="incidents" title="incidents">
            <DataComponentList
                component={({ entity }: { entity: Incident }) => (
                    <div className="row">
                        <ValueDescription
                            value={entity.name}
                            description={entity.description}
                            tag={{ label: `${t('incidents:criticity')} ${entity.criticity}`, color: entity.criticity === 1 ? Colors.ERROR : entity.criticity < 4 ? Colors.WARNING : Colors.SUCCESS }}
                        />
                        <Button color="navigation" icon={<TrashIcon />} onClick={() => deleteIncident(entity)} />
                    </div>
                )}
                data={incidents}
                primaryKey="_id"
                onSearch={(t, k) => joinOptionnalStrings([t.name, t.description]).toLowerCase().includes(k.toLowerCase())}
                onAdd={() => setEditModalVisible(true)}
                sort={[{ field: 'criticity', i18n: 'incidents', label: 'criticity' }]}
                simple
            />
            {!!isEditModalVisible && (
                <Modal
                    size="small"
                    i18n="incidents"
                    title="incident"
                    onClose={() => { reset({}); setEditModalVisible(false); }}
                    onSubmit={createOrUpdate}
                    overflow="visible"
                >
                    <TextField label i18n="entity" {...attachInput('name')} />
                    <TextArea label i18n="entity" rows={5} {...attachInput('description')} />
                    <Select
                        label
                        i18n="incidents"
                        items={[1, 2, 3, 4, 5]}
                        {...attachInput('criticity')}
                    />
                    <TagsInput label="tags" i18n="ui" category="incidents" {...attachInput('tagsIds')} />
                </Modal>
            )}
        </Card>
    )
}
export default DashboardIncidents;