import { Fragment, useMemo } from "react";
import { DirectoryImportProps } from "..";
import DataContainerList, { DataContainerListColumn } from "../../../sg-react/data/DataContainerList";
import { Card } from "../../../sg-react/ui";
import { changeFieldValue } from "../../../sg-react/utils/objects";
import DirectoryImportMapper from "./DirectoryImportMapper";

interface DirectoryItemForMapping extends Record<string, any> {
    _id: string
}

const DIRECTORY_IMPORT_COLUMNS: DataContainerListColumn<DirectoryItemForMapping>[] = [
    { field: 'name', label: 'name', i18n: 'entity' },
    { field: 'description', label: 'description', i18n: 'entity' },
    { field: 'localBusinessId', label: 'localBusinessId', i18n: 'directory' },
    { field: 'localBusinessIdType', label: 'localBusinessIdType', i18n: 'directory' },
    { field: 'internationalBusinessId', label: 'internationalBusinessId', i18n: 'directory' },
    { field: 'internationalBusinessIdType', label: 'internationalBusinessIdType', i18n: 'directory' },
    { field: 'vatId', label: 'vatId', i18n: 'directory' },
    { field: 'type', label: 'type', i18n: 'directory' },

    { field: 'localPhone', label: 'localPhone', i18n: 'directory' },
    { field: 'internationalPhone', label: 'internationalPhone', i18n: 'directory' },
    { field: 'website', label: 'website', i18n: 'directory' },
    { field: 'email', label: 'email', i18n: 'directory' },

    { field: 'location.longitude', label: 'longitude', i18n: 'location', display: (e) => e['location.longitude'] },
    { field: 'location.latitude', label: 'latitude', i18n: 'location', display: (e) => e.location?.latitude },
    { field: 'location.streetNumber', label: 'streetNumber', i18n: 'location', display: (e) => e.location?.streetNumber },
    { field: 'location.route', label: 'route', i18n: 'location', display: (e) => e.location?.route },
    { field: 'location.city', label: 'city', i18n: 'location', display: (e) => e.location?.city },
    { field: 'location.region', label: 'region', i18n: 'location', display: (e) => e.location?.region },
    { field: 'location.country', label: 'country', i18n: 'location', display: (e) => e.location?.country },
    { field: 'location.postalCode', label: 'postalCode', i18n: 'location', display: (e) => e.location?.postalCode },
    { field: 'location.address', label: 'address', i18n: 'location', display: (e) => e.location?.address },
]

const DirectoryImportMapColumns = ({ directoryImport, onChange }: DirectoryImportProps) => {

    const directoryItemsMapped = useMemo(() => directoryImport.data.map((d, i) => {
        let dMapped: DirectoryItemForMapping = { _id: `${directoryImport._id}_${i}` };

        for (const field in directoryImport.mapping) {
            if (directoryImport.mapping[field]) {
                dMapped = changeFieldValue(dMapped, field, d[directoryImport.mapping[field]!])
            }
        }
        return dMapped;
    }), [directoryImport]);

    return (
        <Fragment>
            <Card id="directory-import-mapper" className="col col-lg-25" overflow="hidden">
                <DirectoryImportMapper directoryImport={directoryImport} onChange={(mapping) => onChange({ ...directoryImport, mapping })} />
            </Card>
            <Card className="col col-lg-75" i18n="data" title="data" overflow="hidden">
                <DataContainerList<DirectoryItemForMapping>
                    primaryKey="_id"
                    data={directoryItemsMapped}
                    columns={DIRECTORY_IMPORT_COLUMNS}
                />
            </Card>
        </Fragment>
    )
}
export default DirectoryImportMapColumns;