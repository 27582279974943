import { ReactNode } from "react";
import { Card } from "../../../sg-react/ui";
import Dropdown from "../../../sg-react/ui/Dropdown";
import FakeInput from "../../../sg-react/ui/FakeInput";
import Pill from "../../../sg-react/ui/Pill";
import { Colors } from "../../../sg-react/ui/enums";

interface MainMapFilterProps {
    icon?: ReactNode;
    label: string;
    count?: number;
    children: ReactNode;
}

const MainMapFilter = ({ icon, label, count, children }: MainMapFilterProps) => (
    <Dropdown className="main-map-filter" openOn="click">
        <Dropdown.Header>
            <FakeInput>
                {icon}
                <span>{label}</span>
                {!!count && <Pill color={Colors.ACCENT} value={count} />}
            </FakeInput>
        </Dropdown.Header>
        <Dropdown.Content><Card>{children}</Card></Dropdown.Content>
    </Dropdown>
);

export default MainMapFilter;