import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { DirectoryImportProps } from "..";
import { Checkbox } from "../../../sg-react/form";
import { Card } from "../../../sg-react/ui";
import Info from "../../../sg-react/ui/Info";

const DirectoryImportConfirmation = ({ directoryImport, onChange }: DirectoryImportProps) => {
    const { t } = useTranslation();

    const counts = useMemo(() => ({
        invalidated: (directoryImport.dataMapped?.length ?? 0) - (directoryImport.dataValidated?.length ?? 0),
        skipped: directoryImport.duplicatesValidation?.filter(dv => dv.skip).length ?? 0,
        created: directoryImport.duplicatesValidation?.filter(dv => dv.create).length ?? 0,
        updated: directoryImport.duplicatesValidation?.filter(dv => dv.duplicateId).length ?? 0,
    }), []);

    useEffect(() => {
        onChange({ ...directoryImport, permissions: { allowImport: true, allowSg: true, workspaces: [], users: [] } });
    }, []);

    return (
        <div className="col col-layout col-lg-100">
            <Card
                i18n="ui"
                title="confirmation"
                overflow="hidden"
            >
                {!!counts.skipped && <Info type="info" label={t('data:import.summary_skipped', { count: counts.skipped })} />}
                {!!counts.invalidated && <Info type="error" label={t('data:import.summary_invalidated', { count: counts.invalidated })} />}
                {!!counts.updated && <Info type="warning" label={t('data:import.summary_updated', { count: counts.updated })} />}
                {!!counts.created && <Info type="success" label={t('data:import.summary_created', { count: counts.created })} />}
            </Card>
            <Card
                i18n="directory"
                title="creation.privacy"
                overflow="hidden"
            >
                <Info noIcon i18n="directory" label="creation.privacy_text" />
                <div className="col">
                    <Checkbox
                        id="allowImport"
                        label="allowImport"
                        i18n="directory"
                        value={directoryImport.permissions?.allowImport}
                        onChange={(v) => onChange({ ...directoryImport, permissions: { allowSg: !!directoryImport.permissions?.allowSg, allowImport: !!v, users: [], workspaces: [] } })}
                    />
                    <Checkbox
                        id="allowSg"
                        label="allowSg"
                        i18n="directory"
                        value={directoryImport.permissions?.allowSg}
                        onChange={(v) => onChange({ ...directoryImport, permissions: { allowSg: !!v, allowImport: !!directoryImport.permissions?.allowImport, users: [], workspaces: [] } })}
                    />
                </div>
            </Card>
        </div>
    )
}
export default DirectoryImportConfirmation;