import { Fragment, memo } from "react";
import { useTranslation } from "react-i18next";
import { Cell, PolarAngleAxis, PolarGrid, PolarRadiusAxis, Radar, RadarChart, ResponsiveContainer, Scatter, ScatterChart, XAxis, YAxis, ZAxis } from "recharts";
import { SegmentationsCount } from "../../../models/segmentation";

export interface IndicatorChartData {
    chartData: {
        value: number;
        count: number;
        index: number;
        color: string;
    }[];
    domain: number[];
    ticks: number[];
}

interface MainMapResultsAnalysisProps {
    otdChartData?: IndicatorChartData;
    oqdChartData?: IndicatorChartData;
    segmentationsChartData?: SegmentationsCount[];
}

const MainMapResultsAnalysis = ({ otdChartData, oqdChartData, segmentationsChartData }: MainMapResultsAnalysisProps) => {
    const { t } = useTranslation();

    return (
        <Fragment>
            {!!otdChartData && (
                <div>
                    <h3>OTD</h3>
                    <ResponsiveContainer width="100%" height={60}>
                        <ScatterChart>
                            <XAxis
                                type="number"
                                dataKey="value"
                                ticks={otdChartData.ticks}
                                tickLine={{ transform: 'translate(0, -3)' }}
                                domain={otdChartData.domain}
                            />
                            <YAxis
                                type="number"
                                dataKey="index"
                                name="otd"
                                height={10}
                                width={5}
                                domain={[0.9, 1.1]}
                                tick={false}
                                tickLine={false}
                                axisLine={false}
                            />
                            <ZAxis type="number" dataKey="count" range={[40, 100]} />
                            <Scatter data={otdChartData.chartData} fill="#8884d8">
                                {otdChartData.chartData.map((entry, index) => (
                                    <Cell key={`cell-${index}`} fill={entry.color} fillOpacity={0.5} stroke={entry.color} />
                                ))}
                            </Scatter>
                        </ScatterChart>
                    </ResponsiveContainer>
                </div>
            )}
            {!!oqdChartData && (
                <div>
                    <h3>OQD</h3>
                    <ResponsiveContainer width="100%" height={60}>
                        <ScatterChart>
                            <XAxis
                                type="number"
                                dataKey="value"
                                ticks={oqdChartData.ticks}
                                tickLine={{ transform: 'translate(0, -3)' }}
                                domain={oqdChartData.domain}
                            />
                            <YAxis
                                type="number"
                                dataKey="index"
                                name="oqd"
                                height={10}
                                width={5}
                                domain={[0.9, 1.1]}
                                tick={false}
                                tickLine={false}
                                axisLine={false}
                            />
                            <ZAxis type="number" dataKey="count" range={[40, 100]} />
                            <Scatter data={oqdChartData.chartData} fill="#8884d8">
                                {oqdChartData.chartData.map((entry, index) => (
                                    <Cell key={`cell-${index}`} fill={entry.color} fillOpacity={0.5} stroke={entry.color} />
                                ))}
                            </Scatter>
                        </ScatterChart>
                    </ResponsiveContainer>
                </div>
            )}
            <div>
                <h3>{t('segmentations:segmentations')}</h3>
                <ResponsiveContainer minWidth="100%" minHeight="200px" maxHeight={300} width="100%">
                    <RadarChart cx="50%" cy="50%" outerRadius="80%" data={segmentationsChartData}>
                        <PolarGrid />
                        <PolarAngleAxis dataKey="name" />
                        <PolarRadiusAxis />
                        <Radar dataKey="count" stroke="#269ac8" fill="#269ac8" fillOpacity={0.6} />
                    </RadarChart>
                </ResponsiveContainer>
            </div>
        </Fragment>
    )
}

export default memo(MainMapResultsAnalysis);