import { Fragment, useCallback, useEffect, useRef } from "react";
import { Geosearch } from "../../../sg-react/data";
import { GeosearchResult } from "../../../sg-react/data/Geosearch";
import { UseFocusHandleRef } from "../../../sg-react/hooks";
import { useDropdown } from "../../../sg-react/ui/Dropdown";
import List from "../../../sg-react/ui/List";

interface MainMapFilterGeoProps {
    geosearches?: GeosearchResult[];
    onChange: (g?: GeosearchResult[]) => void;
}

const MainMapFilterGeo = ({ geosearches, onChange }: MainMapFilterGeoProps) => {
    const focusHandle = useRef<UseFocusHandleRef | null>(null);
    const { visible } = useDropdown();

    const handleAdd = useCallback((geosearch: GeosearchResult) => {
        if (geosearches?.some(g => g.id === geosearch.id)) return;

        onChange([geosearch, ...(geosearches ?? [])])
    }, [geosearches, onChange]);

    const handleRadiusChange = useCallback((id: number, radius?: number) => {
        const index = geosearches?.findIndex(g => g.id === id);
        if (index === undefined || index === -1) return;

        const _geosearches = [...geosearches ?? []];
        _geosearches[index].radius = radius;
        onChange(_geosearches);
    }, [geosearches, onChange]);

    useEffect(() => {
        if (visible) focusHandle.current?.focus();
    }, [visible]);

    return (
        <Fragment>
            <Geosearch onResult={handleAdd} focusHandle={focusHandle}>
                {!!geosearches?.length && (
                    <List>
                        {geosearches.map((geosearch, i) => (
                            <List.Item key={geosearch.id} onRemove={() => onChange(geosearches?.filter(g => g.id !== geosearch.id))}>
                                <Geosearch.Result
                                    geosearch={geosearch}
                                    onRadiusChange={(r) => handleRadiusChange(geosearch.id, r)}
                                />
                            </List.Item>
                        ))}
                    </List>
                )}
            </Geosearch>
        </Fragment>
    );
}

export default MainMapFilterGeo;