import React from 'react';
import InputWrapper, { FormPropsInterface } from '../InputWrapper';
import './index.scss';

interface TextAreaProps extends FormPropsInterface<string> {
    rows?: number;
    maxLength?: number;
    placeholder?: string;
    autoComplete?: boolean;
    onKeyDown?: (e: React.KeyboardEvent<HTMLElement>) => void;
    onBlur?: (value?: string) => void;
    onFocus?: (value?: string) => void;
    onBlurValidationError?: (value: string) => void;
    onBlurValidationWarning?: (value: string) => void;
}

const TextArea = ({
    id,
    rows = 3,
    value,
    maxLength,
    placeholder,
    disabled,
    autoComplete,
    onBlur,
    onFocus,
    onChange,
    onKeyDown,
    onBlurValidationError,
    onBlurValidationWarning,
    ...rest
}: TextAreaProps) => {

    return (
        <InputWrapper id={id} type="text-area" {...rest}>
            <textarea
                autoComplete={autoComplete ? "on" : "off"}
                id={id}
                rows={rows}
                readOnly={!!disabled}
                onBlur={typeof onBlur === 'function' ? (e) => onBlur(!e.target.value ? undefined : e.target.value) : undefined}
                onFocus={typeof onFocus === 'function' ? (e) => onFocus(!e.target.value ? undefined : e.target.value) : undefined}
                onChange={typeof onChange === 'function' ? (e) => onChange(!e.target.value ? undefined : e.target.value) : undefined}
                onKeyDown={typeof onKeyDown === 'function' ? (e) => onKeyDown(e) : undefined}
                value={value ?? ''}
                placeholder={placeholder ?? ''}
                maxLength={maxLength ? maxLength : undefined}
            />
        </InputWrapper>
    );
}

export default TextArea;