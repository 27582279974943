import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { FileSearchIcon } from '../../icons';
import { conditionnalClassnames } from '../../utils/helpers';
import './index.scss';

interface NothingProps {
    children?: ReactNode;
    icon?: ReactNode;
    inline?: boolean;
    small?: boolean;
}

const Nothing = ({ children, icon, inline, small }: NothingProps) => {
    const { t } = useTranslation();

    return (
        <div className={conditionnalClassnames({
            col: true,
            nothing: true,
            "nothing-inline": inline,
            "nothing-small": small,
        })}>
            {icon ?? <FileSearchIcon />}
            {!children && <span>{t('data:no_data')}</span>}
            {children}
        </div>
    )
}

export default Nothing;