import { Fragment, useCallback, useEffect, useState } from "react";
import DirectoryItemSearch from "../../../components/DirectoryItemSearch";
import { DirectoryItemsListItem } from "../../../components/DirectoryItemsList";
import PartStepModal from "../../../components/PartStepModal";
import SpecificationSearch from "../../../components/SpecificationSearch";
import { DirectoryItem } from "../../../models/directory-item";
import { Part, PartStep } from "../../../models/part";
import { Specification } from "../../../models/specification";
import { useRequest } from "../../../sg-react/context";
import { ModalDelete } from "../../../sg-react/data";
import TagsList from "../../../sg-react/data/TagsList";
import WorkflowGraph from "../../../sg-react/data/WorkflowGraph";
import { EditIcon, PlusIcon, TrashIcon } from "../../../sg-react/icons";
import { Button, Card } from "../../../sg-react/ui";
import Pill from "../../../sg-react/ui/Pill";
import ValueDescription from "../../../sg-react/ui/ValueDescription";
import { Colors } from "../../../sg-react/ui/enums";

interface PartViewStepsProps {
    part: Part;
    onChange?: () => void;
    onDirectoryItemClick?: (d: DirectoryItem) => void;
}

const PartViewSteps = ({ part, onChange, onDirectoryItemClick }: PartViewStepsProps) => {
    const [stepToEdit, setStepToEdit] = useState<Partial<PartStep & { previousStepId?: string }> | null>(null);
    const [stepToDelete, setStepToDelete] = useState<PartStep | null>(null);
    const [selectedStep, setSelectedStep] = useState<PartStep | null>(null);
    const [isBusinessModalVisible, setBusinessModalVisible] = useState<boolean>(false);
    const [isSpecificationsModalVisible, setSpecificationsModalVisible] = useState<boolean>(false);
    const request = useRequest();

    const createOrUpdate = useCallback((step: PartStep) => {
        if (!onChange) return;
        const requestMethod = !step._id ? request.post : request.put;
        const requestUrl = !step._id ? `/parts/${part._id}/step` : '/parts/step';

        requestMethod<Part>(requestUrl, step, {
            loader: true,
            successMessage: true,
            errorMessage: true,
            withWorkspace: true
        })
            .then(() => onChange())
            .catch(() => null);
    }, [request, onChange, part]);

    const deleteStep = useCallback((step: PartStep) => {
        if (!onChange) return;

        request.delete('/parts/step/' + step._id, {
            loader: true,
            successMessage: true,
            errorMessage: true,
            withWorkspace: true
        })
            .then(() => {
                onChange();
                setStepToDelete(null);
            })
            .catch(() => null);
    }, [request, onChange]);

    const handleAddStep = useCallback((links?: { nextStepId?: string, previousStepId?: string }) => {
        setStepToEdit({ nextStepsIds: links?.nextStepId ? [links.nextStepId] : [], previousStepId: links?.previousStepId });
    }, []);

    const handleAddBusiness = useCallback((selectedDirectoryItems: DirectoryItem[]) => {
        if (!selectedDirectoryItems.length || !selectedStep) return;

        const businesses = [...selectedStep.businesses];
        for (const directoryItem of selectedDirectoryItems) {
            if (!businesses.some(b => b.directoryItemId === directoryItem._id)) {
                businesses.push({ directoryItemId: directoryItem._id, isAlternative: false });
            }
        }

        createOrUpdate({ ...selectedStep, businesses });
    }, [selectedStep, createOrUpdate]);

    const handleAddSpecifications = useCallback((selectedSpecifications: Specification[]) => {
        if (!selectedSpecifications.length || !selectedStep) return;

        createOrUpdate({
            ...selectedStep,
            specificationsIds: selectedSpecifications.map(s => s._id)
        });
    }, [selectedStep, createOrUpdate]);

    const handleRemoveBusiness = useCallback((step: PartStep, directoryItemId: string) => {
        createOrUpdate({
            ...step,
            businesses: step.businesses.filter(b => b.directoryItemId !== directoryItemId)
        });
    }, [createOrUpdate]);

    const handleRemoveSpecification = useCallback((step: PartStep, specificationId: string) => {
        createOrUpdate({
            ...step,
            specificationsIds: step.specificationsIds.filter(s => s !== specificationId)
        });
    }, [createOrUpdate]);

    const getLabel = useCallback((step: PartStep) => {
        const count = step.businesses?.length;
        return (
            <div className="row">
                <span>{step.name}</span>
                <Pill color={count === 0 ? Colors.CRITICAL : count === 1 ? Colors.ERROR : count === 2 ? Colors.WARNING : Colors.SUCCESS} value={count} />
                {selectedStep?._id === step._id && <Button small color="navigation" icon={<EditIcon />} onClick={() => setStepToEdit(step)} />}
            </div>
        );
    }, [selectedStep]);

    useEffect(() => {
        if (selectedStep) {
            const selected = part.steps.find(s => s._id === selectedStep._id);
            setSelectedStep(selected ?? null);
        }
    }, [part]);

    return (
        <Fragment>
            <Card overflow="hidden">
                <div id="part-steps-workflow">
                    <WorkflowGraph<PartStep>
                        items={part.steps}
                        onAdd={handleAddStep}
                        onSelect={(s) => setSelectedStep(s ?? null)}
                        label={getLabel}
                        firstStep
                        lastStep
                    />
                </div>
            </Card>
            {!!selectedStep && (
                <div className="col col-layout">
                    <Card
                        title="specifications"
                        i18n="specifications"
                        overflow="hidden"
                        options={<Button color="navigation" i18n="actions" label="add" icon={<PlusIcon />} onClick={() => setSpecificationsModalVisible(true)} />}
                    >
                        <div className="simple-list">
                            {selectedStep.specifications?.map(specification => (
                                <div className="row" key={specification._id}>
                                    <ValueDescription
                                        value={specification.name}
                                        description={specification.description}
                                        tags={<TagsList tagsIds={specification.tagsIds} />}
                                    />
                                    <Button color="navigation" icon={<TrashIcon />} onClick={() => handleRemoveSpecification(selectedStep, specification._id)} />
                                </div>
                            ))}
                        </div>
                    </Card>
                    <Card
                        title="businesses"
                        i18n="parts"
                        overflow="hidden"
                        options={<Button color="navigation" i18n="actions" label="add" icon={<PlusIcon />} onClick={() => setBusinessModalVisible(true)} />}
                    >
                        <div className="simple-list">
                            {selectedStep.businesses.filter(business => business.directoryItem).map(business => (
                                <DirectoryItemsListItem
                                    key={business.directoryItemId}
                                    entity={business.directoryItem!}
                                >
                                    <Button color="navigation" icon={<TrashIcon />} onClick={() => handleRemoveBusiness(selectedStep, business.directoryItemId)} />
                                </DirectoryItemsListItem>
                            ))}
                        </div>
                    </Card>
                </div>
            )}
            {!!stepToEdit && (
                <PartStepModal part={part} step={stepToEdit} onClose={() => setStepToEdit(null)} onSubmit={createOrUpdate} />
            )}
            {!!isBusinessModalVisible && (
                <DirectoryItemSearch global multiple onClose={() => setBusinessModalVisible(false)} onSubmit={handleAddBusiness} />
            )}
            {!!isSpecificationsModalVisible && (
                <SpecificationSearch
                    onClose={() => setSpecificationsModalVisible(false)}
                    onSubmit={handleAddSpecifications}
                    selected={selectedStep?.specifications}
                    multiple
                />
            )}
            {!!stepToDelete && <ModalDelete onClose={() => setStepToDelete(null)} onSubmit={() => deleteStep(stepToDelete)} />}
        </Fragment>
    )
}

export default PartViewSteps;