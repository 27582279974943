import { DndContext, DragEndEvent, PointerSensor, closestCenter, useSensor, useSensors } from "@dnd-kit/core";
import { SortableContext, arrayMove, useSortable, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Fragment, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ColorPicker, TextArea, TextField } from "../../../sg-react/form";
import Validation, { ObjectValidation } from "../../../sg-react/form/Validation/class";
import { useForm } from "../../../sg-react/hooks";
import { AnalyseIcon, EditIcon, MagnifierIcon, MenuIcon, PlusIcon, TrashIcon } from "../../../sg-react/icons";
import { Button, Card, Modal } from "../../../sg-react/ui";
import DropdownMenu from "../../../sg-react/ui/DropdownMenu";
import Pill from "../../../sg-react/ui/Pill";
import ValueDescription from "../../../sg-react/ui/ValueDescription";
import { Colors } from "../../../sg-react/ui/enums";
import { conditionnalClassnames } from "../../../sg-react/utils/helpers";
import { MapFilters } from "../types";
import { useMapContext } from "./MapContext";


interface MapFiltersStepProps {
    step: MapFilters;
    onEdit: (s: MapFilters) => void;
}

export const MapFiltersStep = ({ step, onEdit }: MapFiltersStepProps) => {
    const { filters, deleteStep, changeCurrentStep } = useMapContext();
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: step.stepId });

    if (!step) return null;

    return (
        <div
            className={conditionnalClassnames({
                "main-map-step": true,
                active: step.stepId === filters.stepId
            })}
            onClick={() => changeCurrentStep(step.stepId)}
            ref={setNodeRef}
            style={{
                transform: CSS.Transform.toString(transform),
                transition
            }}
        >
            <div className="main-map-step-handle" {...attributes} {...listeners}>
                <Pill color={step.color} />
            </div>
            <ValueDescription value={step.name} description={step.description} />
            <DropdownMenu disposition="left" items={[
                { itemKey: 'edit', icon: <EditIcon />, i18n: "actions", label: "edit", onClick: () => onEdit(step) },
                { itemKey: 'delete', icon: <TrashIcon />, i18n: "actions", label: "delete", onClick: () => deleteStep(step.stepId) },
            ]}>
                <Button color="navigation" icon={<MenuIcon />} />
            </DropdownMenu>
        </div >
    );
}

const VALIDATION = new ObjectValidation({
    name: Validation.string().required().minLength(1).maxLength(100),
    color: Validation.string().required().minLength(6).maxLength(10),
})

const MainMapSteps = () => {
    const { t } = useTranslation();
    const { plan, setPlan, addStep, getPlanResults, panels, setFilters } = useMapContext();
    const sensors = useSensors(useSensor(PointerSensor));
    const { entity, validate, attachInput, reset } = useForm<MapFilters>({});
    const navigate = useNavigate();

    const handleDragEnd = useCallback((event: DragEndEvent) => {
        const { active, over } = event;

        if (over !== null && active.id !== over.id) {
            const oldIndex = plan.steps.findIndex(s => s.stepId === active.id);
            const newIndex = plan.steps.findIndex(s => s.stepId === over.id);

            setPlan({ ...plan, steps: arrayMove(plan.steps, oldIndex, newIndex) });
        }
    }, [plan]);

    const handleStepSubmit = useCallback(() => validate(
        (entity) => {
            if (entity.stepId === -1) {
                addStep(entity);
            } else {
                setFilters(entity as MapFilters);
            }
            reset({});

        },
        VALIDATION
    ), [validate, reset, addStep, setFilters]);

    const stepsIds = useMemo(() => plan.steps.map(s => s.stepId), [plan.steps]);

    return (
        <Card
            id="main-map-steps"
            title={t('actions:plan')}
            className={conditionnalClassnames({ visible: panels.steps })}
            overflow="auto"
            options={(
                <Fragment>
                    <Button color="navigation" label="add" icon={<PlusIcon />} i18n="actions" onClick={() => reset({ stepId: -1 })} />
                </Fragment>
            )}
            actions={[
                { color: Colors.SECONDARY, label: 'analyse', icon: <AnalyseIcon />, i18n: 'actions', onClick: () => navigate('/plan-analysis') },
                { color: Colors.PRIMARY, label: 'calculate', icon: <MagnifierIcon />, i18n: 'actions', onClick: () => getPlanResults() },
            ]}
        >
            <div className="simple-list">
                <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
                    <SortableContext items={stepsIds} strategy={verticalListSortingStrategy}>
                        {plan.steps.map((s) => (
                            <MapFiltersStep
                                key={s.stepId}
                                step={s}
                                onEdit={reset}
                            />
                        ))}
                    </SortableContext>
                </DndContext>
            </div>
            {!!entity.stepId && (
                <Modal
                    size="small"
                    title="edit"
                    i18n="actions"
                    onClose={() => reset({})}
                    onSubmit={handleStepSubmit}
                    overflow="visible"
                >
                    <TextField
                        placeholder={t('filters:query_name')}
                        label
                        i18n="entity"
                        {...attachInput('name')}
                    />
                    <TextArea
                        label
                        i18n="entity"
                        {...attachInput('description')}
                    />
                    <ColorPicker
                        label
                        i18n="entity"
                        {...attachInput('color')}
                    />
                </Modal>
            )}
        </Card >
    )
}

export default MainMapSteps;