// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.list-actions {
  align-items: center;
  justify-content: flex-end;
}
.list-actions > .input {
  flex-grow: 1;
}
.list-actions .button {
  padding: 4px;
}`, "",{"version":3,"sources":["webpack://./src/sg-react/data/ListActions/index.scss"],"names":[],"mappings":"AAAA;EACI,mBAAA;EACA,yBAAA;AACJ;AACI;EACI,YAAA;AACR;AAEI;EACI,YAAA;AAAR","sourcesContent":[".list-actions {\n    align-items: center;\n    justify-content: flex-end;\n\n    & > .input {\n        flex-grow: 1;\n    }\n\n    & .button {\n        padding: 4px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
