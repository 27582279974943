import React, { useCallback, useEffect, useRef, useState } from "react";
import { CloseIcon, ErrorIcon, SuccessIcon, WarningIcon } from "../../icons";
import Card from "../Card";
import './index.scss';
import { conditionnalClassnames } from "../../utils/helpers";
import { useTranslation } from "react-i18next";
import { translateLabel } from "../../utils/format";

export type ToastData = {
    _id: string;
    type: 'error' | 'success' | 'warning';
    i18n?: string | true;
    message: string;
    error?: Error;
    data?: any;
    log?: boolean;
}

interface ToastProps {
    toast: ToastData;
    onClose: () => void;
    onClick: () => void;
}

const Toast = ({
    toast,
    onClose,
    onClick
}: ToastProps) => {
    const timeoutIn = useRef<NodeJS.Timeout | null>(null);
    const timeoutOut = useRef<NodeJS.Timeout | null>(null);
    const timeoutAuto = useRef<NodeJS.Timeout | null>(null);
    const [status, setStatus] = useState<'active' | 'delete' | null>(null);
    const [autoDelete, setAutoDelete] = useState<boolean>(true);
    const { t } = useTranslation();

    const handleClose = useCallback(() => {
        setStatus('delete');
        timeoutOut.current = setTimeout(onClose, 300);
    }, []);

    const clearAutoDelete = useCallback(() => {
        setAutoDelete(false);
        if (timeoutAuto.current) {
            clearTimeout(timeoutAuto.current);
        }
    }, []);

    useEffect(() => {
        timeoutIn.current = setTimeout(() => setStatus('active'), 100);
        timeoutAuto.current = setTimeout(handleClose, 5000);

        return () => {
            if (timeoutIn.current) {
                clearTimeout(timeoutIn.current);
            }
            if (timeoutOut.current) {
                clearTimeout(timeoutOut.current);
            }
            if (timeoutAuto.current) {
                clearTimeout(timeoutAuto.current);
            }
        }
    }, []);

    return (
        <Card className={conditionnalClassnames({
            toast: true,
            [`toast-${toast.type}`]: true,
            [`toast-${status}`]: true,
        })}>
            <CloseIcon className="toast-close-icon" onClick={handleClose} />
            <div className="toast-content" onMouseEnter={clearAutoDelete}>
                <div className="toast-icon">
                    {toast.type === 'error' && <ErrorIcon />}
                    {toast.type === 'success' && <SuccessIcon />}
                    {toast.type === 'warning' && <WarningIcon />}
                </div>
                <div className="toast-message">
                    <span>{translateLabel(toast.message, t, toast.i18n === true ? 'messages' : toast.i18n)}</span>
                    {toast.type === 'error' && <span className="link" onClick={onClick}>{t('messages:errors.contact_admin')}</span>}
                </div>
            </div>
            {!!autoDelete && <div className="toast-progress" />}
        </Card >
    );
}

export default Toast;