import { Fragment, useCallback, useEffect } from "react";
import { DataListColumnWithWidth } from ".";
import { useRequest } from "../../context";
import { Checkbox } from "../../form";
import { ObjectValidation } from "../../form/Validation/class";
import { useForm } from "../../hooks";
import { UseFormConstraint } from "../../hooks/useForm";
import { ReloadIcon, SaveIcon, ShowIcon } from "../../icons";
import Cell from "./Cell";

interface RowProps<T extends UseFormConstraint> {
    entity: T;
    selected?: boolean;
    columns: DataListColumnWithWidth<T>[];
    validation?: ObjectValidation;
    endpoint: string;
    onView?: (e: T) => void;
    onSelect?: (e: T) => void;
}

const Row = <T extends UseFormConstraint>({ entity: entityFromProps, selected, columns, endpoint, validation, onView, onSelect }: RowProps<T>) => {
    const { entity, validate, attachInput, hasChanged, reset } = useForm<T>(entityFromProps, validation);
    const request = useRequest();

    const handleSubmit = useCallback(() => validate((entity) => {
        request.put<T>(endpoint, entity, {
            loader: true,
            successMessage: true,
            errorMessage: true
        })
            .then(reset)
            .catch(() => null);
    }), [validate, request]);

    useEffect(() => {
        reset(entityFromProps);
    }, [entityFromProps]);

    return (
        <div className={`data-list-table-row ${hasChanged ? 'data-list-table-row-changed' : ''}`}>
            {!!onSelect && (
                <div className="data-list-table-row-select">
                    <Checkbox id="select" value={selected} onChange={(v) => onSelect(entityFromProps)} />
                </div>
            )}
            {columns.filter(column => !column.hidden).map((column) => {
                return (
                    <Cell
                        key={column.field}
                        entity={entity}
                        column={column}
                        {...attachInput(column.field)}
                    />
                )
            })}
            {(hasChanged || onView) && (
                <div className="data-list-table-row-actions">
                    {!!hasChanged && (
                        <Fragment>
                            <button type="button" className="action-refresh" onClick={() => reset()}><ReloadIcon /></button>
                            <button type="button" className="action-save" onClick={handleSubmit}><SaveIcon /></button>
                        </Fragment>
                    )}
                    {!!onView && <button type="button" className="action-view" onClick={() => onView(entity as T)}><ShowIcon /></button>}
                </div>
            )}
        </div>
    )
}

export default Row;