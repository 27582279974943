import { useCallback } from "react";
import { DatePicker, NumberField } from "../../form";
import Validation, { ObjectValidation } from "../../form/Validation/class";
import { useForm } from "../../hooks";
import { ValueDate } from "../../models/data";
import { Modal } from "../../ui";

interface ValueDateModalProps {
    data?: Partial<ValueDate<number>>;
    title?: string;
    i18n?: string;
    onClose: () => void;
    onSubmit: (v: ValueDate<number>) => void;
}

const VALIDATION = new ObjectValidation({
    value: Validation.number().required(),
    date: Validation.date().required()
})

const ValueDateModal = ({ title, i18n, data, onClose, onSubmit }: ValueDateModalProps) => {
    const { attachInput, validate } = useForm<ValueDate<number>>({ date: new Date(), ...data }, VALIDATION);

    const handleSubmit = useCallback(() => validate(onSubmit), [validate, onSubmit]);

    return (
        <Modal
            title={title}
            i18n={i18n}
            size="small"
            onClose={onClose}
            onSubmit={handleSubmit}
        >
            <NumberField
                label
                i18n="entity"
                {...attachInput('value')}
            />
            <DatePicker
                label
                i18n="entity"
                {...attachInput('date')}
            />
        </Modal>
    )
}
export default ValueDateModal;