import { useTranslation } from 'react-i18next';
import { conditionnalClassnames } from '../../utils/helpers';
import './index.scss';
import { translateLabel } from '../../utils/format';
import { ReactNode } from 'react';

interface LabelValueProps {
    label: string;
    i18n?: string;
    value?: ReactNode;
    inline?: boolean;
    displayIfNull?: boolean;
    fixed?: boolean;
}

const LabelValue = ({ label, i18n, value, inline, displayIfNull, fixed }: LabelValueProps) => {
    const { t } = useTranslation();

    if ((value === undefined || value === null) && !displayIfNull) return null;

    return (
        <div className={conditionnalClassnames({ "label-value": true, inline, fixed })}>
            <label>{translateLabel(label, t, i18n)}</label>
            <span>{value}</span>
        </div>
    );
}

export default LabelValue;