import { useCallback } from "react";
import Checkbox from "../Checkbox";
import InputWrapper, { FormPropsInterface } from "../InputWrapper";
import './index.scss';

interface CheckboxListProps<T> extends FormPropsInterface<T> {
    items: T extends Array<infer U> ? { key: U, label: string }[] : { key: T, label: string }[],
    multiple?: boolean,
}

const CheckboxList = <T,>({
    id,
    value,
    items,
    disabled,
    multiple,
    onChange,
    ...rest
}: CheckboxListProps<T>) => {
    const handleChange = useCallback((item: T extends Array<infer U> ? U : T) => {
        if (disabled || !onChange) return;
        if (!multiple) {
            onChange(value === item ? undefined : item as any);
            return;
        }
        if (!Array.isArray(value) && value !== undefined) {
            if (value === item) {
                onChange([item] as any);
            } else {
                onChange([value, item] as any);
            }
            return;
        }

        const _value = [...(value ?? []) as any[]];
        const index = _value.findIndex(v => v === item);

        if (index === -1) {
            _value.push(item);
        } else {
            _value.splice(index, 1);
        }
        onChange(_value as any);
    }, [value, onChange]);

    return (
        <InputWrapper type="checkbox-list" id={id} {...rest}>
            {items.map(i => (
                <Checkbox
                    key={String(i.key)}
                    label={String(i.label)}
                    value={Array.isArray(value) ? value?.some(v => v === i.key) : value === i.key}
                    onChange={() => handleChange(i.key as T extends Array<infer U> ? U : T)}
                    id={String(i.key)}
                />
            ))}
        </InputWrapper>
    )
}

export default CheckboxList;