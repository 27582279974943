// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#workspaces #workspaces-list .depth-container {
  width: 40%;
  max-width: 300px;
  flex-grow: 0;
  flex-shrink: 0;
}
#workspaces #workspaces-list .depth-container > img {
  width: 100%;
  height: 120px;
  object-fit: cover;
}`, "",{"version":3,"sources":["webpack://./src/sg-react/pages/Workspaces/index.scss"],"names":[],"mappings":"AAEQ;EACI,UAAA;EACA,gBAAA;EACA,YAAA;EACA,cAAA;AADZ;AAGY;EACI,WAAA;EACA,aAAA;EACA,iBAAA;AADhB","sourcesContent":["#workspaces {\n    & #workspaces-list {\n        & .depth-container {\n            width: 40%;\n            max-width: 300px;\n            flex-grow: 0;\n            flex-shrink: 0;\n\n            & > img {\n                width: 100%;\n                height: 120px;\n                object-fit: cover;\n            }\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
