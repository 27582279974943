import { Fragment, useMemo } from "react";
import { MAPPING_LABELS } from "..";
import { DirectoryImport } from "../../../models/directory-item";
import { Select } from "../../../sg-react/form";
import Info from "../../../sg-react/ui/Info";

interface DirectoryImportMapperProps {
    directoryImport: DirectoryImport;
    onChange: (m: Record<string, string | undefined>) => void;
}

const DirectoryImportMapper = ({ directoryImport, onChange }: DirectoryImportMapperProps) => {

    const selectItems = useMemo(() => [
        { key: undefined, label: '--' },
        ...directoryImport.sourceColumns.map(s => ({ key: s, label: s }))
    ], [directoryImport.sourceColumns]);

    return (
        <Fragment>
            <Info noIcon label="import.column_mapping_tooltip" i18n="data" />
            <div id="directory-import-mapper-fields" className="col">
                {directoryImport.targetColumns.map(field => (
                    <Select<string | undefined>
                        key={field}
                        inline
                        id={field}
                        label={MAPPING_LABELS[field]?.label}
                        i18n={MAPPING_LABELS[field]?.i18n}
                        items={selectItems}
                        value={directoryImport.mapping?.[field]}
                        onChange={source => onChange({ ...directoryImport.mapping, [field]: source })}
                    />
                ))}
            </div>
        </Fragment>
    )
}
export default DirectoryImportMapper;