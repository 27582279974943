import { useMemo } from "react";
import { useWorkspaceContext } from "../../context";
import { WorkspaceTag } from "../../models/workspace";
import Tag from "../../ui/Tag";

interface TagsListProps {
    onClose?: (tag: WorkspaceTag) => void;
    tagsIds?: string[];
}

const TagsList = ({ onClose, tagsIds }: TagsListProps) => {
    const { currentWorkspace } = useWorkspaceContext();

    const tags = useMemo(() => currentWorkspace.tags?.filter(t => tagsIds?.includes(t._id)), [tagsIds, currentWorkspace]);

    return (
        <div className="row row-wrap">
            {tags?.map(t => <Tag key={t._id} label={t.name} color={t.color} onClose={onClose ? () => onClose(t) : undefined} />)}
        </div>
    )
}
export default TagsList;