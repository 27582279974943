import { Fragment, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { DirectoryData, DirectoryDataPrivacy, DirectoryDataSettings } from "../../../models/directory-data";
import { DirectoryItem } from "../../../models/directory-item";
import { useRequest, useWorkspaceContext } from "../../../sg-react/context";
import UserSearch from "../../../sg-react/data/UserSearch";
import { Checkbox, Radio } from "../../../sg-react/form";
import ButtonList from "../../../sg-react/form/ButtonList";
import { PlusIcon, TrashIcon } from "../../../sg-react/icons";
import { User } from "../../../sg-react/models/user";
import { WorkspacePrivacy } from "../../../sg-react/models/workspace";
import { Button, Modal, UserItem } from "../../../sg-react/ui";
import Info from "../../../sg-react/ui/Info";
import SoftContainer from "../../../sg-react/ui/SoftContainer";
import ValueDescription from "../../../sg-react/ui/ValueDescription";
import Wizard, { WizardContentComponentProps, WizardStep } from "../../../sg-react/ui/Wizard";
import { translateEnum } from "../../../sg-react/utils/format";
import { toggleInArray } from "../../../sg-react/utils/objects";

const DirectoryDataSettingsWidgets = ({ entity, onChange }: WizardContentComponentProps<DirectoryDataSettings>) => {
    const { currentWorkspace } = useWorkspaceContext();
    const { t } = useTranslation();

    return (
        <Fragment>
            <Radio id="widgets-all" i18n="workspaces" items={[{ key: true, label: 'widgets_all' }, { key: false, label: 'widgets_selection' }]} value={!entity.widgets} onChange={(v) => !!v ? onChange('widgets', undefined) : onChange('widgets', currentWorkspace.widgets)} />
            <div className="list">
                {!!entity.widgets && currentWorkspace.widgets.map(widget => (
                    <SoftContainer key={widget} onClick={() => onChange('widgets', toggleInArray(entity.widgets, widget))}>
                        <div className="row pointer">
                            <Checkbox id={widget} value={entity.widgets?.includes(widget)} onChange={() => onChange('widgets', toggleInArray(entity.widgets, widget))} />
                            <ValueDescription value={t(`application:widgets.${widget}`)} description={t(`application:widgets.${widget}_tooltip`)} />
                        </div>
                    </SoftContainer>
                ))}
            </div>
        </Fragment>
    )
}

interface DirectoryDataSettingsPrivacyProps {
    privacy?: DirectoryDataPrivacy;
    disabledPrivacy?: WorkspacePrivacy[];
    withWidgets?: boolean;
    availableWidgets?: { key: string, label: string }[];
    onChange: (p: DirectoryDataPrivacy) => void;
}

const DirectoryDataSettingsPrivacy = ({ privacy, disabledPrivacy, withWidgets, availableWidgets, onChange }: DirectoryDataSettingsPrivacyProps) => {
    const { t } = useTranslation();
    const [userSearchFor, setUserSearchFor] = useState<'visibility' | 'notifications' | null>(null);

    const privacyList = useMemo(() => translateEnum(WorkspacePrivacy, 'workspaces', 'privacy', t).filter(p => !disabledPrivacy?.length || !disabledPrivacy.includes(p.key)), [disabledPrivacy]);

    const handleAddUser = useCallback((user: User) => {
        if (privacy?.users?.some(u => u.userId === user._id)) return;

        onChange({
            mode: privacy?.mode ?? WorkspacePrivacy.UsersWorkspace,
            users: [...privacy?.users ?? [], { user, userId: user._id }]
        });
    }, [privacy, onChange]);

    const handleDeleteUser = useCallback((userId: string) => {
        if (!privacy?.users?.length) return;

        onChange({
            mode: privacy?.mode ?? WorkspacePrivacy.UsersWorkspace,
            users: privacy?.users.filter(u => u.userId !== userId)
        });
    }, [privacy, onChange]);

    const handleUserWidgets = useCallback((index: number, widgets?: string[]) => {
        if (!privacy?.users?.length || index < 0 || index > privacy?.users?.length - 1) return;

        const users = [...privacy?.users];
        users[index] = {
            ...users[index],
            widgets
        }
        onChange({
            mode: privacy?.mode ?? WorkspacePrivacy.UsersWorkspace,
            users
        });
    }, [privacy, onChange]);

    return (
        <Fragment>
            <Radio<WorkspacePrivacy>
                id="directory-data-visibility"
                items={privacyList}
                value={privacy?.mode ?? WorkspacePrivacy.UsersWorkspace}
                onChange={(v) => onChange({ ...privacy, mode: v ?? WorkspacePrivacy.UsersWorkspace })}
            />
            {privacy?.mode === WorkspacePrivacy.UsersSelection && (
                <Fragment>
                    <div className="row">
                        <Button color="navigation" i18n="users" label="add_user" icon={<PlusIcon />} onClick={() => setUserSearchFor('visibility')} />
                    </div>
                    <div className="list">
                        {privacy.users?.map((u, index) => (
                            <div className="col" key={u.userId}>
                                <UserItem user={u.user}>
                                    <Button small color="navigation" icon={<TrashIcon />} onClick={() => handleDeleteUser(u.userId)} />
                                </UserItem>
                                {!!withWidgets && (
                                    <div className="col">
                                        <Radio id={`${u.userId}.widgets-all`} i18n="workspaces" items={[{ key: true, label: 'widgets_all_enabled' }, { key: false, label: 'widgets_selection' }]} value={!u.widgets} onChange={(v) => handleUserWidgets(index, !!v ? undefined : availableWidgets?.map(w => w.key))} />
                                        {!!u.widgets && <ButtonList small id={`${u.userId}.widgets`} items={availableWidgets ?? []} value={u.widgets ?? []} onChange={(w) => handleUserWidgets(index, w)} />}
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                </Fragment>
            )}
            {!!userSearchFor && <UserSearch onClose={() => setUserSearchFor(null)} onSubmit={(u) => u.length ? handleAddUser(u[0]) : null} />}
        </Fragment>
    )
}

const DirectoryDataSettingsUsers = ({ entity, onChange }: WizardContentComponentProps<DirectoryDataSettings>) => {
    const { currentWorkspace } = useWorkspaceContext();
    const { t } = useTranslation();

    const availableWidgets = useMemo(() => entity.widgets ?? currentWorkspace.widgets, [entity]);
    const availableWidgetsList = useMemo(() => availableWidgets.map(w => ({ key: w, label: t('application:widgets.' + w) })), [availableWidgets]);

    return (
        <DirectoryDataSettingsPrivacy
            privacy={entity.referenceUsers}
            disabledPrivacy={[WorkspacePrivacy.NoOne]}
            withWidgets
            availableWidgets={availableWidgetsList}
            onChange={(p) => onChange('referenceUsers', p)}
        />
    )
}

const DirectoryDataSettingsVisibility = ({ entity, onChange }: WizardContentComponentProps<DirectoryDataSettings>) => {
    const { currentWorkspace } = useWorkspaceContext();
    const { t } = useTranslation();

    const availableWidgets = useMemo(() => entity.widgets ?? currentWorkspace.widgets, [entity]);
    const availableWidgetsList = useMemo(() => availableWidgets.map(w => ({ key: w, label: t('application:widgets.' + w) })), [availableWidgets]);

    return (
        <DirectoryDataSettingsPrivacy
            privacy={entity.visibility}
            disabledPrivacy={[WorkspacePrivacy.NoOne]}
            withWidgets
            availableWidgets={availableWidgetsList}
            onChange={(p) => onChange('visibility', p)}
        />
    )
}

const DirectoryDataSettingsNotifications = ({ entity, onChange }: WizardContentComponentProps<DirectoryDataSettings>) => {
    return (
        <DirectoryDataSettingsPrivacy
            privacy={entity.notifications}
            onChange={(p) => onChange('notifications', p)}
        />
    )
}


const DirectoryDataSettingsGuests = () => <Info type="warning" i18n="ui" label="degraded_feature" />

const STEPS: WizardStep<DirectoryDataSettings>[] = [{
    label: 'widgets',
    tooltip: 'widgets_data_settings_tooltip',
    i18n: 'workspaces',
    component: DirectoryDataSettingsWidgets,
}, {
    label: 'reference_users',
    tooltip: 'reference_users_data_settings_tooltip',
    i18n: 'workspaces',
    component: DirectoryDataSettingsUsers,
}, {
    label: 'visibility',
    tooltip: 'visibility_tooltip',
    i18n: 'workspaces',
    component: DirectoryDataSettingsVisibility,
}, {
    label: 'notifications',
    tooltip: 'notifications_tooltip',
    i18n: 'workspaces',
    component: DirectoryDataSettingsNotifications,
}, {
    label: 'guests',
    tooltip: 'guests_data_settings_tooltip',
    i18n: 'workspaces',
    component: DirectoryDataSettingsGuests,
}];

interface DirectoryDataSettingsModalProps {
    directoryItem: DirectoryItem;
    directoryData: DirectoryData;
    refresh: () => void;
    step?: string;
    onClose: () => void;
}

const DirectoryDataSettingsModal = ({ directoryItem, directoryData, step, refresh, onClose }: DirectoryDataSettingsModalProps) => {
    const { currentWorkspace } = useWorkspaceContext();
    const request = useRequest();

    const initialStep = useMemo(() => STEPS.findIndex(s => s.label === step), []);

    const handleSubmit = useCallback((settings: Partial<DirectoryDataSettings>) => {
        request.put(`/directory-data/${directoryItem._id}/settings`, settings, {
            loader: true,
            withWorkspace: true,
            successMessage: true,
            errorMessage: true,
        })
            .then(() => {
                refresh();
                onClose();
            })
            .catch(() => null);
    }, [request, refresh, onClose]);

    if (!directoryData.settings) return null;

    return (
        <Modal
            id="directory-data-settings"
            i18n="ui"
            title="settings"
            overflow="hidden"
        >
            <Wizard steps={STEPS} initialStep={initialStep === -1 ? 0 : initialStep} initialMaxStep={STEPS.length - 1} initial={directoryData.settings} onSubmit={handleSubmit} onClose={onClose} />
        </Modal>
    )
}
export default DirectoryDataSettingsModal;