// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.linear-dial {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 14px;
  padding: 0 4px;
}
.linear-dial > .linear-dial-dial {
  content: "";
  height: 6px;
  border-radius: 3px;
  position: absolute;
  bottom: 4px;
  left: 0;
  width: 100%;
  background: linear-gradient(90deg, var(--theme-color-critical) 0%, var(--theme-color-error) 30%, var(--theme-color-error) 40%, var(--theme-color-warning) 55%, var(--theme-color-warning) 60%, var(--theme-color-success) 90%, var(--theme-color-success) 100%);
}
.linear-dial.linear-dial-inverted > .linear-dial-dial {
  background: linear-gradient(90deg, var(--theme-color-success) 0%, var(--theme-color-success) 10%, var(--theme-color-warning) 40%, var(--theme-color-warning) 45%, var(--theme-color-error) 60%, var(--theme-color-error) 70%, var(--theme-color-critical) 100%);
}
.linear-dial > .linear-dial-cursor {
  content: "";
  height: 14px;
  border-radius: 2px;
  width: 4px;
  position: absolute;
  bottom: 0;
  transform: translateX(-50%);
  background-color: var(--theme-color-white);
  border: var(--theme-color-label) 1px solid;
}`, "",{"version":3,"sources":["webpack://./src/sg-react/data/LinearDial/index.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,kBAAA;EACA,YAAA;EACA,cAAA;AACJ;AACI;EACI,WAAA;EACA,WAAA;EACA,kBAAA;EACA,kBAAA;EACA,WAAA;EACA,OAAA;EACA,WAAA;EACA,+PAAA;AACR;AAWI;EACI,+PAAA;AATR;AAqBI;EACI,WAAA;EACA,YAAA;EACA,kBAAA;EACA,UAAA;EACA,kBAAA;EACA,SAAA;EACA,2BAAA;EACA,0CAAA;EACA,0CAAA;AAnBR","sourcesContent":[".linear-dial {\n    display: flex;\n    flex-direction: column;\n    position: relative;\n    height: 14px;\n    padding: 0 4px;\n\n    & > .linear-dial-dial {\n        content: \"\";\n        height: 6px;\n        border-radius: 3px;\n        position: absolute;\n        bottom: 4px;\n        left: 0;\n        width: 100%;\n        background: linear-gradient(\n            90deg,\n            var(--theme-color-critical) 0%,\n            var(--theme-color-error) 30%,\n            var(--theme-color-error) 40%,\n            var(--theme-color-warning) 55%,\n            var(--theme-color-warning) 60%,\n            var(--theme-color-success) 90%,\n            var(--theme-color-success) 100%\n        );\n    }\n\n    &.linear-dial-inverted > .linear-dial-dial {\n        background: linear-gradient(\n            90deg,\n            var(--theme-color-success) 0%,\n            var(--theme-color-success) 10%,\n            var(--theme-color-warning) 40%,\n            var(--theme-color-warning) 45%,\n            var(--theme-color-error) 60%,\n            var(--theme-color-error) 70%,\n            var(--theme-color-critical) 100%\n        );\n    }\n\n    & > .linear-dial-cursor {\n        content: \"\";\n        height: 14px;\n        border-radius: 2px;\n        width: 4px;\n        position: absolute;\n        bottom: 0;\n        transform: translateX(-50%);\n        background-color: var(--theme-color-white);\n        border: var(--theme-color-label) 1px solid;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
