import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useAuthContext, useRequest } from "../../../context";
import { Select } from "../../../form";
import { CurrentUserSettings } from "../../../models/user";
import { Card } from "../../../ui";
import Info from "../../../ui/Info";

interface SettingsProps {

}

const Settings = ({ }: SettingsProps) => {
    const { currentUser, refreshUser } = useAuthContext();
    const request = useRequest();
    const { t } = useTranslation();

    const handleChange = useCallback((settings: CurrentUserSettings) => {
        request.patch('/users/settings', settings, {
            loader: true,
            errorMessage: true
        })
            .then(() => refreshUser())
            .catch(() => null);
    }, [request]);

    return (
        <Card title="settings" i18n="users">
            <Info label="settings_info" i18n="users" />
            <Select id="language" i18n="ui" label="language" items={[{ key: 'en', label: 'English' }, { key: 'fr', label: 'Français' }]} value={currentUser.settings?.language} onChange={(language) => handleChange({ ...currentUser.settings, language })} />
            <Select id="darkMode" i18n="ui" label="mode" items={[{ key: true, label: t('ui:dark_mode') }, { key: false, label: t('ui:light_mode') }, { key: undefined, label: t('ui:default_mode') }]} value={currentUser.settings?.darkMode} onChange={(darkMode) => handleChange({ ...currentUser.settings, darkMode })} />
        </Card>
    )
}
export default Settings;