import { Map as MapType } from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { Fragment, ReactElement, forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef } from 'react';
import { FullscreenControl, GeolocateControl, LngLatBounds, default as MapGl, MapLayerMouseEvent, MapRef, MapboxEvent, NavigationControl, ViewStateChangeEvent } from "react-map-gl";
import { useUiContext } from '../../context';
import { LocatedEntity } from '../../models/geo';
import { Location } from '../../models/location';
import { getMarkersBoundingBox } from '../../utils/geo';
import { conditionnalClassnames } from '../../utils/helpers';
import Geocoder from './Geocoder';
import './index.scss';

export interface MapRefProps {
    getBounds: () => LngLatBounds | undefined;
    fitToEntities: <T extends LocatedEntity>(e: T[], options?: { keepZoom?: boolean, animate?: boolean }) => void;
    getZoom: () => number | undefined;
    resize: () => void;
    goTo: (lng: number, lat: number, zoom?: number) => void;
    getMap: () => MapType | undefined
}

interface MapProps {
    markers?: ReactElement[];
    clusterPoints?: ReactElement[];
    withGeocoder?: boolean;
    onGeocoderSelectResponse?: (location: Location) => void;
    onMapClick?: (e: MapLayerMouseEvent) => void;
    onLoad?: (e: MapboxEvent<undefined>) => void;
    onMoveEnd?: (e: ViewStateChangeEvent) => void;
    onMouseMove?: (e: MapLayerMouseEvent) => void;
    fitToMarkers?: 'always' | 'onLoad';
    interactiveLayerIds?: string[];
    mapStyle?: string;
    children?: any;
    withControls?: boolean;
    className?: string;
    disableReuse?: boolean;
}

const Map = forwardRef<MapRefProps, MapProps>(({
    markers,
    withGeocoder,
    onGeocoderSelectResponse,
    onMapClick,
    onLoad,
    onMoveEnd,
    onMouseMove,
    fitToMarkers,
    interactiveLayerIds,
    withControls,
    children,
    mapStyle: mapStyleFromProps,
    className,
    disableReuse
}: MapProps, ref) => {
    const { isDarkMode } = useUiContext();
    const mapRef = useRef<MapRef | null>(null);
    const containerRef = useRef<HTMLDivElement>(null);

    const mapStyle = useMemo(() => mapStyleFromProps ?? (isDarkMode ? 'mapbox://styles/mapbox/dark-v11' : 'mapbox://styles/mapbox/light-v11'), [mapStyleFromProps, isDarkMode]);

    const fitToEntities = useCallback(<T extends LocatedEntity>(entities: T[], options?: { keepZoom?: boolean, animate?: boolean }) => {
        try {
            mapRef.current?.fitBounds(getMarkersBoundingBox(entities), options?.keepZoom ? { zoom: mapRef.current.getZoom(), padding: 150, animate: !!options.animate } : { maxZoom: 10, padding: 150, animate: !!options?.animate });
        } catch { }
    }, []);

    const handleLoad = useCallback((e: MapboxEvent<undefined>) => {
        if (markers?.length) {
            const _props = markers.filter(m => m.props.latitude !== undefined && m.props.longitude !== undefined).map(m => m.props);
            if (_props.length) {
                e.target.fitBounds(getMarkersBoundingBox(_props), { maxZoom: 7, minZoom: 2, maxDuration: 2000 });
            }
        }
        e.target.resize();
        if (onLoad) onLoad(e);
    }, [onLoad, markers]);

    useImperativeHandle(ref, () => ({
        getBounds: () => mapRef.current?.getBounds(),
        fitToEntities,
        getZoom: () => mapRef.current?.getZoom(),
        resize: () => mapRef.current?.resize(),
        goTo: (lng: number, lat: number, zoom?: number) => mapRef.current?.flyTo({ center: [lng, lat], zoom: zoom ?? 14, animate: false }),
        getMap: () => mapRef.current?.getMap()
    })
        , []);

    useEffect(() => {
        if (fitToMarkers === 'always' && markers?.length && mapRef.current) {
            const _props = markers.filter(m => m.props.latitude !== undefined && m.props.longitude !== undefined).map(m => m.props)
            if (_props.length) {
                mapRef.current.fitBounds(getMarkersBoundingBox(_props), { maxZoom: 12, minZoom: 7, maxDuration: 2000 });
            }
        }
    }, [markers, fitToMarkers]);

    useEffect(() => {
        if (!containerRef.current) return;

        const observer = new ResizeObserver(() => {
            mapRef.current?.resize();
        });

        observer.observe(containerRef.current);
        return () => {
            observer.disconnect();
        };
    }, [])

    return (
        <div className={conditionnalClassnames({ map: true, className })} ref={containerRef}>
            <MapGl
                reuseMaps={!disableReuse}
                initialViewState={{
                    longitude: 3,
                    latitude: 47,
                    zoom: 3.5
                }}
                mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
                mapStyle={mapStyle}
                projection={{ name: 'mercator' }}
                ref={mapRef}
                onClick={onMapClick}
                onLoad={handleLoad}
                onMoveEnd={onMoveEnd}
                onMouseMove={onMouseMove}
                interactiveLayerIds={interactiveLayerIds}
            >
                {!!withControls && (
                    <Fragment><GeolocateControl position="bottom-right" />
                        <FullscreenControl position="bottom-right" />
                        <NavigationControl position="bottom-right" />
                    </Fragment>
                )}
                {(!!withGeocoder && !!onGeocoderSelectResponse) && <Geocoder onSelectResponse={onGeocoderSelectResponse} />}
                {markers}
                {children}
            </MapGl>
        </div>
    )
});

export default Map;