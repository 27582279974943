import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { DirectoryItemFormProps } from "..";
import DirectoryItemSearch from "../../../components/DirectoryItemSearch";
import { DirectoryItem, DirectoryItemType } from "../../../models/directory-item";
import { TextArea, TextField } from "../../../sg-react/form";
import ImageField from "../../../sg-react/form/ImageField";
import SelectMultiple from "../../../sg-react/form/SelectMultiple";
import { translateEnum } from "../../../sg-react/utils/format";

const DirectoryItemInformationsForm = ({ entity, attachInput, onMultipleChange }: DirectoryItemFormProps) => {
    const { t } = useTranslation();
    const [isParentModalVisible, setParentModalVisible] = useState<boolean>(false);

    const directoryItemTypes = useMemo(() => translateEnum(DirectoryItemType, 'directory', 'types', t), [t]);

    const handleParentChange = useCallback((parents?: DirectoryItem[]) => {
        if (!parents?.length) return;
        onMultipleChange({
            parentsIds: parents.map(p => p._id),
            parents: parents
        });
    }, [onMultipleChange]);

    return (
        <div className="form-inline-group">
            <TextField label inline tooltip i18n="directory" {...attachInput('name')} />
            <TextArea label inline tooltip i18n="directory" rows={5} {...attachInput('description')} />
            <TextField label inline tooltip i18n="directory" {...attachInput('localBusinessId')} />
            <TextField label inline tooltip i18n="directory" {...attachInput('localBusinessIdType')} />
            <TextField label inline tooltip i18n="directory" {...attachInput('internationalBusinessId')} />
            <TextField label inline tooltip i18n="directory" {...attachInput('internationalBusinessIdType')} />
            <SelectMultiple label inline i18n="directory" items={directoryItemTypes} {...attachInput('type')} />
            <TextField id="parents" inline tooltip value={entity.parents?.map(p => p.name).join(', ')} label i18n="directory" onFocus={() => setParentModalVisible(true)} />
            <ImageField label inline tooltip i18n="directory" endpoint="/directory/logo" {...attachInput('logo')} />

            {!!isParentModalVisible && (
                <DirectoryItemSearch global multiple onClose={() => setParentModalVisible(false)} onSubmit={handleParentChange} />
            )}
        </div>
    )
}

export default DirectoryItemInformationsForm;