// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.directory-item-view-date {
  position: relative;
  display: flex;
  align-items: center;
}
.directory-item-view-date > .value-description {
  flex-grow: 1;
}
.directory-item-view-date::before {
  content: "";
  position: absolute;
  left: -26px;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background-color: var(--theme-container-background-color);
  border: var(--theme-label-color) 1px solid;
}`, "",{"version":3,"sources":["webpack://./src/components/DirectoryDataDate/index.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,aAAA;EACA,mBAAA;AACJ;AACI;EACI,YAAA;AACR;AAEI;EACI,WAAA;EACA,kBAAA;EACA,WAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;EACA,yDAAA;EACA,0CAAA;AAAR","sourcesContent":[".directory-item-view-date {\n    position: relative;\n    display: flex;\n    align-items: center;\n\n    & > .value-description {\n        flex-grow: 1;\n    }\n\n    &::before {\n        content: \"\";\n        position: absolute;\n        left: -26px;\n        width: 14px;\n        height: 14px;\n        border-radius: 50%;\n        background-color: var(--theme-container-background-color);\n        border: var(--theme-label-color) 1px solid;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
