import { Marker } from 'react-map-gl';
import { DirectoryItem } from '../../models/directory-item';
import { SegmentationsCount } from '../../models/segmentation';
import PieChartSvg from '../../sg-react/data/PieChartSvg';
import { conditionnalClassnames } from '../../sg-react/utils/helpers';
import DirectoryItemIcon from '../DirectoryItemIcon';
import './index.scss';

interface DirectoryItemMarkerProps {
    directoryItem: DirectoryItem;
    segmentationsCount?: SegmentationsCount[];
    longitude: number;
    latitude: number;
    onMouseEnter?: () => void;
    onClick?: () => void;
    selected?: boolean;
}

const DirectoryItemMarker = ({ directoryItem, longitude, latitude, onClick, selected, onMouseEnter, segmentationsCount }: DirectoryItemMarkerProps) => {
    return (
        <Marker
            key={`marker-${directoryItem._id}`}
            longitude={longitude}
            latitude={latitude}
            onClick={onClick}
            anchor="center"
        >
            <div
                className={conditionnalClassnames({
                    "directory-item-marker": true,
                    "directory-item-marker-selected": selected

                })}
                onMouseEnter={onMouseEnter}
            >
                <PieChartSvg values={segmentationsCount ?? []} />
                <DirectoryItemIcon type={directoryItem.type} />
            </div>
        </Marker>
    )
}
export default DirectoryItemMarker;