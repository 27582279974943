import { Fragment, useCallback, useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { DirectoryImportProps, MAPPING_LABELS } from "..";
import { DIRECTORY_ITEM_CONTACTS_VALIDATION, DIRECTORY_ITEM_INFORMATION_VALIDATION, DirectoryItem } from "../../../models/directory-item";
import { LocationPicker } from "../../../sg-react/form";
import { ObjectValidation } from "../../../sg-react/form/Validation/class";
import { ObjectValidationException } from "../../../sg-react/form/Validation/exceptions";
import { ObjectValidationErrors } from "../../../sg-react/form/Validation/types";
import { useForm } from "../../../sg-react/hooks";
import { ErrorIcon, SaveIcon, SuccessIcon } from "../../../sg-react/icons";
import { LOCATION_VALIDATION } from "../../../sg-react/models/location";
import { Button, Card } from "../../../sg-react/ui";
import Info from "../../../sg-react/ui/Info";
import ListItem from "../../../sg-react/ui/ListItem";
import { Colors } from "../../../sg-react/ui/enums";
import { joinOptionnalStrings, translateLabel } from "../../../sg-react/utils/format";
import { conditionnalClassnames } from "../../../sg-react/utils/helpers";
import { getNestedField } from "../../../sg-react/utils/objects";
import DirectoryItemContactsForm from "../../DirectoryItemForm/components/DirectoryItemContactsForm";
import DirectoryItemInformationsForm from "../../DirectoryItemForm/components/DirectoryItemInformationsForm";

export const IMPORT_VALIDATION = new ObjectValidation({
    ...DIRECTORY_ITEM_CONTACTS_VALIDATION,
    ...DIRECTORY_ITEM_INFORMATION_VALIDATION,
    location: LOCATION_VALIDATION
});

const DirectoryImportDataValidation = ({ directoryImport, onChange }: DirectoryImportProps) => {
    const { entity, attachInput, reset, setErrors, hasChanged, validate, errors, onChange: onChangeEntity, onMultipleChange } = useForm<Record<string, any> & { _id: string }>({}, IMPORT_VALIDATION);
    const { t } = useTranslation();
    const ref = useRef<HTMLDivElement>(null);

    const goToField = useCallback((id: string) => {
        if (ref.current) {
            ref.current.querySelector<HTMLDivElement>(`div[id='input-${id}']`)?.scrollIntoView({ behavior: 'smooth' });
        }
    }, []);

    const validationData = useMemo(() => {
        const validationData: Record<string, { hasError: boolean, errors: ObjectValidationErrors }> = {};

        if (!directoryImport.dataMapped) return validationData;

        for (const dataMapped of directoryImport.dataMapped) {
            try {
                IMPORT_VALIDATION.validate(dataMapped);
            } catch (e) {
                if (e instanceof ObjectValidationException) {
                    validationData[dataMapped._id] = { hasError: true, errors: e.errors };
                }
            }
        }

        return validationData;
    }, [directoryImport]);

    const handleSave = useCallback(() => validate((entity) => {
        if (!directoryImport.dataValidated) return;
        const index = directoryImport.dataValidated.findIndex(d => d._id === entity._id);

        const dataValidated = [...directoryImport.dataValidated ?? []];

        if (index === -1) {
            dataValidated.push(entity);
        } else {
            dataValidated[index] = entity;
        }
        onChange({ ...directoryImport, dataValidated });
        reset(entity, false);
    }), [validate, directoryImport, reset, onChange]);

    const setEntityToEdit = useCallback((_id: string) => {
        const entityToEdit = directoryImport.dataMapped?.find(d => d._id === _id);

        if (entityToEdit) {
            reset(entityToEdit, false);
            setErrors(validationData[entityToEdit._id]?.errors ?? {});
        }
    }, [reset, directoryImport.dataValidated, validationData]);

    useEffect(() => {
        if (directoryImport.dataMapped?.length) {
            const dataValidated: (Record<string, any> & { _id: string })[] = [];

            for (const dataMapped of directoryImport.dataMapped) {
                try {
                    dataValidated.push(IMPORT_VALIDATION.validate(dataMapped, false));
                } catch (e) { }
            }

            onChange({ ...directoryImport, dataValidated: dataValidated });
            if (!entity._id) {
                setEntityToEdit(directoryImport.dataMapped[0]._id);
            }
        }
    }, []);

    return (
        <Fragment>
            <Card id="directory-import-data-validation-data" className="col col-lg-25" overflow="hidden">
                <Info noIcon label="import.data_validation_tooltip" i18n="data" />
                <div className="list">
                    {directoryImport.dataMapped?.map((d) => (
                        <ListItem
                            key={d._id}
                            value={d.name}
                            description={joinOptionnalStrings([d.location?.city, d.location?.country])}
                            active={entity._id === d._id}
                            onClick={() => setEntityToEdit(d._id)}
                        >
                            {directoryImport.dataValidated?.find(dv => dv._id === d._id) ? <SuccessIcon className="icon icon-success" /> : <ErrorIcon className="icon icon-error" />}
                        </ListItem>
                    ))}
                </div>
            </Card>
            <Card
                className="col col-lg-75"
                title={entity.name ?? ''}
                overflow="hidden"
                color={hasChanged ? 'warning' : undefined}
                options={hasChanged ? <Button label="save" i18n="actions" icon={<SaveIcon />} color={Colors.WARNING} onClick={handleSave} /> : undefined}
            >
                <div className="row row-wrap">
                    {directoryImport.targetColumns.map(c => (
                        <div key={c} className="link" onClick={() => goToField(c)}>
                            <span className={conditionnalClassnames({
                                'color-success': entity._id && !errors[c]?.length && getNestedField(entity, c) !== undefined,
                                'color-error': entity._id && errors[c]?.length,
                            })}>
                                {translateLabel(MAPPING_LABELS[c]?.label, t, MAPPING_LABELS[c]?.i18n)}
                            </span>
                        </div>
                    ))}
                </div>

                <div id="directory-import-data-validation-form" className="col col-layout" ref={ref}>
                    <DirectoryItemInformationsForm entity={entity as Partial<DirectoryItem>} attachInput={attachInput} onMultipleChange={onMultipleChange} />
                    <h3>{t('location:location')}</h3>
                    <LocationPicker inline manual entity={entity as Partial<DirectoryItem>} onChange={onChangeEntity} attachInput={attachInput} />
                    <h3>{t('contacts:contacts')}</h3>
                    <DirectoryItemContactsForm entity={entity as Partial<DirectoryItem>} attachInput={attachInput} onMultipleChange={onMultipleChange} />
                </div>
            </Card>
        </Fragment>
    )
}
export default DirectoryImportDataValidation;