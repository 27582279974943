import './index.scss';
import { ReactNode, useRef } from 'react';

interface ImageUploaderProps {
    children?: ReactNode,
    onChange: (file: File) => void,
}

const ImageUploader = ({
    children,
    onChange,
}: ImageUploaderProps) => {
    const inputRef = useRef<HTMLInputElement | null>(null);

    const handleUpload = () => {
        inputRef.current?.click();
    };

    const handleImagerChange = async (e: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
        if (!e.target.files) {
            return;
        }
        onChange(e.target.files[0]);
    };

    return (
        <div className="image-uploader" onClick={handleUpload}>
            {children}
            <input
                type="file"
                accept="image/png, image/gif, image/jpeg"
                ref={inputRef}
                onChange={handleImagerChange}
                style={{ display: 'none' }}
            />
        </div>
    );
}

export default ImageUploader;
