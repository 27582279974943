import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DirectoryItem, DirectoryItemForMap } from "../../models/directory-item";
import { useMapContext } from "../../pages/MainMap/components/MapContext";
import { useRequest, useWorkspaceContext } from "../../sg-react/context";
import { DownloadIcon, LinkIcon, LocationIcon, PhoneIcon, ViewIcon } from "../../sg-react/icons";
import { WorkspaceForUser, WorkspaceRole } from "../../sg-react/models/workspace";
import { Button, Card, Modal } from "../../sg-react/ui";
import IconValue from "../../sg-react/ui/IconValue";
import Info from "../../sg-react/ui/Info";
import LabelValue from "../../sg-react/ui/LabelValue";
import ListItem from "../../sg-react/ui/ListItem";
import { Colors } from "../../sg-react/ui/enums";
import { dateToLocaleString } from "../../sg-react/utils/date";
import DirectoryItemIcon from "../DirectoryItemIcon";
import SegmentationsTree from "../SegmentationsTree";
import './index.scss';

interface DirectoryItemMapCardProps {
    directoryItemForMap: DirectoryItemForMap;
    onClose: () => void;
}

const DirectoryItemMapCard = ({ directoryItemForMap, onClose }: DirectoryItemMapCardProps) => {
    const request = useRequest();
    const navigate = useNavigate();
    const [isImportModalVisible, setImportModalVisible] = useState<boolean>(false);
    const [selectedWorkspace, setSelectedWorkspace] = useState<WorkspaceForUser | null>(null);
    const [selectedSegmentations, setSelectedSegmentations] = useState<string[]>([]);
    const { currentWorkspace, workspaces } = useWorkspaceContext();
    const [directoryItem, setDirectoryItem] = useState<DirectoryItem | null>(null);
    const { results } = useMapContext();

    const workspacesForImport = useMemo(() => directoryItem?.permissions.allowImport ? workspaces.filter(w =>
        w.role !== WorkspaceRole.User
        && w._id !== currentWorkspace._id
    ) : undefined, [workspaces, currentWorkspace, directoryItem]);

    const handleClearImport = useCallback((close?: boolean) => {
        if (close) {
            setImportModalVisible(false);
        }
        setSelectedSegmentations([]);
        setSelectedWorkspace(null);
    }, []);

    const handleImport = useCallback(() => {
        if (!selectedWorkspace) return;
        request.post(`/directory/${directoryItemForMap._id}/import`,
            { workspaceId: selectedWorkspace._id, segmentationsIds: selectedSegmentations },
            {
                withWorkspace: true,
                loader: true,
                successMessage: {
                    i18n: 'directory', message: 'success.import', variables: { workspace: selectedWorkspace.name }
                },
                errorMessage: true
            }
        )
            .then(() => handleClearImport(true))
            .catch(() => null);
    }, [handleClearImport, directoryItemForMap, selectedWorkspace, selectedSegmentations, request]);

    useEffect(() => {
        request.get<DirectoryItem>(`/directory/${directoryItemForMap._id}`, { withWorkspace: true })
            .then(setDirectoryItem)
            .catch(onClose);
    }, [directoryItemForMap]);

    if (!directoryItem) return null;

    return (
        <Card
            className="directory-item-map-card"
            overflow="auto"
            onClose={onClose}
            options={<Fragment>
                {!!workspacesForImport?.length && <Button icon={<DownloadIcon />} color="navigation" i18n="actions" label="import" onClick={() => setImportModalVisible(true)} />}
                <Button icon={<ViewIcon />} color="navigation" i18n="actions" label="view" onClick={() => navigate(`/view/${directoryItemForMap._id}`)} />
            </Fragment>}
        >
            <div className="directory-item-map-card-header">
                {(directoryItem?.logo?.base64 || directoryItem?.logo?.url) && <img src={directoryItem.logo.base64 ?? directoryItem.logo.url} alt={directoryItem.name} />}
                <div className="row"><DirectoryItemIcon type={directoryItem.type} /><h2>{directoryItem.name}</h2></div>
            </div>
            <div className="directory-item-map-card-content">
                <IconValue icon={<LinkIcon />} value={directoryItem.website ? <a href={directoryItem.website} target="_blank" rel="noreferrer">{directoryItem.website}</a> : undefined} />
                <IconValue icon={<LocationIcon />} value={directoryItem.location?.address} />
                <IconValue icon={<PhoneIcon />} value={directoryItem.localPhone} />
                <IconValue icon={<PhoneIcon />} value={directoryItem.internationalPhone} />
            </div>
            <SegmentationsTree defaultMode="selected" selected={directoryItemForMap.segmentationsIds} matching={results?.segmentationsIds} />
            {!!directoryItem.data && (
                <Fragment>
                    <LabelValue inline label="Altitude du parc" value={directoryItem.data.elevation} />
                    <LabelValue inline label="Nombre de turbines" value={directoryItem.data.turbinesCount} />
                    <LabelValue inline label="Mise en exploitation" value={directoryItem.data.exploitationDate && directoryItem.data.exploitationDate instanceof Date ? dateToLocaleString(directoryItem.data.exploitationDate) : undefined} />
                    <LabelValue inline label="Référence de turbine" value={directoryItem.data.turbinesReference} />
                    <LabelValue inline label="Date repowering" value={directoryItem.data.repoweringDate && directoryItem.data.repoweringDate instanceof Date ? dateToLocaleString(directoryItem.data.repoweringDate) : undefined} />
                    <LabelValue inline label="Référence de turbine pour repowering" value={directoryItem.data.repoweringTurbinesReference} />
                </Fragment>
            )}
            {isImportModalVisible && (
                <Modal
                    id="directory-item-map-card-modal"
                    size={!selectedWorkspace ? 'small' : 'medium'}
                    title="import"
                    i18n="actions"
                    onClose={() => handleClearImport(true)}
                    onSubmit={handleImport}
                    actions={selectedWorkspace ? [
                        { color: Colors.SECONDARY, i18n: 'actions', label: 'previous', onClick: () => handleClearImport() }
                    ] : []}
                >
                    {!selectedWorkspace ? (
                        <Fragment>
                            <Info i18n="directory" label="import_workspace_tooltip" />
                            <div className="list">
                                {workspacesForImport?.map(w => (
                                    <ListItem
                                        key={w._id}
                                        value={w.name}
                                        description={w.description}
                                        tag={directoryItem.permissions?.workspaces.some(pw => pw.workspaceId === w._id) ? { color: Colors.PRIMARY, label: 'Imported' } : undefined}
                                        onClick={directoryItem.permissions?.workspaces.some(pw => pw.workspaceId === w._id) ? undefined : () => setSelectedWorkspace(w)}
                                        preline
                                    />
                                ))}
                            </div>
                        </Fragment>
                    ) : (
                        <Fragment>
                            <Info i18n="directory" label="import_segmentations_tooltip" />
                            <SegmentationsTree workspaceId={selectedWorkspace._id} onSelect={setSelectedSegmentations} selected={selectedSegmentations} />
                        </Fragment>
                    )}
                </Modal>
            )}
        </Card>
    )
}

export default DirectoryItemMapCard;