import { ReactNode } from 'react';
import './index.scss';
import Button, { ButtonProps } from '../Button';
import { CloseIcon } from '../../icons';
import { useTranslation } from 'react-i18next';
import { translateLabel } from '../../utils/format';
import { conditionnalClassnames } from '../../utils/helpers';
import Spinner from '../Spinner';

interface CardProps {
    children?: ReactNode;
    title?: string;
    i18n?: string;
    className?: string;
    color?: 'warning' | 'error' | 'success' | 'pristine';
    id?: string;
    actions?: ButtonProps[];
    options?: ReactNode;
    loading?: boolean;
    overflow?: 'visible' | 'hidden' | 'clip' | 'scroll' | 'auto';
    onClose?: () => void;
}

const Card = ({
    children,
    title,
    color,
    i18n,
    className,
    id,
    actions,
    options,
    loading,
    overflow,
    onClose
}: CardProps) => {
    const { t } = useTranslation();

    return (
        <div className={conditionnalClassnames({
            card: true,
            [`card-${color}`]: color,
            className
        })} id={id}>
            {(!!title || !!onClose || options) && (
                <div className="card-title">
                    <h2>{title ? translateLabel(title, t, i18n) : undefined}</h2>
                    {(!!onClose || options || loading) && (
                        <div className="card-title-options">
                            {!!loading && <Spinner small />}
                            {options}
                            {!!onClose && <CloseIcon className="card-close-icon" onClick={onClose} />}
                        </div>
                    )}
                </div>
            )}
            <div className="card-content" style={{ overflow }}>
                {children}
            </div>
            {!!actions?.length && (
                <div className="card-actions">
                    {actions.map(a => <Button key={a.label} {...a} />)}
                </div>
            )}
        </div>
    )
}

export default Card;