import { FormEvent, useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ObjectValidation } from '../../form/Validation/class';
import useForm, { FormHookReturn, UseFormConstraint } from '../../hooks/useForm';
import { CloseIcon, LeftIcon, RightIcon, SaveIcon } from '../../icons';
import { translateLabel } from '../../utils/format';
import { conditionnalClassnames } from '../../utils/helpers';
import Button from '../Button';
import Modal from '../Modal';
import { Colors } from '../enums';
import './index.scss';

export interface WizardContentComponentProps<T> extends FormHookReturn<T> {
    initial?: Partial<T>;
}

export interface WizardStep<T> {
    label: string;
    tooltip?: string;
    i18n?: string;
    validation?: ObjectValidation;
    component?: React.FC<WizardContentComponentProps<T>>;
    disabled?: (d: Partial<T>) => boolean;
}

interface WizardProps<T> {
    initial: Partial<T>;
    initialStep?: number;
    initialMaxStep?: number;
    steps: WizardStep<T>[];
    onSubmit: (data: Partial<T>, hasChanged: boolean) => void;
    onClose: () => void;
}

const Wizard = <T extends UseFormConstraint>({ initial, initialStep, initialMaxStep, steps, onSubmit, onClose }: WizardProps<T>) => {
    const { t } = useTranslation();
    const [currentStep, setCurrentStep] = useState<number>(initialStep ?? 0);
    const [maxStep, setMaxStep] = useState<number>(initialMaxStep ?? initialStep ?? 0);
    const { entity, validate, reset, ...formProps } = useForm<T>(initial);
    const ref = useRef<HTMLDivElement | null>(null);

    const stepsComponents = useMemo(() => steps.map((s, i) => (
        <div
            key={s.label}
            className={conditionnalClassnames({
                active: i === currentStep,
                disabled: i > maxStep || (s.disabled && s.disabled(entity)),
            })}
            onClick={i <= maxStep && (!s.disabled || !s.disabled(entity)) ? () => setCurrentStep(i) : undefined}
        >
            {translateLabel(s.label, t, s.i18n)}
        </div>
    )), [steps, currentStep]);

    const step = useMemo(() => steps[currentStep], [currentStep, steps]);
    const Component = useMemo(() => step.component, [step]);

    const handleStep = useCallback((direction: -1 | 1) => {
        let nextStep = currentStep + direction;
        while (steps[nextStep].disabled && steps[nextStep].disabled!(entity) && nextStep < steps.length - 1 && nextStep >= 0) {
            nextStep += direction;
        };

        if (direction === 1) {
            let maxStep = nextStep;
            while (((steps[maxStep].disabled && steps[maxStep].disabled!(entity)) || (steps[maxStep].validation && steps[maxStep].validation?.isValid(entity))) && maxStep < steps.length - 1 && maxStep >= 0) {
                maxStep += direction;
            };
            setMaxStep(maxStep);
        }
        ref.current?.scrollTo({ top: 0 });
        setCurrentStep(nextStep);
    }, [entity, currentStep, steps]);

    const handleNext = useCallback((e?: FormEvent) => {
        e?.preventDefault();
        if (currentStep >= steps.length - 1) {
            return;
        }

        validate(() => handleStep(1), steps[currentStep].validation);
    }, [currentStep, validate, steps, handleStep]);

    return (
        <form className="col wizard" onSubmit={handleNext}>
            <div className="row row-layout">
                <div className="col wizard-steps">
                    {stepsComponents}
                </div>
                <div className="col wizard-content" ref={ref}>
                    {!!step.tooltip && <div>{translateLabel(step.tooltip, t, step.i18n)}</div>}
                    {!!Component && <Component initial={initial} entity={entity} validate={validate} reset={reset} {...formProps} />}
                </div>
            </div>
            <div className="row wizard-actions">
                <Button color={Colors.SECONDARY} label="close" i18n="actions" icon={<CloseIcon />} onClick={onClose} />
                {currentStep > 0 && <Button color={Colors.PRIMARY} label="previous" i18n="actions" icon={<LeftIcon />} onClick={() => handleStep(-1)} />}
                {(maxStep >= steps.length - 1 || currentStep === steps.length - 1) && <Button color={Colors.PRIMARY} label="submit" i18n="actions" icon={<SaveIcon />} onClick={() => onSubmit(entity, formProps.hasChanged)} />}
                {currentStep < steps.length - 1 && <Button type="submit" color={Colors.PRIMARY} label={maxStep === 0 ? 'begin' : 'next'} i18n="actions" icon={<RightIcon />} onClick={handleNext} />}
            </div>
        </form>
    )
}

export interface WizardModalProps<T extends UseFormConstraint> extends WizardProps<T> {
    i18n?: string;
    title?: string;
}

export const WizardModal = <T extends UseFormConstraint>({ title, i18n, ...props }: WizardModalProps<T>) => (
    <Modal
        className="wizard-modal"
        i18n={i18n}
        title={title}
        overflow="hidden"
    >
        <Wizard<T> {...props} />
    </Modal>
)

export default Wizard;