import { Fragment, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { ContactService, DirectoryContact } from "../../../models/directory-contact";
import { useRequest } from "../../../sg-react/context";
import { useWorkspaceContext } from "../../../sg-react/context/WorkspaceContext";
import { SearchField } from "../../../sg-react/data";
import DataContainerList from "../../../sg-react/data/DataContainerList";
import { Select, TextField } from "../../../sg-react/form";
import Validation, { ObjectValidation } from "../../../sg-react/form/Validation/class";
import { useForm } from "../../../sg-react/hooks";
import { User } from "../../../sg-react/models/user";
import { Button, Modal } from "../../../sg-react/ui";
import { translateEnum } from "../../../sg-react/utils/format";

interface DirectoryContactFormProps {
    contact: Partial<DirectoryContact>;
    onSubmit: (e: Partial<DirectoryContact>) => void;
    onClose: () => void;
}

const VALIDATION = new ObjectValidation({
    userId: Validation.string().required().isMongoId(),
})

const VALIDATION_MANUAL = new ObjectValidation({
    contact: {
        firstName: Validation.string().required().minLength(3).maxLength(150),
        lastName: Validation.string().required().minLength(3).maxLength(150),
        company: Validation.string().required().minLength(3).maxLength(150),
        position: Validation.string().required().minLength(3).maxLength(150),
        email: Validation.string().required().isEmail(),
        service: Validation.string().required().isEnum(ContactService),
    }
})

const DirectoryContactForm = ({ contact, onSubmit, onClose }: DirectoryContactFormProps) => {
    const { t } = useTranslation();
    const [isManual, setManual] = useState<boolean>(!!contact.contact?.firstName);
    const [users, setUsers] = useState<User[]>([]);
    const { attachInput, validate, onMultipleChange } = useForm<DirectoryContact>(contact);
    const { currentWorkspace } = useWorkspaceContext();
    const request = useRequest();

    const contactServices = useMemo(() => translateEnum(ContactService, 'contacts', 'services', t), [t]);

    const handleSearch = useCallback(async (keyword?: string) => {
        if (!keyword) {
            setUsers([]);
            return;
        }
        await request.get<User[]>(`/workspaces/${currentWorkspace._id}/users/search`, { params: { keyword }, errorMessage: true })
            .then(setUsers)
            .catch(() => null);
    }, [request]);

    const handleSubmit = useCallback(() => validate(
        (entity) => {
            onSubmit(isManual ? {
                ...entity,
                user: undefined,
                userId: undefined,
            } : {
                ...entity,
                contact: undefined,
            });
        },
        isManual ? VALIDATION_MANUAL : VALIDATION
    ), [validate, isManual, onSubmit]);

    return (
        <Modal
            size="medium"
            i18n="contacts"
            title={contact._id
                ? "edit_contact"
                : "new_contact"
            }
            onClose={onClose}
            onSubmit={handleSubmit}
            overflow="visible"
            id="contact-form"
        >
            {!contact._id && (
                <div className="row">
                    <Button color="navigation" i18n="actions" label="search" active={!isManual} onClick={() => setManual(false)} />
                    <Button color="navigation" i18n="actions" label="create" active={isManual} onClick={() => setManual(true)} />
                </div>
            )}
            {!isManual && !contact._id ? (
                <Fragment>
                    <SearchField onChange={handleSearch} loading={request.loading} />
                    <DataContainerList<User>
                        primaryKey="_id"
                        data={users ?? undefined}
                        onSelect={(u) => onMultipleChange(u ? { user: u, userId: u._id } : { user: undefined, userId: undefined })}
                        columns={[
                            { field: 'firstName', label: 'firstName', i18n: 'users' },
                            { field: 'lastName', label: 'lastName', i18n: 'users' },
                            { field: 'position', label: 'position', i18n: 'users' },
                            { field: 'company', label: 'company', i18n: 'users' },
                        ]}
                        sort={{ field: 'firstName', direction: 1 }}
                    />
                </Fragment>
            ) : (
                <Fragment>
                    <div className="form-inline-group">
                        <TextField inline label="firstName" i18n="users" {...attachInput('contact.firstName')} />
                        <TextField inline label="firstName" i18n="users" {...attachInput('contact.lastName')} />
                        <TextField inline label="company" i18n="users" {...attachInput('contact.company')} />
                        <TextField inline label="position" i18n="users" {...attachInput('contact.position')} />
                        <Select inline label="service" items={contactServices} i18n="contacts" {...attachInput('contact.service')} />
                        <TextField inline label="phone" i18n="users" {...attachInput('contact.phone')} />
                        <TextField inline label="email" i18n="users" {...attachInput('contact.email')} />
                    </div>
                </Fragment>
            )}
        </Modal>
    );
}

export default DirectoryContactForm;