import { Ref, useState } from "react";
import { useTranslation } from "react-i18next";
import { TextField } from "../../form";
import { UseFocusHandleRef, useDebounce } from "../../hooks";
import { MagnifierIcon } from "../../icons";
import { Spinner } from "../../ui";

interface SearchFieldProps {
    onChange: (k: string | undefined) => void;
    debounce?: number;
    minLength?: number;
    loading?: boolean;
    focusHandle?: Ref<UseFocusHandleRef>;
}

const SearchField = ({ onChange, minLength = 3, debounce, loading, focusHandle }: SearchFieldProps) => {
    const { t } = useTranslation();
    const [keyword, setKeyword] = useState<string | undefined>();
    useDebounce(() => !keyword || keyword.length >= minLength ? onChange(keyword) : null, debounce ?? 500, [keyword]);

    return (
        <TextField
            id="search-field"
            placeholder={t('actions:search')}
            value={keyword}
            onChange={setKeyword}
            icon={loading ? <Spinner small /> : <MagnifierIcon />}
            focusHandle={focusHandle}
        />
    )
}

export default SearchField;