import { Part } from "../../models/part";
import TagsPicker from "../../sg-react/data/TagsPicker";
import { WizardContentComponentProps } from "../../sg-react/ui/Wizard";


const PartWizardTags = ({ entity, onChange }: WizardContentComponentProps<Part>) => {
    return (
        <TagsPicker category="parts" selected={entity.tagsIds} onSelect={(tagsIds) => onChange('tagsIds', tagsIds)} />
    )
}
export default PartWizardTags;