import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useSearchParams } from "react-router-dom";
import { useAuthContext, useRequest, useUiContext } from "../../../context";
import { Form, Password, TextField } from "../../../form";
import Validation, { ObjectValidation } from "../../../form/Validation/class";
import { useForm } from "../../../hooks";
import { LoginCredentials, TokenResponse } from "../../../models/auth";
import { Button } from "../../../ui";
import Info from "../../../ui/Info";
import { Colors } from "../../../ui/enums";
import { requestErrorToToast } from "../../../utils/format";
import PortalPromoWrapper from "./PortalPromoWrapper";

const VALIDATION = new ObjectValidation({
    email: Validation.string().required().isEmail(),
    password: Validation.string().required(),
})

const Login = () => {
    const { t } = useTranslation();
    const { validate, attachInput } = useForm<LoginCredentials>({ email: "", password: "" });
    const { addToast } = useUiContext();
    const { handleToken } = useAuthContext();
    const request = useRequest();
    const [message, setMessage] = useState<string | null>(null);
    const [searchParams] = useSearchParams();

    const handleLogin = useCallback(() => validate(
        (entity) => {
            if (request.loading) return;

            request.post<TokenResponse>('/auth/login', entity, { loader: true, withCredentials: true })
                .then(handleToken)
                .catch(e => {
                    const toastError = requestErrorToToast(e as Error);

                    if (toastError.i18n === 'auth') {
                        setMessage(toastError.message);
                    } else {
                        addToast(toastError);
                    }
                });
        },
        VALIDATION
    ), [request, handleToken, validate, addToast]);

    useEffect(() => {
        if (searchParams.get('message')) {
            setMessage(searchParams.get('message'));
        }
    }, [searchParams]);

    return (
        <PortalPromoWrapper>
            <Form onSubmit={handleLogin}>
                {!!message && <Info type={message.startsWith('success') ? 'success' : 'warning'} label={message} i18n="auth" />}
                <TextField
                    {...attachInput('email')}
                    i18n="users"
                    label
                    autoComplete
                />
                <Password
                    {...attachInput('password')}
                    label
                    i18n="auth"
                />
                <div className="col col-double-gap col-vertical-padding">
                    <Button color={Colors.ACCENT} i18n="auth" label="login" type="submit" loading={request.loading} />
                    <Link to="/auth/recovery" className="center" >{t('auth:password_lost')}</Link>
                </div>
            </Form>
        </PortalPromoWrapper>
    )
}

export default Login;