// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.value-description {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
}
.value-description > div {
  display: flex;
  align-items: center;
  gap: 12px;
}
.value-description span {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.value-description.wrap {
  overflow: visible;
}
.value-description.wrap span {
  white-space: wrap;
}
.value-description.preline span {
  white-space: pre-line;
}
.value-description > span {
  color: var(--theme-label-color);
  font-size: var(--theme-label-font-size);
}
.value-description.inline {
  flex-direction: row;
  align-items: center;
  gap: 6px;
}`, "",{"version":3,"sources":["webpack://./src/sg-react/ui/ValueDescription/index.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,YAAA;EACA,gBAAA;AACJ;AACI;EACI,aAAA;EACA,mBAAA;EACA,SAAA;AACR;AAEI;EACI,gBAAA;EACA,uBAAA;EACA,mBAAA;AAAR;AAGI;EACI,iBAAA;AADR;AAGQ;EACI,iBAAA;AADZ;AAMQ;EACI,qBAAA;AAJZ;AAQI;EACI,+BAAA;EACA,uCAAA;AANR;AASI;EACI,mBAAA;EACA,mBAAA;EACA,QAAA;AAPR","sourcesContent":[".value-description {\n    display: flex;\n    flex-direction: column;\n    flex-grow: 1;\n    overflow: hidden;\n\n    & > div {\n        display: flex;\n        align-items: center;\n        gap: 12px;\n    }\n\n    & span {\n        overflow: hidden;\n        text-overflow: ellipsis;\n        white-space: nowrap;\n    }\n\n    &.wrap {\n        overflow: visible;\n\n        & span {\n            white-space: wrap;\n        }\n    }\n\n    &.preline {\n        & span {\n            white-space: pre-line;\n        }\n    }\n\n    & > span {\n        color: var(--theme-label-color);\n        font-size: var(--theme-label-font-size);\n    }\n\n    &.inline {\n        flex-direction: row;\n        align-items: center;\n        gap: 6px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
