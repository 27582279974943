import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { MenuProvider, WorkspaceProvider } from "../sg-react/context";
import { WorkspaceRole } from "../sg-react/models/workspace";
import Account from "../sg-react/pages/Account";
import Portal from "../sg-react/pages/Portal";
import ConfirmRecovery from "../sg-react/pages/Portal/components/ConfirmRecovery";
import ConfirmSignup from "../sg-react/pages/Portal/components/ConfirmSignup";
import Login from "../sg-react/pages/Portal/components/Login";
import Recovery from "../sg-react/pages/Portal/components/Recovery";
import Users from "../sg-react/pages/Users";
import Workspaces from "../sg-react/pages/Workspaces";
import { RouteError } from "../sg-react/ui";
import { ProtectedRoute } from "../sg-react/ui/ProtectedRoute";
import { Role } from "../sg-react/utils/enums";
import Dashboard from "./Dashboard";
import DirectoryList from "./DataManagementList";
import DirectoryListLayoutWrapper from "./DataManagementList/components/DirectoryListLayoutWrapper";
import DirectoryImport from "./DirectoryImport";
import DirectoryItemView from "./DirectoryItemView";
import MainMap from "./MainMap";
import MainMapLayoutWrapper from "./MainMap/components/MainMapLayoutWrapper";
import { MapProvider } from "./MainMap/components/MapContext";
import PartView from "./PartView";
import Parts from "./Parts";
import PartsLayoutWrapper from "./Parts/components/PartsLayoutWrapper";
import PlanAnalysis from "./PlanAnalysis";
import Private from "./Private";
import SpecificationView from "./SpecificationView";
import Specifications from "./Specifications";
import SpecificationsLayoutWrapper from "./Specifications/components/SpecificationsLayoutWrapper";


const App = () => <RouterProvider router={createBrowserRouter([
    {
        path: "/",
        element: (
            <ProtectedRoute>
                <WorkspaceProvider>
                    <MenuProvider>
                        <Private />
                    </MenuProvider>
                </WorkspaceProvider>
            </ProtectedRoute>
        ),
        children: [
            {
                path: '/*',
                element: <RouteError />,
            },
            {
                path: '/directory',
                element: <DirectoryListLayoutWrapper />,
                children: [
                    {
                        path: '/directory/',
                        element: <DirectoryList />
                    },
                    {
                        path: '/directory/view/:_id',
                        element: <DirectoryItemView />
                    },
                ]
            },
            {
                path: '/parts',
                element: <PartsLayoutWrapper />,
                children: [
                    {
                        path: '/parts/',
                        element: <Parts />
                    },
                    {
                        path: '/parts/view/:_id',
                        element: <PartView />
                    },
                ]
            },
            {
                path: '/specifications',
                element: <SpecificationsLayoutWrapper />,
                children: [
                    {
                        path: '/specifications/',
                        element: <Specifications />
                    },
                    {
                        path: '/specifications/view/:_id',
                        element: <SpecificationView />
                    },
                ]
            },
            {
                path: '/dashboard',
                element: <Dashboard />
            },
            {
                path: '/dashboard/import/:_id',
                element: <ProtectedRoute roles={{ workspaceRoles: [WorkspaceRole.Maintainer, WorkspaceRole.Owner] }}><DirectoryImport /></ProtectedRoute>
            },
            {
                path: '/dashboard',
                element: <ProtectedRoute><Dashboard /></ProtectedRoute>
            },
            {
                path: '/administration',
                children: [
                    {
                        path: '/administration/workspaces',
                        element: <ProtectedRoute roles={{ roles: [Role.Admin, Role.SuperAdmin] }} ><Workspaces /></ProtectedRoute>
                    },
                    {
                        path: '/administration/users',
                        element: <ProtectedRoute roles={{ roles: [Role.Admin, Role.SuperAdmin] }} ><Users /></ProtectedRoute>
                    },

                ]
            },
            {
                path: '/account',
                element: <Account />
            },
            {
                path: '/',
                element: <MapProvider><MainMapLayoutWrapper /></MapProvider>,
                children: [
                    {
                        path: '/',
                        element: <MainMap />
                    },
                    {
                        path: '/plan-analysis',
                        element: <PlanAnalysis />
                    },
                    {
                        path: '/view/:_id',
                        element: <DirectoryItemView />
                    }
                ]
            },
        ],
    },
    {
        path: "/auth",
        element: <Portal />,
        children: [
            {
                path: "/auth/",
                element: <Login />,
            },
            {
                path: "/auth/recovery",
                element: <Recovery />,
            },
            {
                path: "/auth/confirm-recovery",
                element: <ConfirmRecovery />,
            },
            {
                path: "/auth/confirm-signup",
                element: <ConfirmSignup />,
            },
            {
                path: "/auth/*",
                element: <Login />,
            },
        ],
    },
])}
/>;

export default App;