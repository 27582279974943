import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { WorkspaceSettingsFormProps } from "..";
import { DirectoryDataWidget } from "../../../models/directory-data";
import { Checkbox } from "../../../sg-react/form";
import Info from "../../../sg-react/ui/Info";
import ValueDescription from "../../../sg-react/ui/ValueDescription";
import { toggleInArray } from "../../../sg-react/utils/objects";

const WorkspaceSettingsWidgets = ({ entity, attachInput, onChange }: WorkspaceSettingsFormProps) => {
    const { t } = useTranslation();

    return (
        <Fragment>
            <Info noIcon i18n="workspaces" label="widgets_workspace_tooltip" />
            <div className="simple-list">
                {Object.entries(DirectoryDataWidget).map(([_, widget]) => (
                    <div key={widget} className="row">
                        <Checkbox id={widget} value={entity.widgets?.includes(widget)} onChange={() => onChange('widgets', toggleInArray(entity.widgets, widget))} />
                        <ValueDescription value={t(`application:widgets.${widget}`)} description={t(`application:widgets.${widget}_tooltip`)} />
                    </div>
                ))}
            </div>
        </Fragment>
    )
}
export default WorkspaceSettingsWidgets;