import { useCallback } from "react";
import { DatePicker, ScrollableList, TextField } from "../../form";
import Validation, { ObjectValidation } from "../../form/Validation/class";
import { useForm } from "../../hooks";
import { ValueDate } from "../../models/data";
import { Modal } from "../../ui";

interface StringValueDateModalProps<T extends string> {
    data?: Partial<ValueDate<T>>;
    title?: string;
    items?: { key: T, label?: string }[];
    i18n?: string;
    onClose: () => void;
    onSubmit: (v: ValueDate<T>) => void;
}

const VALIDATION = new ObjectValidation({
    value: Validation.string().required(),
    date: Validation.date().required()
})

const StringValueDateModal = <T extends string>({ title, i18n, data, items, onClose, onSubmit }: StringValueDateModalProps<T>) => {
    const { attachInput, validate } = useForm<ValueDate<T>>({ date: new Date(), ...data }, VALIDATION);

    const handleSubmit = useCallback(() => validate(onSubmit), [validate, onSubmit]);

    return (
        <Modal
            title={title}
            i18n={i18n}
            size="small"
            onClose={onClose}
            onSubmit={handleSubmit}
        >
            {!items
                ? <TextField label i18n="entity" {...attachInput('value')} />
                : <ScrollableList label i18n="entity" items={items} {...attachInput('value')} />}
            <DatePicker
                label
                i18n="entity"
                {...attachInput('date')}
            />
        </Modal>
    )
}
export default StringValueDateModal;