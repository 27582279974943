import { useCallback } from "react";
import { PART_VALIDATION, Part } from "../../models/part";
import { useRequest } from "../../sg-react/context";
import { NAMED_ENTITY_VALIDATION } from "../../sg-react/models/entity";
import { WizardModal, WizardStep } from "../../sg-react/ui/Wizard";
import PartWizardDefinition from "./PartWizardDefinition";
import PartWizardIdentification from "./PartWizardIdentification";
import PartWizardSegmentation from "./PartWizardSegmentation";
import PartWizardTags from "./PartWizardTags";

const STEPS: WizardStep<Part>[] = [{
    label: 'creation.identification',
    tooltip: 'creation.identification_text',
    i18n: 'parts',
    validation: NAMED_ENTITY_VALIDATION,
    component: PartWizardIdentification,
}, {
    label: 'creation.definition',
    tooltip: 'creation.definition_text',
    i18n: 'parts',
    validation: PART_VALIDATION,
    component: PartWizardDefinition,
}, {
    label: 'creation.tags',
    tooltip: 'creation.tags_text',
    i18n: 'parts',
    component: PartWizardTags,
}, {
    label: 'creation.segmentation',
    tooltip: 'creation.segmentation_text',
    i18n: 'parts',
    component: PartWizardSegmentation,
}];

interface PartWizardProps {
    part?: Partial<Part>;
    onClose: () => void;
    onSubmit?: () => void;
}

const PartWizard = ({ onClose, onSubmit, part }: PartWizardProps) => {
    const request = useRequest();

    const handleSubmit = useCallback((entity: Partial<Part>) => {
        const requestMethod = !entity._id ? request.post : request.put;

        requestMethod<Part>('/parts', entity, {
            loader: true,
            successMessage: true,
            errorMessage: true,
            withWorkspace: true
        })
            .then(() => {
                if (onSubmit) {
                    onSubmit();
                }
                onClose();
            })
            .catch(() => null);
    }, [request, onSubmit, onClose]);

    return (
        <WizardModal<Part>
            i18n="actions"
            title={part?._id ? 'edit' : 'create'}
            steps={STEPS}
            initialMaxStep={part?._id ? 3 : undefined}
            initial={part ?? {}}
            onSubmit={handleSubmit}
            onClose={onClose}
        />
    )
}

export default PartWizard;