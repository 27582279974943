import Axios from "axios";
import { isIsoDateString } from "../utils/date";

const handleDates = (body: any) => {
    if (body === null || body === undefined) return body;

    if (Array.isArray(body)) {
        for (const element of body) {
            handleDates(element);
        }
    } else {
        for (const key of Object.keys(body)) {
            const value = body[key];

            if (isIsoDateString(value)) body[key] = new Date(value);
            else if (typeof value === "object") handleDates(value);
        }
    }
    return body;
};

const AxiosInstance = Axios.create({
    baseURL: process.env.REACT_APP_API_URL ?? "http://localhost:3005",
    timeout: 60000,
    headers: { "Content-Type": "application/json" },
    transformResponse: [
        ...(!Axios.defaults.transformResponse
            ? []
            : Array.isArray(Axios.defaults.transformResponse)
            ? Axios.defaults.transformResponse
            : [Axios.defaults.transformResponse]),
        handleDates,
    ],
});

export default AxiosInstance;
