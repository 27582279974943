import { ReactNode, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { CircleHelpIcon } from '../../icons';
import { Tooltip } from '../../ui';
import { conditionnalClassnames } from '../../utils/helpers';
import { ValidationError } from '../Validation/types';
import './index.scss';

interface InputMessagesProps {
    level: 'error' | 'warning',
    errors?: ValidationError[],
}

const InputMessages = ({ level, errors }: InputMessagesProps) => {
    const { t } = useTranslation();

    const error = useMemo(() => errors?.length
        ? (errors[0].data?.each ? t('validation:ARRAY_EACH') : '') + t('validation:' + errors[0].type, errors[0].data)
        : undefined,
        [errors]);

    return !error ? null : <div className={`input-messages-${level}`}>{error}</div>;
}

interface InputWrapperProps {
    id: string;
    type: string;
    className?: string;
    label?: string | boolean;
    icon?: React.ReactNode;
    onIconClick?: () => void;
    tooltip?: string | boolean;
    i18n?: string;
    inline?: boolean;
    errors?: ValidationError[];
    warnings?: ValidationError[];
    children: ReactNode;
}

export interface FormPropsInterface<T> extends Omit<InputWrapperProps, 'children' | 'type'> {
    disabled?: boolean;
    value?: T;
    onChange?: (v?: T) => void;
}

const InputWrapper = ({
    id,
    type,
    label,
    icon,
    onIconClick,
    className,
    tooltip,
    i18n,
    inline,
    errors,
    warnings,
    children,
}: InputWrapperProps) => {
    const { t } = useTranslation();

    const handleIconClick = useCallback((e: React.MouseEvent<HTMLElement>) => {
        if (!onIconClick) return;

        e.stopPropagation();
        onIconClick();
    }, [onIconClick]);

    return (
        <div
            id={`input-${id}`}
            className={conditionnalClassnames({
                input: true,
                className,
                "input-inline": inline,
                "input-error": errors?.length,
                "input-warning": !errors?.length && !!warnings?.length
            })}
            input-type={type}
        >
            {!!label && (
                <label>
                    {i18n ? t(`${i18n}:${label === true ? id : label}`) : label}
                    {!!tooltip && !inline && <Tooltip tooltip={i18n ? t(`${i18n}:${tooltip === true ? id + '_tooltip' : tooltip}`) : tooltip as string}><CircleHelpIcon /></Tooltip>}
                    {!!tooltip && inline && <span className="input-tooltip">{i18n ? t(`${i18n}:${tooltip === true ? id + '_tooltip' : tooltip}`) : tooltip}</span>}
                </label>
            )}
            <div className="input-field">
                {icon && (
                    <div
                        className={conditionnalClassnames({ "input-icon": true, "input-icon-cursor": !!onIconClick })}
                        onClick={handleIconClick}
                    >
                        {icon}
                    </div>
                )}
                {children}
                {(!!errors?.length || !!warnings?.length) && (
                    <div className="input-messages">
                        <InputMessages errors={errors} level="error" />
                        <InputMessages errors={warnings} level="warning" />
                    </div>
                )}
            </div>
        </div>
    );
}

export default InputWrapper;